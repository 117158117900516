import * as React from 'react';
import { CopyIcon } from '../icons/CopyIcon';
import { renderSpam, setStatePromise, toastSuccess } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

export interface ModalCreateAPIKeyProps {
}

export interface ModalCreateAPIKeyResult {

}

interface ModalCreateAPIKeyState {
  title: string;
}

export class ModalCreateAPIKey
    extends ModalBase<ModalCreateAPIKeyProps, ModalCreateAPIKeyResult,
    ModalCreateAPIKeyState> {
  private subtitle: HTMLDivElement | null = null;
  private sessionDurationInput: HTMLInputElement | null = null;

  constructor(props: ModalCreateAPIKeyProps) {
    super(props);
    this.state = {
      title: 'Add API key'
    };
  }

  public showDialog(): Promise<ModalCreateAPIKeyResult> {
    return setStatePromise<ModalCreateAPIKeyState, 
        ModalCreateAPIKey>(this, {})
    .then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalCreateAPIKey');
    return (
      <Modal 
          ref={this.modal}
          //onOK={() => this.onOK()}
          title={this.state.title}
          confirmButtonContent="Copy Key and Close">

        <p>
          Copy this secret key and send it to the developers 
          of the app you would like to integrate.  API documentation
          can be access 
          at <a href="https://swyched.com/docs/api">swyched.com/docs/api</a>
        </p>
        <div className="payment-integration-token-container">
          <label>API Key</label>
          <span>d358c018ac8b03e89afa8d</span>
          <button className="copy-api-key"
            onClick={(event) => {
              this.copyAPIKey('d358c018ac8b03e89afa8d');
            }}>
            <span>
              <CopyIcon width="18" height="18" fill="#C1C5C8"/>
            </span>
          </button>
        </div>

      </Modal>
    );
  }
  
  public copyAPIKey(key: string): void {
    navigator.clipboard.writeText(key);
    toastSuccess('API key "' + key + '" copied to clipboard');
  }
  private onOK(): Promise<ModalCreateAPIKeyResult> {
    return null;

  }
}