import React, { PureComponent, RefObject } from "react";
import {
  RouteComponentProps, withRouter
} from 'react-router-dom';
import { xl8 } from "../translations/i18n";
import { CustomizedToaster } from "./CustomizedToaster";
import { SwychedLogo } from "./icons/SwychedLogo";
import { AuthService } from "./services/auth.service";

import { CognitoService } from "./services/cognito.service";
import { ConfigService } from "./services/config.service";
import {
  renderSpam, setStatePromise, 
  setStatePromiseAtomic, toastError, toastSuccess, uniqueId
} from "./shared/ui";
import { SwychedSpinner } from "./spinner.component";
// import background from './seamless-background.svg';

export interface SignInRouteProps {
  email: string;
  password: string;
}

export interface SignInProps 
    extends RouteComponentProps<SignInRouteProps> {

}

type UIMode = 'signin' | 'signup' | 'changePass' | 'forgot' | 'verify';

export interface SignInState {
  uiMode: UIMode;

  forcedSignin: boolean;

  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
  staySignedIn: boolean;
  busy: number;

  verifyCallback: (verificationCode: string,
    newPassword: string) => void;

  signinAttempted: boolean;
  signinError: string;
  signupError: string;

  // User returned from signup operation
  signupUser: any;

  //private currentTenant: ITenantInfo;

  forcedPasswordResetCallback: (fname: string,
    lname: string, newPassword: string) => void;
}

class SignInComponent
    extends PureComponent<SignInProps, SignInState> {
  private cognitoService = CognitoService.getInstance();
  private authService = AuthService.getInstance();
  private configService = ConfigService.getInstance();

  private firstNameField: RefObject<HTMLInputElement> = React.createRef();
  private signUpButton: RefObject<HTMLButtonElement> = React.createRef();
  private verifyButton: RefObject<HTMLButtonElement> = React.createRef();
  private signInButton: RefObject<HTMLButtonElement> = React.createRef();
  private forgotButton: RefObject<HTMLAnchorElement> = React.createRef();
  private resetButton: RefObject<HTMLButtonElement> = React.createRef();
  private changePassButton: RefObject<HTMLButtonElement> = React.createRef();

  private requiredFields = {
    signin: [
      'email',
      'password'
    ],
    signup: [
      'firstName',
      'lastName',
      'email',
      'password',
      'confirmPassword'
    ]
  };
  private uniq: string = uniqueId();
  private unmounted: boolean = false;

  constructor(props: SignInProps) {
    super(props);
    this.state = {
      uiMode: 'signin',
      forcedSignin: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
      staySignedIn: false,
      busy: 0,
  
      verifyCallback: null,
    
      signinAttempted: false,
      signinError: '',
      signupError: '',
    
      // User returned from signup operation
      signupUser: null,
    
      forcedPasswordResetCallback: null
    };
  }

  componentDidMount(): void {
    this.applyRouteParams(this.props.match.params);
  }

  componentWillUnmount(): void {
    this.unmounted = true;
  }

  private applyRouteParams(params: SignInRouteProps) {
    let search: { [name: string]: string };
    search = this.props.location.search
        .substring(1).split(/&/).map((param) => {
      return param.split(/=/).map((token) => {
        return decodeURIComponent(token);
      });
    }).reduce((search, pair) => {
      search[pair[0]] = pair[1];
      return search;
    }, {});

    if (search.username && search.password && this.state.uiMode !== 'signup') {
      // /login?username=someemail@somedomain&password=somepassword
      // User followed invitation email link,
      // sign straight in with the temporary password
      // which was provided in the url parameters

      this.forceSignInWithCredentials(search.username, search.password);
    // } else if (params.shareablelink) {
    //   // /#/login;shareablelinkid=xxxxx...
    //   this.storageService.verifyShareableLink(params.shareablelink)
    //   .then((result) => {
    //     if (result) {
    //       this.email = result.email;
    //       this.password = result.password;

    //       if (!result.type)
    //         result.type = params.shareablelinktype;

    //       // // HACK!
    //       // result.type = 'file';
    //       // result.id = '0qdRDjHtHud8kgyQ';

    //       //MW hack - Weak attempt at disabling controls if login is a single
    //       // file share
    //       this.renderer.addClass(document.body, 'single-file-share-ui');

    //       this.forceSignInWithCredentials(
    //         result.email, result.password, result.tenantId,
    //         result.type === 'file' ? result.id : null);
    //     } else {
    //       this.toastService.showError(
    //         'Shareable link could not be validated,' +
    //         ' probably because it has been removed', 'Error');
    //     }
    //   }).catch((err) => {
    //     this.toastService.showError('Unable to perform' +
    //       ' shareable link signin: ' + err, 'Error');
    //   });
    } else {
      this.authService.attemptAutoSignIn();
    }

    setTimeout(() => {
      this.firstNameField.current?.focus();
    }, 1000);
  }

  public render(): JSX.Element {
    renderSpam('SigninComponent');
    return (
      <div className="container-login">
        <div className="">
          <span className="login-logo-container p-b-45">
            <SwychedLogo width="170" height="67"/>
          </span>
          <div className="wrap-login">
            <div className="row">
              <div className="signup-section">
                <div className="h-100 col-height">
                  <div hidden={this.state.forcedSignin} className="card-block"
                    onKeyPress={($event) => this.signInKeypress($event)}>
          
                    <div hidden={this.state.uiMode!=='signin'} 
                        className="form-group">
                      <h2 className="text-center">{xl8('signIntoSwyched')}</h2>
                    </div>

                    <div hidden={this.state.uiMode!=='signup'} 
                        className="form-group">
                      <h2 className="text-center">{xl8('finishRegistering')}</h2>
                    </div>
                    <div hidden={this.state.uiMode!=='forgot'}
                        className="form-group">
                      <h2 className="text-center">{xl8('accountRecovery')}</h2>
                    </div>

                    <div hidden={this.state.uiMode!=='changePass'} 
                        className="form-group">
                      <h2 className="text-center">{xl8('verifyAccount')}</h2>
                    </div>
                    
                    <div hidden={this.state.uiMode!=='changePass'} 
                        className="form-group code-sent-text">
                      {xl8('codeSentTo')} { this.state.email }.
                      {xl8('checkEmailForCode')}
                    </div>
          
                    <div hidden={this.state.uiMode!=='signup' || 
                        !!this.state.forcedPasswordResetCallback}
                      className="form-group">
                      {xl8('createNewPassword')}
                    </div>
          
                    <div hidden={this.state.uiMode!=='signup' || 
                        !this.state.forcedPasswordResetCallback}
                      className="form-group signup-instructions">
                      Your account has already been set up for you,
                      all we need is your name and for you to create 
                      a new password below
                    </div>

                    <div hidden={this.state.uiMode!=='forgot'}
                        className="form-group signup-instructions">
                      {xl8('lostNeedReset')}
                    </div>
                    <label hidden={this.state.uiMode!=='signup'} >
                      {xl8('firstName')}
                    </label>
                    <div hidden={this.state.uiMode!=='signup'} 
                      className="wrap-input validate-input m-b-20">
                        <input type="text" name="firstName"
                          value={this.state.firstName}
                          onChange={(event) => {
                            this.setState({
                              firstName: event.target.value
                            });
                          }}
                          className="input form-control"
                        />
                        <span className="focus-input"></span>
                      <div className="validation-error" 
                          hidden={this.isFieldValid('firstName')}
                          data-validation-msg="firstName">
                       {xl8('nameIsRequiredField')}
                      </div>
                    </div>
                    <label hidden={this.state.uiMode!=='signup'} >
                      {xl8('lastName')}
                    </label>
                    <div hidden={this.state.uiMode!=='signup'} 
                        className="wrap-input validate-input m-b-20">
                        <input type="text" name="lastName"
                            value={this.state.lastName}
                            onChange={(event) => {
                              this.setState({
                                lastName: event.target.value
                              });
                            }}
                              className="input form-control"/>
                        <span className="focus-input"></span>
                      <div className="validation-error" 
                          hidden={this.isFieldValid('lastName')}
                          data-validation-msg="lastName">
                        {xl8('lastNameIsRequiredField')}</div>
                    </div>
                    <label hidden={this.state.uiMode==='verify'} 
                        htmlFor={this.uniq + 'email'}>
                      {xl8('emailAddress')}
                    </label>
                    <div hidden={this.state.uiMode==='verify'} 
                        className="wrap-input validate-input m-b-20">

                        <input type="email" name="email"
                          value={this.state.email}
                          onChange={(event) => {
                            this.setState({
                              email: event.target.value
                            });
                          }}
                          id={this.uniq + 'email'}
                          className="input form-control"/>
                      <span className="focus-input"></span>

                    </div>
                    <div className="validation-error"
                        hidden={this.isFieldValid('email')}
                        data-validation-msg="email">
                        {xl8('validEmailRequired')}
                    </div>
                    <label hidden={this.state.uiMode!=='changePass' && 
                      this.state.uiMode!=='verify'}>
                        {xl8('verificationCode')}
                    </label>
                    <div hidden={this.state.uiMode!=='changePass' && 
                        this.state.uiMode!=='verify'}
                        className="wrap-input validate-input m-b-20">
                        <input type="text" name="verificationCode"
                          value={this.state.verificationCode}
                          onChange={(event) => {
                            this.setState({
                              verificationCode: event.target.value
                            });
                          }}
                          className="input form-control" 
                          />
                        <span className="focus-input"></span>
                      <div className="validation-error" 
                          hidden={this.isFieldValid('password')}
                          data-validation-msg="password">
                        {xl8('verificationCodeRequired')}
                      </div>
                    </div>

                    <div className="forgot-container">
                      <label hidden={this.state.uiMode === 'forgot'}
                          htmlFor={this.uniq + 'password'}>
                        {xl8('password')}
                      </label>

                      <div hidden={this.state.uiMode!=='signin'} 
                        className="flex-sb-m w-full forgot-password">
                        <a href="?forgot"
                            ref={this.forgotButton}
                            onClick={(event) => {
                              this.setUiMode('forgot', event);
                            }}
                            className="forgot-text">
                          {xl8('forgotPassword')}?
                        </a>
                      </div>
                    </div>
                    <div hidden={this.state.uiMode==='forgot' ||
                        this.state.uiMode==='verify'}
                        className="wrap-input validate-input m-b-20">

                      <input type="password" name="password"
                        value={this.state.password}
                        onChange={(event) => {
                          this.setState({
                            password: event.target.value
                          });
                        }}
                        id={this.uniq + 'password'} 
                        className="input form-control" 
                      />
                      <span className="focus-input"></span>
                    </div>
                    <div className="validation-error" 
                        hidden={this.isFieldValid('password')}
                        data-validation-msg="password">
                      {xl8('validPasswordRequired')}
                    </div>
                    <label className="pw-policy" 
                        hidden={this.state.uiMode!=='signup' &&
                          this.state.uiMode!=='changePass'}>
                      {xl8('passwordPolicy')}
                    </label>
                    <label hidden={this.state.uiMode!=='signup'} >
                      {xl8('confirmPassword')}
                    </label>
                    <div hidden={this.state.uiMode!=='signup'} 
                      className="wrap-input validate-input m-b-20">
                        <input type="password" name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={(event) => {
                          this.setState({
                            confirmPassword: event.target.value
                          });
                        }}
                          className="input form-control"
                        />
                        <span className="focus-input"></span>
                      <div className="validation-error"
                          hidden={this.isFieldValid('confirmPassword')}
                          data-validation-msg="confirmPassword">
                        {xl8('passwordsMustMatch')}
                      </div>
                    </div>

                    <div hidden={this.state.uiMode!=='signin'} className="row">
                      <div className="col-8 text-left p-b-22 p-t-5">
                        <label>
                          <input 
                            name="staySignedIn"
                            className="m-r-17 form-check-input"
                            checked={this.state.staySignedIn} 
                            onChange={(event) => {
                              this.setState({
                                staySignedIn: event.target.checked
                              });
                            }}
                            type="checkbox"/>
                          {xl8('keepMeSignedIn')}
                        </label>
                      </div>
                    </div>
                    
                    <div hidden={this.state.uiMode!=='signin'} 
                        className="form-group row">
                      <div className="col-12">
                        <button type="button" name="signin" 
                            ref={this.signInButton}
                            className="btn btn-primary login-form-btn"
                            onClick={(event) => {
                              this.invokeSignInInteractive();
                            }}>
                          {xl8('signIn')}
                          <span className="signin-spinner-container">
                            <SwychedSpinner busy={this.state.busy}/>
                          </span>
                          <i hidden={!!this.state.busy} 
                              className="icon-lock-open"></i>

                        </button>
                      </div>
                    </div>
                    
                    <div hidden={this.state.uiMode!=='verify'} 
                        className="form-group row">
                      <div className="col-12">
                        <button type="button" name="verify"
                            ref={this.verifyButton}
                            className="btn btn-primary login-form-btn" 
                            onClick={(event) => this.invokeVerifySignup()}>
                          {xl8('verifyAccount')}
                          <SwychedSpinner busy={this.state.busy}/>
                          <i hidden={!!this.state.busy} 
                              className="icon-lock-open"></i>
                        </button>
                        <a href="?resendConfirmation"
                            onClick={(event) => {
                              this.resendConfirmationCode(event);
                            }}>
                          {xl8('resendConfirmationCode')}
                        </a>

                        <div className="validation-error" 
                            hidden={!this.state.signupError}
                            data-validation-msg="signupError">
                              {this.state.signupError}
                        </div>
                      </div>
                    </div>
        
                    
                    <div hidden={this.state.uiMode!=='signup'} 
                        className="form-group row">
                      <div className="col-12">
                        <button type="button"
                            ref={this.signUpButton}
                            className="btn btn-primary login-form-btn"
                            onClick={(event) => this.invokeSignUp()}>
                          {xl8('createAccount')}
                          <SwychedSpinner busy={0}/>
                        </button>
                        <div className="validation-error" 
                            hidden={!this.state.signupError}
                            data-validation-msg="signupError">
                          {this.state.signupError}
                        </div>
                      </div>
                    </div>

                    <div className="validation-error server-validation-error" 
                        hidden={!this.state.signinError}
                        data-validation-msg="signinError">
                      {this.state.signinError}
                    </div>
                    

                    <div hidden={this.state.uiMode!=='forgot'} 
                        className="form-group row">
                      <div className="col-12">
                        <button type="button"
                            ref={this.resetButton}
                            className="btn btn-primary login-form-btn"
                            onClick={(event) => this.invokeForgot()}>
                          {xl8('resetPassword')}
                          <SwychedSpinner busy={this.state.busy}/>
                        </button>
                        <div className="validation-error" 
                            hidden={!this.state.signupError}
                            data-validation-msg="signupError">
                          {this.state.signupError}
                        </div>
                      </div>
                    </div>
          
                    <div hidden={this.state.uiMode!=='changePass'}
                        className="form-group row">
                      <div className="col-12">
                        <button 
                            className="btn btn-primary login-form-btn m-t-20"
                            type="button" 
                            ref={this.verifyButton}
                            onClick={(event) => this.invokeVerify()}>
                            {xl8('updatePassword')}
                          <SwychedSpinner busy={this.state.busy}/>
                        </button>
                        <div className="validation-error" 
                            hidden={!this.state.signupError}
                          data-validation-msg="signupError">
                            { this.state.signupError }
                        </div>
                      </div>
                      <a href="?forgot" 
                          onClick={($event) => {
                            this.setUiMode('forgot', $event);
                          }}
                          className="text-center p-t-20">
                        {xl8('getNewVerificationCode')}
                      </a>
                    </div>
                    
                    <div className="divider"></div>
          
                    <div hidden={this.state.uiMode!=='signup' &&
                        this.state.uiMode!=='forgot'}
                        className="text-muted text-center p-t-20">
                      <a onClick={($event) => this.setUiMode('signin', $event)} 
                          href="?normalLogin">
                        {xl8('loginExistingAccount')}
                      </a>
                    </div>
                  </div>
          
                  <div hidden={!this.state.forcedSignin} 
                      className="card-block col-lg-8 mx-auto verifying-credentials">
                    <h5>{xl8('verifyingCredentials')}...</h5>
                      <i className="fa fa-circle-o-notch fa-spin swyched-spinner"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <h5 className="powered-by">Powered By</h5>
          <span className="login-logo-container p-b-30">
            <img src="images/swyched-logo-grey.png"
              className="logo" alt="swyched logo" />     
          </span> */}
          <div className="signin-footer p-t-21">
            <a href="https://swyched.com">© Swyched</a>
            <div className="horizontal-divider">|</div> 
            <a href="https://swyched.com/contact">{xl8('contact')}</a>
          </div>
        </div>
        <CustomizedToaster/>
      </div>
    );
  }

  private forceSignInWithCredentials(email: string, password: string,
      tenantId?: number, fileId?: string): Promise<void> {
    return this.adjBusy(1, {
      forcedSignin: true,
      email: email,
      password: password
    }).then(() => {
      return this.invokeSignIn(tenantId, fileId, true);
    }).catch((err) => {
      console.log('invokeSignIn failed with', err);
      return this.adjBusy(-1, {
        email: '',
        password: '',
        forcedSignin: false
      }).then(() => {
        throw err;
      });
    });
  }


  isFieldValid(key: keyof SignInState): boolean {
    if (!this.state.signinAttempted)
      return true;

    // 4 validation errors detected:
    //   Value at 'password' failed to satisfy constraint: 
    //     Member must satisfy regular expression pattern: [\S]+;
    //     Member must have length greater than or equal to 6;
    //   Value at 'username' failed to satisfy constraint:
    //     Member must satisfy regular expression pattern: [\p{L}\p{M}\p{S}\p{N}\p{P}]+;
    //     Value at 'username' failed to satisfy constraint:
    //     Member must have length greater than or equal to 1

    switch (key) {
    case 'email':
      return /^.+@.+\..+$/.test(this.state.email);
    
    case 'password':
      // Password must be at least 6 characters
      return /^.{6,}$/.test(this.state.password);

    case 'confirmPassword':
      return this.state.password === this.state.confirmPassword;
    
    default:
      return /[a-zA-Z0-9_]/.test(this.state[key]);
    }
  }

  private newPasswordRequiredHandler(
      userAttributes: any, requiredAttributes: any,
      setNewPassword: (password: string) => void): void {
    if (this.unmounted)
      return;
      
    this.setState({
      forcedPasswordResetCallback: (firstName: string, 
          lastName: string, newPassword: string) => {
        userAttributes.given_name = firstName;
        userAttributes.family_name = lastName;
        delete userAttributes.email_verified;
        setNewPassword(newPassword);
      },
      // Clear out the temporary password
      password: ''
    });

    // Go to the signup page. When they click the submit button,
    // the handler will see that forcedPasswordResetCallback is truthy
    // and they will invoke the password reset callback instead of
    // doing the normal thing
    this.setUiMode('signup');
  }

  public invokeSignInInteractive(tenantId?: number, fileId?: string, 
      forceEmail?: boolean): Promise<boolean> {    
    return this.invokeSignIn(tenantId, fileId, forceEmail).then(() => {
      return true;
    }).catch((err) => {
      toastError(err.message);
      return false;
    });
  }

  public invokeSignIn(tenantId?: number, fileId?: string, 
      forceEmail?: boolean): Promise<void> {
    return this.adjBusy(1, {
      signinAttempted: true,
      signinError: ''
    }).then(() => {
      // 2nd parameter is a callback which is called when the backend
      // requires a new password to be set. It returns a Promise<string>
      // which is resolved (eventually) with the new password provided
      // by the user. This case is detected by seeing that 
      // forcedPasswordResetCallback is truthy
      return this.cognitoService.signIn({
        email: forceEmail 
          ? this.state.email 
          : this.state.email.toLocaleLowerCase(),
        password: this.state.password
      }, (userAttributes, requiredAttributes, setNewPassword) => {
        if (this.unmounted)
          return;

        console.log('Requiring new password');
  
        // Allow the user to see the form
        this.setState({
          forcedSignin: false
        });
        
        this.newPasswordRequiredHandler(
          userAttributes, requiredAttributes, setNewPassword);
      }, (badNewPasswordMessage: string) => {
        if (this.unmounted)
          return;

        console.log('New password required failed with message: ',
          badNewPasswordMessage);
        this.setState({
          signinError: badNewPasswordMessage
        });
      });
    }).then((response) => {
      console.log('Authentication success: ', response);
      return Promise.all([
        response,
        setStatePromise<SignInState, SignInComponent>(this, {
          signinError: ''
        })
      ]);
    }).then(([response, _]) => {
      response.staySignedIn = this.state.staySignedIn;
      this.authService.saveAutoSignIn(response);

      return this.authService.signIn(tenantId, fileId)
      .catch((err) => {
        return setStatePromise<SignInState, SignInComponent>(this, {
          signinError: err.message
        }).then(() => {
          throw err;
        });
      });
    }).catch((err) => {
      let stateUpd: Partial<SignInState> = {};
      if (err.code === 'UserNotConfirmedException') {
        // User signed in with an account that is not verified
        // Kick them to the verify UI mode
        this.setUiMode('verify');
      } else {
        console.log('Authentication failed: ', err.message);
        stateUpd.signinError = err.message;
        // this.refresh();
      }
      return this.adjBusy(-1, stateUpd).then(() => {
        throw err;
      });
    }).then(() => {
      return this.adjBusy(-1);
    });
  }

  public signInKeypress($event: React.KeyboardEvent<HTMLDivElement>): void {
    if ($event.key === 'Enter' || $event.key === 'NumpadEnter') {
      switch (this.state.uiMode) {
        case 'signin':
          this.signInButton.current?.click();
          break;
        case 'signup':
          this.signUpButton.current?.click();
          break;
        case 'verify':
          this.verifyButton.current?.click();
          break;
        case 'changePass':
          this.changePassButton.current?.click();
          break;
        case 'forgot':
          this.forgotButton.current?.click();
          break;
      }
    }
  }

  public invokeSignUp(): void {
    // If the user reached here because of a forced password reset
    // from the backend, just invoke the forced password reset
    // callback instead of doing the usual thing. The signup
    // that required the forced password reset will continue its
    // flow and that signin will succeed, instead of doing the
    // signup below.
    if (this.state.forcedPasswordResetCallback) {
      this.state.forcedPasswordResetCallback(
        this.state.firstName, this.state.lastName, this.state.password);
      return;
    }

    if (this.state.password !== this.state.confirmPassword)
      return;

    this.adjBusy(1, {
      signinAttempted: true
    }).then(() => {
      return this.cognitoService.signUp({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password
      });
    }).then((cognitoUser) => {
      console.log('signup succeeded:', cognitoUser);
      cognitoUser.staySignedIn = this.state.staySignedIn;
      return this.adjBusy(-1, {
        signupError: '',
        signupUser: cognitoUser,
      }).then(() => {
        return cognitoUser;
      });
    }).then((cognitoUser) => {
      this.authService.saveAutoSignIn(cognitoUser);
      return this.setUiMode('verify');
    }).catch((err) => {
      console.warn('signup failed:', err);
      this.adjBusy(-1, {
        signupError: err.message        
      });
      console.log('Signup failed: ', err);
    });
  }

  public invokeDeveloperSignIn() {
    this.adjBusy(1, {
      email: this.configService.devSigninUsername,
      password: this.configService.devSigninPassword
    }).then(() => {
      return this.invokeSignIn().catch(() => {
        return this.adjBusy(-1);
      });
    });
  }

  public invokeForgot(sendMail: boolean = true) {
    console.log('requesting password reset for', this.state.email);

    let verifySent: boolean = false;

    let needVerifyCallback: (verifyCallback: (verificationCode: string,
        newPassword: string) => void) => void;
    needVerifyCallback = (verifyCallback: (verificationCode: string,
        newPassword: string) => void) => {
      console.log('invokeForgot wants verification code');
      let cb = function(verificationCode: string, 
          newPassword: string) {
        verifySent = true;
        
        return verifyCallback(verificationCode, newPassword);
      };
      
      // Stop busy indicator while waiting for verification code input
      // and store verify callback in state
      this.adjBusy(-1, {
        verifyCallback: cb,
        verificationCode: '',
        password: '',
        confirmPassword: ''
      });

      this.setUiMode('changePass');
    };

    // Start busy while waiting for forgot password response
    this.adjBusy(1).then(() => {
      return this.cognitoService.forgotPassword(
        this.state.email, needVerifyCallback,
      (passwordRejectedMessage: string) => {
        return setStatePromise<SignInState, SignInComponent>(this, {
          signupError: passwordRejectedMessage
        });
      },
      (err: Error) => {
        this.adjBusy(-1);
      });
    }).then((response) => {
      console.log('password reset successful, response=', response);
      return this.adjBusy(-1);
    }).then(() => {
      return this.invokeSignIn();
    }).catch((err: Error) => {
      this.adjBusy(-1).then(() => {
        console.log('invokeForgot failed');
        if (verifySent) {
          toastError('Reset failed: ' + err.message);
          toastError('Reset failed: ' +
              'Sending new verification email');

          return this.invokeForgot();
        } else {
          this.setUiMode('signin');
        }
      });
    });
  }

  private adjBusy(adj: number, 
      statePartial?: Partial<SignInState>): Promise<void> {
    if (this.unmounted)
        return Promise.resolve();
    if (!statePartial)
        statePartial = {};
    return setStatePromiseAtomic<SignInState, SignInComponent>(this, 
      (prevState: SignInState) => {
        return {
          busy: prevState.busy + adj,
          ...statePartial
        };
      });
  }

  public invokeVerify() {
    console.log('Attempting to verify password reset');
    // The original request that wanted the verify is going to reduce
    // busy on both success and failure
    this.adjBusy(1).then(() => {
      this.state.verifyCallback(this.state.verificationCode, 
          this.state.password);
    });
  }

  public invokeVerifySignup(): Promise<void> {
    console.log('verifying signup');

    return this.adjBusy(1).then(() => {
      return this.cognitoService.confirmRegistration(this.state.email, 
        true, this.state.verificationCode);
    }).then(() => {
      return this.adjBusy(-1);
    }).then(() => {
      return this.invokeSignIn();
    }).catch((err) => {
      return this.adjBusy(-1).then(() => {
        console.warn('verify failed with error:', err);
        return setStatePromise<SignInState, SignInComponent>(this, {
          signupError: err.message
        });
      }).then(() => {
        throw err;
      });
    });

    // this.cognitoService.verifyAttribute(this.signupUser, 
    //   this.email, this.password, 'email', this.verificationCode)
    // .then(() => {
    //   this.invokeSignIn();
    // }).catch((err) => {
    //   this.signupError = err.message;
    // });
  }

  public resendConfirmationCode($event?: React.MouseEvent<HTMLAnchorElement>)
      : Promise<void> {
    if ($event)
      $event.preventDefault();
    
    return setStatePromise<SignInState, SignInComponent>(this, {
      signupError: ''
    }).then(() => {
      return this.adjBusy(1);
    }).then(() => {
      return this.cognitoService.resendConfirmation(this.state.email);
    }).then(() => {
      return this.adjBusy(-1);
    }).then(() => {
      toastSuccess('Resend confirmation: ' +
          'Confirmation code sent');
    }).catch((err) => {
      return this.adjBusy(-1, {
        signupError: err.message
      });
    });
  }

  public resendEmailVerification($event: Event): Promise<void> {
    if ($event)
      $event.preventDefault();
    
    this.setState({
      signupError: ''
    });
    
    return this.cognitoService.verifyAttribute('email', this.state.email);
  }

  private resetValidation(): void {
    this.setState({
      signinError: '',
      signinAttempted: false,
      signupError: ''
    });
  }

  public setUiMode(uiMode: UIMode, 
      event?: React.MouseEvent<HTMLAnchorElement>): Promise<void> {
    if (event)
      event.preventDefault();
    
    this.resetValidation();

    console.assert([
      'signin', 'signup', 'forgot', 'changePass', 'verify'
    ].indexOf(uiMode) >= 0);

    return setStatePromise<SignInState, SignInComponent>(this, {
      uiMode: uiMode
    });
  }
}

export default withRouter(SignInComponent);
