import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface MenuIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class MenuIcon extends PureComponent<MenuIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} fill={this.props.fill || "#555"} viewBox="0 0 24 24">
        <path d="M4 6H20M4 12H20M4 18H20" 
          stroke={this.props.fill || "#175785"} 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth="2.5"/>
      </svg>
    );
  }
}