import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface DisableCardIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class DisableCardIcon extends PureComponent<DisableCardIconProps, {}> {
  render(): ReactNode {
    return (
      <svg height={this.props.height} viewBox="0 0 24 24" width={this.props.width} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 15.5C12.7107 15.5 13.372 15.2882 13.9241 14.9242L9.07584 10.0759C8.71185 10.628 8.5 11.2893 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z" fill={this.props.fill}/>
        <path d="M10.1497 9.02851L14.9715 13.8503C15.3065 13.3135 15.5 12.6793 15.5 12C15.5 10.067 13.933 8.5 12 8.5C11.3207 8.5 10.6865 8.69355 10.1497 9.02851Z" fill={this.props.fill}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" fill={this.props.fill}/>
      </svg>  
    );
  }
}