import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface HonkLogoProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class HonkLogo extends PureComponent<HonkLogoProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 151 202" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.48 40.375H2C0.89543 40.375 0 41.2704 0 42.375V159.625C0 160.73 0.89543 161.625 2 161.625H30.48C31.5846 161.625 32.48 160.73 32.48 159.625V42.375C32.48 41.2704 31.5846 40.375 30.48 40.375Z" fill="#2B2B2B"/>
        <path d="M89.2447 0H60.7647C59.6601 0 58.7647 0.89543 58.7647 2V86.9167C58.7647 88.0212 59.6601 88.9167 60.7647 88.9167H89.2447C90.3493 88.9167 91.2447 88.0212 91.2447 86.9167V2C91.2447 0.89543 90.3493 0 89.2447 0Z" fill="#2B2B2B"/>
        <path d="M89.2353 113.083H60.7647C59.6601 113.083 58.7647 113.979 58.7647 115.083V200C58.7647 201.105 59.6601 202 60.7647 202H89.2353C90.3399 202 91.2353 201.105 91.2353 200V115.083C91.2353 113.979 90.3399 113.083 89.2353 113.083Z" fill="#00C1E0"/>
        <path d="M148.009 40.375H119.529C118.425 40.375 117.529 41.2704 117.529 42.375V159.625C117.529 160.73 118.425 161.625 119.529 161.625H148.009C149.114 161.625 150.009 160.73 150.009 159.625V42.375C150.009 41.2704 149.114 40.375 148.009 40.375Z" fill="#2B2B2B"/>
      </svg>
    );
  }
}