import React from "react";
import { Component, ReactNode } from "react";
import { Dropdown } from "semantic-ui-react";
import { deleteAllNotifications, deleteNotification, 
  Device, getAllSiteDevices, getReceivedNotifications, 
  Organization, Site, SiteDevices
} from "../api";
import { 
  AppNotification, 
  AppNotificationsResponse
} from "../api/notification";
import { 
  addDays, friendlyDate, preventAndStop, 
  setStatePromise, startOfDay, startOfMonth, toastError 
} from "./shared/ui";
import { SwychedSpinner } from "./spinner.component";

import './NotificationPanel.scss';

import { NotificationBatteryFullIcon } from "./icons/NotificationBatteryFullIcon";
import { NotificationWarningIcon } from "./icons/NotificationWarningIcon";
//import { NotificationStartFailedIcon } from "./icons/NotificationStartFailedIcon";
import { NotificationMailReadIcon } from "./icons/NotificationMailReadIcon";
import { NotificationMailUnreadIcon } from "./icons/NotificationMailUnreadIcon";
import { EllipsisIcon } from "./icons/EllipsisIcon";
import { UnfoldMoreIcon } from "./icons/UnfoldMoreIcon";
import { NoUnreadNotificationIcons } from "./icons/NoUnreadNotificationIcons";
import { xl8 } from "../translations/i18n";

export interface NotificationsProps {
  organization: Organization;
  countChanged: (notificationCount: number) => void;
}

interface NotificationsState {
  organization: Organization;
  notifications: AppNotification[] | null;
  notificationCount: number | null;
  newNotificationCount: number | null;
  siteDevices: SiteDevices | null;
  deviceLookup: Map<number, Device>;
  siteLookup: Map<number, Site>;
  notificationsLongWait: boolean;
  showAll: boolean;
  showAllBusy: boolean;
  showHistory: boolean;
  isHistory: boolean;
}

export class Notifications 
    extends Component<NotificationsProps, NotificationsState> {
  private unmounted = false;
  private refreshTimeout: NodeJS.Timer = null;
  private readTimeout: NodeJS.Timer = null;

  constructor(props: NotificationsProps) {
    super(props);
    this.state = {
      organization: props.organization,
      notifications: null,
      notificationCount: null,
      newNotificationCount: null,
      siteDevices: null,
      deviceLookup: null,
      siteLookup: null,
      notificationsLongWait: true,
      showAll: false,
      showAllBusy: false,
      showHistory: false,
      isHistory: false
    };
  }

  componentDidMount(): void {
    if (this.state.organization) {
      this.refreshNotifications();
      this.updateSiteDevices();
    }
  }

  shouldComponentUpdate(
      nextProps: Readonly<NotificationsProps>, 
      nextState: Readonly<NotificationsState>, 
      nextContext: never): boolean {
    return this.props.organization !== nextProps.organization ||
        this.state.notificationCount !== nextState.notificationCount ||
        this.state.newNotificationCount !== nextState.newNotificationCount ||
        this.state.notifications !== nextState.notifications ||
        this.state.organization !== nextState.organization ||
        this.state.siteDevices !== nextState.siteDevices ||
        this.state.siteLookup !== nextState.siteLookup ||
        this.state.deviceLookup !== nextState.deviceLookup ||
        this.state.showAll !== nextState.showAll ||
        this.state.showAllBusy !== nextState.showAllBusy ||
        this.state.showHistory !== nextState.showHistory;
  }

  componentDidUpdate(
      prevProps: Readonly<NotificationsProps>,
      prevState: Readonly<NotificationsState>, 
      snapshot?: never): void {
    if (prevProps.organization !== this.props.organization) {
      //
      // Organization prop changed

      this.setState({
        organization: this.props.organization
      });

      if (!this.props.organization)
        return;
    }

    if (prevState.organization !== this.state.organization && 
        this.state.organization) {
      // Organization changed and have organization
      this.updateSiteDevices();
      this.refreshNotifications();
    } else if (!prevState.showAll && this.state.showAll && 
        this.state.notifications?.length < this.state.notificationCount) {
      this.refreshNotifications();
    } else if (prevState.showHistory !== this.state.showHistory) {
      this.refreshNotifications();
    }

    if (!this.state.isHistory &&
        this.state.newNotificationCount !==
        this.state.notificationCount) {
      this.setState({
        newNotificationCount: this.state.notificationCount
      });
    }
    
    if (prevState.siteDevices !== this.state.siteDevices) {
      // Site data changed, update fast lookup tables
      this.setState({
        deviceLookup: Object.values(this.state.siteDevices.deviceListBySite)
          .reduce((deviceLookup, deviceList) => {
            deviceList.forEach((device) => {
              deviceLookup.set(device.id, device);
            });
            return deviceLookup;
          }, new Map<number, Device>()),
        siteLookup: this.state.siteDevices.sites.reduce((siteLookup, site) => {
          siteLookup.set(site.id, site);
          return siteLookup;
        }, new Map<number, Site>())
      });
    }
    
    // if (prevState.notifications !== this.state.notifications) {
    //   // Notification list changed
    //   if (this.state.notifications && 
    //       this.state.notifications.length !== this.state.notificationCount) {
    //     // Correct the notification count
    //     this.setState({
    //       notificationCount: this.state.notifications.length || null
    //     });
    //   }
    // }

    if (prevState.newNotificationCount !== this.state.newNotificationCount)
      this.props.countChanged(this.state.newNotificationCount);
    
  }

  componentWillUnmount(): void {
    this.unmounted = true;
  }

  private updateSiteDevices() {
    getAllSiteDevices(this.props.organization.id).then((siteDevices) => {
      this.setState({
        siteDevices: siteDevices
      });
    }).catch((err) => {
      toastError(err.message);
    });
  }

  refreshNotifications(): Promise<void> {
    if (this.unmounted)
      return Promise.resolve();
    
    let notificationsPromise: Promise<AppNotificationsResponse | null>;

    if (this.refreshTimeout !== null) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }

    let dismissed = this.state.showHistory;
    if (this.state.organization) {
      let offset: number = 0;
      let limit: number | null = this.state.showAll ? null : 16;
      notificationsPromise = getReceivedNotifications(
        this.state.organization.id, offset, limit, 
        dismissed, (done: boolean) => {
          if (this.unmounted)
            return;
          this.setState((prevState) => {
            let wait = !done;
            if (prevState.notificationsLongWait !== wait) {
              return {
                notificationsLongWait: wait
              };
            }

            return null;
          });            
        });
    } else {
      notificationsPromise = Promise.resolve(null);
    }

    return notificationsPromise.then((notifications) => {
      if (this.unmounted)
        return;
      this.setState({
        notificationCount: notifications?.notificationCount || 0,
        notifications: notifications?.notifications || null,
        isHistory: dismissed
      });
    }).catch((err) => {
      toastError('Notification error: ' + err.message);
      if (this.unmounted)
        return;
      this.setState({
        notificationCount: 0,
        notifications: null,
        isHistory: dismissed
      });
    }).then(() => {
      if (this.unmounted)
        return;
      this.refreshTimeout = setTimeout(() => {
        this.refreshTimeout = null;
        this.refreshNotifications();
      }, 60000);
    });
  }

  render(): ReactNode {
    return (
        <div className="notification-container">
          <Dropdown
              icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>} 
              className="vertical-center clear-all">
            <Dropdown.Menu className="left pointing">
              <Dropdown.Item 
                icon={
                  <NotificationMailUnreadIcon width="16px" height="16px" />
                }
                text={xl8('markAllAsRead')}
                onClick={(event) => {
                  preventAndStop(event);
                  this.allNotificationsRead();
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
          <h2 className="notification-drawer-heading">
            {xl8('notifications')}
          </h2>
          
          <div className="notification-state-controls">
            {
              [false, true].map((isHistoryButton) => {
                return (
                  <button key={'history' + +isHistoryButton}
                      className={'all-notifications-btn' +
                        (!this.state.showHistory === !isHistoryButton 
                          ? ' selected' : '')}
                          
                      onClick={(event) => {
                        preventAndStop(event);
                        this.setState({
                          showHistory: isHistoryButton
                        });
                      }}>
                    {isHistoryButton ? xl8('all') : xl8('unread')}
                  </button>
                );
              })
            }
          </div>
          <div className="notification-container-body">
            {this.renderDrawerContent()}
          </div>
      </div>
    );
   
  }
  
  private allNotificationsRead(): Promise<void> {
    return deleteAllNotifications(this.state.organization.id).then(() => {
      return this.refreshNotifications();
    });
  }

  renderDrawerContent(): ReactNode {

    
    let renderedNotifications: AppNotification[];
    renderedNotifications = this.state.notifications;

    if (!this.state.showAll)
      renderedNotifications = renderedNotifications?.slice(0, 16);
    
    if (!renderedNotifications?.length) {
      return (
        <div className="notification-drawer-empty">
          <div className="">
            <NoUnreadNotificationIcons width="72px" height="72px" />
          </div>
          
          {/* No {this.state.isHistory ? 'old' : 'new'} notifications to display */}
          You’re all caught up!
        </div>
      );
    }

    // Split the list into three groups
    let now = new Date();
    let today = startOfDay(now);
    let yesterday = addDays(today, -1);
    let thisWeek = addDays(today, -6);
    let thisMonth = startOfMonth(today);

    interface GroupBoundary {
      title: string;
      date: Date;
    }

    let groupBoundaries: GroupBoundary[] = [
      {
        title: xl8('today'),
        date: today,
      }, {
        title: xl8('yesterday'),
        date: yesterday
      }, {
        title: xl8('thisWeek'),
        date: thisWeek
      }, {
        title: xl8('thisMonth'),
        date: thisMonth
      }, {
        title: xl8('earlier'),
        date: new Date(0)
      }
    ];

    let groups: AppNotification[][] = Array.from({
      length: groupBoundaries.length
    });
    groups.forEach((_, index, groups) => {
      groups[index] = [];
    });

    renderedNotifications.forEach((notification) => {
      let bucketIndex;
      for (bucketIndex = 0; bucketIndex < groupBoundaries.length;
          ++bucketIndex) {
        if (addDays(notification.sentAt, 0) >= 
            groupBoundaries[bucketIndex].date) {
          break;
        }        
      }

      groups[bucketIndex]?.push(notification);
    });

    let groupElements = groups.map((renderedNotifications, groupIndex) => {
      return {
        groupIndex,
        renderedNotifications
      };
    }).filter((groupInfo) => {
      return groupInfo.renderedNotifications.length > 0;
    }).map((groupInfo, mapIndex, mapList) => {
      let { groupIndex, renderedNotifications } = groupInfo;
      let groupKey = '_' + groupInfo.groupIndex;
      let rows: ReactNode[] = renderedNotifications.map((notification) => {
        let summary = notification.subject; // 'Device Fault'

        let rowKey = groupKey + '_' + notification.id;
        //console.log('Notification rowKey=', rowKey);

        return (
          <React.Fragment key={rowKey}>
            <div className="notification-item">
              <div className="notification-item-inner">
                <span className="blue-indicator-dot" 
                  hidden={this.state.showHistory}></span>
                <span className="notification-item-indicator">
                  {this.iconFromNotificationType(notification)}
                </span>
                <Dropdown
                    icon={<EllipsisIcon width="32" height="32" fill="#555"/>} 
                    className="vertical-center">
                  <Dropdown.Menu className="left pointing">
                    <Dropdown.Item 
                      icon={
                      this.state.showHistory 
                      ? <NotificationMailUnreadIcon width="25px" height="16px" />
                      : <NotificationMailReadIcon width="25px" height="16px" />
                      }
                      text={
                        this.state.showHistory
                        ? xl8('markAsUnread')
                        : xl8('markAsRead')
                      }
                      onClick={(event) => {
                        this.removeNotification(notification.id);
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                
                <p className="notification-item-detail">
                    {summary}&nbsp;at&nbsp;
                    <b>
                      {this.lookupDeviceStationName(
                        notification.sourceEvseId, 
                        notification.sourceConnectorId)}&nbsp; 
                    </b>
                    {/* at&nbsp;
                    <span className="notification-detail-site">
                      {this.state.siteLookup?.get(
                        notification.sourceLocationId)?.name}&nbsp;
                    </span>  */}
                    {notification.body}&nbsp;
                    {/* <span className="notification-more-detail">
                      Learn more
                    </span> */}
                  <span className="notification-item-date-time">
                    {friendlyDate(notification.sentAt)}
                  </span>
                  
                </p>
                {/* <span className="read-indicator-dot vertical-center"
                  onClick={(event) => {
                    this.removeNotification(notification.id);
                  }}>
                </span> */}
              </div>
            </div>
          </React.Fragment>
        );
      });

      let showAllButton = (
        <button type="button"
            style={{
              display: 'block',
              textAlign: 'center',
              width: '100%'
            }}
            key='showAllButton'
            className="show-all-btn"
            onClick={(event) => {
              setStatePromise<NotificationsState, Notifications>(
              this, {
                showAllBusy: true
              }).then(() => {
                return new Promise((resolve, reject) => {
                  setImmediate(() => {
                    try {
                      resolve(setStatePromise<NotificationsState, 
                        Notifications>(this, {
                          showAll: true
                        }));
                    } catch (err) {
                      reject(err);
                    }
                  });
                });
              }).then(() => {
                return setStatePromise<NotificationsState, 
                Notifications>(this, {
                  showAllBusy: false
                });
              });
            }}>
          Show All 
          <span className="show-all">
            <UnfoldMoreIcon width="16" height="16" fill="#aaa" />
          </span> 
          
          <SwychedSpinner busy={+this.state.showAllBusy}/>
        </button>        
      );

      return (
        <React.Fragment key={groupBoundaries[groupIndex].date.getTime()}>
          <div className="notification-timeline-header">
            {groupBoundaries[groupIndex].title}
          </div>
          {rows}
          { !this.state.showAll && mapIndex === 
            (mapList.length - 1) && showAllButton }
        </React.Fragment>
      );
    });

    return groupElements;
  }
  
  private lookupDeviceStationName(
      sourceEvseId: number, sourceConnectorId: number): string {
    let device = this.state.deviceLookup?.get(sourceEvseId);
    if (device?.isHub) {
      let connector = device?.connectors.find((connector) => {
        return connector.portConnectorId === sourceConnectorId;
      });
      if (connector && connector.name)
        return connector.name;
    }

    return device?.name ?? '';
  }

  iconFromNotificationType(notification: AppNotification): ReactNode {
    switch (notification.icon) {
      case "battery_full":
        return <NotificationBatteryFullIcon width="25px" height="25px" />;

      case "warning":
        return <NotificationWarningIcon width="50px" height="50px" />;
      
      // case "charge_start_failed":
      //   return <NotificationStartFailedIcon width="57px" height="57px"/>

      default:
        // Image for unknown type
        return '';
    }
  }
  
  private removeNotification(notificationId: number) {
    deleteNotification(this.state.organization.id, notificationId)
    .then(() => {
      this.setState((prevState) => {
        let newNotifications = prevState.notifications.slice();
        let index = newNotifications.findIndex((notification) => {
          return notification.id === notificationId;
        });
        newNotifications.splice(index, 1);
        return {
          notifications: newNotifications,
          notificationCount: prevState.notificationCount - 1
        };
      });
    }).catch((err) => {
      toastError(err.message);
    });
  }
}
