import React, { Component, ReactNode } from "react";
import { allSiteDevices, Device, Organization, Site, SiteDevices, virtualDeviceName } from "../api";
import { xl8 } from "../translations/i18n";
import { SwychedOption, SwychedSelect, SwychedSelectMode } from "./select.component";
import { hookState } from "./shared/ui";
import './SiteDropdown.css';

export interface SiteDropdownProps {
  siteOnly: boolean;
  organization: Organization;
  siteDevices: SiteDevices;
  hideLabel?: boolean;
  value: Set<number>;
  onGetInitialSelection?: (organization: Organization) => (Set<number> | null);
  asButton?: (onClick) => ReactNode;
  onChange: (selection: Set<number>, organization: Organization) => void;
}

interface SiteDropdownState {
  selectedDevices: Set<number>;
  siteDevices: SiteDevices;
  deviceLookup: Map<number, Device>;
  buttonDropdownOpen: boolean;
}

export class SiteDropdown 
extends Component<SiteDropdownProps, SiteDropdownState> {
  private buttonDropdownRef = React.createRef<HTMLDivElement>();
  constructor(props: SiteDropdownProps) {
    super(props);

    this.state = /*hookState(false, this,*/ {
      selectedDevices: new Set<number>(this.props.value),
      deviceLookup: this.buildDeviceLookup(this.props.siteDevices),
      siteDevices: this.props.siteDevices,
      buttonDropdownOpen: false     
    }/*, 'SiteDropdown')*/;

  }

  componentDidUpdate(
      prevProps: Readonly<SiteDropdownProps>, 
      prevState: Readonly<SiteDropdownState>, 
      snapshot?: never): void {
    if (prevProps.organization !== this.props.organization) {
      this.setState({
        selectedDevices: this.props.onGetInitialSelection?.(
          this.props.organization) ?? new Set<number>()
      });
    }

    if (prevProps.siteDevices !== this.props.siteDevices) {
      this.setState({
        siteDevices: this.props.siteDevices,
        deviceLookup: this.buildDeviceLookup(this.props.siteDevices)
      });
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedDevices: this.props.value
      });
    }

    if (prevState.selectedDevices !== this.state.selectedDevices) {
      console.log('device selection changed', this.state.selectedDevices);
      this.props.onChange(this.state.selectedDevices, this.props.organization);
    }
  }

  render(): ReactNode {
    if (this.props.asButton)
      return this.renderAsButton();

    return (
      <SwychedSelect mode={SwychedSelectMode.CUSTOM}
          label={!this.props.hideLabel && (this.props.siteOnly 
            ? 'For Site'
            : 'For Site / Device')
          }>
        <SwychedOption value="">
          {this.renderSiteSelection(this.props.siteOnly)}
        </SwychedOption>
        <div>
          {this.renderSiteDropdown(this.props.siteOnly)}
        </div>
      </SwychedSelect>
    );
  }
  
  renderAsButton(): ReactNode {

    let button = this.props.asButton(() => {
      this.setState({
        buttonDropdownOpen: true
      });
      this.buttonDropdownRef.current.focus();
    });

    let dropdown = (
      <div ref={this.buttonDropdownRef} tabIndex={-1}
          style={{
            position: 'absolute',
            zIndex: 1000
          }}
          onBlur={(event) => {
            console.log('got blur, target=', event.target, 
              'relatedTarget=', event.relatedTarget);
            if (!this.buttonDropdownRef.current.contains(event.relatedTarget)) {
              this.setState({
                buttonDropdownOpen: false
              });
            }
          }}>
        <div 
          hidden={!this.state.buttonDropdownOpen}
          style={{
            position: 'relative',
            background: 'white',
            border: '1px solid #C1C5C8',
            borderRadius: '3px',
            padding: '12px',
            minWidth: '300px',
            marginTop: '3px'
          }}>
          {this.renderSiteDropdown(this.props.siteOnly)}
        </div>
      </div>
    );

    return (
      <span>
        {button}
        {dropdown}
      </span>
    );
  }

  public renderSiteSelection(siteOnly: boolean): ReactNode {
    let sites = this.allSites();

    // Possible displays:
    //  All devices
    //  All devices at <site>
    //  <deviceName>
    //  <deviceName>, <deviceName>
    //  N devices selected at L locations
    //
    // Not possible:
    //  No devices
    //
    // When no devices are selected, it means all devices,
    // you are not saying which devices, 
    // so you must mean all of them

    let entireSites: Site[] = [];
    let partialSites: Site[] = [];

    sites.forEach((site) => {
      switch (this.isEntireSiteChecked(site)) {
        case 1:
          partialSites.push(site);
          break;
        case 2:
          entireSites.push(site);
          break;
      }
    });

    let allDevices = this.allDevices();
    let allSites = this.allSites();

    if (siteOnly && entireSites.length === 1 && partialSites.length === 0)
      return entireSites[0].name;
    
    if (siteOnly && (entireSites.length === 0 || 
        entireSites.length === allSites.length))
      return xl8('all') + ' ' + allSites.length + ' ' + xl8('sites');
    
    if (this.state.selectedDevices.size === 1) {
      let device = this.getDeviceById(
        Array.from(this.state.selectedDevices)[0]);
      return device.name; // virtualDeviceName(device) || '';
    }

    if (!this.state.selectedDevices.size || 
        this.state.selectedDevices.size === allDevices.length)
      return xl8('all') + ' ' + allDevices.length + ' ' + xl8('devices');
    
    if (entireSites.length === 1 && partialSites.length === 0) {
      let onlySite = entireSites[0];
      let count = this.devicesAtSite(onlySite).length;
      return 'All ' + count + ' devices at ' + onlySite.name;
    }
    
    if (entireSites.length === 0 && partialSites.length === 1) {
      let onlySite = partialSites[0];
      let count = this.state.selectedDevices.size;
      return count + ' devices at ' + onlySite.name;
    }
    
    if (entireSites.length > 1 && partialSites.length === 0) {
      let siteList = entireSites.map((site) => site.name).join(', ');
      if (siteList.length <= 20)
        return 'Sites: ' + siteList;
      return entireSites.length + ' sites';
    }

    return this.state.selectedDevices.size + ' devices';
  }

  allSites(): Site[] {
    return this.state.siteDevices?.sites || [];
  }

  renderSiteDropdown(siteOnly: boolean): ReactNode {
    return (
      <>
        <div>
          <span className="select-list-controls">
            <button type="button"
                onClick={(event) => {
                  console.log('user did nothing!');
                }}>              
            </button> 
            <button type="button"
                onClick={(event) => {
                  this.setAllSitesChecked(true);
                }}>
              {xl8('selectAll')}
            </button> 
            &nbsp;/&nbsp;
            <button type="button"
                onClick={(event) => {
                  this.setAllSitesChecked(false);
                }}>
              {xl8('unselectAll')}
            </button>
          </span>
        </div>
        {this.renderSiteCheckboxes(siteOnly)}
      </>
    );
  }

  setSiteChecked(site: Site, value: boolean): void {
    this.devicesAtSite(site).forEach((device) => {
      this.setDeviceChecked(site, device, value);
    });
  }

  private devicesAtSite(site: Site): Device[] {
    return Object.values(
      this.state.siteDevices?.deviceListBySite[site.id] || {});
  }

  private setAllSitesChecked(value: boolean) {
    this.allSites().forEach((site) => {
      this.setSiteChecked(site, value);
    });
  }

  private isEntireSiteChecked(site: Site): number {
    let deviceList = this.devicesAtSite(site);

    let countChecked = deviceList.reduce((countChecked, device) => {
      return countChecked + 
        Number(this.state.selectedDevices.has(device.id));
    }, 0);
    
    let allChecked = countChecked === deviceList.length && 
      deviceList.length > 0;
    let someChecked = countChecked > 0;

    //console.log('entire', site.name, 'site checked:', allChecked);

    console.assert(typeof allChecked === 'boolean');

    return allChecked ? 2 : someChecked ? 1 : 0;
  }

  private allDevices(): Device[] {
    return allSiteDevices(this.state.siteDevices);
  }
  
  private getDeviceById(deviceId: number): Device {
    return this.state.deviceLookup.get(deviceId);
  }

  private renderSiteCheckboxes(siteOnly: boolean): ReactNode {
    return this.allSites().filter((site) => {
      return this.isAnyDevicesAtSite(site);
    }).map((site) => {
      return (
        <div key={'s_' + site.id} style={{margin: '10px 10px'}}>
          <div>
            <label>
              <input
                type="checkbox"
                className="form-check-input"
                ref={(el) => { 
                  if (el)
                    el.indeterminate = (this.isEntireSiteChecked(site) === 1);
                }}
                checked={!!this.isEntireSiteChecked(site)}
                onChange={(event) => {
                  //event.preventDefault();
                  let isChecked = event.target.checked;
                  console.log('check', site, '=', isChecked);
                  this.setSiteChecked(site, isChecked);
                }}/>
              <span className="select-site-header">
                {site.name}
              </span>
            </label>
          </div>
          {
            !siteOnly && this.devicesAtSite(site).map((device) => {
              return (
                <div key={'d_' + device.id} className=""
                    style={{marginLeft:15}}>
                  <label>
                    <input type="checkbox"
                        className="form-check-input" 
                        onChange={(event) => {
                          //event.preventDefault();
                          // console.log('setting', site.name,
                          //   'to',event.target.checked);
                          this.setDeviceChecked(
                            site, device, event.target.checked);
                        }}
                        checked={!!this.isDeviceChecked(device.id)}
                        />
                    <span style={{marginLeft:6}}>
                      {device.name}
                    </span>
                  </label>
                </div>
              );
            })
          }
        </div>
      );
    });
  }

  private setDeviceChecked(site: Site, device: Device, value: boolean): void {
    //console.log('setting checked', value, 'on', device.id);
    let deviceId = device.id;
    this.setState((prevState) => {
      let wasSelected = prevState.selectedDevices.has(deviceId);

      if (!value && wasSelected) {
        // Remove it
        let updatedSelection = new Set<number>(prevState.selectedDevices);
        updatedSelection.delete(deviceId);
        let result: Partial<SiteDropdownState> = {
          selectedDevices: updatedSelection
        };

        return result as SiteDropdownState;
      } else if (value && !wasSelected) {
        // Add it
        let updatedSelection = new Set<number>(prevState.selectedDevices);
        updatedSelection.add(deviceId);
        let result: Partial<SiteDropdownState> = {
          selectedDevices: updatedSelection
        };

        return result as SiteDropdownState;
      } else {
        // console.log('Nothing happened', deviceId, 
        //   'wasSelected', wasSelected, 
        //   'selected', this.state.selectedDevices);
      }

      return null;
    });
  }

  private isAnyDevicesAtSite(site: Site): boolean {
    return !!this.state.siteDevices?.deviceListBySite[site.id];
  }

  private isDeviceChecked(deviceId: number): boolean {
    let result = this.state.selectedDevices.has(deviceId);
    //console.log('isDeviceChecked', deviceId, '=>', result);
    console.assert(typeof result === 'boolean');
    return result;
  }

  public getSelectedSiteIds(): number[] {
    return this.allSites().filter((site) => {
      return this.isEntireSiteChecked(site) > 0;
    }).map((site) => {
      return site.id;
    });
  }
  
  public getSelectedDeviceIds(): number[] {
    return (!this.state.selectedDevices.size ||
      this.state.selectedDevices.size === this.state.deviceLookup.size)
      ? null
      : Array.from(this.state.selectedDevices);
  }

  private buildDeviceLookup(siteDevices: SiteDevices): Map<number, Device> {
    let newDeviceLookup = new Map<number, Device>();

    Object.values(siteDevices?.deviceListBySite || {})
    .forEach((deviceList) => {
      deviceList.forEach((device) => {
        newDeviceLookup.set(device.id, device);
      });
    });

    return newDeviceLookup;
  }
}

