import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface LeftArrowIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class LeftArrowIcon extends PureComponent<LeftArrowIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7682 6.35984C15.1218 6.78412 15.0644 7.41468 14.6402 7.76825L9.56202 12L14.6402 16.2318C15.0644 16.5854 15.1218 17.2159 14.7682 17.6402C14.4146 18.0645 13.7841 18.1218 13.3598 17.7682L7.35979 12.7682C7.1318 12.5783 6.99998 12.2968 6.99998 12C6.99998 11.7032 7.1318 11.4218 7.35979 11.2318L13.3598 6.2318C13.7841 5.87824 14.4146 5.93556 14.7682 6.35984Z" fill={this.props.fill}/>
      </svg>
      
    );
  }
}