import React, { Component, ReactNode } from 'react';
import toast from 'react-hot-toast';
import { Toast } from 'react-hot-toast/dist/core/types';
import { SiteDevices } from '../../api';
import { xl8 } from '../../translations/i18n';

let lastUniqueIdSequence: number = 0;

export function uniqueId(): string {
  return '-swyunique_' + (++lastUniqueIdSequence).toString(36) + '_';
}

export function leadZero(value: number, len: number): string {
  let text = '' + value;
  while (text.length < len)
    text = '0' + text;
  return text;
}

export function timestampDiffToHours(end: number,
  start: number): number {

  let totalSeconds = end - start;
  let totalMinutes = totalSeconds / 60;
  let totalHours = totalMinutes / 60;

  return totalHours;
}

export function formatUnixDuration(end: number, start: number, 
    precise?: boolean): string {
  let totalSeconds = end - start;
  let n = totalSeconds;
  let seconds = n % 60;
  n /= 60;
  let minutes = Math.floor(n % 60);
  n /= 60;
  let hours = Math.floor(n % 24);
  n /= 24;
  let days = Math.floor(n % 7);
  n /= 7;
  let weeks = Math.floor(n);

  let result: string[] = [];

  if (weeks > 0)
    result.push(weeks + ' w');

  if (days > 0)
    result.push(days + ' d');
  
  if (hours > 0)
    result.push(hours + ' h');

  if (minutes > 0)
    result.push(minutes + ' min');
  
  if (precise && totalSeconds < 60)
    result.push(seconds.toFixed(0) + ' sec');

  if (precise && totalSeconds < 1)
    result.push((seconds * 1000).toFixed(0) + ' ms');

  if (precise && totalSeconds < 1e-3)
    result.push((seconds * 1000000).toFixed(0) + ' μs');

  if (totalSeconds < 0)
    return '';

  return result.join(' ') || '0';

}

export function formatGeneralDate(date: Date | number,
    dateSeparator: string = '-',
    timeSeparator: string = ':',
    dateTimeSeparator: string = ' '): string {
  if (typeof date === 'number')
      date = dateFromUnix(date);
  return [
    [
      leadZero(date.getFullYear(), 4),
      leadZero(date.getMonth() + 1, 2),
      leadZero(date.getDate(), 2)
    ].join(dateSeparator),
    [
      leadZero(date.getHours(), 2),
      leadZero(date.getMinutes(), 2),
      leadZero(date.getSeconds(), 2)
      // + '.' + leadZero(date.getMilliseconds(), 3)
    ].join(timeSeparator)
  ].join(dateTimeSeparator);
}

export function formatFilenameDate(date: Date | number): string {
  return formatSqlDate(date);
}

export function formatReportDate(date: Date | number): string {
  return formatGeneralDate(date, '-', ':', ' ');
}

export function formatSqlDate(date: Date | number): string {
  return formatGeneralDate(date, '-', '-', ' ');
}

// Watch out! if date is a number it is a unix time in seconds, 
// not js millisecond thing
export function formatDate(date: Date | number, 
    withTime: boolean = true,
    withDate: boolean = true): string {
  let result: string[] = [];

  if (typeof date === 'number')
      date = dateFromUnix(date);

  if (date && withDate) {
    result.push([
      leadZero(date.getMonth() + 1, 2),
      leadZero(date.getDate(), 2),
      leadZero(date.getFullYear(), 4)
    ].join('-'));
  }

  if (date && withTime) {
    result.push([
      leadZero(date.getHours(), 2),
      leadZero(date.getMinutes(), 2),
      //leadZero(date.getSeconds(), 2)
    ].join(':'));
  }

  return result.join(' ');
}

  
export function formatUnixDate(scanDate: string | number): string {
  let unixDate = +scanDate * 1000;
  let date = new Date(unixDate);
  return formatDate(date);
}

export function setStatePromise<S, C extends Component>(
    component: C, state: Partial<S>): Promise<void> {
  return new Promise((resolve, _) => {
    component.setState(state, () => {
      resolve();
    });
  });
}

export function setStatePromiseAtomic<S, C extends Component>(
    component: C, callback: (prevState: S) => Partial<S>): Promise<void> {
  return new Promise((resolve, _) => {  
    component.setState((prevState: S) => {
      return callback(prevState);
    }, () => {
      resolve();
    });
  });
}

interface PreventableAndStoppable {
  preventDefault(): void;
  stopPropagation(): void;
}

export function preventAndStop<T extends PreventableAndStoppable>(
    event: T): void {
  event.preventDefault();
  event.stopPropagation();
}

export function minutesAdd(start: number, minutes: number): number {
  return 60000 * minutes + start;
}

export function minutesUntil(start: number, end: number): number {
  return (end - start) / 60000;
}

export function focusAdjacentElement(distance: number = 1,
    active: Element = document.activeElement): boolean {
  if (!active)
    return false;

  let focusableSelector = [
    'a:not([disabled])',
    'button:not([disabled])',
    'input[type=text]:not([disabled])',
    '[tabindex]:not([disabled]):not([tabindex="-1"])'
  ].join(',');

  let focusable: Element[] = Array.prototype.filter.call(
    document.querySelectorAll(focusableSelector), (el) => {
      return el.offsetWidth > 0 || el.offsetHeight > 0 || 
      el === document.activeElement;
    });
    
  if (distance >= focusable.length)
    distance = 1;
  else if (distance < -focusable.length)
    distance = -1;

  let index = focusable.indexOf(active);

  let next: Element = null;

  if (index < 0) {
    // Oh no. We have to lookup everything to see where we are relative
    // to the focusable stuff
    let everything = document.querySelectorAll('*');
    
    // Make a set for fast lookup
    let focusableSet = new Set<Element>(focusable);

    // Find where that is in everything
    let everythingIndex = Array.prototype.indexOf.call(everything, active);

    if (everythingIndex < 0) {
      console.warn('cannot find active element');
      return false;
    }

    let dir: number;
    
    // Find away from the direction we want to tab, so when we are there
    // we can just move to the adjacent focusable element
    if (distance < 0)
      dir = 1;
    else 
      dir = -1;

    // Scan for a focusable element
    index = -1;
    while ((everythingIndex += dir) !== everything.length &&
        everythingIndex >= 0) {
      if (focusableSet.has(everything[everythingIndex])) {
        index = focusable.indexOf(everything[everythingIndex]);
        break;
      }
    }

    // Wrap
    if (index < 0 && dir > 0)
      index = 0;
    else if (index < 0)
      index = focusable.length - 1;
  }

  if (!Number.isNaN(distance))
    index += distance;
  else
    index = 0;

  if (index < 0)
    index += focusable.length;
  
  index = index % focusable.length;

  next = focusable[index];
  
  if (next && 'focus' in next)
    (next as HTMLElement).focus();
  
  return true;
}

export interface DateTransform {
  year?: number;
  month?: number;
  date?: number;
  hour?: number;
  minute?: number;
  second?: number;
  milliseconds?: number;
}

export interface DateTransformAddend {
  add?: DateTransform;
}

// Sets day of week, without changing what week
// If you want next sunday or last sunday then first add or subtract 7*days,
// then call this with that date
export function setDayOfWeek(date: Date | number, dayNumber: number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);

  let adj = dayNumber - date.getDay();
  return addDays(date, adj);
}

export function addDays(date: Date | number, days: number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return transformDate(date, {
    add: {
      date: days
    }
  });
}

export function testFriendlyDate(date: Date | number): boolean {
  let now = new Date();

  return [
    friendlyDate(now, now) === 'Just now'
  ].every((e) => e);
}

export function friendlyDate(date: Date | number, 
    now: Date | number = null): string {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  
  if (now === null)
    now = Date.now();
  else if (typeof now === 'object')
    now = +now;

  let millisecondsAgo = now - date.getTime();
  let secondsAgo = millisecondsAgo / 1000;
  let minutesAgo = secondsAgo / 60;
  let hoursAgo = minutesAgo / 60;
  let daysAgo = hoursAgo / 24;
  let weeksAgo = daysAgo / 7;
  
  let startOfDateDay = startOfDay(date);
  let startOfToday = startOfDay(new Date(now));

  let todayMonthNum = monthNumber(startOfToday);
  let dateMonthNum = monthNumber(startOfDateDay);
  let monthsAgo = todayMonthNum - dateMonthNum;
  let yearsAgo = monthsAgo / 12;

  //let startOfThisMonth = startOfMonth(startOfToday);
  //let startOfLastMonth = addMonths(startOfThisMonth, -1);
  //let startOfNextMonth = addMonths(startOfThisMonth, 1);
  //let endOfNextMonth = addMonths(startOfNextMonth, 1);
  //let startOfTomorrow = addDays(startOfToday, 1);
  //let endOfTomorrow = addDays(startOfTomorrow, 1);
  let startOfYesterday = addDays(startOfToday, -1);
  //let startOfWeek = setDayOfWeek(startOfToday, 0);
  //let startOfLastWeek = addDays(startOfWeek, -7);
  //let startOfNextWeek = addDays(startOfWeek, 7);
  //let endOfNextWeek = addDays(startOfNextWeek, 7);

  let dayText = '';
  if (yearsAgo > 1) {
    dayText = Math.floor(yearsAgo) + ' ' + xl8('yearsAgo');
  } else if (monthsAgo > 1) {
    dayText = Math.floor(monthsAgo) + ' ' + xl8('monthsAgo');
  } else if (weeksAgo > 1) {
    dayText = Math.floor(weeksAgo) + ' ' + xl8('weeksAgo');
  } else if (daysAgo >= 1 && daysAgo < 2) {
    dayText = xl8('yesterday');
  } else if (daysAgo > 1) {
    dayText = Math.floor(daysAgo) + ' ' + xl8('daysAgo');
  } else if (hoursAgo > 1 && hoursAgo < 2) {
    dayText = Math.floor(hoursAgo) + ' ' + xl8('hourAgo');
  } else if (hoursAgo > 1) {
    dayText = Math.floor(hoursAgo) + ' ' + xl8('hoursAgo');
  } else if (minutesAgo > 1) {
    dayText = Math.floor(minutesAgo) + ' ' + xl8('minutesAgo');
  } else if (minutesAgo > -1) {
    dayText = xl8('justNow');
  } else if (date >= startOfYesterday && date < startOfToday) {
    dayText = xl8('yesterday');
  } else if (minutesAgo > -60) {
    dayText = 'In ' + Math.floor(-minutesAgo) + ' minutes';
  } else if (hoursAgo > -24) {
    dayText = 'In ' + Math.floor(-hoursAgo) + ' hours';
  } else if (daysAgo > -7) {
    dayText = 'In ' + Math.floor(-daysAgo) + ' days';
  } else if (weeksAgo > -4) {
    dayText = 'In ' + Math.floor(-daysAgo) + ' days';
  } else if (monthsAgo > -1) {
    dayText = 'In ' + Math.floor(-monthsAgo) + ' months';
  } else {
    dayText = 'In ' + Math.floor(-yearsAgo) + ' ' + xl8('years');
  }


  // if (secondsAgo >= -15 && secondsAgo < 15) {
  //   dayText = 'Just now';
  // } if (date > endOfNextMonth) {
  //   dayText = Math.floor(-monthsAgo) + ' months from now';
  // } else if (date >= endOfNextWeek) {
  //   dayText = Math.floor(-weeksAgo) + ' weeks from now';
  // } else if (date >= endOfTomorrow) {
  //   dayText = Math.floor(-daysAgo) + ' days from now';
  // } else if (date >= startOfTomorrow && date < endOfTomorrow) {
  //   dayText = 'Tomorrow';
  // } else if (date >= startOfToday && date < startOfTomorrow) {
  //   dayText = 'Today';
  // } else if (date >= startOfYesterday && date < startOfToday) {
  //   dayText = 'Yesterday';
  // } else if (date >= startOfLastWeek && date < startOfYesterday) {
  //   dayText = Math.floor(daysAgo) + ' days ago';
  // } else if (date >= startOfLastMonth && date < startOfLastWeek) {
  //   dayText = Math.floor(weeksAgo) + ' weeks ago';
  // } else if (date < startOfLastMonth) {
  //   dayText = monthsAgo + ' months ago';
  // }

  let hour = date.getHours();
  let minutes = date.getMinutes();
  let ampm = 'AM';
  if (hour >= 12)
    ampm = 'PM';
  if (hour > 12)
    hour -= 12;    
  if (hour === 0)
    hour = 12;
  return [
    dayText,
    ', ',
    hour,
    ':',
    leadZero(minutes, 2),
    ampm
  ].join('');
}

export function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}

export function dateFromUnix(unix: number): Date {
  return new Date(unix * 1000);
}

export function addHours(date: Date | number, hours: number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);

  return transformDate(cloneDate(date), {
    add: {
      hour: hours
    }
  });
}

export function format_kW(watts: number | null): string {
    if (typeof watts !== 'number')
        return '';

    return (watts / 1000).toFixed(2);
}

// Null is not the same as anything, even another null
export function isSameDate(a: Date, b: Date): boolean {
  return a && b && a.getTime() === b.getTime();
}

export function addMonths(date: Date | number, months: number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return transformDate(cloneDate(date), {
    add: {
      month: months
    }
  });
}

export function dayNumber(date: Date | number): number {
  if (typeof date === 'number') {
    // unix date, divide by seconds per day
    return Math.floor(date / 86400);
  }

  // js time stamp, divide by ms per day
  return Math.floor(+date / 86400000);
}

export function monthNumber(date: Date | number): number {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return (date.getFullYear() - 1970) * 12 + date.getMonth();
}

export function yearNumber(date: Date | number): number {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return date.getFullYear() - 1970;
}

export function startOfMonth(date: Date | number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return transformDate(cloneDate(date), {
    date: 1
  });
}

export function startOfDay(date: Date | number): Date {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  return transformDate(cloneDate(date), {
    hour: 0,
    minute: 0,
    second: 0,
    milliseconds: 0
  });
}

export function transformDate(from: Date | number, 
    transform: DateTransform & DateTransformAddend): Date {
  if (typeof from === 'number')
    from = dateFromUnix(from);
  
  let result: DateTransform = {
    year: from.getFullYear(),
    month: from.getMonth(),
    date: from.getDate(),
    hour: from.getHours(),
    minute: from.getMinutes(),
    second: from.getSeconds(),
    milliseconds: from.getMilliseconds()
  };

  Object.assign(result, transform);

  if (transform.add) {
    Object.keys(transform.add).forEach((key) => {
      result[key] += transform.add[key];
    });
  }

  return new Date(
    result.year,
    result.month,
    result.date,
    result.hour,
    result.minute,
    result.second,
    result.milliseconds
  );
}

export function getStackTrace(): { stack: string[] } {
  try {
    throw new Error('innocent stack trace exception');
  } catch (err) {
    return { 
      stack: (err as any).stack.split('\n')
    };
  }
}

interface HasStateProperty<T> {
  state: T;
}

export function hookState<C extends HasStateProperty<T>, T>(
    enabled: boolean, target: C, 
    state: T, title: string, spam: boolean = false): T {
  if (!enabled) {
    // Step aside
    target.state = state;
    return state;
  }
  Object.defineProperty(target, 'state', {
    enumerable: true,
    configurable: false,
    get: function() {
      // if (spam)
      //   console.debug('reading state property');
      return state;
    },
    set: function(value) {
      // if (spam)
      //   console.debug('setting state property! intervening');

      let deletedProperties = Object.keys(state).filter((key) => {
        return !Object.prototype.hasOwnProperty.call(value, key);
      });
      let createdProperties = Object.keys(value).filter((key) => {
        return !Object.prototype.hasOwnProperty.call(state, key);
      });
      let modifiedProperties = Object.keys(value).filter((key) => {
        return value[key] !== state[key];
      });

      let message: any = {};

      if (deletedProperties.length)
        message.deleted = deletedProperties.join(', ');

      let reduceAssignments = (partial: Partial<T>, key: string) => {
        partial[key] = value[key];
        return partial;
      };

      if (createdProperties.length) {
        message.created = createdProperties.reduce(
            reduceAssignments, Object.create(null));
      }

      if (modifiedProperties.length) {
        message.modified = modifiedProperties.reduce(
            reduceAssignments, Object.create(null));
      }

      if (Object.keys(message).length) {
        console.debug('intercepted state replacement with changes', 
          message, value, getStackTrace());
      } else if (spam)
        console.debug('intercepted state replacement with no changes',
          message, value, getStackTrace());
      
      state = hookedState(value, title);
    }
  });
  return state;
}

export function hookedState<T>(state: T, 
    title: string, spam: boolean = false): T {
  if (spam)
    console.debug('creating hooked state', getStackTrace());
  let wrapper = Object.create(Object.getPrototypeOf(state));
  Object.keys(state).forEach((key) => {
    Object.defineProperty(wrapper, key, {
      enumerable: true,
      configurable: false,
      get: function() {
        if (spam) {
          console.debug(title, 'reading', key, 
            'got', state[key], getStackTrace());
        }
        return state[key];
      },
      set: function(value) {
        console.debug(title, ' writing key ' + key + ' = ', value,
          getStackTrace());
        state[key] = value;
      }
    });
  });
  return wrapper;
}

export function pathToElement(element: HTMLElement, 
    stop: HTMLElement = null): HTMLElement[] {
  let result = [];
  while (element !== stop) {
    result.push(element);
    element = element.parentElement;
  }
  return result.reverse();
}

export class FocusTrap {
  private attached = false;

  // Pass a callback, which is asked what element to trap focus within
  // The callback can say "don't trap" by returning null
  constructor(private getTrap: () => HTMLElement) {
  }

  private handler = (event: FocusEvent) => {
    if (!event.target)
      return;
    let trap = this.getTrap();
    if (!trap)
      return;
    if (!trap.contains(event.target as HTMLElement))
      focusFirstWithin(trap);
  };

  attach(): void {
    console.assert(!this.attached);
    document.addEventListener('focus', this.handler, true);
    this.attached = true;
  }

  detach(): void {
    console.assert(this.attached);
    document.removeEventListener('focus', this.handler, true);
    this.attached = false;
  }
}

const weakFocusableSelector: string = [
  'button',
  'input',
  'select',
  'input',
  'textarea',

  // Include myself in the result so I can use the
  // next item in the array that isn't also a dialog
  '.modal-frame[role="dialog"]'
].join(',');

const strongFocusableSelector: string = [
  weakFocusableSelector,
  'input:read-only:not([tabindex="-1"])',
  '[href]:not([tabindex="-1"])',
  '[tabindex]:not([tabindex="-1"])',

  // Include myself in the result so I can use the
  // next item in the array that isn't also a dialog
  '.modal-frame[role="dialog"]'
].join(',');

// Returns true on success, false if unable
export function focusFirstWithin(element: HTMLElement | null): boolean {
  if (!element)
    return false;

  let work: [string, boolean][] = [
    [weakFocusableSelector, false],
    [strongFocusableSelector, true]
  ];

  let found: boolean = work.some(([selector, strong]) => {
    let focusable: HTMLElement[] = Array.from(
      element?.querySelectorAll(selector));
    focusable = focusable.filter((candidate: HTMLElement) => {
      if (('disabled' in (candidate as any)) && 
          (candidate as unknown as { disabled: boolean }).disabled)
        return false;
      if (('readOnly' in (candidate as any)) && 
          (candidate as unknown as { readOnly: boolean}).readOnly)
        return false;
      if (candidate.tabIndex < 0)
        return false;

      let path = pathToElement(candidate, element);

      // Filter out nodes with a hidden ancestor
      // Return true if all not bad
      return !path.some((pathElement) => {
        // Return true if it is bad

        if (pathElement.hidden)
          return true;
        
        let style = window.getComputedStyle(pathElement);
        if (style?.visibility === 'hidden')
          return true;

        return false;
      });
    });
    
    // -1 is fine
    let index = focusable.indexOf(element);

    while (++index < focusable.length) {
      if (!focusable[index].classList.contains('.modal-frame'))
        break;
    }

    if (index < focusable.length) {
      focusable[index].focus();
      return true;
    }

    return false;
  });

  return found;
}

export interface GroupedReportColumn {
  name: string;
  // Summable fields are assumed to be right justified, otherwise left
  // align supersedes autodetected alignment
  align?: 'l' | 'r';

  // True if it makes sense to group by this field
  canGroup?: boolean;

  // True if it makes sense to keep running totals of this field
  summable?: boolean;

  // Number of digits after the decimal
  precision?: number; 
}

// Looks like a number if you treat it like one, but you can tell
// it is a subtotal if you look
export class ReportSubtotal {
  public constructor(public value: number = 0) {
  }

  public set(n: number): ReportSubtotal {
    this.value = +n;
    return this;
  }

  public add(n: number): ReportSubtotal {
    this.value += n;
    return this;
  }

  public toString(radix: number = 10): string {
    return this.value.toString(radix);
  }

  public valueOf(): number {
    return this.value;
  }

  public get isSubtotal(): boolean {
    return true;
  }
}

export function injectSubtotals(
    cols: GroupedReportColumn[],
    rows: any[], 
    groups: string[])
    : [resultCols: GroupedReportColumn[], result: any[]] {
  let resultCols: GroupedReportColumn[] = [];
  let result: any[] = [];

  // Quickly return nothing if nothing to do
  // Lets us assume there is at least one row later on
  if (rows.length === 0)
    return [cols, []];

  // Stable-sort the input by groups
  rows = multilevelStableSort(rows, groups);

  // sumCols lists the key of every column where a sum makes sense

  // sumCols is provided by the backend with the report data in the
  // page 0 response
  
  // For every sumCols column, keep track of a sum
  // Each group gets its own copy of the totals list
  // They are all initialized to zeros at the beginning of the
  // report

  // Group -1 is the whole report group.
  // Its sum runs for the whole report, top to bottom
  // Group 0-n are the user specified groups, their sums are reset whenever
  // a new group is entered at that level
  // The sums for that group are emitted when exiting that group

  // Each group has its own copy of everything in the sums array
  // when entering a group, the group gets all zero sums for the sums array
  // when exiting a group, emit the subtotals for that group totals 

  // All the columns where it is sensible to keep running totals
  let summableCols = cols.filter((col) => {
    return col.summable;
  });

  // Make an array of grand totals that correspond 1:1 to summableCols
  let grandTotals: ReportSubtotal[] = summableCols.map((_) => {
    return new ReportSubtotal();
  });

  // Make an array of subtotals, per group
  let groupTotals: number[][] = groups.map((group) => {
    // One zero per summable column
    return summableCols.map((_) => 0);
  });

  // Begin already in the group for the first row
  let firstRow = rows[0];
  let currentGroup: string[] = groups.map((group) => firstRow[group]);

  let index;
  for (index = 0; index <= rows.length; ++index) {
    // Make it as if there is one more row at the end with null everything
    // To make all the groups end naturally
    let row = rows[index];
    row = index < rows.length
      ? cols.reduce((r, col) => {
        r[col.name] = row[col.name] || null;
        return r;
      }, {})
      : cols.reduce((r, col) => {
        r[col.name] = null;
        return r;
      }, {});
    
    // Append detail row
    result.push(row);
    
    // Update the grand totals
    summableCols.forEach((col, index) => {
      grandTotals[index] += row[col.name];
    });

    // Update the group totals
    groupTotals.forEach((totals, index) => {
      // Loop through the summable columns and update total
      summableCols.forEach((col, index) => {
        totals[index] += row[col.name];
      });
    });

    // Scan across the groups, and see if any of them changed
    let changedGroup: number;
    for (changedGroup = 0; changedGroup < groups.length; ++changedGroup) {
      let group = groups[changedGroup];
      let value = row[group];
      // Break out at the highest level group that changed
      if (value !== currentGroup[changedGroup])
        break;
    }

    // Work back from the end of the groups list,
    // emit a totals row for each group
    // and stop after changedGroup totals row has emitted
    // then start each group from there to the end, with the current value
    // of that column, and zeroed sums
    let endedGroupIndex: number;
    for (endedGroupIndex = groups.length - 1; 
        endedGroupIndex >= changedGroup; --endedGroupIndex) {
      let endedGroup: string = groups[endedGroupIndex];
      let endedTotals: number[] = groupTotals[endedGroupIndex];

      // Construct a row with summable fields, but the value is the subtotal
      let subtotal_row = summableCols.reduce((subtotals, col, index) => {
        subtotals[col.name] = new ReportSubtotal(endedTotals[index]);
        // Reset running total for ended group
        endedTotals[index] = 0;
        return subtotals;
      }, {});
      subtotal_row['_tag'] = 'Group ' + endedGroup + ' ended';

      result.push(subtotal_row);
    }
  }

  return [resultCols, result];
}

function multilevelStableSort<T>(rows: T[], groups: string[]): T[] {
  let sortedRows = rows.slice();
  
  // Build a mapping from object to original index
  // to enable the following sort to be a stable sort
  let rowToIndex = new Map<T, number>();
  sortedRows.forEach((row, index) => {
    rowToIndex.set(row, index);
  });

  return sortedRows.sort((lhsRow, rhsRow) => {
    // Look for the first different group and use that difference
    for (let groupIndex = 0; groupIndex < groups.length; ++groupIndex) {
      let group = groups[groupIndex];
      let lhs = lhsRow[group];
      let rhs = rhsRow[group];

      // null is less than everything that isn't null
      if (lhs === null && rhs !== null)
        return -1;
      if (lhs !== null && rhs === null)
        return 1;

      // no type mismatch of number/string/etc vs object null can reach here
      let typeofLhs = typeof lhs;
      let typeofRhs = typeof rhs;

      // How can values in the same column not be the same type?
      console.assert(typeofLhs === typeofRhs);

      // Invoke their valueOf if they are number-like objects
      // Invoke their toString otherwise
      if (typeofLhs === 'object') {
        if (lhs.valueOf !== Object.prototype.valueOf) {
          lhs = +lhs;
          rhs = +rhs;
        } else {
          lhs = lhs.toString();
          rhs = rhs.toString();
        }
      }

      let diff: number;
      switch (typeof lhs) {
        case 'number':
          // Compare as numbers
          diff = lhs - rhs;
          break;

        case 'string':
          // Compare as text
          diff = lhs.localeCompare(rhs);
          break;

        case 'boolean':
          // Convert boolean to 0/1 and compare those numbers
          diff = +lhs - +rhs;
          break;

        default:
          // Throw it at the comparison operator and hope
          diff = lhs < rhs ? -1 : rhs < lhs ? 1 : 0;
          break;
      }

      // We found our answer at first difference
      if (diff !== 0)
        return diff;
    }

    // If it reaches here, all the groups are the same.
    // Make it stable and sort by original order, so if the input is
    // sorted a certain way, the result preserves that as much as possible
    let lhsIndex = rowToIndex.get(lhsRow);
    let rhsIndex = rowToIndex.get(rhsRow);
    return lhsIndex - rhsIndex;
  });
}

export function rowsToCsvDataUrl(rows: string[]): Promise<string> {
  return new Promise<number[]>((resolve, reject) => {
    try {
      let chars: number[] = [];
      for (let r = 0, er = rows.length; r < er; ++r) {
        let row = rows[r];
        for (let i = 0, ei = row.length; i < ei; ++i) {
          let code = row.charCodeAt(i);
          chars.push(code);
        }
        chars.push(10); // 10 is ascii newline as in \n
      }
      resolve(chars);
    } catch (err) {
      reject(err);
    }
  }).then((utf16cps) => {
    let utf8: number[] = [];
    for (let i = 0; i < utf16cps.length; ++i) {
      let hi = utf16cps[i];

      if (hi >= 0xD800 && hi < 0xE000) {
        hi -= 0xD800;
        hi <<= 10;
        let lo = utf16cps[++i];
        lo -= 0xDC00;
        hi += lo;
        hi += 0x10000;
      }

      // hi is ucs32 code unit

      if (hi < 0x80) {
        utf8.push(hi);
      } else if (hi >= 0x10000) {
        utf8.push(0xF0 | (0x07 & (hi >> (6*3))));
        utf8.push(0x80 | (0x3F & (hi >> (6*2))));
        utf8.push(0x80 | (0x3F & (hi >> (6*1))));
        utf8.push(0x80 | (0x3F & (hi >> (6*0))));
      } else if (hi >= 0x800) {
        utf8.push(0xE0 | (0x0F & (hi >> (6*2))));
        utf8.push(0x80 | (0x3F & (hi >> (6*1))));
        utf8.push(0x80 | (0x3F & (hi >> (6*0))));
      } else if (hi >= 0x80) {
        utf8.push(0xC0 | (0x1F & (hi >> (6*1))));
        utf8.push(0x80 | (0x3F & (hi >> (6*0))));
      }
    }

    return utf8;
  }).then((utf8) => {
    let buffer = new Uint8Array(utf8);
    
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (ev) => {
        reject(new Error('Error creating data URL'));
      };
      let blob = new Blob([buffer.buffer], {
        type: 'text/csv;charset=utf-8'
      });
      reader.readAsDataURL(blob);
    });
  });
}

// Resolves null if Notification API is not present
// Otherwise, if permission is granted, resolve true
// Otherwise, if permission is not denied, request permission
export function sendNotification(text: string): Promise<boolean | null> {
  return new Promise<boolean>((resolve, _) => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return resolve(null);
    }

    if (Notification.permission === "granted") {
      new Notification(text);
      return resolve(true);
    }
  
    // Otherwise, we need to ask the user for permission
    if (Notification.permission === 'denied') {
      console.log('Notification permission is denied, notification failed');
      return resolve(false);
    }
    
    Notification.requestPermission((permission) => {
      // If the user accepts, let's create a notification
      if (permission !== 'granted') {
        console.log('Notification permission is denied, notification failed');
        return resolve(false);
      }
      
      new Notification(text);
      resolve(true);      
    });
  });
}

export function blinkyUserSelectable(): void {
  let allElements = document.querySelectorAll('*');
  Array.prototype.forEach.call(allElements, (el: HTMLElement) => {
    if (!('classList' in el))
      return;
    let style = getComputedStyle(el);
    if (style.userSelect === 'none')
      el.classList.add('blinky');
  });
}

export class NotAuthenticatedError extends Error {
  constructor() {
    super('Not authenticated');
  }
}

export function sequentialPromiseMap<T, R>(input: T[], 
    callback: (value: T, index: number, input: T[]) => Promise<R>,
    stopAtFirstError: boolean = false)
    : Promise<R>[] {
  let resultPromises: Promise<R>[] = [];

  input.reduce((sequence, inputItem, inputIndex, input) => {
    let itemPromise: Promise<R>;

    if (sequence) {
      itemPromise = sequence.then(() => {
        return callback(inputItem, inputIndex, input);
      });
    } else {
      itemPromise = callback(inputItem, inputIndex, input);
    }

    resultPromises.push(itemPromise);
    
    return itemPromise.then(noop, 
      stopAtFirstError ? undefined : noop);
  }, null as Promise<void>);

  return resultPromises;
}

export function cleanBlankLines(input: string, delim: string = '\n'): string {
  let isBlank = /^\S+$/;
  return input.split(delim).map((line) => {
    let rt = line.match(/^(.*?)\s*$/);
    line = rt[1];
    if (!isBlank.test(line))
      return line;
    return '';
  }).join(delim);
}

export function smartSort<T>(input: T[], prop: keyof T, dir: number = 1): T[] {
  return smartSortCustom(input, (item: T) => {
    return String(item[prop]);
  }, dir);
}

const charCodeOfZero = '0'.charCodeAt(0);

export function nextTokenAt(input: string, offset: number = 0)
    : [string,boolean,number] {
  let numericToken = false;
  let result = '';

  for ( ; offset < input.length; ++offset) {
    let cc = input.charCodeAt(offset);

    let isDigit = cc >= charCodeOfZero && cc < charCodeOfZero + 10;

    if (!result.length) {
      numericToken = isDigit;
    } else if (isDigit !== numericToken) {
      break;
    }

    result += String.fromCharCode(cc);
  }

  return [result, numericToken, numericToken ? +result : NaN];
}

// Experimental new number aware callback, more efficient I think
export function smartSortCustom2<T>(input: T[],
    callback: (item: T) => string, dir: number = 1): T[] {
  let result = input?.slice();
  result?.sort((a, b): number => {
    let av = callback(a);
    let bv = callback(b);

    if (av === null && bv !== null)
      return -1;
    if (av !== null && bv === null)
      return 0;
    if (av === bv)
      return 0;

    let ai = 0;
    let bi = 0;

    do {
      // Get text fragment, whether it is digits, and the numeric value
      let [at,ad,an] = nextTokenAt(av, ai);
      let [bt,bd,bn] = nextTokenAt(bv, bi);
      
      // Advance by size of text fragment
      ai += at.length;
      bi += bt.length;

      // Digits come before non-digits
      if (ad && !bd)
        return -1;
      if (!ad && bd)
        return 1;
      // If they are both numbers then use sign of difference
      if (ad && bd)
        return Math.sign(bn - an);
      
      // Not numbers, compare as text fragments
      let cmp = at.localeCompare(bt);
      if (cmp !== 0)
        return cmp;
    } while (ai < av.length && bi < bv.length);

    // If a didn't run out of fragments, a is greater
    if (ai < av.length)
      return 1;
    // If b didn't run out of fragments, b is greater
    if (bi < bv.length)
      return -1;

    // Equal
    return 0;
  });
  return result;
}

export function smartSortCustom<T>(input: T[], 
    callback: (item: T) => string, dir: number = 1): T[] {
  // Take each one, read the specified key, casted the value
  // of that property to string with the String constructor.
  // Take each string, make an array of arrays. each inner
  // array is the string broken into string and number fragments
  // with the number fragments actually being numbers, not text
  let tokenized = input.map((item: T) => {
    let text = callback(item);

    // Find all of the numbers
    let numbers: { index: number, length: number }[] = [];
    let re = /\d+/g;

    // Keep looking for another number
    for (;;) {
      let match = re.exec(text);

      // Stop if we didn't find a number
      if (!match)
        break;

      // Remember information about this match
      // (We process them in reverse order later)
      numbers.push({
        index: match.index,
        length: match[0].length
      });
    }

    let output: Array<number | string> = [];

    while (numbers.length) {
      let item = numbers.pop();
      let numberEnd = item.index + item.length;

      let fragment: string;

      // Take the string after the number, if any
      if (numberEnd < text.length) {
        // Extract everything after the number
        fragment = text.substring(numberEnd);
        
        // Uppercase it for case insensitive sort
        fragment = fragment.toLocaleUpperCase();

        output.push(fragment);

        // Remove the text
        text = text.substring(0, numberEnd);
      }
      
      // Take the number text
      fragment = text.substring(item.index, numberEnd);

      // Parse the text into a number and push the number
      output.push(+fragment);

      // Remove the number text
      text = text.substring(0, item.index);
    }

    if (text.length)
      output.push(text.toLocaleUpperCase());
    
    if (output.length > 1)
      output.reverse();

    return output;
  });

  let handles = tokenized.map((tokenList, index) => {
    return {
      tokens: tokenList,
      index: index
    };
  });

  handles.sort((a, b) => {
    for (let i = 0; i < a.tokens.length && i < b.tokens.length; ++i) {
      let toka = a.tokens[i];
      let tokb = b.tokens[i];

      if (toka === tokb)
        continue;

      let typea = typeof toka;
      let typeb = typeof tokb;

      let numa = typea === 'number';
      let numb = typeb === 'number';

      // Compare as numbers
      if (numa && numb)
        return toka < tokb ? -1 : tokb < toka ? 1 : 0;

      // Compare as strings
      if (!numa && !numb)
        return (toka as string).localeCompare(tokb as string);
      
      // Left side is a number and right isn't, left comes first
      if (numa)
        return -dir;
      
      // Right side is a number and left isn't, right comes first
      return dir;
    }

    // It matches up to the end of the shorter one
    // Shorter one comes first
    if (a.tokens.length < b.tokens.length)
      return -dir;
    if (b.tokens.length < a.tokens.length)
      return dir;

    // Same length, all equal. They are equal.

    // Stable sort, lower index comes first
    if (a.index < b.index)
      return -dir;
    if (b.index < a.index)
      return dir;

    return 0;
  });

  // Recreate in sorted order
  let result = handles.map((item) => {
    return input[item.index];
  });

  return result;
}

export function setComponentStateObjectProp<P, C extends Component>(
    component: C, stateProp: keyof C['state'], partial: Partial<P>, 
    unconditional: boolean = false): void {
  component.setState(setStateObjectProp(stateProp, partial, unconditional));
}

export function setStateObjectProp<T, P>(
  stateProp: keyof T, 
  partial: Partial<P>,
  unconditional: boolean = false)
  : (prevState: Readonly<T>) => T | Pick<T, keyof T> {
  return (prevState: Readonly<T>): Pick<T, keyof T> => {
    let oldObject: P = prevState[stateProp] as unknown as P;
    let newObject: P = {
      ...oldObject
    };
    let changed = false;
    Object.keys(partial).forEach((key) => {
      let newValue = partial[key];
      let oldValue = newObject[key];
      if (newValue !== oldValue)
        changed = true;
      
      newObject[key] = partial[key];
    });
    if (!unconditional && !changed)
      return null;
    
    let newState: any = {};
    newState[stateProp] = {
      ...prevState[stateProp]
    };
    newState[stateProp] = newObject;
    return changed ? newState as T : null;
  };
}

export function renderSpam(name: string): void {
  //console.debug(name, 'rendered');
}

export type ToastCallOptions = Partial<Pick<Toast, 
  'id' | 'icon' | 'duration' | 'role' | 
  'ariaLive' | 'className' | 'style' | 'iconTheme'
  >> | undefined;

export function toastSuccess(message: string, 
    options?: ToastCallOptions): string {
  console.log('toast success:', message);
  return toast.success(message, options);
}

export function toastError(message: string, 
    options?: ToastCallOptions): string {
  console.log('toast error:', message);
  return toast.error(message, options);
}

export function getInnerSize(element: HTMLElement): { w: number, h: number } {
  let computed: CSSStyleDeclaration = getComputedStyle(element);
  let paddingW: number = +computed.paddingLeft + +computed.paddingRight;
  let paddingH: number = +computed.paddingTop + +computed.paddingBottom;
  
  return {
    w: element.clientWidth - paddingW,
    h: element.clientHeight - paddingH
  };
}

const hookPreventDefault = false;

if (hookPreventDefault) {
  let oldPreventDefault = Event.prototype.preventDefault;
  Event.prototype.preventDefault = function() {
    console.log('preventDefault at ', getStackTrace());
    return oldPreventDefault.call(this);
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(): void {
}

export function formatSeconds(seconds: number): string {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);      
  let result = (hours ? hours + 'h '
    : '') + minutes + 'm';
  return result;
}

export function renderSiteDevicesOptions(
    siteDevices: SiteDevices): ReactNode[] {
  let sites = siteDevices?.sites ?? [];
  return sites.map((site) => {
    let devices = siteDevices.deviceListBySite[site.id] || [];

    return (
      <React.Fragment key={site.id}>{
        devices.map((device) => {
          return (
            <option key={device.id} value={device.id}>
              {site.name}/{device.name}
            </option>
          );
        })
      }</React.Fragment>                   
    );
  });
}

export function isFieldValid (key): boolean {
  switch (key) {
    case 'email':
      return /^.+@.+\..+$/.test(key.email);
    
    default:
      return /[a-zA-Z0-9_]/.test(key.state[key]);
    }
}

export class Debouncer {
  private timer: NodeJS.Timer | null = null;
  constructor(private ms: number) {
  }

  public cancel(): void {
    this.timer = null;
  }

  public trigger(callback: () => void): void {
    if (this.timer)
      clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.timer) {
        this.timer = null;
        callback();
      }
    }, this.ms);
  }
}

export function generatePassword(len: number = 32, strong: boolean = false) {
  let data = new Uint8Array(len);
  crypto.getRandomValues(data);
  let password = data.reduce((password, value) => {
    if (!strong) {
      value = value % (10 + 26 + 26);
      
      if (value < 10)
        return password + String.fromCharCode(value + 48);
      
      if (value < 10 + 26)
        return password + String.fromCharCode(value - 10 + 97);
      
      return password + String.fromCharCode(value - 10 - 26 + 65);
    }
    return password + String.fromCharCode((value % (127-32)) + 32);
  }, '');
  return password;
}

export const currencyList: string[] = [
  'CAD',
  'USD'
];

export function renderCurrencyOptions(): ReactNode {
  return currencyList.map((currency) => {
    return <option key={currency} value={currency}>
      {currency}
    </option>;
  });
}

export function isDeveloper(): boolean {
  return window.location.href.includes('//localhost:') &&
    !localStorage.getItem('disableDebugging');
}

export function isDemo(): boolean {
  return false;
}

export function formatDaySecondToTimeOfDay(
    secondsFromMidnight: number, use12hr?: boolean, 
    withSec?: boolean): string {
  let hr = Math.floor(secondsFromMidnight / 3600);
  let secFromHr = Math.floor(secondsFromMidnight % 3600);
  let mn = Math.floor(secFromHr / 60);
  let secFromMn = Math.floor(secFromHr % 60);

  let ampm = '';
  if (use12hr) {
    ampm = (hr < 12) ? 'am' : 'pm';
    
    if (hr > 12)
      hr -= 12;
    if (hr == 0)
      hr = 12;
  }

  let sec = withSec ? ':' + leadZero(secFromMn, 2) : '';

  return leadZero(hr, 2) + ':' + leadZero(mn, 2) + sec + ampm;
}
