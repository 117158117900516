import React from "react";
import { CheckIcon } from "../icons/CheckIcon";
import { renderSpam, setStatePromise, toastError } from "../shared/ui";
import { Modal, ModalBase, ModalFactory } from "./Modal";

export interface ModalConfirmProps {
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;  
}

export interface ModalConfirmState extends ModalConfirmProps {
}

export interface ModalConfirmResult {
  ok: boolean;
}

export interface ModalConfirmOptions {
  message?: string;
  title?: string;
  okText?: string;
  confirmButtonIcon?: string;
  cancelText?: string;
  okCallback?: () => boolean | null | Promise<boolean | null>;
}

export class ModalConfirm 
    extends ModalBase<ModalConfirmProps, 
      ModalConfirmResult, ModalConfirmState> {
  private okCallback: () => boolean | null | Promise<boolean | null>;

  constructor(props: ModalConfirmProps) {
    super(props);
    this.state = Object.assign({
      title: 'Confirm',
      message: 'Are you sure',
    }, {
      ...props
    });
  }

  render(): JSX.Element {
    renderSpam('ModalConfirm');
    return (
      <Modal
          ref={this.modal}
          title={this.state.title}
          confirmButtonContent={this.state.okText}
          cancelButtonContent={this.state.cancelText}
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}
          onOK={() => this.onOK()}>
        <div className="last">
          <span>{this.state.message}</span>
        </div>
      </Modal>
    );
  }

  private onOK(): Promise<ModalConfirmResult> {
    if (this.okCallback) {
      let okCallbackPromise: Promise<boolean | null>;
      try {
        let okCallbackResult = this.okCallback();
        okCallbackPromise = Promise.resolve(okCallbackResult);
      } catch (err) {
        okCallbackPromise = Promise.reject(err);
      }
      return okCallbackPromise.then((result) => {
        if (result === null)
          return null;
        return {
          ok: result
        };
      }).catch((err) => {
        toastError(err.message);
        return null;
      });
    }
    console.log('old ModalConfirm call' +
      ' fell through to premature disappearance');
    return Promise.resolve({
      ok: true
    });
  }

  public static showIndirect(options: ModalConfirmOptions)
      : Promise<ModalConfirmResult> {
    return ModalFactory.withDialog(ModalConfirm, (modal) => {    
      return modal.showDialog(options.message, options.title,
          options.okText, options.cancelText, options.okCallback);
    });
  }

  public showDialog(message?: string | ModalConfirmOptions, title?: string, 
      okText?: string, cancelText?: string,
      okCallback?: () => boolean | null | Promise<boolean | null>)
      : Promise<ModalConfirmResult> {
    let stateUpdate: Partial<ModalConfirmState> = {};

    this.okCallback = okCallback;

    if (typeof message === 'object') {
      title = message.title;
      okText = message.okText;
      cancelText = message.cancelText;
      okCallback = message.okCallback;
      message = message.message;
    }

    if (message)
      stateUpdate.message = message;
    
    if (title)
      stateUpdate.title = title;
    
    if (okText)
      stateUpdate.okText = okText;
    
    if (cancelText)
      stateUpdate.cancelText = cancelText;
    
    return setStatePromise<ModalConfirmState, ModalConfirm>(this, stateUpdate)
    .then(() => {
      return this.show();
    });
  }
}
