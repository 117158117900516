import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface HubIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class HubIcon extends PureComponent<HubIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.3167 16.0826C22.9247 16.5601 22.1935 16.5528 21.7136 16.1638C20.7214 15.3596 19.4573 14.8778 18.0807 14.8778C16.6734 14.8778 15.3837 15.3813 14.3819 16.218C13.9071 16.6146 13.1745 16.6319 12.776 16.1586C12.4646 15.7888 12.4662 15.2415 12.8225 14.9147C14.2074 13.6447 16.0535 12.8696 18.0807 12.8696C20.0665 12.8696 21.8785 13.6134 23.2535 14.8376C23.6153 15.1598 23.6242 15.7082 23.3167 16.0826Z" fill={this.props.fill || "#175785"}/>
        <path d="M26.1643 12.6151C25.7878 13.0736 25.098 13.0991 24.641 12.7206C22.86 11.2457 20.5739 10.3593 18.0808 10.3593C15.5366 10.3593 13.2079 11.2824 11.4117 12.8121C10.9595 13.1971 10.2686 13.1807 9.88606 12.7263C9.55566 12.334 9.57506 11.7512 9.96023 11.4124C12.1266 9.50674 14.9687 8.35105 18.0808 8.35105C21.1318 8.35105 23.9233 9.46185 26.0731 11.3011C26.4633 11.6349 26.4903 12.2182 26.1643 12.6151Z" fill={this.props.fill || "#175785"}/>
        <path d="M29.0239 9.13302C28.6542 9.58322 27.981 9.62048 27.5327 9.24847C24.9681 7.12025 21.6738 5.84076 18.0807 5.84076C14.4164 5.84076 11.0629 7.17146 8.47709 9.37598C8.03348 9.75417 7.35934 9.72552 6.98388 9.2796C6.64568 8.87794 6.67396 8.2805 7.07075 7.93661C10.021 5.37973 13.8702 3.83252 18.0807 3.83252C22.2104 3.83252 25.9925 5.32091 28.9198 7.79053C29.3214 8.12937 29.3574 8.72693 29.0239 9.13302Z" fill={this.props.fill || "#175785"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 21C3 20.1716 3.67157 19.5 4.5 19.5H31.5C32.3284 19.5 33 20.1716 33 21V31.5C33 32.3284 32.3284 33 31.5 33H4.5C3.67157 33 3 32.3284 3 31.5V21ZM22.5 26.25C22.5 27.0784 21.8284 27.75 21 27.75C20.1716 27.75 19.5 27.0784 19.5 26.25C19.5 25.4216 20.1716 24.75 21 24.75C21.8284 24.75 22.5 25.4216 22.5 26.25ZM27 27.75C27.8284 27.75 28.5 27.0784 28.5 26.25C28.5 25.4216 27.8284 24.75 27 24.75C26.1716 24.75 25.5 25.4216 25.5 26.25C25.5 27.0784 26.1716 27.75 27 27.75ZM7.5 26.25C7.5 25.4216 8.17157 24.75 9 24.75H15C15.8284 24.75 16.5 25.4216 16.5 26.25C16.5 27.0784 15.8284 27.75 15 27.75H9C8.17157 27.75 7.5 27.0784 7.5 26.25Z" 
          fill={this.props.fill || "#175785"}/>
      </svg>
    );
  }
}