import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NoSitesIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NoSitesIcon extends PureComponent<NoSitesIconProps, {}> {
  render(): ReactNode {
    return (
    <svg width={this.props.width} height={this.props.height}  viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10.5" y="27" width="78" height="58.5" rx="4.5" fill="#175785"/>
      <rect x="9" y="28.5" width="75" height="55.5" rx="3" fill="white" stroke="#80A7C3" strokeWidth="3"/>
      <line x1="10.5" y1="72" x2="82.5" y2="72" stroke="#DCE3EB" strokeWidth="3"/>
      <line x1="66" y1="30" x2="66" y2="82.5" stroke="#DCE3EB" strokeWidth="3"/>
      <rect x="34.5" y="34.5" width="25.5" height="31.5" fill="#DCE3EB"/>
      <rect x="10.5" y="52.5" width="19.5" height="13.5" fill="#DCE3EB"/>
      <rect x="10.5" y="34.5" width="19.5" height="13.5" fill="#DCE3EB"/>
      <rect x="72" y="42" width="10.5" height="24" fill="#DCE3EB"/>
      <rect x="15" y="78" width="45" height="4.5" fill="#DCE3EB"/>
      <path d="M46.5 52.6863C48.9198 41.2465 60 36 60 25.5C60 18.0442 53.9558 12 46.5 12C39.0442 12 33 18.0442 33 25.5C33 36 44.0802 41.2465 46.5 52.6863Z" fill="#175785"/>
      <path d="M48.0275 19.5V25.7298L51.6217 25.7298L44.9725 33.9513V27.7215H41.3784L48.0275 19.5Z" fill="white"/>
    </svg>
    );
  }
}