import React, { PureComponent, ReactNode } from "react";
import { SwychedDateInput } from "./date.component";
import { SwychedOption, SwychedSelect, SwychedSelectMode } from "./select.component";
import {
  addDays, formatDate, isSameDate, 
  renderSpam, startOfDay
} from "./shared/ui";

import { DatePicker, Space } from 'antd';

import './SwychedDateSelect.scss';

import moment from "moment";
import { xl8 } from "../translations/i18n";

interface DatePreset {
  title: string;
  start: Date;
  end: Date;
  lit?: boolean;
}    

export interface SwychedDateSelectProps {
  startDate: Date;
  endDate: Date;
  // undefined means unchanged
  onChange: (start: Date | null | undefined, 
      end: Date | null | undefined) => void;
}

interface SwychedDateSelectState {
  startDate: Date;
  endDate: Date;
}

export class SwychedDateSelect
    extends PureComponent<SwychedDateSelectProps, SwychedDateSelectState> {
  private startDateRef = React.createRef<SwychedDateInput>();
  private dateSelectRef = React.createRef<SwychedSelect>();

  constructor(props: SwychedDateSelectProps) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  public getDisplayNode(): ReactNode {
    let [node] = this.getDisplayInfo();
    return node;
  }

  public getDisplayInfo(): [ReactNode, DatePreset[]] {
    let endBeforeStart = this.state.startDate && this.state.endDate &&
      this.state.startDate.getTime() >
      this.state.endDate.getTime();

    let haveEndDate: boolean = !!this.state.endDate;
    let haveStartDate: boolean = !!this.state.startDate;
    let haveStartAndEnd: boolean = haveStartDate && haveEndDate;

    let now = new Date();    
    let today = startOfDay(now);
    let yesterday = addDays(today, -1);
    let sevenDaysAgo = addDays(today, -7);
    let thirtyDaysAgo = addDays(today, -30); 

    let presets: DatePreset[] = [{
      title: xl8('today'),
      start: today,
      end: today
    }, {
      title: xl8('yesterday'),
      start: yesterday,
      end: yesterday
    }, {
      title: xl8('last7Days'),
      start: sevenDaysAgo,
      end: yesterday
    }, {
      title: xl8('last30Days'),
      start: thirtyDaysAgo,
      end: yesterday
    }, {
      title: xl8('custom'),
      start: null,
      end: null
    }];

    presets.forEach((item) => {
      item.lit = !!(item.start && item.end &&
          this.state.startDate && 
          this.state.startDate.getTime() === item.start.getTime() &&
          this.state.endDate &&
          this.state.endDate.getTime() === item.end.getTime());
    });

    let litPreset = presets.find((preset) => preset.lit);

    // Custom is lit if none else are
    presets[presets.length - 1].lit = !presets.slice(0, -1).some((preset) => {
      return preset.lit;
    });

    let node = (
      endBeforeStart
      // end before start
      ? <span>
          <span className="material-icons-outlined float-l centered-v"
            >error_outline
          </span>
          <span className="float-l centered-v m-l-6">
            End before start
          </span>
        </span>
      : litPreset
      // valid, and date range matches a preset
      ? litPreset.title
      : haveStartAndEnd && isSameDate(
          this.state.startDate, this.state.endDate)
      // valid and start and end are equal
      ? formatDate(this.state.startDate, false) + ' only'
      : haveStartAndEnd
      ? formatDate(this.state.startDate, false) + 
        ' to ' + formatDate(this.state.endDate, false)
      : haveEndDate
      // start missing
      ? <span>
          <span className="material-icons-outlined float-">
            error_outline
          </span>
          <span className="float-l m-l-6">
            Start date is missing
          </span>
        </span>
      : haveStartDate
      // end missing
      ? <span>
          <span className="material-icons-outlined float-l">
            error_outline
          </span>
          <span className="float-l m-l-6">
            End date is missing
          </span>
        </span>
      // everything missing
      : <div>
          <span className="material-icons-outlined float-l swyched-input-error-icon"
              style={{color: '#e23230'}}>
            error_outline
          </span>
          <span className="float-l m-l-6">
            {xl8('select')} {xl8('dateRange')}
          </span>
        </div>
    );

    return [node, presets];
  }

  componentDidUpdate(
      prevProps: SwychedDateSelectProps, 
      prevState: SwychedDateSelectState): void {
    let startChange: Date | null | undefined = undefined;
    let endChange: Date | null | undefined = undefined;

    if (prevProps.startDate !== this.props.startDate &&
        this.props.startDate && 
        this.props.startDate?.getTime() !== this.state.startDate?.getTime()) {
      this.setState({
        startDate: this.props.startDate
      });
    }

    if (prevProps.endDate !== this.props.endDate && 
        this.props.endDate &&
        this.props.endDate?.getTime() !== this.state.endDate?.getTime()) {
      this.setState({
        endDate: this.props.endDate
      });
    }

    if ((!prevState.startDate !== !this.state.startDate) ||
        (prevState.startDate && prevState.startDate.getTime() !==
          this.state.startDate.getTime()))
      startChange = this.state.startDate;

    if ((!prevState.endDate !== !this.state.endDate) ||
        (prevState.endDate && prevState.endDate.getTime() !==
          this.state.endDate.getTime()))
      endChange = this.state.endDate;
    
    if (startChange !== undefined || endChange !== undefined) {
      console.log('Date range changed', startChange, endChange);
      this.props.onChange(startChange, endChange);
    }
  }

  render(): ReactNode {
    renderSpam('SwychedDateSelect');

    let [displayNode, presets] = this.getDisplayInfo();

    return (
      <SwychedSelect mode={SwychedSelectMode.CUSTOM}
          ref={this.dateSelectRef}>
        <SwychedOption value="">
          {displayNode}
        </SwychedOption>
        <div className="swyched-date-controls-container">
          <ul className="swyched-date-select-preset">
            {/* <span className="calendar-date-range-header">
              {xl8('dateRange')}
            </span> */}
          {
            presets.map((info) => {
              return (
                <li key={info.title}
                    role="button"
                    tabIndex={0}
                    className={[
                      'swyched-date-select-preset-item',
                      info.lit ? 'selected' : '',
                    ].filter((s) => !!s).join(' ')}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (info.start && info.end) {
                        this.setState({
                          startDate: info.start,
                          endDate: info.end
                        });
                        this.dateSelectRef.current?.close();
                      } else {
                        this.startDateRef.current?.focus();
                      }
                    }}>
                    {info.title}
                </li>  
              );
            })
          }
          </ul>
          <div className={'swyched-date-select-custom'}>
            <DatePicker.RangePicker 
              value={[
                this.state.startDate ? moment(this.state.startDate) : null, 
                this.state.endDate ? moment(this.state.endDate) : null
              ]}
              onChange={([startMoment, endMoment]) => {
                this.setState({
                  startDate: startMoment.toDate(),
                  endDate: endMoment.toDate()
                });
              }}/>

            {/* <SwychedDateInput 
              ref={this.startDateRef}
              value={this.state.startDate}
              maxValue={this.state.endDate}
              label={"From"}
              lines={false}
              onChange={(newDate) => {
                this.setState({
                  startDate: newDate
                });
              }}
              />
            <SwychedDateInput 
              value={this.state.endDate}
              minValue={this.state.startDate}
              label={"To"}
              onChange={(newDate) => {
                this.setState({
                  endDate: newDate
                });
              }}/> */}
          </div>
        </div>
      </SwychedSelect>
    );
  }
}
