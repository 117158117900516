import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface RemoveUserIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class RemoveUserIcon extends PureComponent<RemoveUserIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" width={this.props.width} height={this.props.height} viewBox="0 0 24 24">
        <g><rect height="24" width="24" fill="none"/></g>
        <g><path fill={this.props.fill} d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M17,10v2h6v-2H17z M2,18v2h16v-2c0-2.66-5.33-4-8-4 S2,15.34,2,18z"/></g>
      </svg>
    );
  }
}