import { getOrganizations } from "../../api";

export class StorageService {
  private static instance: StorageService = null;
  
  public static getInstance(): StorageService {
    if (!StorageService.instance)
    StorageService.instance = new StorageService();
    return StorageService.instance;
  }
  
  public static dropInstance(): void {
    StorageService.instance = null;
  }
  
  static flushCaches(): void {
  }

  getTenants(force: boolean): Promise<ITenantInfo[]> {
    return getOrganizations().then((orgs) => {
      return orgs.map((org) => {
        return {
          tenantid: org.id,
          title: org.name,
          workspace: 'Tenant'
        };
      });
    });
  }
}

export interface ICurrentTenant {
  tenantId: number;
  tenant: ITenantInfo;
  tenants: ITenantInfo[];
}

export interface ITenantInfo {
  tenantid: number;
  title: string;
  workspace: string;
}

// export interface User {
//   id: number;
//   email: string;
//   firstName: string;
//   lastName: string;
// }

/*
CREATE DEFINER=`admin`@`%` PROCEDURE `getUserOrgAccessGraph`(IN user_id INT)
BEGIN
    select p1.*, coalesce(p2.role,p3.role,p4.role) as inheritedRole
    from (
		select j.role, j.appUserId, o.* 
		from joinAppUserOrganization j
        inner join appUser u
			on j.appUserId=u.id 
			and u.id=user_id
        right join `organization` o
			on j.organizationId=o.id
	) p1
	left join (
		select j.role, j.appUserId, o.*
        from joinAppUserOrganization j
        inner join appUser u 
			on j.appUserId=u.id 
			and u.id=user_id 
		right join `organization` o
			on j.organizationId=o.id
	) p2
		on p2.id = p1.parentId 
	left join (
		select j.role, j.appUserId, o.*
        from joinAppUserOrganization j
        inner join appUser u
			on j.appUserId=u.id
			and u.id=user_id
		right join `organization` o
			on j.organizationId=o.id
	) p3
		on p3.id = p2.parentId 
	left join (
		select j.role, j.appUserId, o.*
		from joinAppUserOrganization j
		inner join appUser u
			on j.appUserId=u.id
			and u.id=user_id
		right join `organization` o
			on j.organizationId=o.id
	) p4
		on p4.id = p3.parentId  
	AND user_id in (
		p1.appUserId, 
		p2.appUserId, 
		p3.appUserId, 
		p4.appUserId
	);
END
-- IN root_id INT
-- 	where root_id in (p1.id, p1.parentId, p2.parentId, p3.parentId, p4.parentId )
*/