import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface SearchIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class SearchIcon extends PureComponent<SearchIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3347 15.2489C13.2597 16.0356 11.9341 16.5 10.5 16.5C6.91015 16.5 4 13.5899 4 10C4 6.41015 6.91015 3.5 10.5 3.5C14.0899 3.5 17 6.41015 17 10C17 11.4341 16.5356 12.7597 15.7489 13.8347L20.7071 18.7929C21.0976 19.1834 21.0976 19.8166 20.7071 20.2071C20.3166 20.5976 19.6834 20.5976 19.2929 20.2071L14.3347 15.2489ZM14.9736 10C14.9736 12.4707 12.9707 14.4736 10.5 14.4736C8.02929 14.4736 6.02639 12.4707 6.02639 10C6.02639 7.52929 8.02929 5.52639 10.5 5.52639C12.9707 5.52639 14.9736 7.52929 14.9736 10Z" fill={this.props.fill}/>
      </svg> 
    );
  }
}