import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface DeviceIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class DeviceIcon extends PureComponent<DeviceIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height}  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0141 4.09893C13.3105 3.73247 13.9029 3.94203 13.9029 4.41334V10.2596C13.9029 10.5357 14.1268 10.7596 14.4029 10.7596L17.3322 10.7596C17.7527 10.7596 17.9854 11.247 17.721 11.574L10.9865 19.901C10.6901 20.2675 10.0977 20.0579 10.0977 19.5866V13.7404C10.0977 13.4643 9.87385 13.2404 9.59771 13.2404L6.66842 13.2404C6.24793 13.2404 6.01524 12.7529 6.27965 12.426L13.0141 4.09893Z" fill={this.props.fill || '#555'}/>
      </svg>
    );
  }
}