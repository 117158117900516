import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface AlertIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class AlertIcon extends PureComponent<AlertIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM10.9998 8.00001C10.9998 7.44773 11.4475 7.00001 11.9998 7.00001C12.5521 7.00001 12.9998 7.44773 12.9998 8.00001V12C12.9998 12.5523 12.5521 13 11.9998 13C11.4475 13 10.9998 12.5523 10.9998 12V8.00001ZM13.2499 15.75C13.2499 16.4404 12.6903 17 11.9999 17C11.3096 17 10.7499 16.4404 10.7499 15.75C10.7499 15.0597 11.3096 14.5 11.9999 14.5C12.6903 14.5 13.2499 15.0597 13.2499 15.75Z" fill={this.props.fill}/>
      </svg>    
    );
  }
}