import * as React from 'react';
import { AccessControlPlan,
  AccessControlPlanGroup, AccessControlPlansResponse,
  upsertAccessControlPlan } from '../../api';
import { AccessControlGroupDropdown } from '../AccessControlGroupDropdown';
import { renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

export interface ModalEditAccessControlPlanProps {
}

export interface ModalEditAccessControlPlanResult {
  planId: number;
}

interface ModalEditAccessControlPlanState {
  
}

export class ModalEditAccessControlPlan
    extends ModalBase<ModalEditAccessControlPlanProps,
     ModalEditAccessControlPlanResult,
     ModalEditAccessControlPlanState> {
  private accessControlPlans: AccessControlPlansResponse = null;
  private planId: number = 0;
  private groupMemberships: AccessControlPlanGroup[] = [];
  private plan: AccessControlPlan;
  private organizationId: number;
  private lastNewPlanId: number = 0;

  public showDialog(organizationId: number,
      accessControlPlans: AccessControlPlansResponse, 
      accessControlPlan: AccessControlPlan)
      : Promise<ModalEditAccessControlPlanResult> {
    this.organizationId = organizationId;
    this.accessControlPlans = accessControlPlans;
    
    if (accessControlPlan) {
      this.planId = accessControlPlan.id;
      this.plan = {...accessControlPlan};
      let memberships = accessControlPlans.planGroups[this.planId] || [];
      this.groupMemberships = memberships.map((orig) => {
        return {...orig};
      });
    } else {
      this.planId = 0;
      this.plan = {
        id: 0,
        organizationId: organizationId,
        name: '',
        accessMethod: 'rfidcard'
      };
      this.groupMemberships = [];
    }

    return setStatePromise<ModalEditAccessControlPlanState, 
        ModalEditAccessControlPlan>(this, {}).then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalEditAccessControlPlan');
    return (
      <Modal 
          ref={this.modal}
          onOK={() => this.onOK()}
          title="Create Access Control Plan"
          confirmButtonContent="Create Plan">
        <div>
          <span>Name</span>
          <input className="form-control" 
            defaultValue={this.plan && this.plan.name}
            onChange={(event) => this.plan.name = event.target.value}
            name="name"/>
        </div>
        <form>
          <label>Access Method</label>
          {/*
          <div className="form-check">
            <label>
              <input
                type="radio"
                name="Access Method"
                value="Fleet App"
                defaultChecked={this.plan &&
                    this.plan.accessMethod === "Fleet App"}
                onChange={(event) => {
                  this.plan.accessMethod = event.target.value
                }}
                className="form-check-input"/>
              Fleet App
            </label>
          </div>
          */}

          <div className="form-check">
            <label>
              <input
                type="radio"
                name="Access Method"
                value="rfidcard"
                defaultChecked={this.plan && 
                  this.plan.accessMethod === "rfidcard"}
                onChange={(event) => {
                  this.plan.accessMethod = event.target.value;
                }}
                className="form-check-input"
              />
              RFID Card
            </label>
          </div>

          <div>{this.planGroupSelects()}</div>

          <button className="btn btn-primary add-group-membership"
              type="button"
              onClick={(event) => this.addGroupMembership(event)}
              disabled={this.canAddMembership()}>
             Add Group Membership
          </button>
        </form>
      </Modal>
    );
  }

  addGroupMembership(event: React.MouseEvent<HTMLButtonElement>): void {
    this.groupMemberships = this.onlySetMemberships();
    this.groupMemberships.push({
      id: --this.lastNewPlanId,
      acpId: this.planId,
      acpgId: 0
    });
    this.setState({});
  }

  private planGroupSelects(): React.ReactNode {
    return this.groupMemberships.map((planGroup) => {
      return (
        <div key={planGroup.id}>
          <AccessControlGroupDropdown
            groups={this.accessControlPlans.groups}
            organizationId={this.organizationId}
            value={planGroup.acpgId}
            onChange={(acpgId) => {
              planGroup.acpgId = acpgId;
            }}/>          
          {/*
            <select
                className="form-select group-form-select"
                defaultValue={planGroup.acpgId}
                onChange={(event) => {
                  planGroup.acpgId = +event.target.value;
                this.setState({});
                }}>
              <option value=""></option>
              {this.planGroupSelectOptions(planGroup.acpgId)}
            </select>
            <button className="btn btn-primary remove-group-btn"
                type="button" 
                onClick={(event) => this.deleteGroupMembership(planGroup.id)}>
              <i className="material-icons without-text">close</i>
            </button>
          */}
        </div>
      );
    });
  }

  private deleteGroupMembership(id: number): void {
    let index = this.groupMemberships.findIndex((planGroup) => {
      return planGroup.id === id;
    });

    this.groupMemberships.splice(index, 1);
    this.setState({});
  }

  // Return all of the group membership options that have not been added
  // but force the specified group id into the option list
  private planGroupSelectOptions(forcePresent: number): React.ReactNode {
    if (!this.accessControlPlans)
      return <></>;

    return Object.values(this.accessControlPlans.groups).filter((group) => {
      if (group.id === forcePresent)
        return true;
      
      let used = this.groupMemberships.some((planGroup) => {
        return planGroup.acpgId === group.id;
      });

      return !used;
    }).map((group) => {
      return (
        <option key={group.id} value={group.id}>
          {group.name}
        </option>
      );
    });
  }

  private onlySetMemberships(): AccessControlPlanGroup[] {
    return this.groupMemberships.filter((membership) => {
      return membership.acpgId > 0;
    });
  }

  private canAddMembership(): boolean {
    let setMemberships = this.onlySetMemberships();
    return !this.accessControlPlans ||
      (setMemberships.length >= 
      Object.values(this.accessControlPlans.groups).length);
  }
  
  private onOK(): Promise<ModalEditAccessControlPlanResult> {
    // if (!/\S/.test(this.nameInput.value))
    //   return Promise.resolve(null);

    this.groupMemberships = this.onlySetMemberships();
    this.setState({});
    
    return upsertAccessControlPlan(this.plan, this.groupMemberships, [])
    .then((planId) => {
      return {
        planId: planId
      };        
    });
  }
}
