import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface CCS1IconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class CCS1Icon extends PureComponent<CCS1IconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 16.5C18.5 16.7761 18.2761 17 18 17C17.7239 17 17.5 16.7761 17.5 16.5C17.5 16.2239 17.7239 16 18 16C18.2761 16 18.5 16.2239 18.5 16.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.5 16.5C20.5 17.8807 19.3807 19 18 19C16.6193 19 15.5 17.8807 15.5 16.5C15.5 15.1193 16.6193 14 18 14C19.3807 14 20.5 15.1193 20.5 16.5ZM19.5 16.5C19.5 17.3284 18.8284 18 18 18C17.1716 18 16.5 17.3284 16.5 16.5C16.5 15.6716 17.1716 15 18 15C18.8284 15 19.5 15.6716 19.5 16.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M15 8.5C15 8.77614 14.7761 9 14.5 9C14.2239 9 14 8.77614 14 8.5C14 8.22386 14.2239 8 14.5 8C14.7761 8 15 8.22386 15 8.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 11C15.8807 11 17 9.88071 17 8.5C17 7.11929 15.8807 6 14.5 6C13.1193 6 12 7.11929 12 8.5C12 9.88071 13.1193 11 14.5 11ZM14.5 10C15.3284 10 16 9.32843 16 8.5C16 7.67157 15.3284 7 14.5 7C13.6716 7 13 7.67157 13 8.5C13 9.32843 13.6716 10 14.5 10Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M21.5 9C21.7761 9 22 8.77614 22 8.5C22 8.22386 21.7761 8 21.5 8C21.2239 8 21 8.22386 21 8.5C21 8.77614 21.2239 9 21.5 9Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24 8.5C24 9.88071 22.8807 11 21.5 11C20.1193 11 19 9.88071 19 8.5C19 7.11929 20.1193 6 21.5 6C22.8807 6 24 7.11929 24 8.5ZM23 8.5C23 9.32843 22.3284 10 21.5 10C20.6716 10 20 9.32843 20 8.5C20 7.67157 20.6716 7 21.5 7C22.3284 7 23 7.67157 23 8.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M23 14.5C23.2761 14.5 23.5 14.2761 23.5 14C23.5 13.7239 23.2761 13.5 23 13.5C22.7239 13.5 22.5 13.7239 22.5 14C22.5 14.2761 22.7239 14.5 23 14.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23 16C24.1046 16 25 15.1046 25 14C25 12.8954 24.1046 12 23 12C21.8954 12 21 12.8954 21 14C21 15.1046 21.8954 16 23 16ZM23 15C23.5523 15 24 14.5523 24 14C24 13.4477 23.5523 13 23 13C22.4477 13 22 13.4477 22 14C22 14.5523 22.4477 15 23 15Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M13.5 14C13.5 14.2761 13.2761 14.5 13 14.5C12.7239 14.5 12.5 14.2761 12.5 14C12.5 13.7239 12.7239 13.5 13 13.5C13.2761 13.5 13.5 13.7239 13.5 14Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 14C15 15.1046 14.1046 16 13 16C11.8954 16 11 15.1046 11 14C11 12.8954 11.8954 12 13 12C14.1046 12 15 12.8954 15 14ZM14 14C14 14.5523 13.5523 15 13 15C12.4477 15 12 14.5523 12 14C12 13.4477 12.4477 13 13 13C13.5523 13 14 13.4477 14 14Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27 12C27 16.9706 22.9706 21 18 21C13.0294 21 9 16.9706 9 12C9 7.02944 13.0294 3 18 3C22.9706 3 27 7.02944 27 12ZM26 12C26 16.4183 22.4183 20 18 20C13.5817 20 10 16.4183 10 12C10 7.58172 13.5817 4 18 4C22.4183 4 26 7.58172 26 12Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M15 27.5C15 28.3284 14.3284 29 13.5 29C12.6716 29 12 28.3284 12 27.5C12 26.6716 12.6716 26 13.5 26C14.3284 26 15 26.6716 15 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.5 31C15.433 31 17 29.433 17 27.5C17 25.567 15.433 24 13.5 24C11.567 24 10 25.567 10 27.5C10 29.433 11.567 31 13.5 31ZM13.5 30C14.8807 30 16 28.8807 16 27.5C16 26.1193 14.8807 25 13.5 25C12.1193 25 11 26.1193 11 27.5C11 28.8807 12.1193 30 13.5 30Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M22.5 29C23.3284 29 24 28.3284 24 27.5C24 26.6716 23.3284 26 22.5 26C21.6716 26 21 26.6716 21 27.5C21 28.3284 21.6716 29 22.5 29Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26 27.5C26 29.433 24.433 31 22.5 31C20.567 31 19 29.433 19 27.5C19 25.567 20.567 24 22.5 24C24.433 24 26 25.567 26 27.5ZM25 27.5C25 28.8807 23.8807 30 22.5 30C21.1193 30 20 28.8807 20 27.5C20 26.1193 21.1193 25 22.5 25C23.8807 25 25 26.1193 25 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 27.5C7 24.4624 9.46243 22 12.5 22H23.5C26.5376 22 29 24.4624 29 27.5C29 30.5376 26.5376 33 23.5 33H12.5C9.46243 33 7 30.5376 7 27.5ZM8 27.5C8 25.0147 10.0147 23 12.5 23H23.5C25.9853 23 28 25.0147 28 27.5C28 29.9853 25.9853 32 23.5 32H12.5C10.0147 32 8 29.9853 8 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
      </svg>
    );
  }
}