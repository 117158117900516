import * as React from 'react';
import { xl8 } from '../../translations/i18n';
import { SearchIcon } from '../icons/SearchIcon';
import { renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

export interface ModalIntegrationUsageProps {
}

export interface ModalIntegrationUsageResult {

}

interface ModalIntegrationUsageState {
  title: string;
}

export class ModalIntegrationUsage
    extends ModalBase<ModalIntegrationUsageProps, ModalIntegrationUsageResult,
    ModalIntegrationUsageState> {
  private subtitle: HTMLDivElement | null = null;
  private sessionDurationInput: HTMLInputElement | null = null;

  constructor(props: ModalIntegrationUsageProps) {
    super(props);
    this.state = {
      title: 'API Key Usage'
    };
  }

  public showDialog(): Promise<ModalIntegrationUsageResult> {
    return setStatePromise<ModalIntegrationUsageState, 
        ModalIntegrationUsage>(this, {})
    .then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalIntegrationUsage');
    return (
      <Modal 
          ref={this.modal}
          // onOK={() => this.onOK()}
          title={this.state.title}
          confirmButtonContent="Close">
        <div className="row">
          <div className="col-8">
            <div className="input-group search-filter">
              <input type="search" className="form-control"
                  placeholder={xl8('search')} aria-label="Search"
                  // value="value"
                  onChange={(event) => {
                  }}
                  aria-describedby="search-addon" />
              <button className="btn btn-outline"
                  type="button" 
                  onClick={(event) => {

                  }}>
                    
                <SearchIcon width="20" height="20" />
              </button>
            </div>
          </div>
        </div>
        <h4 className="m-b-30">Sites using this API key</h4>
        <div className="integration-usage-container">
          <div className="row">
            <div className="col-md-6">
              <label className="form-switch-label">Manitoba Yard</label>
            </div>
            <div className="col-md-2">
              <div className="form-check form-switch">
                <input type="checkbox" className="form-check-input"
                  checked></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-switch-label">Fraser River Parkade</label>
            </div>
            <div className="col-md-2">
              <div className="form-check form-switch">
                <input type="checkbox" className="form-check-input" checked></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-switch-label">Thunderbird Parkade</label>
            </div>
            <div className="col-md-2">
              <div className="form-check form-switch">
                <input type="checkbox" className="form-check-input"></input>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  
  private onOK(): Promise<ModalIntegrationUsageResult> {
    return null;

  }
}