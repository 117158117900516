import React, { ReactNode } from "react";
import {
  ChargerType, ConnectorStatus, DeviceType, 
  getOcppDevices, OcppChargepoint, OcppDevices, 
  OcppLocation, postDeviceBySite, postSiteByOrganization, Site
} from "../../api";
import { FormSwitch } from "../FormSwitch";
import {
  sequentialPromiseMap, setStatePromise, toastError
} from "../shared/ui";
import { SwychedSpinner } from "../spinner.component";
import { Modal, ModalBase } from "./Modal";

import "./ModalImportDevices.scss";

type ChargepointsByLocationMap = {
  [locationId: number]: OcppChargepoint[]
};

export interface ModalImportDevicesProps {

}

export interface ModalImportDevicesState {
  organizationId: number;
  ocppInfo: OcppDevices;
  locationGroups: ChargepointsByLocationMap;
  selectedChargepoints: Set<number>;
  checkedAtLocation: Map<number, number>;
  countAtLocation: Map<number, number>;
  hubFlags: Set<number>;
}

export interface ModalImportDevicesResult {
  devicesWereAdded: boolean;
}

type LocationCheckboxRefMap = { 
  [locationId: number]: React.RefObject<HTMLInputElement>
};

export class ModalImportDevices
    extends ModalBase<ModalImportDevicesProps, 
      ModalImportDevicesResult, ModalImportDevicesState> {
  private locationCheckboxes: LocationCheckboxRefMap = Object.create(null);
  constructor(props: ModalImportDevicesProps) {
    super(props);    

    this.state = {
      organizationId: 0,
      ocppInfo: null,
      locationGroups: null,
      selectedChargepoints: new Set<number>(),
      checkedAtLocation: new Map<number, number>(),
      countAtLocation: new Map<number, number>(),
      hubFlags: new Set<number>()
    };
  }

  public showDialog(organizationId: number)
      : Promise<ModalImportDevicesResult> {
    let statePromise: Promise<void>;

    console.assert(typeof organizationId === 'number' && organizationId > 0);

    statePromise = setStatePromise<
        ModalImportDevicesState, ModalImportDevices>(this, {
      organizationId: organizationId
    });

    return statePromise.then(() => {
      return this.show();
    });
  }

  componentDidUpdate(
      prevProps: Readonly<ModalImportDevicesProps>,
      prevState: Readonly<ModalImportDevicesState>): void {
    if (prevState.organizationId !== this.state.organizationId)
      this.refreshOcppList(prevState);

    // if (prevState.selectedChargepoints !== this.state.selectedChargepoints ||
    //       prevState.ocppInfo !== this.state.ocppInfo) {
    //   this.updateCountsAtLocations();
    // }
    
    if (prevState.countAtLocation !== this.state.countAtLocation ||
        prevState.checkedAtLocation !== this.state.checkedAtLocation) {
      let checkboxes = Object.values(this.locationCheckboxes);
      let locationIds = Object.keys(this.locationCheckboxes);
      locationIds.forEach((locationIdStr: string, index) => {
        let locationId = +locationIdStr;
        let checkbox = checkboxes[index].current;
        if (checkbox) {
          let countAtLocation = this.state.countAtLocation.get(
            +locationId) || 0;
          let checkedAtLocation = this.state.checkedAtLocation.get(
            +locationId) || 0;
          checkbox.indeterminate = checkedAtLocation > 0 && 
              checkedAtLocation < countAtLocation;
        }
      });
    }
  }
  
  private updateCountsAtLocations() {
    this.setState((prevState) => {
      let newCountAtLocation = new Map<number, number>();
      let newCheckedAtLocation = new Map<number, number>();

      let locationKeys = Object.keys(prevState.locationGroups);
      Object.values(prevState.locationGroups)
      .forEach((chargepointList: OcppChargepoint[], index) => {
        let locationId = +locationKeys[index];
        newCountAtLocation.set(locationId, chargepointList.length);
      });

      // can't iterate Set<number>? Come on!
      Array.from(prevState.selectedChargepoints).forEach((chargepointId) => {
        let chargepoint = prevState.ocppInfo?.chargepoints[chargepointId];
        let locationId = chargepoint?.locationId;
        if (locationId) {
          newCheckedAtLocation.set(locationId,
            (newCheckedAtLocation.get(locationId) || 0) + 1);        
        }
      });

      return {
        countAtLocation: newCountAtLocation,
        checkedAtLocation: newCheckedAtLocation
      };
    });
  }

  private refreshOcppList(prevState: Readonly<ModalImportDevicesState>) {
    getOcppDevices(this.state.organizationId)
    .then((info) => {
      let newCountAtLocation = new Map<number, number>();

      // Group the chargepoints into by-location lookup
      let locationGroups: ChargepointsByLocationMap = Object.create(null);
      Object.values(info.chargepoints)
      .forEach((chargepoint: OcppChargepoint) => {
        let list = locationGroups[chargepoint.locationId];
        if (list)
          list.push(chargepoint);
        else
          locationGroups[chargepoint.locationId] = [chargepoint];
        
        newCountAtLocation.set(chargepoint.locationId,
            +(newCountAtLocation.get(chargepoint.locationId) || 0) + 1);
      });

      this.setState({
        ocppInfo: info,
        locationGroups: locationGroups,
        selectedChargepoints: new Set<number>(),
        checkedAtLocation: new Map<number, number>(),
        countAtLocation: newCountAtLocation
      });
    });
  }

  render(): ReactNode {
    return (
      <Modal<ModalImportDevicesResult> 
          title="Import From Ocpp"
          ref={this.modal}
          onOK={() => this.onOK()}
          confirmButtonContent="Import Locations/Devices">
        <span className="import-disabled-disclaimer">
          Devices that have been added already are disabled
        </span>
        <div className="import-device-container">
          {this.renderDeviceList()}
        </div>
      </Modal>
    );
  }

  private setChargepointChecked(
      chargepoint: OcppChargepoint, checked: boolean,
      skipCounts: boolean = false) {
    this.setState((prevState) => {
      let newSelected = new Set<number>(prevState.selectedChargepoints);
      if (checked && !newSelected.has(chargepoint.chargepointId))
        newSelected.add(chargepoint.chargepointId);
      else if (!checked && newSelected.has(chargepoint.chargepointId))
        newSelected.delete(chargepoint.chargepointId);
      else
        return null;        
      
      return {
        selectedChargepoints: newSelected
      };
    }, () => {
      if (!skipCounts)
        this.updateCountsAtLocations();
    });
  }

  private renderDeviceList(): ReactNode {
    let ocppInfo = this.state.ocppInfo;
    let locationGroups = this.state.locationGroups;
    if (!ocppInfo)
      return <SwychedSpinner busy={1}/>;
    
    let chargepointLists = Object.values(locationGroups);
    return chargepointLists.map((chargepoints: OcppChargepoint[]) => {
      let location: OcppLocation;
      location = ocppInfo.locations[chargepoints[0].locationId];

      let locationCheckboxRef = this.locationCheckboxes[location.locationId];
      if (!locationCheckboxRef) {
        locationCheckboxRef = React.createRef<HTMLInputElement>();
        this.locationCheckboxes[location.locationId] = locationCheckboxRef;
      }

      return (
        <div key={chargepoints[0]?.chargepointId ?? 'empty'}
            className="import-device-site-list">
          <label className="import-site-label">
            <h2 className="import-device-site-heading">
                <input type="checkbox"
                    className="form-check-input"
                    ref={locationCheckboxRef}
                    checked={
                      (this.state.checkedAtLocation.get(
                        location.locationId) || 0) > 0
                    }
                    onChange={(event) => {
                      event.stopPropagation();
                      this.setLocationChecked(location.locationId,
                          event.target.checked);
                    }}
                    /> {location.description} 
            </h2>
          </label>
          {
            chargepoints.map((chargepoint) => {
              return (
                <div key={chargepoint.chargepointId}
                    className="import-device-item">
                  <label>
                    <input type="checkbox"
                      className="form-check-input"
                      checked={this.state.selectedChargepoints.has(
                        chargepoint.chargepointId)}
                      disabled={this.chargepointAlreadyAdded(chargepoint)}
                      onChange={(event) => {
                        event.stopPropagation();
                        this.setChargepointChecked(
                          chargepoint, event.target.checked);
                      }}
                    /> {chargepoint.name} ({chargepoint.HTTP_CP})
                  </label>
                  <FormSwitch 
                    value={
                      this.state.hubFlags.has(chargepoint.chargepointId)
                    }
                    label={'Hub'}
                    onChange={(value) => {
                      this.setState((prevState) => {
                        let updated = new Set(prevState.hubFlags);
                        if (value)
                          updated.add(chargepoint.chargepointId);
                        else
                          updated.delete(chargepoint.chargepointId);
                        return {
                          hubFlags: updated
                        };
                      });                      
                    }}/>
                </div>
              );
            })
          }
        </div>
      );
    });
  }

  private setLocationChecked(locationId: number, checked: boolean) {
    this.state.locationGroups[locationId]
    .forEach((chargepoint, index, list) => {
      this.setChargepointChecked(chargepoint, checked,
        index + 1 < list.length);
    });
  }

  private chargepointAlreadyAdded(chargepoint: OcppChargepoint): boolean {
    return Object.prototype.hasOwnProperty.call(
      this.state.ocppInfo?.evseOcpp || {}, chargepoint.HTTP_CP);
  }

  onOK(): Promise<ModalImportDevicesResult> {
    let ocppInfo = this.state.ocppInfo;
    if (!ocppInfo)
      return null;

    let newSelected = new Set<number>(this.state.selectedChargepoints);

    Object.values(ocppInfo.chargepoints).forEach((chargepoint) => {
      if (Object.prototype.hasOwnProperty.call(ocppInfo.evseOcpp,
          chargepoint.HTTP_CP))
        newSelected.delete(chargepoint.chargepointId);
    });

    let initialPromise: Promise<void>;

    if (newSelected.size !== this.state.selectedChargepoints.size) {
      initialPromise = setStatePromise<
        ModalImportDevicesState, ModalImportDevices>(this, {
          selectedChargepoints: newSelected
        });
    } else {
      initialPromise = Promise.resolve();
    }

    let locationIdToSiteId: { [locationId: number]: number };
    let locationsToCreate: { [locationId: number]: string };
    let newSiteNames: string[];
    let newSiteSources: string[];

    return initialPromise.then(() => {
      // Make a lookup table for mapping locationId to siteId
      locationIdToSiteId = Object.create(null);

      // -----

      // Mapping from location to be created to name of created site
      locationsToCreate = Object.create(null);    
      
      // Make a lookup table from chargepointId to siteId
      Object.values(ocppInfo.chargepoints).forEach((chargepoint) => {
        // If we have this one there already on the dashboard...
        if (Object.prototype.hasOwnProperty.call(ocppInfo.evseOcpp, 
            chargepoint.HTTP_CP)) {
          // ...remember the mapping from locationId to siteId
          locationIdToSiteId[chargepoint.locationId] =
            ocppInfo.evseOcpp[chargepoint.HTTP_CP].locationId;
        }

        // -----
        
        // If the chargepoint is checked...
        if (this.state.selectedChargepoints.has(chargepoint.chargepointId)) {
          // ...lookup the chargepoint's location
          let locationId = chargepoint.locationId;
          let location = ocppInfo.locations[locationId];

          // If we don't have a mapping from that locationId
          // to a siteId...
          if (!Object.prototype.hasOwnProperty.call(
              locationIdToSiteId, locationId)) {
            // ...and we didn't already find a siteId for it
            if (!locationsToCreate[locationId]) {
              // ...then remember the locationId 
              // and associated name to be created
              locationsToCreate[locationId] = 
                location.description || location.name;
            }
          }
        }
      });

      // Create the sites
      newSiteNames = Object.values(locationsToCreate);
      newSiteSources = Object.keys(locationsToCreate);
      let createSitePromises: Promise<Site>[];
      createSitePromises = sequentialPromiseMap(newSiteNames, (name) => {
        return postSiteByOrganization(this.state.organizationId, {
          id: null,
          organizationId: this.state.organizationId,
          name: name,
          address: null,
          billingCity: null,
          billingContact: null,
          billingCountry: null,
          billingPostal: null,
          billingRegion: null,
          notes: null,
          accessControlGroups: [],
          popul8_url: null
        });
      });

      return Promise.all(createSitePromises);
    }).then((createSiteResponses) => {
      // Add the created sites to our locationId->siteId map
      createSiteResponses.forEach((site, index) => {
        let locationId = +newSiteSources[index];
        locationIdToSiteId[locationId] = site.id;
      });

      // Now we know the siteId for every chargepoint being created
      let chargpointsToPost = Array.from(this.state.selectedChargepoints)
      .map((chargepointId) => {
        return ocppInfo.chargepoints[chargepointId];
      });
      
      let createDeviceResponses = sequentialPromiseMap(
        chargpointsToPost, (chargepoint) => {
        let siteId = locationIdToSiteId[chargepoint.locationId];
        let connectors = ocppInfo.ports[chargepoint.chargepointId]?.map((port) => {
          return {
            id: 0,
            type: port.type,
            name: '',//port.name,
            status: ConnectorStatus.Available,
            portConnectorId: port.portConnectorId,
            chargerType: ChargerType.None
          };
        }) || [];

        let isHub = this.state.hubFlags.has(chargepoint.chargepointId);

        if (!isHub) {
          connectors = connectors.map((connector, index) => {
            return {
              ...connector,
              portConnectorId: index + 1
            };
          });
        }

        return postDeviceBySite(this.state.organizationId, siteId, {
          id: null,
          locationId: siteId,
          name: chargepoint.name,
          organizationId: this.state.organizationId,
          type: DeviceType.Ocpp,
          ocppChargepointId: chargepoint.HTTP_CP,
          accessControlGroups: [],
          accessControlPlans: [],
          isHub: isHub,
          chargerType: ChargerType.None,
          connectors: connectors,
          autostartTagId: null
        });
      });

      return Promise.all(createDeviceResponses);
    }).then((_) => {
      return {
        devicesWereAdded: true
      };
    }).catch((err) => {
      toastError(err.message);
      return null;
    });
  }
}