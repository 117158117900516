import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface RightArrowIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class RightArrowIcon extends PureComponent<RightArrowIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.2318 17.6402C8.87824 17.2159 8.93556 16.5854 9.35984 16.2318L14.438 12L9.35984 7.76826C8.93556 7.41469 8.87824 6.78413 9.2318 6.35985C9.58537 5.93557 10.2159 5.87825 10.6402 6.23182L16.6402 11.2318C16.8682 11.4218 17 11.7033 17 12C17 12.2968 16.8682 12.5783 16.6402 12.7683L10.6402 17.7683C10.2159 18.1218 9.58537 18.0645 9.2318 17.6402Z" fill={this.props.fill}/>
      </svg>
      
    );
  }
}