import React, { PureComponent, ReactNode } from "react";
import toast, { Toaster } from "react-hot-toast";
import { renderSpam } from "./shared/ui";

export class CustomizedToaster extends PureComponent<{}, {}> {
  render(): ReactNode {
    renderSpam('CustomizedToaster');
    return (

      <Toaster
        position="top-right"
        reverseOrder={false}
        border-radius="2px"
        toastOptions={{
          className: '',
          style: {
            margin: '10px',
            padding: '15px',
            background: 'rgba(0,0,0,0.8)',
            color: '#fff',
            zIndex: 1,
            borderRadius: "2px",
          },
          duration: 5000,
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}>
        {(t) => {
          console.log('toaster thing', t);

          return <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              console.log('dismissing toast');
              toast.dismiss(t.id);
            }}/>;
          // {/* <ToastBar toast={t} onHeight={} offset={} position={}/> */}
          // </div>
        }}
      </Toaster>
    );
  }
}