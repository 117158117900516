import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface PlayIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class PlayIcon extends PureComponent<PlayIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" 
        width={this.props.width} height={this.props.height} 
        viewBox="0 0 24 24"
        fill='#fff'
        >
        {/* <path d="M0 0h24v24H0z"/> */}
        <path d="M8 5v14l11-7z" fill={this.props.fill}/>
      </svg>
    );
  }
}