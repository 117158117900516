import * as React from 'react';
import { Component, ReactNode } from 'react';
import { 
  getAllSiteDevices,
  getNetEnergyReport,
  locationsFromDeviceIds,
  Organization,
  ReportNetEnergyDateMap,
  ReportNetEnergySiteMap,
  Site,
  SiteDevices
 } from '../../api';

import { formatDayOfWeek, xl8 } from '../../translations/i18n';
import { addDays, dayNumber, startOfDay, toastError } from '../shared/ui';
import { SwychedSpinner } from '../spinner.component';
import './NetEnergyWidget.css';

export interface NetEnergyWidgetProps {
  organization: Organization;
  filterEvseIds: Set<number> | null;
  evseIdToSiteId: Map<number, number> | null;
  siteDevices: SiteDevices;
}

interface NetEnergyWidgetState {
  displayedDate: Date;
  siteDevices: SiteDevices;
  inputData: ReportNetEnergySiteMap | null;
  tableRows: NetEnergyRow[] | null;
  tableCols: NetEnergyCol[] | null;
}

interface NetEnergyCol {
  date: Date | null;
  title: string;
}

interface NetEnergyRow {
  site: string;
  values: string[];
}

export class NetEnergyWidget 
    extends Component<NetEnergyWidgetProps, NetEnergyWidgetState> {
  constructor(props: NetEnergyWidgetProps) {
    super(props);
    this.state = {
      displayedDate: null,
      inputData: null,
      siteDevices: null,
      tableCols: null,
      tableRows: null
    };
  }

  private mounted: boolean = false;

  componentDidMount(): void {
    console.assert(!this.mounted);
    this.mounted = true;
    this.setState({
      displayedDate: startOfDay(new Date())
    });
  }

  componentWillUnmount(): void {
    console.assert(this.mounted);
    this.mounted = false;
  }

  componentDidUpdate(
      prevProps: Readonly<NetEnergyWidgetProps>, 
      prevState: Readonly<NetEnergyWidgetState>,
      snapshot?: never): void {
    // When the date or organization changes, update inputData
    if (this.state.displayedDate && this.props.organization &&
        (prevState.displayedDate !== this.state.displayedDate ||
        prevProps.organization?.id !== this.props.organization?.id)) {
      this.refreshInputData(this.state.displayedDate);
    }

    // When inputData changes, recalculate tableRows and tableCols
    if (prevState.inputData !== this.state.inputData ||
        prevState.siteDevices !== this.state.siteDevices ||
        prevProps.evseIdToSiteId !== this.props.evseIdToSiteId ||
        prevProps.filterEvseIds !== this.props.filterEvseIds) {
      this.refreshTableRows();
    }
  }

  private refreshTableRows() {
    let newTableCols: NetEnergyCol[];
    let newTableRows: NetEnergyRow[];
    
    newTableCols = NetEnergyWidget.dayOffsets.map((offset) => {
      let date = addDays(this.state.displayedDate, offset);
      return {
        date: date,
        title: formatDayOfWeek(date)
      };
    });

    let siteLookup: { [siteId: string]: Site } = this.state.siteDevices?.sites
    .reduce((siteLookup, site) => {
      siteLookup[site.id] = site;
      return siteLookup;
    }, {});

    let filterLocations = new Set<string>(locationsFromDeviceIds(
      this.props.filterEvseIds, this.props.siteDevices, 
      this.props.evseIdToSiteId)
      .map((site) => {
        return '' + site.id;
      }));

    newTableRows = this.state.inputData ? Object.keys(this.state.inputData)
    .filter((siteIdStr: string): boolean => {
      return !filterLocations.size ||
        filterLocations.has(siteIdStr);
    }).map((siteIdStr: string): NetEnergyRow => {
      let dateLookup: ReportNetEnergyDateMap = this.state.inputData[siteIdStr];
      return {
        site: siteLookup[siteIdStr]?.name,
        values: NetEnergyWidget.dayOffsets.map((offset, index) => {
          let unixDate = +addDays(this.state.displayedDate, offset) / 1000;
          let valueWh = dateLookup[unixDate] || 0;
          let kWh = valueWh / 1000;
          let kWhText = kWh ? kWh.toFixed(0) : '0';
          return kWhText;
        })
      };
    }) : [];

    this.setState({
      tableCols: newTableCols,
      tableRows: newTableRows
    });
  }

  private refreshInputData(at: Date): void {
    console.log('refreshing net energy input');

    if (!this.props.organization) {
      console.log('no organization');
      this.setState({
        displayedDate: null,
        siteDevices: null,
        tableRows: null
      });
      return;
    }

    let netEnergyPromise = getNetEnergyReport(this.props.organization.id, at);    
    let siteDevicesPromise = getAllSiteDevices(this.props.organization.id);

    Promise.all([
      netEnergyPromise,
      siteDevicesPromise
    ]).catch((err) => {
      toastError(err.message);
      return [null, null];
    }).then(([response, siteDevices]) => {
      if (!this.mounted)
        return;
      this.setState({
        displayedDate: at,
        inputData: response?.sites || null,
        siteDevices: siteDevices || null
      });
    });
  }

  render(): JSX.Element {
    return (    
      <>
        <h4 className="widget-header">
          {xl8('netEnergy')}
          <span>
          {xl8('overLastSevenDays')}
          </span>
        </h4>

        <div className="scrollable net-energy-table">
          <div>
            <table className="table tablesorter header-fixed">
              <thead className="text-primary">
                {this.renderNetEnergyHeadingsRow()}
              </thead>

              <tbody>
                {this.renderNetEnergyDetailRows()}
              </tbody>
            </table>
          </div>
        </div>
      </> 
    ); 
  }
  
  private renderNetEnergyDetailRows(): ReactNode {
    return this.state.tableRows?.map((netEnergyItem, index) => {
      return (
        <tr key={index}>
          <td data-label="Site" 
              className="net-energy-site-heading text-truncate">
            {netEnergyItem.site}
          </td>
          {this.renderNetEnergyValues(netEnergyItem)}
        </tr>
      );
    }) || (
      <tr>
        <td>
          <SwychedSpinner busy={1}/>
        </td>
      </tr>
    );
  }

  private static readonly dayOffsets = [ -7, -6, -5, -4, -3, -2, -1 ];

  private renderNetEnergyHeadingsRow(): ReactNode {
    if (!this.state.tableCols) {
      return (
        <>
        {/* <tr>
          <td>
            <SwychedSpinner busy={1}/>
          </td>
        </tr> */}
        </>
      );
    }

    return (
      <>
        <tr>
          <th scope="col">
            {xl8('site')}
          </th>
          {
            this.state.tableCols.map((col, index) => {
              return (
                <th key={+col.date} scope="col" 
                    className='net-energy-table-numeric'>
                  {formatDayOfWeek(addDays(this.state.displayedDate, 
                    NetEnergyWidget.dayOffsets[index]))}
                </th>
              );
            })
          }
        </tr>
      </>
    );
  }

  private renderNetEnergyValues(item: NetEnergyRow): ReactNode {
    return NetEnergyWidget.dayOffsets.map((offset, index) => {
      let date = addDays(this.state.displayedDate, offset);
      let dayNum = dayNumber(date);
      return (
        <td key={dayNum}
            data-label={formatDayOfWeek(date)}
            className="net-energy-table-numeric">
          {item.values[index]} kWh
        </td>
      );
    });
  }
}
