import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface ChargerIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class ChargerIcon extends PureComponent<ChargerIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        onMouseOver={(event) => {
        }}>
        <path fillRule="evenodd" clipRule="evenodd" 
          d="M2 6C2 3.79086 3.79086 2 6 2H9C11.2091 2 13 3.79086 13 6V11.6898C14.9801 11.7917 16.5542 13.4294 16.5542 15.4348V19.4021C16.5542 19.8704 16.9338 20.25 17.4021 20.25C17.8704 20.25 18.25 19.8704 18.25 19.4021V11.9055C16.9561 11.5725 16 10.3979 16 9V7.5C16 7.22386 16.2239 7 16.5 7H17V4.99999H18V7H20V4.99999H21V7H21.5C21.7761 7 22 7.22386 22 7.5V9C22 10.3979 21.0439 11.5725 19.75 11.9055V19.4021C19.75 20.6988 18.6988 21.75 17.4021 21.75C16.1054 21.75 15.0542 20.6988 15.0542 19.4021V15.4348C15.0542 14.2581 14.151 13.2924 13 13.1932V22H2V6ZM10.3643 9.44662L8.36247 9.44662V6L4.65 10.5474H6.65789L6.65788 14L10.3643 9.44662Z" 
          fill={this.props.fill || "#175785"}/>
      </svg>
    );
  }
}