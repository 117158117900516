import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface CheckIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class CheckIcon extends PureComponent<CheckIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill}>
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </svg>
    );
  }
}