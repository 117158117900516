import * as React from 'react';
import { 
  patchUserById, 
  postUserByOrganization, User,
} from '../../api';

import { 
  isFieldValid
} from '../shared/ui';
import { AppNotificationTrigger } from '../../api/notification';
import { xl8 } from '../../translations/i18n';
import { CheckIcon } from '../icons/CheckIcon';
import { renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

export interface ModalEditUserProps {
}

export interface ModalEditUserResult {
  user: User;
}

interface ModalEditUserState {
  user: User;
  notificationTriggers: AppNotificationTrigger[];
  emailDisabled: boolean;
  title: string;// = 'Edit';
  okText: string;// = 'OK';   
  organizationId: number;
}

export class ModalEditUser
    extends ModalBase<ModalEditUserProps, ModalEditUserResult,
    ModalEditUserState> {

  constructor(props: ModalEditUserProps) {
    super(props);
    this.state = {
      user: null,
      emailDisabled: false,
      title: 'Edit',
      okText: 'OK',
      organizationId: 0,
      notificationTriggers: null
    };
  }

  componentDidUpdate(
      prevProps: Readonly<ModalEditUserProps>, 
      prevState: Readonly<ModalEditUserState>, 
      snapshot?: never): void {
    // if (prevState.organizationId !== this.state.organizationId)
    //   this.refreshNotificationTriggers();
  }

  // private refreshNotificationTriggers(): Promise<void> {
  //   if (!this.state.organizationId)
  //     return Promise.resolve();

  //   let triggerPromise = getNotificationTriggers(this.state.organizationId);
    
  //   return triggerPromise.then((triggers) => {
  //     this.setState({
  //       notificationTriggers: triggers
  //     });
  //   });
  // }

  public showDialog(organizationId: number, 
      notificationTriggers: Promise<AppNotificationTrigger[]>,
      user: User, title: string, okText: string)
      : Promise<ModalEditUserResult> {
    let statePromise: Promise<void>;

    console.assert(typeof organizationId === 'number' && organizationId > 0);
    console.assert(typeof user === 'object');
    console.assert(typeof title === 'string');
    console.assert(typeof okText === 'string');

    notificationTriggers.then((triggers) => {
      this.setState({
        notificationTriggers: triggers
      });
    });

    if (!user) {
      statePromise = notificationTriggers.then((triggers) => {
        return setStatePromise<ModalEditUserState, ModalEditUser>(this, {
          title: title,
          okText: okText,
          user: {
            id: 0,
            organizationId: organizationId,
            email: '',
            role: 'admin',
            firstName: '',
            lastName: '',
            notificationSubs: triggers.map((trigger) => {
              return trigger.id;
            }),
            notificationSites: [],
            phone: '',
            notifyEmail: false,
            notifySms: false,
            notificationEmail: '',
            notificationSms: ''
          },
          emailDisabled: false,
          organizationId: organizationId
        });
      });

    } else {
      statePromise = setStatePromise<ModalEditUserState, ModalEditUser>(this, {
        title: title,
        okText: okText,
        user: {
          ...user,
          organizationId: organizationId
        },
        emailDisabled: true,
        organizationId: organizationId
      });
    }

    return statePromise.then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalEditUser');
    return (
      <Modal 
          ref={this.modal}
          onOK={() => this.onOK()}
          title={this.state.title}
          confirmButtonContent={this.state.okText}
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}>
        {/* <div>
          <label>
            <span>First name</span>
            <input className="form-control user-name-field"
              value={this.state.user?.firstName}
              onChange={(event) => {
                this.setState((prevState) => {
                  return {
                    user: {
                      ...prevState.user,
                      firstName: event.target.value
                    }
                  };
                });
              }}>
            </input>
          </label>
        </div>
        <div>
          <label>
            <span>Last name</span>
            <input className="form-control user-name-field"
              value={this.state.user?.lastName}
              onChange={(event) => {
                this.setState((prevState) => {
                  return {
                    user: {
                      ...prevState.user,
                      lastName: event.target.value
                    }
                  };
                });
              }}>
            </input>
          </label>
        </div> */}
        <label>
          <span>
            {xl8('emailAddress')}
          </span>
          <input className="form-control"
            readOnly={this.state.emailDisabled}
            name="email"
            disabled={this.state.emailDisabled}
            value={this.state.user?.email || ''}
            onChange={(event) => {
              this.setState((prevState) => {
                return {
                  user: {
                    ...prevState.user,
                    email: event.target.value
                  }
                };
              });
            }}/>
          <div className="validation-error"
            hidden={!isFieldValid('email')}
            data-validation-msg="email">
            {xl8('validEmailRequired')}
          </div>
        </label>
        <label>
          <span>
            {xl8('role')}
          </span>
          <select className="form-select"
            value={this.state.user?.role || ''}
            onChange={(event) => {
              this.setState((prevState) => {
                return {
                  user: {
                    ...this.state.user,
                    role: event.target.value
                  }
                };
              });
            }}
            name="role">
            <option value="admin">Admin</option>
            <option value="readonly">Readonly</option>
          </select>
        </label>
        {/* <h4 className="modal-sub-heading">
          Notification Settings
        </h4>
        <div className="user-notification-container">
          <div className="row">
            <FormSwitch 
              label={'Email'}
              value={this.state.user?.notifyEmail}
              onChange={(newValue) => {
                this.setState((prevState) => {
                  if (!prevState.user)
                    return null;
                  return {
                    user: {
                      ...prevState.user,
                      notifyEmail: newValue
                    }
                  };
                });
              }}
              />
          </div>
          <div className="row">
              <FormSwitch
                className="centered-v"
                label={
                  <>
                    <label>
                      SMS
                      <input className="form-control" type="text"
                        placeholder="(000)-000-0000"
                        value={this.state.user?.phone}
                        onChange={(event) => {
                          this.setState((prevState) => {
                            if (!prevState.user)
                              return null;
                            return {
                              user: {
                                ...prevState.user,
                                phone: event.target.value
                              }
                            };
                          });
                        }} />
                    </label>
                  </>
                }
                value={this.state.user?.notifySms}
                onChange={(newValue) => {
                  this.setState((prevState) => {
                    return {
                      user: {
                        ...prevState.user,
                        notifySms: newValue
                      }
                    };
                  });
                }}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-10">
            <h4 className="modal-sub-heading">
              Subscribed Notifications
            </h4>
            <div>
              {
                this.state.notificationTriggers?.map((sub) => {
                  return (
                    <FormSwitch value={
                      this.state.user
                      ?.notificationSubs
                      ?.includes(sub.id) || false
                    }
                    label={sub.description}
                    onChange={(newValue) => {
                      this.changeSub(sub.id, newValue);
                    }}/>
                  );
                }) || <SwychedSpinner busy={1}/>
              }
            </div>
          </div>
        </div> */}
      </Modal>
    );
  }

  private changeSub(subscriptionPatternId: number, subscribed: boolean): void {
    this.setState((prevState) => {      
      let newSubs = prevState.user.notificationSubs?.slice() || [];
      
      let index = newSubs.indexOf(subscriptionPatternId);          
      
      if (subscribed && index < 0) {
        newSubs.push(subscriptionPatternId);
      } else if (!subscribed && index >= 0) {
        newSubs.splice(index, 1);
      } else {
        return null;
      }

      return {
        user: {
          ...prevState.user,
          notificationSubs: newSubs
        }
      };
    });
  }
  
  private onOK(): Promise<ModalEditUserResult> {
    let user = this.state.user;

    if (!user || !/\S/.test(user.email))
      return Promise.resolve(null);

    if (!user.id) {
      return postUserByOrganization(this.state.organizationId, user)
      .then((user) => {
        return {
          user: user
        };
      });
    } else {
      return patchUserById(this.state.organizationId, user).then(() => {
        return {
          user: {
            ...user
          }
        };
      }).then(() => {
        return {
          user: {
            ...user
          }
        };
      });
    }
  }
}
