import { ConfigService } from "./config.service";
import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { AuthData } from "./auth.service";
import { NotAuthenticatedError } from "../shared/ui";
import { GetUserRequest } from "aws-sdk/clients/cognitoidentityserviceprovider";

interface CognitoServiceSignupParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

interface CognitoServiceSignInParams {
  email: string;
  password: string;
}

export class CognitoService {
  private static instance: CognitoService = null;

  public static getInstance(): CognitoService {
    if (!CognitoService.instance)
      CognitoService.instance = new CognitoService();
    return CognitoService.instance;
  }

  public static dropInstance(): void {
    CognitoService.instance = null;
  }

  private configService: ConfigService = new ConfigService();
  private tokenStorageKey: string = 'swychedtoken';
  
  private amazonUserPool(): AWSCognito.CognitoUserPool {
    let poolData = {
      UserPoolId: this.configService.awsUserPoolId,
      ClientId: this.configService.awsClientId,
      Storage: window.sessionStorage
    };

    let userPool = new AWSCognito.CognitoUserPool(poolData);
    
    return userPool;
  }
  

  private amazonAttributeList(data: CognitoServiceSignupParams)
      : AWSCognito.CognitoUserAttribute[] {
    

    let attributeList = [
      new AWSCognito.CognitoUserAttribute({
        Name: 'email',
        Value: data.email
      }),
      new AWSCognito.CognitoUserAttribute({
        Name: 'given_name',
        Value: data.firstName
      }),
      new AWSCognito.CognitoUserAttribute({
        Name: 'family_name',
        Value: data.lastName
      })
    ];

    return attributeList;
  }

  public signOut(): boolean {
    let user = this.getCurrentUser();
    if (!user)
      return false;

    user.signOut();
    return true;
  }

  // Resolves to a cognitoUser
  public signUp(data: CognitoServiceSignupParams): Promise<any> {
    let userPool = this.amazonUserPool();
    let attributeList = this.amazonAttributeList(data);
    
    return new Promise<any>((resolve, reject) => {
      userPool.signUp(data.email, data.password, 
          attributeList, null, (err, result) => {
        if (!err)
          resolve(result.user);
        else
          reject(err);
      });
    });
  }

  private cognitoUserFromEmail(email: string): any {
    let userPool = this.amazonUserPool();

    let userData = {
      Username: email,
      Pool: userPool,
      Storage: sessionStorage
    };

    let cognitoUser = new AWSCognito
      .CognitoUser(userData);
    
    return cognitoUser;
  }

  // Authenticate the passed cognitoUser
  // Does not handle scenario where a new password is required
  private authenticateWithUsernamePassword(cognitoUser: any, 
      email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let authenticationData = {
        Username: email,
        Password: password
      };

      let authenticationDetails = new AWSCognito
        .AuthenticationDetails(authenticationData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          resolve(result);
        },

        onFailure(err) {
          reject(err);
        }
      });
    });
  }

  // The returned promise is not rejected when a new password is required
  // Instead, the passed newPasswordRejectedCallback is invoked with the
  // failure message. Eventually, when the user successfully signs in
  // or provides a usable new password, the promise originally returned
  // is resolved. If a new password was not required, an unsuccessful
  // login will reject the promise originally returned.
  public signIn(data: CognitoServiceSignInParams,
      newPasswordRequiredCallback: (userAttributes: any, 
      requiredAttributes: any,
      setNewPassword: (password: string) => void) => void, 
      newPasswordRejectedCallback: (message: string) => void)
      : Promise<any> {
    let requiringNewPassword: boolean;

    return new Promise<any>((resolve, reject) => {
      let authenticationData = {
        Username: data.email,
        Password: data.password
      };
      let authenticationDetails = new AWSCognito
        .AuthenticationDetails(authenticationData);
      
      let cognitoUser = this.cognitoUserFromEmail(data.email);

      console.log('authenticating Username=', authenticationData.Username,
        'password=', authenticationData.Password.replace(/./g, '*'));

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          console.log('authentication success', result);
          resolve(result);
        },

        onFailure(err: Error) {
          console.warn('authentication failed', err);
          if (requiringNewPassword)
            newPasswordRejectedCallback(err.message);
          else
            reject(err);
        },

        newPasswordRequired(userAttributes, requiredAttributes) {
          console.log('cognito requires new password');
          requiringNewPassword = true;

          newPasswordRequiredCallback(userAttributes, requiredAttributes,
          (newPassword: string) => {
            console.log('attempting to set required new password');
            cognitoUser.completeNewPasswordChallenge(
              newPassword, userAttributes, this);
          });
        }
      });
    });
  }

  public forgotPassword(email: string,
      needVerificationCallback: (verifyCallback: (verificationCode: string,
        newPassword: string) => void) => void,
        newPasswordRejectedCallback: (message: string) => void,
        failureCallback?: (err: Error) => void)
      : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let cognitoUser = this.cognitoUserFromEmail(email);

      cognitoUser.forgotPassword({
        onSuccess(result) {
          console.log('call result: ' + result);
          resolve(result);
        },

        onFailure(err: Error) {
          console.warn('password reset failed: ', err.message);
          failureCallback && failureCallback(err);
          newPasswordRejectedCallback(err.message);          
        },

        inputVerificationCode() {
          needVerificationCallback((verificationCode: string,
              newPassword: string) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, this);
          });
        }
      });
    });
  }
  // MW WIP
  // public resetPassword(username): Promise<void> {
 
  //   let cognitoUser = this.cognitoUserFromEmail(username);
  //   let userPool = this.amazonUserPool();
    
  //   cognitoUser = new AWSCognito.CognitoUser({
  //       Username: username,
  //       Pool: userPool
  //   });

  //   cognitoUser.forgotPassword({
  //       onSuccess: function(result) {
  //           console.log('call result: ' + result);
  //       },
  //       onFailure: function(err) {
  //           alert(err);
  //       }
  //   });

  // }


  private getCurrentUser(): AWSCognito.CognitoUser {
    let userPool = this.amazonUserPool();
    let user = userPool.getCurrentUser();
    return user;
  }

  public refresh(): Promise<AuthData> {
    let currentUser = this.getCurrentUser();
    let staySignedIn;

    return new Promise<AuthData>((resolve, reject) => {      
      let token: AuthData;

      if (!currentUser) {
        let tokenJson = localStorage.getItem(this.tokenStorageKey);
        
        try {
          token = null;
          if (tokenJson)
            token = JSON.parse(tokenJson);
        } catch (ex) {
          // Ignore
        }
      }

      if (token) {
        staySignedIn = token.staySignedIn;

        const accessToken = new AWSCognito
            .CognitoAccessToken({
          AccessToken: token.accessToken.jwtToken
        });
        
        const idToken = new AWSCognito
            .CognitoIdToken({
          IdToken: token.idToken.jwtToken
        });
        
        const refreshToken = new AWSCognito
            .CognitoRefreshToken({
          RefreshToken: token.refreshToken.jwtToken
        });

        const sessionData: AWSCognito.ICognitoUserSessionData = {
          IdToken: idToken,
          AccessToken: accessToken,
          RefreshToken: refreshToken
        };
        
        const userSession = new AWSCognito
          .CognitoUserSession(sessionData);

        const params: GetUserRequest = {
          AccessToken: token.accessToken.jwtToken
        };

        AWS.config.region = this.configService.awsRegion;
        let identityProvider = new AWS.CognitoIdentityServiceProvider();
        identityProvider.getUser(params, (err, user) => {
          if (err) {
            reject(err);
            return;
          }

          const userData: AWSCognito.ICognitoUserData = {
            Username: user.Username,
            Pool: this.amazonUserPool()
          };

          const cognitoUser = new AWSCognito.CognitoUser(userData);
          cognitoUser.setSignInUserSession(userSession);

          currentUser = cognitoUser;

          cognitoUser.getSession(function (err, session) {
            if (session.isValid()) {
              // Update attributes or whatever else you want to do
              resolve(session);
            } else {
              reject(err);
            }
          });
        });
      } else {
        resolve(null);
      }
    }).then((session) => {
      return new Promise<any>((resolve, reject) => {
        if (session) {
          resolve(session);
        } else if (currentUser) {
          currentUser.getSession((err, session) => {
            if (!err)
              resolve(session);
            else
              reject(err);
          });
        } else {
          reject(new NotAuthenticatedError());
        }
      });
    }).then((session) => {
      let attrPromise = new Promise<UserAttributes>((resolve, reject) => {
        currentUser.getUserAttributes((err, attr) => {
          if (!err)
            resolve(CognitoService.getUserAttributesFrom(attr));
          else
            reject(err);
        });
      });
      
      return Promise.all([
        session, 
        attrPromise
      ]);
    }).then(([session, attr]) => {    
      let result: AuthData = {
        accessToken: session.accessToken,
        idToken: session.idToken,
        refreshToken: session.refreshToken,
        expires: session.expires,
        email: attr.email,
        staySignedIn: staySignedIn
      };
      return result;
    });
  }

  public static getUserAttributesFrom(
      attr: Array<AWSCognito.CognitoUserAttribute>): UserAttributes {
    return attr.reduce((result, item) => {
      result[item.Name] = item.Value;
      return result;
    }, Object.create(null));
  }

  public getUserAttributes(): Promise<UserAttributes> {
    return new Promise<UserAttributes>((resolve, reject) => {
      let user = this.getCurrentUser();

      if (!user) {
        console.log('Cannot get user attributes, no current user');
        resolve(null);
        return;
      }

      user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        user.getUserAttributes((err, 
            attr: Array<AWSCognito.CognitoUserAttribute>) => {
          if (err) {
            reject(err);
            return;
          }

          let result: UserAttributes = 
            CognitoService.getUserAttributesFrom(attr);

          resolve(result);
        });
      });
    });
  }

  public setUserAttributes(attr: UserAttributes): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let user = this.getCurrentUser();

      if (!user) {
        reject(new Error('Cannot set user attributes, no current user'));
        return;
      }

      user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        let attrList = Object.keys(attr).filter((key) => {
          return [
            'email',
            'given_name',
            'family_name'
          ].indexOf(key) >= 0;
        }).map((key) => {
          return {
            Name: key,
            Value: attr[key]
          };
        });

        user.updateAttributes(attrList, (err, attr) => {
          if (err) {
            reject(err);
            return;
          }

          resolve();
        });
      });
    });
  }

  public changePassword(oldPassword: string,
      newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let user = this.getCurrentUser();

      if (!user) {
        reject(new Error('Cannot change password, no current user'));
        return;
      }
      
      user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        user.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
            return;
          }

          resolve();
        });
      });
    });
  }

  public verifyAttribute(prop: string, email: string,
      setVerificationCodeCallback?: (callback: (code: string) => void) => void)
      : Promise<void> {
    console.log('verifying attribute=', prop);

    return new Promise<void>((resolve, reject) => {
      let cognitoUser;
      
      if (email)
        cognitoUser = this.cognitoUserFromEmail(email);
      else
        cognitoUser = this.getCurrentUser();
      
      if (!cognitoUser) {
        reject(new Error('Cannot verify attribute, no current user'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        cognitoUser.getAttributeVerificationCode(prop, {
          onSuccess(result) {
            resolve();
          },

          onFailure(err) {
            reject(err);
          },

          inputVerificationCode() {
            if (setVerificationCodeCallback) {
              setVerificationCodeCallback((code: string) => {
                cognitoUser.verifyAttribute(prop, code, this);
              });
            } else {
              resolve();
            }
          }
        });
      });
    }).catch((err) => {
      console.warn('Failed to authenticate user in verifyAttribute');
      throw err;
    });
  }

  public confirmRegistration(email: string, forceAliasCreation: boolean,
      verificationCode: string): Promise<void> {
    console.log('confirming registration email=', email,
      'verificationCode=', verificationCode);

    return new Promise<void>((resolve, reject) => {
      let cognitoUser = this.cognitoUserFromEmail(email);

      cognitoUser.confirmRegistration(verificationCode, forceAliasCreation,
      (err, result) => {
        if (err) {
          reject(err);
          return;
        }

        resolve();
      });
    });
  }

  public resendConfirmation(email: string): Promise<void> {
    console.log('resending verification code');
    return new Promise<void>((resolve, reject) => {
      let user = this.cognitoUserFromEmail(email);
      user.resendConfirmationCode(function(err, result) {
        if (err) {
          reject(err);
          return;
        }
        
        resolve();
      });
    });
  }
}

export interface UserAttributes {
  [name: string]: string;
}
