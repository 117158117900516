import * as React from 'react';
import { 
  AccessCard,
  AccessCardAuthorizationStatus,
  AccessControlGroupMap,
  patchPostAccessCardByOrganization
} from '../../api';
import { AccessControlGroupDropdown } from '../AccessControlGroupDropdown';
import { CheckIcon } from '../icons/CheckIcon';
import { formatUnixDate, renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';
import { ModalValidator } from "./ModalValidator";

export interface ModalEditAccessCardProps {
}

export interface ModalEditAccessCardResult {
  card: AccessCard;
}

interface ModalEditAccessCardState {
  organizationId: number;
  groups: AccessControlGroupMap;
  originalCard: AccessCard | null;
  card_id: number;
  card_acpgId: number;
  card_name: string;
  card_dateAdded: number;
  card_cardValue: string;
  card_authorizationStatus: AccessCardAuthorizationStatus;
}

export class ModalEditAccessCard
    extends ModalBase<ModalEditAccessCardProps, ModalEditAccessCardResult,
    ModalEditAccessCardState> {
  private generationCounter: number = 0;

  private validator = new ModalValidator();
  private cardNameInput = React.createRef<HTMLInputElement>();
  private groupInput = React.createRef<AccessControlGroupDropdown>();

  constructor(props: ModalEditAccessCardProps) {
    super(props);
    this.state = {
      organizationId: null,
      groups: null,
      originalCard: null,
      card_id: 0,
      card_acpgId: 0,
      card_name: '',
      card_dateAdded: 0,
      card_cardValue: '',
      card_authorizationStatus: AccessCardAuthorizationStatus.Accepted
    };

    this.validator.mustBeNotEmptyTrimmed(
      'card_name', 'Card name cannot be empty man', this.cardNameInput);
    this.validator.mustBeNonzero(
      'card_acpgId', 'Must select a group', this.groupInput);
  }

  public showDialog(card: AccessCard, 
      organizationId: number, groups: AccessControlGroupMap):
      Promise<ModalEditAccessCardResult> {
    ++this.generationCounter;
    return setStatePromise<ModalEditAccessCardState, 
        ModalEditAccessCard>(this, {
      groups: groups,
      organizationId: organizationId,
      originalCard: card,
      card_id: card ? card.id : 0,
      card_acpgId: card ? card.acpgId : -1,
      card_name: card ? card.name : '',
      card_dateAdded: card ? card.dateAdded : 0,
      card_cardValue: card ? card.cardValue : ''
    }).then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalEditAccessCard');
    return (
      <Modal 
          key={this.generationCounter}
          ref={this.modal}
          onOK={() => this.onOK()}
          title="Edit Access Card"
          confirmButtonContent="Edit Access Card"
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}>
          <label>
            <span>Date Added</span>
            <input className="form-control" 
              value={formatUnixDate(this.state.card_dateAdded)}
              readOnly={true}
              name="card-date"/>
          </label>
          <label>
            <span>RFID Value </span>
            <input className="form-control"
              value={this.state.card_cardValue}
              readOnly={true}
              name="card-value"/>
          </label>
          <label>
          <span>Name / ID</span>
            <input className="form-control" 
                ref={this.cardNameInput}
                value={this.state.card_name}
                onChange={(event) => {
                  this.setState({
                    card_name: event.target.value
                  });
                }}
                name="card-id"/>
            <span className="input-desc">
              Enter a descriptive name or ID to identify the card assignee
            </span>
          </label>
          <span>Group</span>
          <AccessControlGroupDropdown
            ref={this.groupInput}
            organizationId={this.state.organizationId}
            groups={this.state.groups}
            value={this.state.card_acpgId}
            onChange={(acpgId) => {
              this.setState({
                card_acpgId: acpgId
              });
            }}/>
      </Modal>
    );
  }
  
  private onOK(): Promise<ModalEditAccessCardResult> | null {
    if (!this.validator.isValid(this.state))
      return null;
    
    return patchPostAccessCardByOrganization(
        'PATCH', this.state.organizationId, {
        id: this.state.card_id,
        acpgId: this.state.card_acpgId,
        name: this.state.card_name,
        dateAdded: this.state.card_dateAdded,
        cardValue: this.state.card_cardValue,
        authorizationStatus: this.state.card_authorizationStatus
      }).then((card) => {
      Object.assign(this.state.originalCard, card);
      return {
        card: this.state.originalCard
      };
    });
  }
}
