import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NoUnreadNotificationIconsProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NoUnreadNotificationIcons extends PureComponent<NoUnreadNotificationIconsProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 28H62V61C62 62.6569 60.6569 64 59 64H13C11.3431 64 10 62.6569 10 61V28Z" fill="#DCE3EB"/>
        <path d="M34.3842 9.15417L11.1671 25.7378C9.61484 26.8465 9.61484 29.1535 11.1671 30.2622L34.3842 46.8458C35.3508 47.5363 36.6492 47.5363 37.6158 46.8458L60.833 30.2622C62.3852 29.1535 62.3852 26.8465 60.833 25.7378L37.6158 9.15417C36.6492 8.46375 35.3508 8.46374 34.3842 9.15417Z" fill="#80A7C3"/>
        <path d="M61.4627 62.7131C60.9207 63.4909 60.0196 63.9999 58.9997 63.9999H12.9997C11.9799 63.9999 11.0789 63.4911 10.5369 62.7134L34.2981 44.3176C35.3001 43.5418 36.6999 43.5418 37.7018 44.3176L61.4627 62.7131Z" fill="#E8EEF4"/>
      </svg>
    );
  }
}