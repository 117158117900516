import * as React from 'react';
import { SwychedOption, SwychedSelect } from '../select.component';
import { isDeveloper, renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';
import { 
  Aggregation,
  Device,
  Organization,
  ReportType,
  SiteDevices
} from '../../api';
import { SwychedDateSelect } from '../SwychedDateSelect';
import { SiteDropdown } from '../SiteDropdown';
import { AccessCardDropdown } from '../AccessCardDropdown';
import { ReactNode } from 'react';
import { RefreshIcon } from '../icons/RefreshIcon';
import { xl8 } from '../../translations/i18n';


export interface ModalUpdateReportProps {
}

export interface ModalUpdateReportOptions {
  organization: Organization;
  reportType: ReportType;
  startDate: Date;
  endDate: Date;
  aggregation: Aggregation;
  selectedDevices: number[];
  selectedSites: number[];
  selectedAccessCards: number[];
  siteDevices: SiteDevices;
}

export interface ModalUpdateReportResult {
  options: ModalUpdateReportOptions;
  renderedDateSelection: ReactNode;
  renderedSiteSelection: ReactNode;
  renderedDeviceSelection: ReactNode;
  renderedAccessCardSelection: ReactNode;
}

interface ModalUpdateReportState {
  reportType: ReportType;
  startDate: Date;
  endDate: Date;
  selectedDevices: Set<number>;
  selectedAccessCards: Set<number>;
  aggregation: Aggregation;
  organization: Organization;
  siteDevices: SiteDevices;
}

export class ModalUpdateReport
    extends ModalBase<ModalUpdateReportProps, ModalUpdateReportResult,
    ModalUpdateReportState> {

  private dateSelectRef = React.createRef<SwychedDateSelect>();
  private siteOnlyDropdownRef = React.createRef<SiteDropdown>();
  private siteDeviceDropdownRef = React.createRef<SiteDropdown>();
  private accessCardDropdownRef = React.createRef<AccessCardDropdown>();

  constructor(props: ModalUpdateReportProps) {
    super(props);

    this.state = {
      reportType: null,
      startDate: null,
      endDate: null,
      organization: null,
      selectedDevices: new Set<number>(),
      selectedAccessCards: new Set<number>(),
      aggregation: Aggregation.hour,
      siteDevices: null
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ModalUpdateReportProps>,
    prevState: Readonly<ModalUpdateReportState>,
    snapshot?: never): void {

    if (prevState.reportType !== this.state.reportType) {
      let wasSites = this.needSites(prevState.reportType);
      let isSites = this.needSites();

      if (wasSites !== isSites) {
        this.transitionSelection(wasSites, isSites);
      }
    }
  }

  private transitionSelection(wasSites: boolean, isSites: boolean): void {
    let siteDevices = this.state.siteDevices;
    let deviceListBySite = siteDevices.deviceListBySite;
    let siteIdStrs: string[] = Object.keys(deviceListBySite);
    let deviceLists: Device[][] = Object.values(deviceListBySite);

    let newSelection = new Set<number>();
    let oldSelection: Set<number> = this.state.selectedDevices;
    
    if (!wasSites && isSites) {
      // Transition to by side from by device
      // Select only devices at sites
      // where every device at the site is selected      

      siteIdStrs.forEach((siteIdStr, index) => {
        let devicesAtSite = deviceLists[index];

        let allSelected = devicesAtSite.every((device) => {
          return oldSelection.has(device.id);
        });

        if (allSelected) {
          devicesAtSite.forEach((device) => {
            newSelection.add(device.id);
          });
        }
      });

      this.setState({
        selectedDevices: newSelection
      });
    }
  }

  public showDialog(initial: 
      ModalUpdateReportOptions): Promise<ModalUpdateReportResult> {
    //this.siteAddressInput.value = site.address;
    console.log('initial device selection', initial.selectedDevices);
    return setStatePromise<ModalUpdateReportState, ModalUpdateReport>(this, {
      organization: initial.organization,
      siteDevices: initial.siteDevices,
      reportType: initial.reportType,
      startDate: initial.startDate,
      endDate: initial.endDate,
      aggregation: initial.aggregation,
      selectedDevices: new Set<number>(initial.selectedDevices),
      selectedAccessCards: new Set<number>(initial.selectedAccessCards)
    }).then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalUpdateReport');
    return (
      <Modal 
          ref={this.modal}
          onOK={() => this.onOK()}
          title={xl8('updateReport')}
          confirmButtonIcon={<RefreshIcon width="24" height="24" fill="#fff"/>}
          confirmButtonContent={xl8('updateReport')}
          bodyClassName="calendar-autoscroll-target"
          // confirmDisabled={!this.isDateRangeValid()}
          >
          <label>
            {xl8('reportType')}
            <SwychedSelect value={this.state.reportType}
              onChange={(setting) => {
                setStatePromise<ModalUpdateReportState, 
                    ModalUpdateReport>(this, {
                  reportType: setting
                });
              }}
              >
            <SwychedOption value={ReportType.session}>
              {xl8('sessionHistory')}
            </SwychedOption>
            <SwychedOption value={ReportType.fault}>
              {xl8('faultHistory')}
            </SwychedOption>
            {/* <SwychedOption value={ReportType.occupancy}>
              Peak Occupancy
            </SwychedOption> */}
            {/* <SwychedOption value={ReportType.power}
              // hidden={isDeveloper()}
            >
              {xl8('peakPower')}
            </SwychedOption> */}
          </SwychedSelect>
        </label>
        <label>
          {xl8('dateRange')} 
          <SwychedDateSelect
            ref={this.dateSelectRef}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={(start: Date | undefined, 
                end: Date | undefined) => {
              let stateUpd: Partial<ModalUpdateReportState> = {};
              if (start !== undefined)
                stateUpd.startDate = start;
              if (end !== undefined)
                stateUpd.endDate = end;
              this.setState(stateUpd as ModalUpdateReportState);
          }}
          />
        </label>

        {
          this.needDevices() && 
          <label>
            {xl8('select')} {xl8('sites')} / {xl8('devices')}
            <div>
              <SiteDropdown
                ref={this.siteDeviceDropdownRef}
                siteOnly={false}
                organization={this.state.organization}
                siteDevices={this.state.siteDevices}
                hideLabel={true}    
                value={this.state.selectedDevices}
                onChange={(value) => {
                  console.log('devices changed', value);
                  this.setState({
                    selectedDevices: value
                  });
                }}
                />
            </div>
          </label>
        }

        {
          this.needSites() && 
          <label>
            {xl8('selectSite')}
            <div>
              <SiteDropdown
                ref={this.siteOnlyDropdownRef}
                siteOnly={true}
                organization={this.state.organization}
                siteDevices={this.state.siteDevices}
                hideLabel={true}
                value={this.state.selectedDevices}
                onChange={(value) => {
                  console.log('sites changed', value);
                  this.setState({
                    selectedDevices: value
                  });
                }}
                />
            </div>
          </label>
        }

        <label hidden={!this.needAggregation()}>
          {xl8('aggregatedBy')}:
          <div>
            <SwychedSelect value={this.state.aggregation}
                onChange={(value) => {
                  this.setState({
                    aggregation: value
                  });
                }}>
              <SwychedOption value={Aggregation.day}>
                {xl8('day')}
              </SwychedOption>
              <SwychedOption value={Aggregation.hour}>
                {xl8('hour')}
              </SwychedOption>
            </SwychedSelect>
          </div>
        </label>
      </Modal>
    );
  }
  
  private isDateRangeValid() {
    return this.state.startDate &&
      this.state.endDate &&
      this.state.startDate.getTime() > 0 &&
      this.state.endDate.getTime() <= 0xffffffff * 1000 &&
      this.state.startDate.getTime() <=
      this.state.endDate.getTime();
  }
  
  needDevices(type?: ReportType): boolean {
    return [
      ReportType.fault,
      ReportType.session
    ].includes(type || this.state.reportType);
  }

  needSites(type?: ReportType): boolean {
    return [
      ReportType.occupancy,
      ReportType.power
    ].includes(type || this.state.reportType);
  }

  needAggregation(type?: ReportType): boolean {
    return [
      ReportType.occupancy,
      ReportType.power
    ].includes(type || this.state.reportType);
  }

  private onOK(): Promise<ModalUpdateReportResult> {
    let siteDropdown = this.needSites() 
      ? this.siteOnlyDropdownRef?.current
      : this.siteDeviceDropdownRef.current;
    let selectedSites = siteDropdown?.getSelectedSiteIds() || [];
    let selectedDevices = siteDropdown?.getSelectedDeviceIds() || [];
    console.log('returning site selection=', 
      selectedSites, 'device selection=', selectedDevices);
    return Promise.resolve({
      options: {
        organization: this.state.organization,
        siteDevices: this.state.siteDevices,
        reportType: this.state.reportType,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        aggregation: this.state.aggregation,
        selectedAccessCards: this.accessCardDropdownRef.current
          ?.getSelectedCardIds() || [],
        selectedSites: selectedSites,
        selectedDevices: selectedDevices
      },
      renderedDateSelection: this.dateSelectRef.current?.getDisplayNode(),
      renderedSiteSelection: this.siteOnlyDropdownRef.current
        ?.renderSiteSelection(true) || null,
      renderedDeviceSelection: this.siteDeviceDropdownRef.current
        ?.renderSiteSelection(false) || null,
      renderedAccessCardSelection: this.accessCardDropdownRef.current
        ?.renderGroupSelection() || null
    });
  }
}
