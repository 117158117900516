import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface CCS2IconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class CCS2Icon extends PureComponent<CCS2IconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4792 11.25C11.8819 11.25 12.2083 10.9142 12.2083 10.5C12.2083 10.0858 11.8819 9.75 11.4792 9.75C11.0765 9.75 10.75 10.0858 10.75 10.5C10.75 10.9142 11.0765 11.25 11.4792 11.25Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 13C12.8807 13 14 11.8807 14 10.5C14 9.11929 12.8807 8 11.5 8C10.1193 8 9 9.11929 9 10.5C9 11.8807 10.1193 13 11.5 13ZM11.5 12C12.3284 12 13 11.3284 13 10.5C13 9.67157 12.3284 9 11.5 9C10.6716 9 10 9.67157 10 10.5C10 11.3284 10.6716 12 11.5 12Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M15.5 15.4898C15.5 15.904 15.1642 16.2398 14.75 16.2398C14.3358 16.2398 14 15.904 14 15.4898C14 15.0756 14.3358 14.7398 14.75 14.7398C15.1642 14.7398 15.5 15.0756 15.5 15.4898Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.25 15.5C17.25 16.8807 16.1307 18 14.75 18C13.3693 18 12.25 16.8807 12.25 15.5C12.25 14.1193 13.3693 13 14.75 13C16.1307 13 17.25 14.1193 17.25 15.5ZM16.25 15.5C16.25 16.3284 15.5784 17 14.75 17C13.9216 17 13.25 16.3284 13.25 15.5C13.25 14.6716 13.9216 14 14.75 14C15.5784 14 16.25 14.6716 16.25 15.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M18 11.25C18.4142 11.25 18.75 10.9142 18.75 10.5C18.75 10.0858 18.4142 9.75 18 9.75C17.5858 9.75 17.25 10.0858 17.25 10.5C17.25 10.9142 17.5858 11.25 18 11.25Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18 13C19.3807 13 20.5 11.8807 20.5 10.5C20.5 9.11929 19.3807 8 18 8C16.6193 8 15.5 9.11929 15.5 10.5C15.5 11.8807 16.6193 13 18 13ZM18 12C18.8284 12 19.5 11.3284 19.5 10.5C19.5 9.67157 18.8284 9 18 9C17.1716 9 16.5 9.67157 16.5 10.5C16.5 11.3284 17.1716 12 18 12Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M22 15.4898C22 15.904 21.6642 16.2398 21.25 16.2398C20.8358 16.2398 20.5 15.904 20.5 15.4898C20.5 15.0756 20.8358 14.7398 21.25 14.7398C21.6642 14.7398 22 15.0756 22 15.4898Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.75 15.5C23.75 16.8807 22.6307 18 21.25 18C19.8693 18 18.75 16.8807 18.75 15.5C18.75 14.1193 19.8693 13 21.25 13C22.6307 13 23.75 14.1193 23.75 15.5ZM22.75 15.5C22.75 16.3284 22.0784 17 21.25 17C20.4216 17 19.75 16.3284 19.75 15.5C19.75 14.6716 20.4216 14 21.25 14C22.0784 14 22.75 14.6716 22.75 15.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M24.5 11.25C24.9142 11.25 25.25 10.9142 25.25 10.5C25.25 10.0858 24.9142 9.75 24.5 9.75C24.0858 9.75 23.75 10.0858 23.75 10.5C23.75 10.9142 24.0858 11.25 24.5 11.25Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5 13C25.8807 13 27 11.8807 27 10.5C27 9.11929 25.8807 8 24.5 8C23.1193 8 22 9.11929 22 10.5C22 11.8807 23.1193 13 24.5 13ZM24.5 12C25.3284 12 26 11.3284 26 10.5C26 9.67157 25.3284 9 24.5 9C23.6716 9 23 9.67157 23 10.5C23 11.3284 23.6716 12 24.5 12Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 8C15.3284 8 16 7.32843 16 6.5C16 5.67157 15.3284 5 14.5 5C13.6716 5 13 5.67157 13 6.5C13 7.32843 13.6716 8 14.5 8ZM14.5 7C14.7761 7 15 6.77614 15 6.5C15 6.22386 14.7761 6 14.5 6C14.2239 6 14 6.22386 14 6.5C14 6.77614 14.2239 7 14.5 7Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23 6.5C23 7.32843 22.3284 8 21.5 8C20.6716 8 20 7.32843 20 6.5C20 5.67157 20.6716 5 21.5 5C22.3284 5 23 5.67157 23 6.5ZM22 6.5C22 6.77614 21.7761 7 21.5 7C21.2239 7 21 6.77614 21 6.5C21 6.22386 21.2239 6 21.5 6C21.7761 6 22 6.22386 22 6.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.9356 3H9.06442C7.7652 4.85765 7 7.13691 7 9.6C7 15.8487 11.9249 20.9143 18 20.9143C24.0751 20.9143 29 15.8487 29 9.6C29 7.13691 28.2348 4.85765 26.9356 3ZM28 9.6C28 15.3232 23.4965 19.9143 18 19.9143C12.5035 19.9143 8 15.3232 8 9.6C8 7.53249 8.58884 5.61096 9.60079 4H26.3992C27.4112 5.61096 28 7.53249 28 9.6Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M13.5 29C14.3284 29 15 28.3284 15 27.5C15 26.6716 14.3284 26 13.5 26C12.6716 26 12 26.6716 12 27.5C12 28.3284 12.6716 29 13.5 29Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17 27.5C17 29.433 15.433 31 13.5 31C11.567 31 10 29.433 10 27.5C10 25.567 11.567 24 13.5 24C15.433 24 17 25.567 17 27.5ZM16 27.5C16 28.8807 14.8807 30 13.5 30C12.1193 30 11 28.8807 11 27.5C11 26.1193 12.1193 25 13.5 25C14.8807 25 16 26.1193 16 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path d="M24 27.5C24 28.3284 23.3284 29 22.5 29C21.6716 29 21 28.3284 21 27.5C21 26.6716 21.6716 26 22.5 26C23.3284 26 24 26.6716 24 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.5 31C24.433 31 26 29.433 26 27.5C26 25.567 24.433 24 22.5 24C20.567 24 19 25.567 19 27.5C19 29.433 20.567 31 22.5 31ZM22.5 30C23.8807 30 25 28.8807 25 27.5C25 26.1193 23.8807 25 22.5 25C21.1193 25 20 26.1193 20 27.5C20 28.8807 21.1193 30 22.5 30Z" fill={this.props.fill || "#C1C5C8"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 27.5C7 24.4624 9.46243 22 12.5 22H23.5C26.5376 22 29 24.4624 29 27.5C29 30.5376 26.5376 33 23.5 33H12.5C9.46243 33 7 30.5376 7 27.5ZM8 27.5C8 25.0147 10.0147 23 12.5 23H23.5C25.9853 23 28 25.0147 28 27.5C28 29.9853 25.9853 32 23.5 32H12.5C10.0147 32 8 29.9853 8 27.5Z" fill={this.props.fill || "#C1C5C8"}/>
      </svg>  
    );
  }
}