import React from "react";
import { PieLabel, PieLabelRenderProps } from "recharts";

//const RADIAN = Math.PI / 180;

export function CustomPieChartLabel(text: string)
    : (props: PieLabelRenderProps) => PieLabel {
  return (props: PieLabelRenderProps) => {
    console.assert(props, 'what do we do with null props eh?');
    //const sin = Math.sin(-RADIAN * props.midAngle);
    //const cos = Math.cos(-RADIAN * props.midAngle);
    //const sx = +props.cx + (+props.outerRadius + 10) * cos;
    //const sy = +props.cy + (+props.outerRadius + 10) * sin;
    //const mx = +props.cx + (+props.outerRadius + 30) * cos;
    //const my = +props.cy + (+props.outerRadius + 30) * sin;
    //const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    //const ey = my;
    //const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <>
        {/* <text x={props.cx} y={props.cy} 
          textAnchor="middle" fill={props.fill}>
          {!text && props.centerText.title}
        </text> */}
        {(text || props.centerText.value)
        .split('\n').map((line, index, lines) => {
          return (
            <text className="pie-center-label"
                x={props.cx} 
                y={+props.cy + +props.outerRadius * 0.22 * 
                  (index - (lines.length - 1) / 2)} 
                dy={5} 
                textAnchor="middle" 
                fill="#555"
                key={line}>
              {line}
            </text>
          );
        })}
      </>
    );
  };
}
