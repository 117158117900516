import { AuthService } from '../components/services/auth.service';
import { BroadcastService } from '../components/services/broadcast.service';
import React, { Component, ReactNode } from 'react';
import {
  addDays, NotAuthenticatedError, smartSort, startOfDay, toastError
} from '../components/shared/ui';
import {
  AppNotificationsResponse,
  AppNotificationTrigger
} from './notification';
import { Layout, Layouts } from 'react-grid-layout';

const errorHack = false;
if (errorHack) {
  let origError: any = Error as unknown as any;
  let target: any = window as unknown as any;
  target.Error = function(this: any, ...args: any[]) {
    console.log('error constructed');
    return origError.apply(this, Array.prototype.slice.call(args));
  } as unknown;
}

let mock = true;
let apiUnique = '1xev67zyxi';
let apiRegion = 'ca-central-1';
let apiStage = 'dev';
let apiRoot = 'https://' +
  apiUnique +
  '.execute-api.' + 
  apiRegion + 
  '.amazonaws.com/' + 
  apiStage;

export enum ChangeOp {
  DEL,
  ADD,
  EDIT
}

export type ChangeListener<T> = (updatedItem: T, op: ChangeOp) => void;
let organizationListeners: ChangeListener<Organization>[] = [];

export interface Whitelabel {
  id: number,
  organizationId: number,
  hostname: string,
  wallpaper: string,
  logo: string
}

// type ConnectorTypeString = 
//   'SAE-J1772' | 'Type-2' | 'CHAdeMO' | 'CCS' | 'CCS2';

export enum ConnectorType {
  SAEJ1772 = 'SAE-J1772',
  Type2 = 'Type-2',
  CCS = 'CCS',
  CCS2 = 'CCS2',
  CHAdeMO = 'CHAdeMO',

}

export type DeviceStatusString = 
  'Available' | 'Preparing' | 'Charging' | 'SuspendedEV' | 'SuspendedEVSE' |
  'Finishing' | 'Reserved' | 'Faulted' | 'Unavailable' | 'Unknown';

export enum ConnectorStatus {
  Available = 'Available',
  Preparing = 'Preparing',
  Charging = 'Charging',
  SuspendedEV = 'SuspendedEV',
  SuspendedEVSE = 'SuspendedEVSE',
  Finishing = 'Finishing',
  Reserved = 'Reserved',
  Faulted = 'Faulted',
  Unavailable = 'Unavailable',
  Unknown = 'Unknown'
}

export interface Connector {
  id: number;
  type: string;
  name: string | null;
  portConnectorId: number | null;
  chargerType: ChargerType;
  status: ConnectorStatus | DeviceStatusString;
}

// export interface DeviceAccessControlPlan {
//   id: number;
//   evseId: number;
//   acpId: number;
// }

export enum ChargerType {
  None = '',
  DCFastCharger = 'dcfc',
  ACL2Charger = 'ac'
}

export interface Device {
  id: number;
  locationId: number;
  organizationId: number;
  name: string;
  type: DeviceType | DeviceTypeString;
  ocppChargepointId: string;
  connectors: Connector[];
  accessControlPlans: number[];
  accessControlGroups: number[];
  status?: DeviceStatusString;
  virtualDeviceIndex?: number;
  isHub: boolean;
  chargerType: ChargerType | null;
  autostartTagId: string | null;
}

export type DeviceTypeString =
  'evseEVBox' | 'evseOcpp' | 'evseModbus';

export enum DeviceType { 
  EVBox = 'evseEVBox',
  Ocpp = 'evseOcpp',
  Modbus = 'evseModbus'
}

export interface Organization {
  id: number,
  parentId: number | null,
  name: string,
  icons?: {
    default?: string,
    small?: string,
    medium?: string,
    large?: string
  },

  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  billingStreet: string;
  billingCity: string;
  billingCountry: string;
  billingRegion: string;
  billingPostalZip: string;
  billingPlan: string;
  billingFrequency: string;
  billingCurrency: string;
}

export interface Folder {
  id: number;
  name: string;
}
export interface PostOrganizationPayload {
  name: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  billingStreet: string;
  billingCity: string;
  billingCountry: string;
  billingRegion: string;
  billingPostalZip: string;
  billingPlan: string;
  billingFrequency: string;
  billingCurrency: string;
}

export interface PostOrganizationResponse {
  id: number;
}

export interface PatchOrganizationPayload {
  id: number;
  name?: string;
}

export interface Site {
  id: number;
  organizationId: number;
  name: string;
  address: string;
  popul8_url: string;
  notes: string;
  accessControlGroups: number[];
  billingContact: string;
  billingCity: string;
  billingPostal: string;
  billingRegion: string;
  billingCountry: string;
}

export interface AccessControlGroup {
  id: number;
  organizationId: number;
  name: string;
}

export type AccessControlPlanMap = {
  [planId: number]: AccessControlPlan
}

export type AccessControlPlanGroupMap = { 
  [planId: number]: AccessControlPlanGroup[]
}

export type AccessControlGroupMap = {
  [groupId: number]: AccessControlGroup;
}

export interface AccessControlPlansResponse {
  plans: AccessControlPlanMap;
  planGroups: AccessControlPlanGroupMap;
  groups: AccessControlGroupMap;
}

export interface AccessControlPlanGroup {
  id: number;

  // Access control plan id
  acpId: number;

  // Access control plan group id
  acpgId: number;
}

export interface AccessControlPlan {
  id: number;
  organizationId: number;
  name: string;
  accessMethod: string;
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  organizationId: number;
  phone: string;
  notificationSubs: number[];
  notificationSites: number[];
  notifyEmail: boolean;
  notifySms: boolean;
  notificationEmail: string;
  notificationSms: string;
}

export type PricingPlanType = 'static' | 'time' | 'demand' | 'duration';

export type PricingPlanAnyKey = 
  keyof PricingPlanStatic | 
  keyof PricingPlanDynamic;

export interface PricingPlanBase {
  id: number;
  organizationId: number;
  name: string;
  type: PricingPlanType;
  nextRev: null | number;
}

export type CurrencyString = string;

export interface PriceMap {
  level2: CurrencyString;
  dcfc: CurrencyString;
  ultrafast: CurrencyString;
}

export interface PricingPlanStatic extends PricingPlanBase {
  unit: string;
  currency: string;
  prices: PriceMap;
}

export interface PricingPlanDynamicRule {
  ruleId: number;
  dayFilter: number;
  threshold0: number;
  threshold1: number;
  rulePlanId: number;
}

export interface PricingPlanDynamic extends PricingPlanBase {
  defaultPlanId: number;
  rules: PricingPlanDynamicRule[];
}

export interface PricingPlansResponse {
  planLookup: Map<number, PricingPlanBase>;
  staticPricingPlans: PricingPlanStatic[];
  dynamicPricingPlans: PricingPlanDynamic[];
}

function getFakeStaticPricingPlans(): PricingPlanStatic[] {
    let staticPricingPlans: PricingPlanStatic[] = [
    {
      id: 1,
      organizationId: 1,
      name: 'Peak Pricing',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.12 CAD/kWh',
        dcfc: '0.15 CAD/kWh',
        ultrafast: '0.20 CAD/kWh'
      },
      nextRev: null
    },
    {
      id: 2,
      organizationId: 1,
      name: 'Shoulder Pricing',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.09 CAD/kWh',
        dcfc: '0.12 CAD/kWh',
        ultrafast: '0.17 CAD/kWh'
      },
      nextRev: null
    }, 
    {
      id: 3,
      organizationId: 1,
      name: 'Off-Peak Pricing',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.06 CAD/kWh',
        dcfc: '0.09 CAD/kWh',
        ultrafast: '0.12 CAD/kWh'
      },
      nextRev: null
    }, 
    {
      id: 4,
      organizationId: 1,
      name: 'Peak Pricing for Honk',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.04 CAD/min',
        dcfc: '0.06 CAD/min',
        ultrafast: '0.07 CAD/min'
      },
      nextRev: null
    }, 
    {
      id: 5,
      organizationId: 1,
      name: 'Shoulder Pricing for Honk',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.03 CAD/min',
        dcfc: '0.04 CAD/min',
        ultrafast: '0.06 CAD/min'
      },
      nextRev: null
    }, 
    {
      id: 6,
      organizationId: 1,
      name: 'Off-Peak Pricing for Honk',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.02 CAD/min',
        dcfc: '0.04 CAD/min',
        ultrafast: '0.05 CAD/min'
      },
      nextRev: null
    }, 
    {
      id: 7,
      organizationId: 1,
      name: 'Peak Pricing for UIC',
      unit: 'minute',
      currency: 'cad',
      type: 'static',
      prices: {
        level2: '0.10 CAD/min',
        dcfc: '0.12 CAD/min',
        ultrafast: '0.18 CAD/min'
      },
      nextRev: null
    }
  ];
  return staticPricingPlans;
}

export function getPlansByOrganization(
    organizationId: number): Promise<PricingPlansResponse> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/pricing-plans';

  return fetchJsonWithAuth<PricingPlanBase[]>('GET', url).then((plans) => {
    return plans.reduce((result, plan) => {
      if (plan.type === 'static')
        result.staticPricingPlans.push(plan as PricingPlanStatic);
      else
        result.dynamicPricingPlans.push(plan as PricingPlanDynamic);
      result.planLookup.set(plan.id, plan);
      return result;
    }, {
      staticPricingPlans: [],
      dynamicPricingPlans: [],
      planLookup: new Map<number, PricingPlanBase>()
    } as PricingPlansResponse);
  });
}

// export function getSyntheticDynamicPricingPlansByOrganization(
//   organizationId: number): Promise<PricingPlanDynamic[]> {

//   let dynamicPricingPlans: PricingPlanDynamic[] = [
//     {
//       id: 1,
//       organizationId: null,
//       name: 'Peak Pricing',
//       day: 'Weekdays',
//       startTime: '9:00',
//       endTime: '21:00'
//     },
//     {
//       id: 2,
//       organizationId: null,
//       name: 'Shoulder Pricing',
//       days: 'Weekends & Holidays',
//       startTime: '9:00',
//       endTime: '21:00'
//     },
//     {
//       id: 3,
//       organizationId: null,
//       name: 'Off-Peak Pricing',
//       days: 'Weekdays',
//       startTime: '09:00',
//       endTime: '17:00'
//     },
//     {
//       id: 4,
//       organizationId: null,
//       name: 'Peak Pricing',
//       days: 'all days of the week',
//       startTime: '06:00',
//       endTime: '21:00'
//     },
//     {
//       id: 5,
//       organizationId: null,
//       name: 'Shoulder Pricing',
//       days: 'Weekdays',
//       startTime: '05:00',
//       endTime: '24:00'
//     }
//   ];

//   return Promise.resolve(dynamicPricingPlans);
// }

export interface TicketStatus {
  id: number;
  name: string;
}

// When the holiday bit is 0, it means "excluding holidays"
// When the holiday bit is 1, it means, "including holidays"
export enum PricingDayMaskBit {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
  Holidays = 7
}

export enum PricingDayMask {
  Sun = 1 << PricingDayMaskBit.Sun,
  Mon = 1 << PricingDayMaskBit.Mon,
  Tue = 1 << PricingDayMaskBit.Tue,
  Wed = 1 << PricingDayMaskBit.Wed,
  Thu = 1 << PricingDayMaskBit.Thu,
  Fri = 1 << PricingDayMaskBit.Fri,
  Sat = 1 << PricingDayMaskBit.Sat,
  Holidays = 1 << PricingDayMaskBit.Holidays,
  Weekdays = Mon | Tue | Wed | Thu | Fri,
  Weekends = Sat | Sun,
  WeekendsAndHolidays = Weekends | Holidays,
  AllDays = Weekdays | WeekendsAndHolidays
}

export function getTicketStatuses(): Promise<TicketStatus[]> {
  let statuses: TicketStatus[] = [{
    id: 1,
    name: 'Open'
  }, {
    id: 2,
    name: 'Pending'
  }, {
    id: 3,
    name: 'Solved'
  }, {
    id: 4,
    name: 'Closed'
  },
];

  return Promise.resolve(statuses);
}

export enum SupportTicketStatus {
  open,
  pending,
  solved,
  closed
}

export function patchPostSupportTicketByOrganization(
  verb: "PATCH" | "POST",
  organizationId: number, ticket: SupportTicket): Promise<SupportTicket> {
  let url = apiRoot + '/organizations/' +  
    organizationId + '/support-tickets' + 
    (ticket.id ? '/' + ticket.id : '');
  return fetchWithAuthBodyResponse<SupportTicket>(verb, url, ticket);
}

export interface DevicePair {
  evseId: number;
  connectorId: number | null;
}

export interface SupportTicketComment {
  id: number;
  timestamp: number;
  comment: string;
}

export interface SupportTicket {
  id: number;
  organizationId: number;
  subject: string;
  status: SupportTicketStatus;
  dateAdded: number;
  sites: number[];
  assignees: number[];
  devices: DevicePair[];
  groups: number[];
  comments: SupportTicketComment[];
  notes: string;
  version: number;
}

export interface SupportTicketGroup {
  id: number;
  organizationId: number;
  name: string;
}

interface SupportTicketsResponse {
  tickets: SupportTicket[];
}

export enum SupportTicketStatus {
  Open = 'Open',
  Pending = 'Pending',
  Solved = 'Solved',
  Closed = "Closed"
}

export enum AccessCardAuthorizationStatus {
  Accepted = 'Accepted',
  Blocked = 'Blocked',
  Expired = 'Expired',
  Invalid = 'Invalid',
  ConcurrentTx = 'ConcurrentTx',
  Unknown = ''
}

export interface AccessCard {
  id: number;
  acpgId: number;
  name: string;
  dateAdded: number;
  cardValue: string;
  authorizationStatus: AccessCardAuthorizationStatus;
}

// Entry as in putting cards into the system
export interface AccessCardEntry {
  dateAdded: number;
  cardValue: string;
}

// From listening for access cards at a device
export interface AccessCardEntryResponse {
  lastId: number;
  cards: AccessCardEntry[];
}

interface AccessCardsResponse {
  cards: AccessCard[];
}

export interface PaymentIntegration {
  id: number;
  organizationId: number;
  name: string;
  icon: string;
}

interface CreateUserPostBody {
  email: string;
  role: string;
  // firstName: string;
  // lastName: string;
}

export interface CreateUserResponse {
  id: number;
}

export interface Permission {
  id: number;
  parent: number;
  name: string;
  readable: boolean;
  writable: boolean;
  heading: boolean;
}

export interface RestartDeviceResponse {
  result: string;
}

export interface PostSitePayload {
  name: string;  
}
export interface PostUserPayload {
  email: string;
  role: string;
}

// export interface PostConnector {
//   ocppConnectorId: number,
//   type: ConnectorType | ConnectorTypeString;
//   name: string | null;
// }

// export interface PostDevicePayload {
//   name: string;
//   type: DeviceType | DeviceTypeString;
//   ocppChargepointId: string;
//   chargerType: ChargerType;
//   ocppVersion?: string;
//   connectors: PostConnector[];
//   accessControlPlans: number[];
//   accessControlGroups: number[];
// }

// enforce at least one of optional parameters required
// export type PatchDevicePayload = {
//   name?: string,
//   ocppChargepointId?: string, 
//   connectors?: Connector[]
// } & ({ name: string, ocppChargepointId } | 
//   { ocppChargepointId: string } | 
//   { connectors: Connector[] });

interface StationPick {
  siteId: number;
  evseId: number;
  connectorId: number;
}

function prng(seed: number) {
  return {
    state: seed
  };
}

function rand(prng) {
  let n: number = prng.state;
  let b0 = n & 1;
  let b4 = n & 16;
  n = ((n << 1) & 0x7f) | (b0 ^ b4);
  prng.state = n;

  return (n & 0x7f) / 0x80;
}

function pickFakeStations(
    organizationId: number, count: number): Promise<StationPick[]> {
  return getAllSiteDevices(organizationId).then((siteDevices) => {
    let picks: StationPick[] = [];

    let siteIds = Object.keys(siteDevices.deviceListBySite);
    let deviceLists = Object.values(siteDevices.deviceListBySite);

    deviceLists.forEach((devices, siteIndex) => {
      devices.forEach((dev) => {
        dev.connectors.forEach((conn) => {
          if (!conn.portConnectorId)
            return;
          picks.push({
            siteId: +siteIds[siteIndex],
            evseId: dev.id,
            connectorId: conn.portConnectorId
          });
        });
      });
    });

    let rng = prng(organizationId);
    while (picks.length > count) {
      let index = (rand(rng) * picks.length) ^ 0;
      picks.splice(index, 1);
    }

    return picks;
  });
}

// export function getSupportTicketsByOrganization(
//   organizationId: number): Promise<SupportTicket[]> {
//     let url = apiRoot + '/organizations/' +  
//       organizationId + '/support-tickets';
//     return fetchJsonWithAuth<SupportTicketsResponse>('GET', url).then((response) => {
//       return response.tickets;
//     });
// }

export function getSupportTicketsByOrganization(
    organizationId: number): Promise<SupportTicketsResponse> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/support-tickets';
  return fetchJsonWithAuth<SupportTicketsResponse>('GET', url);
}

// export function getSupportTicketsByOrganization_fake(
//     organizationId: number): Promise<SupportTicket[]> {
//   let tickets: SupportTicket[] = [{
//     id: 1,
//     organizationId: null,
//     stgId: 2,
//     siteId: 0,
//     group: 1,
//     //station: ,
//     status: SupportTicketStatus.closed,
//     summary: 'Broken Plug',
//     description: 'More details',
//     dateAdded: 0,
//     evseId: 0,
//     connectorId: 0
//   }, {
//     id: 2,
//     organizationId: null,
//     stgId: 3,
//     siteId: 0,
//     group: 1,
//     //station: ,
//     status: SupportTicketStatus.open,
//     summary: 'Short Circuit',
//     description: 'More details',
//     dateAdded: 0,
//     evseId: 0,
//     connectorId: 0
//   }, {
//     id: 3,
//     organizationId: null,
//     stgId: 2,
//     siteId: 1,
//     group: 1,
//     //station: ,
//     status: SupportTicketStatus.open,
//     summary: 'Replace connector',
//     description: 'More details',
//     dateAdded: 0,
//     evseId: 1,
//     connectorId: 1
//   }, {
//     id: 4,
//     organizationId: null,
//     stgId: 3,
//     siteId: 1,
//     group: 1,
//     //station: ,
//     status: SupportTicketStatus.open,
//     summary: 'Device not responsive',
//     description: 'More details',
//     dateAdded: 0,
//     evseId: 1,
//     connectorId: 1
//   }];
  
//   return pickFakeStations(organizationId, tickets.length)
//   .then((picks) => {
//     let items = tickets.slice();
//     return picks.map((pick, index) => {
//       let item = {
//         ...items[index]
//       };
//       item.siteId = pick.siteId;
//       item.evseId = pick.evseId;
//       item.connectorId = pick.connectorId;
//       return item;
//     });
//   });
//   return Promise.resolve(tickets);
// }

export function getSupportTicketGroupsByOrganization(
    organizationId: number): Promise<SupportTicketGroup[]> {
  let ticketGroups: SupportTicketGroup[] = [
    {
      id: 1,
      organizationId: null,
      name: 'Open'
    },
    {
    id: 2,
    organizationId: null,
    name: 'Pending'
    },
    {
    id: 3,
    organizationId: null,
    name: 'Solved'
    },
    {
    id: 4,
    organizationId: null,
    name: 'Closed'
    }
  ];

  return Promise.resolve(ticketGroups);
}

export function getWhitelabel(organizationId: number): Promise<Whitelabel> {
  // return Promise.resolve({
  //   id: '1',
  //   organizationId: '1',
  //   hostname: 'localhost',
  //   wallpaper: '',
  //   logo: '//placehold.it/200x200'
  // });

  return getOrganizations().then((orgs) => {
    let org = orgs && orgs[0];
    if (!org)
      return null;
    
    return {
      id: 0,
      organizationId: org.id,
      hostname: 'localhost',
      wallpaper: '',
      logo: org.icons.default || org.icons.small ||
        org.icons.medium || org.icons.large || ''
    } as Whitelabel;
  });
}

export class HttpError extends Error {
  public static readonly HTTP_OK = 200;
  public static readonly HTTP_NOT_FOUND = 404;
  public static readonly HTTP_CONFLICT = 409;
  public static readonly HTTP_UNAUTHORIZED = 401;
  public static readonly HTTP_FORBIDDEN = 403;
  public static readonly HTTP_CLIENT_ERROR_BASE = 400;
  public static readonly HTTP_SERVER_ERROR_BASE = 500;
  public static readonly HTTP_ERROR_BASE = 
    HttpError.HTTP_CLIENT_ERROR_BASE;
  public static readonly HTTP_SERVICE_UNAVAILABLE = 503;

  static throwIfErrorResponse(response: Response)
      : Promise<Response> | Response {
    let retryAfter = +(response.headers['Retry-After'] ?? Infinity);
    if (response.status >= HttpError.HTTP_ERROR_BASE) {
      if (response.headers['Content-Type']?.includes('application/json')) {
        return response.json().then((body) => {
          throw new HttpError(response.status, 
              body.message || body.errorMessage, retryAfter);
        });
      }
      return response.text().then((body) => {
        let parsed: any;
        try {
          // It didn't say it was JSON, try anyway :(
          parsed = JSON.parse(body);
        } catch (err) {
          throw new HttpError(response.status, body, retryAfter);
        }
        throw new HttpError(response.status, 
          parsed.message || parsed.errorMessage, retryAfter);
      });
    }
    return response;
  }

  constructor(public statusCode: number, message: string, 
      private retryAfter: number = Infinity) {
    super(message);
  }

  public isConflict(): boolean {
    return this.statusCode === HttpError.HTTP_CONFLICT;
  }

  public isUnauthorized(): boolean {
    return this.statusCode === HttpError.HTTP_UNAUTHORIZED;
  }

  public isForbidden(): boolean {
    return this.statusCode === HttpError.HTTP_FORBIDDEN;
  }

  public isServerError(): boolean {
    return this.inRange(HttpError.HTTP_SERVER_ERROR_BASE);
  }
  
  public isClientError(): boolean {
    return this.inRange(HttpError.HTTP_CLIENT_ERROR_BASE);
  }

  private inRange(base: number): boolean {
    return this.statusCode >= base && this.statusCode < (base + 100);
  }

  public getRetryInterval(): number {
    return this.retryAfter;
  }
}

function throwErrorMessageIfError<T>(res: Response, 
    empty?: boolean): Promise<T> {
  let contentType = res.headers.get('Content-Type');
  let isJson = (contentType === 'application/json');
  let retryAfter = +(res.headers['Retry-After'] ?? Infinity);

  if (!isJson) {
    return res.text().then((text) => {
      throw new HttpError(res.status, text, retryAfter);
    });
  }

  // If empty is true, a sane caller's T=void
  if (empty)
    return Promise.resolve(null);
  
  try {
    return res.json().then((obj) => {
      if (typeof obj !== 'object') {
        throw new HttpError(res.status, 
          'Response is not JSON (' + contentType + '): ' + 
          JSON.stringify(obj), retryAfter);
      }
      
      if (obj && ('errorMessage' in obj))
        throw new HttpError(res.status, obj.errorMessage, retryAfter);
      
      if (obj && ('message' in obj))
        throw new HttpError(res.status, obj.message, retryAfter);
      
      if (res.status === 401)
        throw new HttpError(res.status, 'Access Denied', retryAfter);
      
      if (res.status === 403)
        throw new HttpError(res.status, 'Permission Denied', retryAfter);
      
      if (res.status >= 500)
        throw new HttpError(res.status, 
          'Server Error: ' + (obj.errorMessage || 'unknown', retryAfter));
      
      if (res.status >= 400)
        throw new HttpError(res.status, 
          'Invalid Request: ' + (obj.errorMessage || 'unknown', retryAfter));
      
      if (!empty)
        return obj;
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

function jsonResponseHandler<T>(): (res: Response) => Promise<T> {
  return (res: Response) => {
    return throwErrorMessageIfError<T>(res);
  };
}

function emptyResponseHandler(): (res: Response) => Promise<void> {
  return (res) => {
    return throwErrorMessageIfError(res, true);
  };
}

export function fetchJsonWithAuth<T>(method: string, url: string, 
    options?: RequestInit): Promise<T> {
  return fetchWithAuth(method, url, {
    ...options,
    headers: {
      Accept: 'application/json',
      ...options?.headers
    }
  }, false)
  .then(jsonResponseHandler<T>());
}

export function fetchWithAuthBodyResponse<T>(
    method: HTTPMethod, url: string, body: {},
    options?: RequestInit): Promise<T> {
  let extendedOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...options?.headers
    },
    body: JSON.stringify(body)
  };

  return fetchJsonWithAuth<T>(method, url, extendedOptions);
}

export function getOrganizations(): Promise<Organization[]> {
  let url = apiRoot + '/organizations';
  return fetchJsonWithAuth<Organization[]>('GET', url);
}

export function getSitesByOrganization(
    organizationId: number): Promise<Site[]> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) + 
    '/locations';
  return fetchJsonWithAuth<Site[]>('GET', url);
}

export function getUsersByOrganization(
    organizationId: number): Promise<User[]> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/users';
  return fetchJsonWithAuth<User[]>('GET', url).then((users) => {
    // HACK: Make it not fake
    users.forEach((user) => {
      user.email = user.email || '';
      user.firstName = user.firstName || '';
      user.lastName = user.lastName || '';
      user.role = user.role || '';
      user.notificationSubs = user.notificationSubs || [];
      user.notificationSites = user.notificationSites || [];
    });
    return users;
  });
}

export function getNotificationTriggers(
    organizationId: number): Promise<AppNotificationTrigger[]> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/notifications/triggers';
  return fetchJsonWithAuth<AppNotificationTrigger[]>('GET', url);
}

export function getReceivedNotifications(
    organizationId: number, offset: number | null = null, 
    limit: number | null = null, dismissed?: boolean, 
    retryCallback: ((done: boolean) => void) | null = null)
    : Promise<AppNotificationsResponse> {
  let query: any = {};
  
  if (dismissed)
    query.select = 'history';
  
  if (offset !== null)
    query.offset = '' + offset;
  
  if (limit !== null)
    query.limit = '' + limit;
  
  let queryStr = Object.keys(query).sort().map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
  }).join('&');
  
  let url = apiRoot + 
      '/organizations/' + encodeURIComponent(organizationId) +
      //'/notifications/messages' + queryStr;
      '/locations/status?fastRequest=messages' + 
      (queryStr ? '&' + queryStr : '');
  
  let attempt: () => Promise<AppNotificationsResponse> = () => {
    return fetchJsonWithAuth<AppNotificationsResponse>('GET', url)
    .then((response) => {
      if (response.status === 'needRetry') {
        if (retryCallback)
          retryCallback(false);
        return attempt();
      }
      return response;
    });
  };

  return attempt().then((result) => {
    if (retryCallback)
      retryCallback(true);
    return result;
  });
}

export function deleteAllNotifications(organizationId: number): Promise<void> {
  return deleteNotification(organizationId, 'all');
}

export function deleteNotification(organizationId: number, 
    notificationId: number | 'all'): Promise<void> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
     '/notifications/messages/' + encodeURIComponent(notificationId);
  
  return fetchJsonWithAuth<void>('DELETE', url);
}

export function postOrganization(
    parentOrganizationId: number, 
    payload: PostOrganizationPayload): Promise<Organization> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(parentOrganizationId);

  return fetchWithAuthBodyResponse<PostOrganizationResponse>(
    'POST', url, payload)
  .then((response) => {
    let updated: Organization = {
      id: response.id,
      parentId: parentOrganizationId,
      ...payload
    };
    
    return notifyChangeListeners(organizationListeners, updated, ChangeOp.ADD);
  });
}

export function deleteOrganizationById(
  organizationId: number): Promise<void> {
  let url = apiRoot + 
      '/organizations/' + encodeURIComponent(organizationId);
  
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function updateOrganization(
    organization: PatchOrganizationPayload)
    : Promise<Organization> {
  let url = apiRoot + 
        '/organizations/' + encodeURIComponent(organization.id);
  return fetchWithAuthBodyResponse<Organization>(
    'PATCH', url, organization);
}

export function postSiteByOrganization(
  organizationId: number, payload: Site): Promise<Site> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations';
  invalidateSiteDevices();
  return fetchWithAuthBodyResponse<Site>('POST', url, payload);
}

export interface OptionData {
  value: string;
  text: string;
}

export function connectorTypeOptions(): OptionData[] {
  return [
    { value: ConnectorType.SAEJ1772, text: 'Type 1 (J1772)' },
    { value: ConnectorType.Type2, text: 'Type 2' },
    { value: ConnectorType.CCS, text: 'CCS1' },
    { value: ConnectorType.CCS2, text: 'CCS2' },
    { value: ConnectorType.CHAdeMO, text: 'CHAdeMO' }
  ];
}

export function connectorTypeLookup(): Map<ConnectorType, OptionData> {
  return connectorTypeOptions().reduce((lookup, option) => {
    lookup.set(option.value as ConnectorType, option);
    return lookup;
  }, new Map<ConnectorType, OptionData>());
}

export function postUserByOrganization(
  organizationId: number, payload: PostUserPayload): Promise<User> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/users';
  return fetchWithAuthBodyResponse<User>('POST', url, payload);
}

export function deleteSiteById(
    organizationId: number, siteId: number): Promise<void> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(siteId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function getDevicesBySite(
    organizationId: number,
    siteId: number): Promise<Device[]> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(siteId) +
    '/evse';
  return fetchJsonWithAuth<Device[]>('GET', url);
}

export function postDeviceBySite(
    organizationId: number, siteId: number, 
    device: Device): Promise<{ id: number }> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(siteId) + 
    '/evse';

  // let payload: Device = {
  //   connectors: device.connectors,
  //   // .map((connector, index) => {
  //   //   return {
  //   //     ocppConnectorId: (index + 1),
  //   //     type: connector.type as ConnectorType,
  //   //     name: connector.name
  //   //   } as PostConnector;
  //   // }),
  //   name: device.name,
  //   ocppChargepointId: device.ocppChargepointId,
  //   type: device.type,
  //   chargerType: device.char
  //   accessControlPlans: device.accessControlPlans,
  //   accessControlGroups: device.accessControlGroups
  // };

  invalidateSiteDevices();

  return fetchWithAuthBodyResponse<{ id: number }>(
    'POST', url, device);
}

// export function patchDevice(deviceId: number, payload: PatchDevicePayload){
//   return new Promise<Device>((resolve, reject)=>{
//     let device=sampleDevices.find(x=>x.id===deviceId)
//     if (device) {
//       let newDevice: Device = {...device, ...payload};
//       mergeArrayWithObject(sampleDevices,newDevice);
//       setPersistedJson('devices', sampleDevices);
//       resolve(newDevice);
//     } else {
//       reject("404");
//     }
//   });
// }

export function deleteDeviceById(
    organizationId: number, locationId: number, 
    deviceId: number): Promise<void> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(locationId) +
    '/evse/' + encodeURIComponent(deviceId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function patchDevice(
    organizationId: number, locationId: number, 
    device: Device): Promise<Device> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(locationId) +
    '/evse/' + encodeURIComponent(device.id);
    return fetchWithAuthBodyResponse<Device>('PATCH', url, device);
}

export function restartDeviceById(organizationId: number,
    locationId: number, deviceId: number): Promise<string> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(locationId) +
    '/evse/' + encodeURIComponent(deviceId) +
    '/restart';
  return fetchJsonWithAuth<RestartDeviceResponse>('POST', url)
  .then((response) => {
    return response.result;
  });
}

export interface ConnectorStatusResponse {
  //connectorId: number;
  status: string;
}

export interface DeviceStatusConnectorLookupTimestampStatus {
  id: number;
  timestamp: number;
  status: DeviceStatusString;
}

export type DeviceStatusConnectorLookup = {
  [portConnectorId: number]: DeviceStatusConnectorLookupTimestampStatus;
}

export type DeviceStatusesDeviceLookup  = { 
  [evseId: number]: DeviceStatusConnectorLookup;
}

export type DeviceStatusesResponse = {
  organizationId: number;
  devices: DeviceStatusesDeviceLookup;
}

export type DeviceStatusesCompactResponse = {
  tokens: DeviceStatusString[];
  data: Array<[number, number, number, number, number]>;
}

export function getInstantResponse(organizationId: number): Promise<void> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations' +
    '?instant=';
  
  return fetchWithAuth('GET', url)
  .then(emptyResponseHandler());
}

export function getDeviceStatuses(organizationId: number, 
    sinceResponse: DeviceStatusesResponse = null)
    : Promise<DeviceStatusesResponse> {
  let since = 0;
  if (sinceResponse?.organizationId === organizationId) {
    since = Object.values(sinceResponse.devices)
    .reduce((latestId, connectorLookups) => {
      return Object.values(connectorLookups).reduce((latestId, info) => {
        return Math.max(latestId, info.id);
      }, latestId);
    }, 0);
    console.assert(!Number.isNaN(since));
  }
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations' +
    '/status?ver=3&since=' + encodeURIComponent(+since);
  
  return fetchJsonWithAuth<DeviceStatusesCompactResponse>('GET', url)
  .then((compact) => {
    let unpacked = compact.data.map(([serverCacheId, timestamp, 
        evseId, connectorId, statusIndex], index, data) => {
      return {
        id: +(index ? serverCacheId + data[0][0] : serverCacheId),
        timestamp: +(index ? timestamp + data[0][1] : timestamp),
        evseId: +evseId,
        connectorId: +connectorId,
        status: compact.tokens[statusIndex]
      };
    }).reduce((response, unpacked) => {
      let connectors = response[unpacked.evseId];
      if (!connectors) {
        connectors = Object.create(null);
        response[unpacked.evseId] = connectors;
      }
      connectors[unpacked.connectorId] = {
        id: unpacked.id,
        timestamp: unpacked.timestamp,
        status: unpacked.status
      };
      return response;
    }, Object.create(null) as DeviceStatusesDeviceLookup);

    console.log('unpacked', unpacked);

    if (sinceResponse) {
      let devices = sinceResponse.devices;
      Object.keys(devices).forEach((oldEvseIdStr) => {
        let newConnLookup = unpacked[oldEvseIdStr];
        if (newConnLookup) {
          // Stitch in old connector statuses
          let oldConnectorLookup = devices[oldEvseIdStr];
          Object.keys(oldConnectorLookup).forEach((oldConnId) => {
            if (!newConnLookup[oldConnId]) {
              newConnLookup[oldConnId] = {
                ...oldConnectorLookup[oldConnId]
              };
            }
          });
        } else {
          unpacked[oldEvseIdStr] = {
            ...devices[oldEvseIdStr]
          };
        }
      });
    }

    return {
      organizationId,
      devices: unpacked
    };
  });
}

export interface RealtimePowerPortConnectorSample {
  timestamp: number;
  W?: number;
  Wh?: number;
  A?: number;
  V?: number;
  SoC?: number;
}

export interface RealtimePowerPortConnectorSamplePair {
  curr: RealtimePowerPortConnectorSample;
  prev: RealtimePowerPortConnectorSample;
  elap_sec: number;
  age_sec: number;
  power: number;
}

export interface RealtimePowerPortConnectorLookup {
  [portConnectorId: number]: RealtimePowerPortConnectorSamplePair;
}

export interface RealtimePowerDeviceLookup {
  [evseId: number]: RealtimePowerPortConnectorLookup;
}

export interface RealtimePowerResponse {
  devices: RealtimePowerDeviceLookup;
  log?: string[];
  watts: number;
}

export function getRealtimePower(organizationId: number)
    : Promise<RealtimePowerResponse> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations' +
    '/status?fastRequest=realtimePower';
  return fetchJsonWithAuth<RealtimePowerResponse>('GET', url)
  .catch((err) => {
    return retryIfNeeded<RealtimePowerResponse>(err, () => {
      return getRealtimePower(organizationId);
    });
  });
}

export function getOcppDevices(organizationId: number): Promise<OcppDevices> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations?ocpp';
  
  return fetchJsonWithAuth<OcppDevices>('GET', url);
}

export type SourceOverrideMap = {
  [moduleName: string]: string
};

export interface SourceOverride {
  id: number;
  name: string;
  content: SourceOverrideMap;
  version: number;
}

export function getSourceOverrides(): Promise<SourceOverride[]> {
  let url = apiRoot +
    '/dev/deployments';
  
  return fetchJsonWithAuth<SourceOverride[]>('GET', url);
}

export function patchSourceOverride(
    override: Partial<SourceOverride>): Promise<SourceOverride> {
  let url = apiRoot +
    '/dev/deployments';
  
  return fetchWithAuthBodyResponse<SourceOverride>('PATCH', url, override);
}

export function putSourceOverride(
    override: SourceOverride): Promise<SourceOverride> {
  let url = apiRoot +
    '/dev/deployments';
  
  return fetchWithAuthBodyResponse<SourceOverride>('PUT', url, override);
}

export interface EnumerateSourcesDirEntry {
  id: number;
  name: string;
  isDir: boolean;
  isFile: boolean;
}

export function enumerateSources(path: string)
    : Promise<EnumerateSourcesDirEntry[]> {
  let url = apiRoot +
    '/dev/sources?path=' + encodeURIComponent(path);
  
  return fetchJsonWithAuth<EnumerateSourcesDirEntry[]>('GET', url);
}

export function getSource(pathname: string): Promise<string> {
  let url = apiRoot +
    '/dev/sources?file=' + encodeURIComponent(pathname);
  
  return fetchJsonWithAuth<{ base64: string }>('GET', url).then((response) => {
    return atob(response.base64);
  });
}

export interface SourceTreeEntry extends EnumerateSourcesDirEntry {
  parent: SourceTreeEntry;
  children: SourceTreeEntry[];
}

export function enumerateSourceTree(
    pathname: string = '.'): Promise<SourceTreeEntry> {
  let url = apiRoot +
    '/dev/sources?tree=' + encodeURIComponent(pathname);

  return fetchJsonWithAuth<SourceTreeEntry>('GET', url).then((tree) => {
    return populateParentRefs(tree);
  }).catch((err) => {
    toastError(err.message);
    return null as SourceTreeEntry;
  });
}

export interface OcppDatabaseCredential {
  database: string;
  username: string;
  password: string | null;
  endpoint: string;
}

export type DatabaseSchemaTableMap = {
  [tableName: string]: string
};

export interface DatabaseSchemaResponse {
  tables: DatabaseSchemaTableMap;
}

export function getDatabaseSchema(name: 'dashboard' | 'ocpp', 
    organizationId?: number, log?: boolean)
    : Promise<DatabaseSchemaResponse> {
  let url = apiRoot + 
    '/dev/db?name=' + encodeURIComponent(name) +
    (organizationId ? '&org=' + encodeURIComponent(organizationId) : '');
  return fetchJsonWithAuth<DatabaseSchemaResponse>('GET', url);
}

export interface OcppServerLogRecord {
  ocppServerLogId: number;
  timestamp: number;
  chargepointId: number | null;
  logLevel: number | null;
  logAction: string | null;
  logDirection: string | null;
  logMessage: string | null;
  customerId: number | null;
}

export interface OcppServerLog {
  columns: string[];
  rows: (string | number | boolean | null)[][];
}

export interface OcppServerLogResponse {
  columns: string[];
  tokens: string[];
  rows: number[][];
}

export function getOcppServerLog(organizationId: number, after: number)
    : Promise<OcppServerLogResponse> {
  let url = apiRoot + 
    '/dev/db?name=' + encodeURIComponent('ocpp') +
    (organizationId ? '&org=' + encodeURIComponent(organizationId) : '') +
    '&after=' + encodeURIComponent(after) +
    '&log=1';
  return fetchJsonWithAuth('GET', url);
}

export function populateParentRefs<
    T extends { parent: T, children: T[] }>(root: T): T {
  let todo: [T, T[]][] = [[root, root.children]];
  while (todo.length) {
    let [ parentRef, childList ] = todo.pop();
    childList.forEach((child) => {
      child.parent = parentRef;
      if (child.children)
        todo.push([child, child.children]);
    });
  }
  return root;
}

export type NodeTreeLookupById<T> = { [id: number]: T };

export function makeNodeTreeLookupById<
    T extends { id: number, children: T[] }>(root: T)
    : NodeTreeLookupById<T> {
  let result: NodeTreeLookupById<T> = Object.create(null);
  let todo: T[][] = [root.children];
  while (todo.length) {
    let childList = todo.pop();
    childList.forEach((child) => {
      result[child.id] = child;
      if (child.children)
        todo.push(child.children);
    });
  }
  return result;
}

export function nodePathOfNode<
    T extends { parent: T, name: string }>(node: T): T[] {
  let result: T[] = [];
  while (node) {
    result.push(node);
    node = node.parent;
  }
  return result;
}

export function pathOfNode<T extends { parent: T, name: string }>(
    node: T, separator: string = '/'): string {
  return nodePathOfNode(node)
  .reverse()
  .map((node) => node.name)
  .join(separator);
}

export interface SendMailDetails {
  to: string[],
  cc: string[],
  bcc: string[],
  replyTo: string[],
  subject: string,
  text: string,
  html: string
}

export function sendTestMail(details: SendMailDetails): Promise<void> {
  let url = apiRoot +
    '/dev/sendmail';
  
  return fetchWithAuthBodyResponse<void>('POST', url, details);
}

export interface OcppCredentialByOrg {
  organizationId: number;
  ocppDatabaseParameterKey: string;
  ocppReadonlyParameterKey: string | null;
  credential: OcppDatabaseCredential;
  credentialRO: OcppDatabaseCredential | null;
}

export type OcppParameterKeyProp = 
  'ocppDatabaseParameterKey' | 
  'ocppReadonlyParameterKey';

export type OcppCredentialProp =
  'credential' | 'credentialRO';

export interface OcppCredentialsResponse {
  organizations: OcppCredentialByOrg[];
}

export function getOcppCredentials(): Promise<OcppCredentialsResponse> {
  let url = apiRoot +
    '/dev/ocpp-credential';
  return fetchJsonWithAuth('GET', url);
}

export function updateOcppCredential(organizationId: number, 
    keyKey: 'ocppDatabaseParameterKey' | 'ocppReadonlyParameterKey',
    credProp: 'credential' | 'credentialRO',
    cred: OcppCredentialByOrg)
    : Promise<void> {
  let url = apiRoot +
    '/dev/ocpp-credential';
  return fetchWithAuthBodyResponse<void>('PATCH', url, {
    organizationId,
    keyKey,
    credProp,
    cred
  });
}

export function invokeRepair(target: string): Promise<void> {
  let url = apiRoot +
    '/dev/repair/' + encodeURIComponent(target);
  
  return fetchJsonWithAuth('POST', url);
}

export interface BuildAdjacentResponse {
  elap: number,
  count: number,
  start: number
}

export interface BuildAdjacentStream {
  serverCacheInfoId: number;
  chargepointId: number;
  connectorId: number;
  id: number;
}

export interface BuildAdjacentStreams {
  continuations: BuildAdjacentStream[];
}

export function invokeBuildAdjacentStreams(
    streams: BuildAdjacentStreams | null): Promise<BuildAdjacentStreams> {
  let url = apiRoot +
    '/dev/build/adjacentSam' +
    '?fast=1';
  return fetchWithAuthBodyResponse('POST', url, streams || {
    continuations: null
  } as BuildAdjacentStreams);
}

export function invokeBuildAdjacent(start: number = 0)
  : Promise<BuildAdjacentResponse> {
  let url = apiRoot +
    '/dev/build/adjacentSam' +
    '?start=' + encodeURIComponent(+start);
  return fetchJsonWithAuth('POST', url);
}

export function invokeServerCacheStatus(): Promise<ServerCacheStatus[]> {
  let url = apiRoot +
    '/dev/status/serverCache';
  return fetchJsonWithAuth('POST', url);
}

export interface ServerStatusOpt {
  files: string[];
  env: {[name: string]: string};
}

export function invokeServerStatusOpt(): Promise<ServerStatusOpt> {
  let url = apiRoot +
    '/dev/status/opt';
  return fetchJsonWithAuth('GET', url);
}

type DeviceListBySite = { [siteId: number]: Device[] };

export interface SiteDevices {
  sites: Site[];
  deviceListBySite: DeviceListBySite;
}

let siteDevicesCache = {
  organizationId: 0,
  value: null as Promise<SiteDevices>,
  timeout: null as NodeJS.Timer
};

export function allSiteDevices(siteDevices: SiteDevices): Device[] {
  return siteDevices
    ? Object.values(siteDevices.deviceListBySite).reduce((result, list) => {
      return result.concat(list);
    }, [])
    : [];
}

export function makeEvseIdToSiteIdMap(
  siteDevices: SiteDevices | null): Map<number, number> {
  let allDevices = allSiteDevices(siteDevices);
  return allDevices.reduce((map, device) => {
    map.set(device.id, device.locationId);
    return map;
  }, new Map<number, number>());
}

export function locationsFromDeviceIds(selectedDevices: Set<number> | null, 
    siteDevices: SiteDevices | null, 
    evseIdtoSiteId: Map<number, number>): Site[] {
  if (!selectedDevices || !siteDevices || !evseIdtoSiteId)
    return [];
  let deviceIds = Array.from(selectedDevices);
  if (deviceIds.length === 0)
    return [];
  return Object.values(deviceIds.reduce((sites, deviceId) => {
    let siteId = evseIdtoSiteId.get(deviceId);
    let site = siteDevices.sites.find((site) => {
      return site.id === +siteId;
    });
    // Handle torn state during transition to new org and its device tree
    if (site)
      sites[site.id] = site;
    return sites;
  }, {} as { [siteId: string]: Site }));
}

function deepCloneImpl<T>(result: T, input: T, prop: string | number): T {
  let value = input[prop];

  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      result[prop] = [];
      value.forEach((item, index) => {
        deepCloneImpl(result[prop], value, index);
      });
    } else {
      result[prop] = {};
      Object.keys(value).forEach((key) => {
        deepCloneImpl(result[prop], value, key);
      });
    }
  } else {
    result[prop] = value;
  }

  return result;
}

export function deepClone<T>(input: T): T {
  let result = {} as T;
  Object.keys(input).forEach((key) => {
    deepCloneImpl(result, input, key);
  });
  return result;
}

function invalidateSiteDevices(): void {
  siteDevicesCache.organizationId = 0;
  siteDevicesCache.value = null;
}

export interface GetAllSiteDevicesOptions {
  noCache?: boolean;
}

export function getAllSiteDevices(
    organizationId: number, 
    options?: GetAllSiteDevicesOptions): Promise<SiteDevices> {
  if (!options?.noCache && siteDevicesCache.value && 
      siteDevicesCache.organizationId === organizationId) {
    return siteDevicesCache.value.then((result) => {
      return deepClone(result);
    });
  }
  
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
      //organizationId + '/locations?tree';
      '/locations/status?fastRequest=locationTree';
  let result = fetchJsonWithAuth<SiteDevices>('GET', url).then((siteDevices) => {
    siteDevices.sites = smartSort(siteDevices.sites, 'name');

    Object.keys(siteDevices.deviceListBySite).forEach((siteId) => {
      let deviceList: Device[] = siteDevices.deviceListBySite[siteId];
      deviceList = smartSort(deviceList, 'name');
      siteDevices.deviceListBySite[siteId] = deviceList;

      // Don't sort, they can control it now
      // deviceList.forEach((device) => {
      //   device.connectors = smartSort(device.connectors, 'name');
      // });
    });

    return siteDevices;
  });

  siteDevicesCache.organizationId = organizationId;
  siteDevicesCache.value = result;

  siteDevicesCache.timeout = setTimeout(() => {
    if (siteDevicesCache.organizationId === organizationId) {
      siteDevicesCache.organizationId = 0;
      siteDevicesCache.value = null;
    }
  }, 2000);

  return result;
}

export interface PermissionUser {
  id: number;
  username: string;
  email: string;
  role: string;
  passwordreset: string;
}

export interface PermissionTreeNode {
  parent: PermissionTreeNode;
  indent: number;
  permission: Permission;
  readable: boolean;
  writable?: boolean;
  children: PermissionTreeNode[];
}

export function getPermissions(): Promise<Permission[]> {
  if (mock) {
    let result: Permission[] = [{
      id: 1,
      parent: null,
      name: 'Organization',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 2,
      parent: 1,
      name: '*',
      readable: false,
      writable: false,
      heading: true
    }, {
      id: 5,
      parent: 2,
      name: 'Location',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 3,
      parent: 5,
      name: 'Devices',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 6,
      parent: 3,
      name: 'EVSE',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 7,
      parent: 3,
      name: 'Commands',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 8,
      parent: 1,
      name: 'Payment Plans',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 9,
      parent: 1,
      name: 'Access Control',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 10,
      parent: 9,
      name: 'Groups',
      readable: true,
      writable: true,
      heading: false
    }, {
      id: 11,
      parent: 10,
      name: 'Entry',
      readable: true,
      writable: true,
      heading: false
    }];

    return Promise.resolve(result);
  } else {
    return new Promise<Permission[]>((resolve, reject) => {
      throw new Error('Unimplmented');
    });
  }
}

export function getPermissionTree(): Promise<PermissionTreeNode[]> {
  return getPermissions().then((perms) => {
    let lookup: { [id: number]: PermissionTreeNode } = 
      Object.create(null);
    
    let nodes: PermissionTreeNode[] = perms.map((perm) => {
      let node: PermissionTreeNode = {
        parent: null as PermissionTreeNode,
        permission: perm,
        indent: 0,
        readable: perm.readable,
        writable: perm.writable,
        children: [] as PermissionTreeNode[]
      };

      lookup[perm.id || ''] = node;

      return node;
    });

    // Fill in parent and child links
    nodes.forEach((node) => {
      node.parent = lookup[node.permission.parent || ''] || null;
      if (node.parent) {
        node.parent.children.push(node);
        node.indent = node.parent.indent + 1;
      }
    });
    
    nodes.forEach((node) => {
      if (node.parent)
        node.indent = node.parent.indent + 1;
    });

    return nodes;
  });
}

export interface PermissionRole {
  id: number;
  name: string;
}

export function getPermissionRoles(): Promise<PermissionRole[]> {
  let roles: PermissionRole[] = [{
    id: 1,
    name: 'Owner'
  }, {
    id: 2,
    name: 'Admin'
  }, {
    id: 3,
    name: 'Operator'
  }];

  return Promise.resolve(roles);
}

export interface PermissionOverride {
  overrideId: number;
  permissionId: number;
  canRead: boolean;
  canWrite: boolean;
}

export function getPermissionOverrides(): Promise<PermissionOverride[]> {
  let persistedOverrides = 
    getPersistedJson<PermissionOverride[]>('permissionOverrides', []);
  
  return Promise.resolve(persistedOverrides);
}

export function postPermissionOverrides(
    overrides: PermissionOverride): Promise<void> {
  setPersistedJson('permissionOverrides', overrides);

  return Promise.resolve();
}

export function createUser(organizationId: number,
    email: string, role: string): Promise<number> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/users';

  let body: CreateUserPostBody = {
    email: email,
    role: role
    // firstName: firstName,
    // lastName: lastName
  };
  
  return fetchWithAuthBodyResponse<CreateUserResponse>('POST', url, body)
  .then((response) => {
    return response.id;
  });
}

function getPersistedJson<T>(key: string, defaultData: T): T {
  let persistedDataJson: string = localStorage.getItem(key);
  let persistedData: T = null;
  try {
    if (persistedDataJson)
      persistedData = JSON.parse(persistedDataJson);
  } catch (err) {
    // Do nothing
  }
  
  return persistedData ? persistedData : defaultData;
}

function setPersistedJson<T>(key: string, value: T): void {
  console.assert(key);
  localStorage.setItem(key, JSON.stringify(value));
}

export function fetchWithAuth(method: string,
    url: string, options?: RequestInit, 
    isRetry?: boolean): Promise<Response> {
  let promise = AuthService.authToken().then((authToken) => {
    if (!authToken)
      throw new HttpError(HttpError.HTTP_UNAUTHORIZED, 'Not signed in');

    let extendedOptions: RequestInit = {
      ...options,
      method: method,
      mode: 'cors'
    };

    if (!extendedOptions.headers)
      extendedOptions.headers = {};

    extendedOptions.headers['Authorization'] = authToken;
    
    return fetch(url, extendedOptions);
  }).then((response) => {
    return HttpError.throwIfErrorResponse(response);
  });

  if (!isRetry) {
    promise = promise.catch((err) => {
      // If it was an authentication problem error code...
      if ((err instanceof HttpError && 
          (err.statusCode === 401 || err.statusCode === 403)) ||
          err instanceof NotAuthenticatedError) {
        // ...then force the token to refresh, and retry the request
        return AuthService.getInstance().forceTokenRefresh().then(() => {
          return fetchWithAuth(method, url, options, true);
        });
      }
      // Otherwise, propagate the error without retry
      throw err;
    });
  }

  return promise;
}

export type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export function postWithAuth<TBody>(method: HTTPMethod, 
    url: string, body: TBody, options?: RequestInit): Promise<Response> {
  let extendedOptions: RequestInit = {
    ...options,
    body: JSON.stringify(body)
  };

  if (!extendedOptions.headers)
    extendedOptions.headers = {};

  extendedOptions.headers['Content-Type'] = 'application/json';

  return fetchWithAuth(method, url, extendedOptions);
}

export function deleteUserById(
    organizationId: number, userId: number): Promise<void> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/users/' + encodeURIComponent(userId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function patchLocationById(
    organizationId: number, site: Site): Promise<Site> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(site.id);
  return fetchWithAuthBodyResponse<Site>('PATCH', url, site);
}

export function patchUserById(organizationId: number, 
    user: User): Promise<User> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/users/' + encodeURIComponent(user.id);

  let requestBody: Partial<User> = {
    email: user.email,
    role: user.role,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    notifySms: user.notifySms,
    notifyEmail: user.notifyEmail,
    notificationSubs: user.notificationSubs || null,
    notificationSites: user.notificationSites,
    notificationEmail: user.notificationEmail,
    notificationSms: user.notificationSms
  };

  return fetchWithAuthBodyResponse<User>('PATCH', url, requestBody);
}

interface StartSessionResponse {
  queue_id: number;
}

export function startSession(organizationId: number, 
    site: Site, device: Device, portConnectorId: number, minutes: number)
    : Promise<StartSessionResponse> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(site.id) + 
    '/evse/' + encodeURIComponent(device.id) + 
    '/start';
  
  return fetchWithAuthBodyResponse<StartSessionResponse>('POST', url, {
    minutes: minutes,
    portConnectorId: portConnectorId
  });
}

interface StopSessionResponse {
  message: string;
}

export function stopSession(organizationId: number, 
    site: Site, device: Device, 
    transactionId: number): Promise<StopSessionResponse> {      
  let portConnectorId: number = portConnectorIdFromVirtual(device);

  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(site.id) + 
    '/evse/' + encodeURIComponent(device.id) + 
    '/stop?connectorId=' + portConnectorId;

  return fetchWithAuthBodyResponse<StopSessionResponse>('POST', url, {
    transactionId: transactionId
  });
}

export interface CheckSessionExistsResponse {
  // If session exists
  started: boolean;
  transaction_id: number;
}

export interface CheckSessionNotExistsResponse {
  // If session does not exist
  queue_status: string;
  queue_reason: string;
}

export type CheckSessionResponse = 
  CheckSessionExistsResponse | CheckSessionNotExistsResponse;

export function checkSession(organizationId: number,
    site: Site, device: Device)
    : Promise<CheckSessionResponse> {
  let portConnectorId: number = portConnectorIdFromVirtual(device);

  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(site.id) + 
    '/evse/' + encodeURIComponent(device.id) + 
    '/checksession?connectorId=' + encodeURIComponent(portConnectorId);

  return fetchJsonWithAuth<CheckSessionResponse>('GET', url);
}

export interface OcppLocation {
  locationId: number;
  name: string;
  description: string;
}

export interface OcppChargepoint {
  chargepointId: number;
  name: string;
  locationId: number;
  HTTP_CP: string;
}

export interface OcppPort {
  type: ConnectorType;
  chargepointId: number;
  portId: number;
  portConnectorId: number | null;
}

export interface OcppJoin {
  evseId: number;
  locationId: number;
  evseName: string;
  evseType: DeviceType,
  ocppChargepointId: string;
  locationName: string;
}

export type OcppLocationMap = { [locationId: number]: OcppLocation }
export type OcppChargepointMap = { [chargepointId: number]: OcppChargepoint }
export type OcppPortListMap = { [chargepointId: number]: OcppPort[] }
export type OcppJoinMap = { [ocppChargepointId: string]: OcppJoin }

export interface OcppDevices {
  locations: OcppLocationMap;
  chargepoints: OcppChargepointMap;
  ports: OcppPortListMap;
  evseOcpp: OcppJoinMap;
}

export interface FaultReportRow {
  timestamp: number;
  evseId: number;
  evseName: string;
  stationName: string | null;
  portConnectorId: number;
  siteId: number;
  siteName: string;
  sessionId: number | null;
  error: string;
  info: string;
  ocppChargepointId: string;
}

export interface SessionReportRow {
  startTime: number;
  endTime: number;
  siteName: string | null;
  evseName: string | null;
  stationName: string | null;
  evseId: number | null;
  portConnectorId: number | null;
  sessionId: number;
  sessionInitiation: string;
  idTag: string | null;
  groupName: string;
  chargerType: string;
  cost: number | null;
  netEnergy: number;
  stopReason: string;
  ocppChargepointId: string;
  autostartTagId: string | null;
}

export interface OccupancyReportRow {
  date: number;
  hour: number;
  siteId: number;
  siteName: string;
  occupied: number;
  total: number;
}

export interface PowerReportRow {
  st: number;
  en: number;
  siteId: number;
  siteName: string;
  peakPower: number;
}

export enum ReportResponseStatus {
  needRetry = 'needRetry',
  complete = 'complete'
}

export enum ReportColumnType {
  string='string',
  datetime='datetime',
  date='date',
  time='time',
  text='text',
  number='number',
  boolean="boolean"
}

// Information about a report column in report response metadata
export interface ReportColumn {
  title: string;
  type: ReportColumnType;

  // True if it makes sense to compute and show sums of this column
  couldTotal?: boolean;

  // True if it would make sense to sort the report by this column,
  // so all records with the same value in this field area adjacent,
  // where a group is defined by one or more records that have the
  // same value in this field. Have each time this column changes 
  // be a transition into a new group that ends the current group,
  // shows any running subtotals, and starts new subtotals
  couldGroupBy?: boolean;
}

export enum ReportOutputRowType {
  detail,
  groupHeader,
  groupFooter
}

export interface ReportOutputRow {
  type: ReportOutputRowType;
}

export interface ReportMetadata {
  columns: ReportColumn[];
}

export interface ReportResponse<T> {
  status: ReportResponseStatus;
  page: number;
  pageSize: number;
  totalRecords: number;
  metadata: ReportMetadata;
  records: T[];
}

export interface FaultReportResponse
    extends ReportResponse<FaultReportRow> {
}

export interface SessionReportResponse
    extends ReportResponse<SessionReportRow> {
}

export interface OccupancyReportResponse
    extends ReportResponse<OccupancyReportRow> {
}

export interface PowerReportResponse
    extends ReportResponse<PowerReportRow> {
}

interface ReportRequestBody {
  reportType: string;
  startDate: number;
  endDate: number;
  devices: number[] | null | undefined;
  cards: number[] | null | undefined;
  locations: number[] | null | undefined;
  aggregation: Aggregation | undefined;
  page: number;
  pageSize: number;
}

export enum ReportType {
  fault = 'fault',
  occupancy = 'occupancy',
  power = 'power',
  session = 'session'
}

export enum Aggregation {
  hour = 'Hour',
  day = 'Day'
}

export function getReport(type: ReportType.fault,
  organizationId: number,
  startDate: Date, endDate: Date, 
  devices: number[],
  locations: null,
  aggregation: null,
  cards: null,
  page: number, pageSize: number,
  longWaitHandler: (start: boolean, cancel: () => void) => void)
  : Promise<FaultReportResponse>;

export function getReport(type: ReportType.occupancy,
  organizationId: number,
  startDate: Date, endDate: Date, 
  devices: null,
  locations: number[],
  aggregation: Aggregation,
  cards: null,
  page: number, pageSize: number,
  longWaitHandler: (start: boolean, cancel: () => void) => void)
  : Promise<OccupancyReportResponse>;

export function getReport(type: ReportType.power,
  organizationId: number,
  startDate: Date, endDate: Date, 
  devices: number[],
  locations: null,
  aggregation: Aggregation,
  cards: null,
  page: number, pageSize: number,
  longWaitHandler: (start: boolean, cancel: () => void) => void)
  : Promise<PowerReportResponse>;

export function getReport(type: ReportType.session,
  organizationId: number,
  startDate: Date, endDate: Date, 
  devices: number[],
  locations: null,
  aggregation: null,
  cards: null,
  page: number, pageSize: number,
  longWaitHandler: (start: boolean, cancel: () => void) => void)
  : Promise<SessionReportResponse>;

export function getReport(type: ReportType,
  organizationId: number,
  startDate: Date, endDate: Date, 
  devices: number[] | null,
  locations: number[] | null,
  aggregation: Aggregation | null,
  cards: number[] | null,
  page: number, pageSize: number,
  longWaitHandler: (start: boolean, cancel: () => void) => void)
  : Promise<FaultReportResponse | OccupancyReportResponse |
    PowerReportResponse | SessionReportResponse>;

export function getReport(type: ReportType,
    organizationId: number,
    startDate: Date, endDate: Date, 
    devices: number[],
    locations: number[],
    aggregation: Aggregation | null,
    cards: number[] | null,  
    page: number, pageSize: number,
    // If server reports retry, longWaitHandler is called
    // with true and a callback to call to cancel
    // If this callback was called with start=true, then
    // it is guaranteed that a call with start=false will
    // occur before the fault report promise is resolved
    longWaitHandler: (start: boolean, cancel: () => void) => void)
    : Promise<unknown> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
      (!pageSize ? '/locations/status' : '/reports');
  
  // Only check for human mistakes
  if (pageSize && (!startDate || !endDate))
    return Promise.reject(new Error('Invalid date range'));
  
  // if (!startDate || !endDate) {
  //   let epoch = Date.UTC(1970,0,1,0,0,0);
  //   startDate = epoch;
  //   endDate = epoch;
  // }
  
  // Add one day to end date due to it being interpreted as
  // a half-open range [range)
  let requestBody: ReportRequestBody = {
    reportType: type || ReportType.session,
    startDate: startDate ? Math.floor(startDate.getTime() / 1000) : 0,
    endDate: endDate ? Math.floor(addDays(endDate, 1).getTime() / 1000) : 0,
    devices,
    cards,
    locations,
    aggregation,
    page,
    pageSize
  };

  let prefix: string;
  prefix = !pageSize ? '?fastRequest=reportCache&' : '?';

  // URL encode the request parameters
  let requestQuery = prefix + Object.keys(requestBody).sort().map((key) => {
    let encodedName = encodeURIComponent(key);
    let value = requestBody[key]?.toString() || '';
    if (!value)
      return null;
    let encodedValue = encodeURIComponent(value);
    return encodedName + (encodedValue.length ? '=' + encodedValue : '');
  }).filter((a) => a).join('&');

  let useGet = requestQuery.length < 512;

  let longWaiting = false;
  let longWaitCancelled = false;

  let longwaitCancel = () => {
    longWaitCancelled = true;
  };

  let runRequest: () => Promise<ReportResponse<unknown>> = () => {
    let req: Promise<ReportResponse<unknown>>;
    if (useGet) {
      // Small enough, use GET and make it cacheable
      req = fetchJsonWithAuth<ReportResponse<unknown>>(
        'GET', url + requestQuery);
    } else {
      // Giant request parameters, resort to post
      req = fetchWithAuthBodyResponse<ReportResponse<unknown>>(
        'POST', url, requestBody);
    }
    return req.then((response) => {
      if (response.status === 'complete') {
        // If we were long waiting, 
        // tell the long wait handler we aren't waiting
        if (longWaiting) {
          longWaiting = false;
          if (longWaitHandler)
            longWaitHandler(false, longwaitCancel);
        }
        return response;
      }
      
      if (response.status === 'needRetry') {
        if (!longWaiting) {
          longWaiting = true;
          if (longWaitHandler)
            longWaitHandler(true, longwaitCancel);
        } else if (longWaitCancelled) {
          throw new Error('Cancelled on user request');
        }
        
        return runRequest();
      }

      throw new Error('Unrecognized status: ' + response.status);
    });  
  };
  
  return runRequest();
}

export type ReportNetEnergyDateMap = { 
  [unixDate: number]: number;
};

export type ReportNetEnergySiteMap = {
  [siteId: number]: ReportNetEnergyDateMap
};

export interface ReportNetEnergy {
  sites: ReportNetEnergySiteMap;
}

export function getNetEnergyReport(
    organizationId: number, at: Date): Promise<ReportNetEnergy> {
  if (!at)
    return Promise.resolve(null);
  
  // Make the 8 day edges around each day
  let edgesLeft = 8;
  let datesu = [];
  let dt = startOfDay(at);
  do {
    let udt = Math.floor(+dt / 1000);
    datesu.push(udt);
    dt = addDays(dt, -1);
  } while (--edgesLeft);
  datesu.reverse();

  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/reports' +
    '?special=netEnergy' +
    '&edges=' + encodeURIComponent(datesu.join('-'));
  return fetchJsonWithAuth<ReportNetEnergy>('GET', url);
}

export interface ReportSessionLength {
  utilization: number;
  sumSeconds: number;
  sessionCount: number;
  averageSeconds: number;
  reportSeconds: number;
  deviceCount: number;
}

export function getSessionLengthReport(
    organizationId: number, at: Date): Promise<ReportSessionLength> {
  if (!at)
    return Promise.resolve(null);

  let en = startOfDay(at);
  let st = addDays(en, -7);
  let enu = Math.ceil(+en / 1000);
  let stu = Math.floor(+st / 1000);
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/reports' +
    '?special=sessionLength' +
    '&st=' + stu.toFixed(0) +
    '&en=' + enu.toFixed(0);
  return fetchJsonWithAuth<ReportSessionLength>('GET', url);
}

export interface ReportSessionCharging {
  count: number;
  activeCount: number;
  totalSeconds: number;
  chargingSeconds: number;
  averageSeconds: number;
  percentCharging: number;
}

export function getSessionCharging(organizationId: number, 
    at: Date, locationIds: number[] | null): Promise<ReportSessionCharging> {
  let url = apiRoot +
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations' +
    '/status?fastRequest=chargingPercent' +
    '&st=' + encodeURIComponent((+at) / 1000 - 86400 * 7) +
    '&en=' + encodeURIComponent(+at / 1000) +
    (locationIds
    ? '&loc=' + encodeURIComponent(locationIds.join(','))
    : '');
  
  return fetchJsonWithAuth('GET', url);  
}

//
// Change listeners

export function addChangeListener<T>(
    list: ChangeListener<T>[], callback: ChangeListener<T>)
    : ChangeListener<T> {
  list.push(callback);
  return callback;
}

export function removeChangeListener<T>(
  list: ChangeListener<T>[],
    callback: ChangeListener<T>): boolean {
  let index = list.indexOf(callback);
  if (index >= 0)
    list.splice(index, 1);
  return index >= 0;
}

function notifyChangeListeners<T>(
    list: ChangeListener<T>[],
    updatedItem: T,
    op: ChangeOp): T {
  list.forEach((callback) => {
    callback(updatedItem, op);
  });
  return updatedItem;
}

export function organizationListenerAdd(
    callback: ChangeListener<Organization>): ChangeListener<Organization> {
  return addChangeListener(organizationListeners, callback);
}

export function organizationListenerRemove(
    callback: ChangeListener<Organization>): boolean {
  return removeChangeListener(organizationListeners, callback);
}

export function getAccessControlPlansByOrganization(
    organizationId: number): Promise<AccessControlPlansResponse> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-control-plans';
  return fetchJsonWithAuth<AccessControlPlansResponse>('GET', url);
}

export interface AccessControlPlanPostResponse {
  planId: number;
}

// Create all groups with negative ids
// Update corresponding planGroups with created group ids
// Create plan to get plan id
// Assign plan ids to each planGroup
// Returns promise that resolves to planId
export function upsertAccessControlPlan(plan: AccessControlPlan,
    planGroups: AccessControlPlanGroup[],
    groups: AccessControlGroup[]): Promise<number> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(plan.organizationId) + 
    '/access-control-plans';
  
  if (plan.id)
    url += '/' + plan.id;
  
  let body = {
    plan: plan,
    planGroups: planGroups,
    groups: groups
  };

  return fetchWithAuthBodyResponse<AccessControlPlanPostResponse>(
    plan.id ? 'PATCH' : 'POST', url, body)
  .then((response) => {
    return response.planId;
  });
}

export function deleteAccessControlPlanById(
    organizationId: number, accessControlPlanId: number): Promise<void> {
    
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-control-plans/' + encodeURIComponent(accessControlPlanId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function listenForCardAtEvse(organizationId: number,
    siteId: number, evseId: number, since: number, abortCtrl: AbortController)
    : Promise<AccessCardEntryResponse> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/locations/' + encodeURIComponent(siteId) +
    '/evse/' + encodeURIComponent(evseId) + '/rfid' +
    '?since=' + encodeURIComponent(since);
  
  return fetchJsonWithAuth<AccessCardEntryResponse>('GET', url, {
    signal: abortCtrl.signal
  });
}

export function deleteAccessControlGroupById(
    organizationId: number, accessControlGroupId: number): Promise<void> {
  
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-control-groups/' + encodeURIComponent(accessControlGroupId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}


export interface AccessControlGroupPostResponse {
  groupId: number;
}

export function upsertAccessControlGroup(group: AccessControlGroup)
    : Promise<number> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(group.organizationId) +
    '/access-control-groups';

  if (group.id)
    url += '/' + group.id;

  let body = {
    group: group
  };

  return fetchWithAuthBodyResponse<AccessControlGroupPostResponse>(
    group.id > 0 ? 'PATCH' : 'POST', url, body)
  .then((response) => {
    group.id = response.groupId;
    return response.groupId;
  });
}


export function deleteAccessCardById(
  organizationId: number, accessCardId: number): Promise<void> {
    
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-cards/' + encodeURIComponent(accessCardId);
  return fetchWithAuth('DELETE', url)
  .then(emptyResponseHandler());
}

export function getAccessCardsByOrganization(
    organizationId: number): Promise<AccessCard[]> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-cards';
  return fetchJsonWithAuth<AccessCardsResponse>('GET', url).then((response) => {
    return response.cards;
  });
}

export function patchPostAccessCardByOrganization(
    verb: "PATCH" | "POST",
    organizationId: number, card: AccessCard): Promise<AccessCard> {
  let url = apiRoot + 
    '/organizations/' + encodeURIComponent(organizationId) +
    '/access-cards' + 
    (card.id ? '/' + encodeURIComponent(card.id) : '');
  return fetchWithAuthBodyResponse<AccessCard>(verb, url, card);
}

export interface AccessControlPlanSyncReply {
  plans: AccessControlPlansResponse;
  component?: Component;
  stateToSet?: any;
  setStateCallback?: () => void;
}

export class AccessControlPlanSync {
  private broadcastService = BroadcastService.getInstance();
  private groupAdd: (info: any) => void;
  private groupDel: (info: any) => void;
  private groupUpd: (info: any) => void;

  constructor(private callback: (info: any) => AccessControlPlanSyncReply) {
    this.groupAdd = this.broadcastService.register('groupAdd', 
    (group: AccessControlGroup) => {
      let reply = callback(group);

      let plans = reply.plans;
      
      if (!plans)
        return;
      
      plans.groups[group.id] = group;
      
      if (reply.component)
        reply.component.setState(reply.stateToSet || {}, 
            reply.setStateCallback);
    });

    this.groupDel = this.broadcastService.register('groupDel', 
    (group: AccessControlGroup) => {
      let reply = callback(group);
      
      let plans = reply.plans;
      
      if (!plans)
        return;
      
      delete plans.groups[group.id];

      if (reply.component)
        reply.component.setState(reply.stateToSet || {}, 
            reply.setStateCallback);
    });

    this.groupUpd = this.broadcastService.register('groupUpd', 
    (group: AccessControlGroup) => {
      let reply = callback(group);
      
      let plans = reply.plans;

      if (!plans)
        return;
      
      let oldGroup = plans.groups[group.id];

      if (oldGroup) {
        Object.keys(oldGroup).forEach((key) => {
          delete oldGroup[key];
        });
        Object.assign(oldGroup, group);
      }      

      if (reply.component)
        reply.component.setState(reply.stateToSet || {}, 
            reply.setStateCallback);
    });
  }

  public detach(): void {
    this.broadcastService.unregister('groupAdd', this.groupAdd);
    this.groupAdd = null;

    this.broadcastService.unregister('groupDel', this.groupDel);
    this.groupAdd = null;

    this.broadcastService.unregister('groupUpd', this.groupUpd);
    this.groupAdd = null;
  }
}

let reportCacheCache = {
  organizationId: 0,
  timeout: null as NodeJS.Timeout,
  value: null as Promise<void>
};

export function updateReportCache(organizationId: number): Promise<void> {  
  if (reportCacheCache.value && 
      organizationId === reportCacheCache.organizationId) {
    console.log('Report cache cache hit');
    return reportCacheCache.value;
  }
  
  console.log('Report cache cache miss');
  let result = getReport(ReportType.fault, organizationId,
    null, null, null, null, null, null, 0, 0, null)
  .then(() => {
    if (reportCacheCache.timeout)
      clearTimeout(reportCacheCache.timeout);
    reportCacheCache.timeout = setTimeout(() => {
      reportCacheCache.timeout = null;
      if (reportCacheCache.value) {
        console.log('Report cache cache invalidated');
        reportCacheCache.value = null;
      }
    }, 5000);
  }).catch((err) => {
    if (reportCacheCache.organizationId === organizationId)
      reportCacheCache.value = null;
    return Promise.reject(err);
  });

  reportCacheCache.value = result;
  reportCacheCache.organizationId = organizationId;

  return result;
}

export function portConnectorIdFromVirtual(device: Device): number {
  return device.connectors[device.virtualDeviceIndex ?? 0]?.portConnectorId ?? 0;
}

export function virtualDeviceName(
  device: Device, title: boolean = false, forceIndex?: number)
  : string | null {
  if (!device)
    return null;

  // If it has no virtualDeviceIndex, 
  // it is as if it had 0
  let vdi = device.virtualDeviceIndex || 0;

  if (typeof forceIndex === 'number')
    vdi = forceIndex;

  let name: string | null = null;

  if (!device.isHub)
    return device.name;

  // If the vdi is within the connector list length,
  // then use the connector name
  if (vdi < device.connectors.length)
    name = device.connectors[vdi]?.name || name;
  
  // If something failed above or the name is null,
  // then use the device name with a number
  if (!name && device.connectors.length > 1)
    name = device.name + ' (#' + (vdi + 1) + ')';
  
  if (!name)
    name = device.name;

  if (title) {
    if (name === device.name)
      return null;
    
    return 'Connector ' + (vdi + 1) + ' of ' + device.name;
  }
  
  return name;
}

export interface ServerCacheStatus {
  organizationId: number;
  organizationName: string;
  serverId: number;
  lastEvent: number;
}

//
// Upload

export interface MitmCsvFileEntry {
  name: string;
  size: number;
  modified: number;
}

export function listCsvImports(): Promise<MitmCsvFileEntry[]> {
  let url = apiRoot + '/dev/import/csv?list=1';
  return fetchJsonWithAuth('GET', url);
}

export class UploadFileError extends Error {  
}

export type UploadProgressCallback = (loaded: number, total: number) => void;

interface UploadResponse {
  fields: { [name: string]: string };
  url: string;
}

let useformData = true;

export function uploadCsvImportWithAuth(
    file: File, progress: UploadProgressCallback): Promise<void> {
  let url = apiRoot + '/dev/import/csv';
  return fetchWithAuthBodyResponse<UploadResponse>('POST', url, {
    filename: file.name,
    contentType: file.type,
    contentLength: file.size
  }).then((response) => {
    let data: FormData | File;
    if (response.fields) {
      let values = Object.values(response.fields);
      let keys = Object.keys(response.fields);
      if (!useformData) {
        let params = keys.map((key, index) => {
          let value = values[index];
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        }).join('&');

        response.url += '?';
        response.url += params;
        data = file;
      } else {
        let formData = new FormData();
        Object.keys(response.fields).forEach((key, index) => {
          formData.append(key, values[index]);
        });
        //data.set('file', file);
        formData.append('file', file, file.name);
        data = formData;
      }
    } else {
      data = file;
    }
    return uploadFile('PUT', response.url, data, progress);
  });
}

export function uploadFile(
    method: string, url: string, data: File | FormData, 
    progress: UploadProgressCallback,
    authorization?: string)
    : Promise<void> {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (event) => {
      progress(event.loaded, event.total);
    });
    xhr.upload.addEventListener('load', (event) => {
      progress(event.loaded, event.loaded);
    });
    xhr.addEventListener('load', (event) => {
      resolve();
    });
    xhr.addEventListener('error', (event) => {
      reject(new UploadFileError('Upload failed'));
    });      
    xhr.open(method, url);
    if (authorization)
      xhr.setRequestHeader('Authorization', authorization);
    // if (data instanceof File)
    //   xhr.setRequestHeader('Content-Length', '' + data.size);
    if (!(data instanceof FormData))
      xhr.setRequestHeader('Content-Type', data.type);
    xhr.send(data);
  });
}

export function uploadFileWithAuth(
    method: string, url: string, 
    data: File | FormData, progress: UploadProgressCallback)
    : Promise<void> {
  return AuthService.authToken().then((authToken) => {
    return uploadFile(method, url, data, progress, authToken);
  });
}

export interface ProcessMitmCsvResponse {
  importInfoId: number;
}

export function processMitmCsv(organizationId: number, 
    name: string, justCount: boolean)
    : Promise<ProcessMitmCsvResponse> {
  let url = apiRoot + '/dev/import/csv';
  return fetchWithAuthBodyResponse('POST', url, {
    'organizationId': organizationId,
    'name': name,
    'justCount': justCount
  });
}

export function retryIfNeeded<T>(err: Error, 
    retry: () => Promise<T>): Promise<T> {
  if (!(err instanceof HttpError) || 
      err.statusCode !== HttpError.HTTP_SERVICE_UNAVAILABLE)
    return Promise.reject(err);

  let retryInterval = err.getRetryInterval();
  
  if (retryInterval === Infinity)
    throw err;
  
  return new Promise<T>((resolve, reject) => {
    setInterval(() => {
      try {
        resolve(retry());
      } catch (err) {
        reject(err);
      }
    }, retryInterval * 1000);
  });
}

export function loadResponsiveLayout(uniqueName: string, 
    defaultLayouts?: Layouts): Layouts {
  let layouts: Layouts;
  try {
    let name = 'responsiveLayouts.' + uniqueName;
    layouts = JSON.parse(sessionStorage.getItem(name) ||
      localStorage.getItem(name));
  } catch (err) {
    layouts = null;
  }
  return layouts || defaultLayouts || {};
}

export function makeSaveResponsiveLayout(uniqueName: string)
    : (currentLayout: Layout[], allLayouts: Layouts) => void {
  return (currentLayout: Layout[], allLayouts: Layouts) => {
    try {
      let name = 'responsiveLayouts.' + uniqueName;
      let encoded = JSON.stringify(allLayouts);
      sessionStorage.setItem(name, encoded);
      localStorage.setItem(name, encoded);
    } catch (err) {
      return;
    }
  };
}

///

export const pricingPlanKeys: Array<keyof PriceMap> = [
  'dcfc',
  'level2',
  'ultrafast'
];
