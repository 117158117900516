
export class DropdownWorkaround {
  public static hook(root: Element): (rawEvent: Event) => void {
    let handler = (rawEvent: Event) => {
      let target = rawEvent.target as HTMLElement;

      let closestMenu: HTMLElement;
      closestMenu = target.closest('.dropdown[role="listbox"]');
      if (!closestMenu)
        return;
      let options: HTMLElement[] = Array.from(
        closestMenu.querySelectorAll('.item[role="option"]'));
      let active = options.find((el) => el.classList.contains('active'));
      let currentIndex = options.indexOf(active);
      let nextItem = options[currentIndex + 1];
      let prevItem = options[currentIndex - 1];
  
      let event = rawEvent as KeyboardEvent;
      switch (rawEvent.type) {
        case 'focusin':
          console.log('focusin', rawEvent.target);
          break;
        case 'focusout':
          console.log('focusout', rawEvent.target);
          options.forEach((option) => {
            option.classList.remove('active');
          });
          break;

        case 'keydown':
          switch (event.code) {
            case 'ArrowUp':
              if (prevItem) {
                console.log('focusing', prevItem);
                active?.classList.remove('active');
                prevItem.classList.add('active');
              } else {
                closestMenu.focus();
              }
              break;
            case 'ArrowDown':
              if (nextItem) {
                console.log('focusing', nextItem);
                active?.classList.remove('active');
                nextItem.classList.add('active');
              }
              break;

            case 'Enter':       // fall thru
            case 'NumpadEnter':
              if (active)
                active.click();
              else
                nextItem.classList.add('active');
              break;

            case 'Escape':
              active?.blur();
              break;
            
            default:
              return;
          }
          event.preventDefault();
          event.stopPropagation();
          break;
      }
    };
    root.addEventListener('keydown', handler, true);
    root.addEventListener('focusin', handler, true);
    root.addEventListener('focusout', handler, true);
    return handler;
  }

  public static unhook(root: Element, 
      handler: (rawEvent: Event) => void): void {
    root.removeEventListener('keydown', handler, true);
    root.removeEventListener('focusin', handler, true);
    root.removeEventListener('focusout', handler, true);
  }
}

// export interface DropdownMenuProps {
//   className: string;
// }

// interface DropdownMenuState {
//   open: boolean;
// }

// export class DropdownMenu 
//     extends Component<DropdownMenuProps, DropdownMenuState> {
//   constructor(props: DropdownMenuProps) {
//     super(props);
//     this.state = {
//       open: false
//     };
//   }

//   shouldComponentUpdate(
//       nextProps: DropdownMenuProps, 
//       nextState: DropdownMenuState): boolean {
//     return this.state.open !== nextState.open ||
//       this.props.className !== nextProps.className;
//   }

//   render(): ReactNode {
//     return (
//       <div role="menu"
//           tabIndex={0}
//           style={{
//             zIndex: 100, 
//             overflow: 'visible',
//             position: 'absolute'
//           }}
//           className={"menu " + this.props.className}
//           onFocus={(event) => {
//             this.setState({
//               open: true
//             });
//           }}
//           onBlur={(event) => {
//             this.setState({
//               open: false
//             });
//           }}
//           onClick={(event) => {
//             this.setState({
//               open: true
//             });
//           }}>
//         <div hidden={!this.state.open}>
//           {this.props.children}
//         </div>
//       </div>
//     );
//   }
// }

// // ============================================================================

// export interface DropdownItemProps {
//   //'data-tip': string;
//   icon: string;
//   text: string;
//   onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
// }

// interface DropdownItemState {

// }

// export class DropdownItem
//     extends Component<DropdownItemProps, DropdownItemState> {
//   render(): ReactNode {
//     return (
//       <div role="menuitem" 
//           className="ui dropdown"
//           onClick={(event) => {
//             if (this.props.onClick)
//               this.props.onClick(event)}
//           }>
//         {this.props.text}
//         {this.props.children}
//       </div>
//     );
//   }
// }

// // ============================================================================

// export interface DropdownProps {
//   'data-tip'?: string;
//   icon: string;
// }

// interface DropdownState {

// }

// export class Dropdown 
//     extends Component<DropdownProps, DropdownState> {
//   public static readonly Menu = DropdownMenu;
//   public static readonly Item = DropdownItem;

//   render(): ReactNode {
//     return (
//       <>
//         <div>
//           <i className={this.props.icon + ' icon'}/>
//           {this.props.children}
//         </div>
//       </>
//     );
//   }
// }
