import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface AccountIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class AccountIcon extends PureComponent<AccountIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill || '#555'} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 11.5C13.933 11.5 15.5 9.933 15.5 8C15.5 6.067 13.933 4.5 12 4.5C10.067 4.5 8.5 6.067 8.5 8C8.5 9.933 10.067 11.5 12 11.5Z" fill={this.props.fill || '#555'}/>
        <path d="M4.09134 17.4538C3.91898 17.795 3.99703 18.2054 4.27085 18.4722C6.26688 20.4175 8.99427 21.6158 12.0015 21.6158C15.009 21.6158 17.7365 20.4173 19.7326 18.4719C20.0064 18.205 20.0844 17.7946 19.9121 17.4534C18.5885 14.8332 15.5449 13 12.0018 13C8.4585 13 5.41477 14.8334 4.09134 17.4538Z" fill={this.props.fill || '#555'}/>
      </svg>
    );
  }
}