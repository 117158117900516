import * as React from 'react';
import { Component, PureComponent, ReactNode } from 'react';
import { 
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip
} from 'recharts';

import './AverageSessionWidget.css';
import './CustomPieChartLegend.scss';
import { getSessionCharging, getSessionLengthReport, locationsFromDeviceIds, Organization, SiteDevices } from '../../api';
import { Debouncer, formatSeconds, startOfDay, toastError } from '../shared/ui';
import { CustomPieChartLabel } from './CustomPieChartLabel';
import { xl8 } from '../../translations/i18n';

const renderLegend = (props): ReactNode =>{
  // This happens unfortunately
  if (!props)
    return null;

  const { payload } = props;

  return payload.map((entry, index) => {
    if (typeof entry?.payload?.percent !== 'number') {
      console.log('payload entry did not have percent' +
        ', so dropped legend render');
      return null;
    }
    return (
      <div key={index} className="widget-legend-item">
        <svg className="recharts-surface widget-legend-icon" 
          width="16" height="16"
          viewBox="0 0 32 32" version="1.1">
            <rect fill={entry.color} x="5" y="5" width="26"
              height="26" 
              stroke={entry.color} 
              strokeWidth="3px" 
              rx="4px" 
              ry="4px" 
              strokeLinejoin="round" 
            />
        </svg>
        <span key={`item-${index}`} className="widget-legend-text">
          {(entry.payload.percent * 100).toFixed(0) + "% " + entry.value}
        </span>
      </div>
    );
  });
};

const averageSessionPieColors: string[] = 
  ['#6899E3','#FECF56'];

const style: React.CSSProperties = {
  top: '50%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '22px',
};

export interface AverageSessionWidgetProps {
  organization: Organization;
  charging?: boolean;
  filterEvseIds: Set<number> | null;
  evseIdToSiteId: Map<number, number> | null;
  siteDevices: SiteDevices;
}

interface ChartValue {
  name: string;
  value: number;
}

interface AverageSessionWidgetState {
  displayedDate: Date | null;
  chartData: ChartValue[] | null;
  averageSessionLength: string;
}

export class AverageSessionWidget 
    extends PureComponent<AverageSessionWidgetProps, 
      AverageSessionWidgetState> {
  private mounted: boolean = false;
  constructor(props: AverageSessionWidgetProps) {
    super(props);

    this.state = {
      displayedDate: null,
      chartData: null,
      averageSessionLength: null
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    let now = new Date();
    
    let thisMorning = startOfDay(now);

    if (this.mounted) {
      this.setState({
        displayedDate: thisMorning
      });
    }
  }

  private updateDebouncer = new Debouncer(200);

  componentDidUpdate(
      prevProps: Readonly<AverageSessionWidgetProps>, 
      prevState: Readonly<AverageSessionWidgetState>, 
      snapshot?: never): void {
    if ((prevProps.organization !== this.props.organization ||
        prevState.displayedDate !== this.state.displayedDate ||
        prevProps.filterEvseIds !== this.props.filterEvseIds ||
        prevProps.evseIdToSiteId !== this.props.evseIdToSiteId ||
        prevProps.siteDevices !== this.props.siteDevices ||
        prevProps.charging !== this.props.charging) &&
        this.props.organization &&
        this.state.displayedDate)
      this.updateDebouncer.trigger(() => {
        this.updateChart();
      });
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  private updateChart() {
    if (!this.props.organization) {
      if (this.mounted) {
        this.setState({
          displayedDate: null,
          chartData: null,
          averageSessionLength: ''
       });
      }
    }

    interface SessionWidgetResult {
      newChartData: ChartValue[];
      averageSessionLength: string;
    }

    let promise: Promise<SessionWidgetResult>;

    if (this.props.charging) {
      let locationIds = locationsFromDeviceIds(this.props.filterEvseIds,
        this.props.siteDevices, this.props.evseIdToSiteId)
      .map((site) => {
        return site.id;
      });
      promise = getSessionCharging(this.props.organization.id, 
        this.state.displayedDate, locationIds)
      .then((result) => {
        let newChartData: ChartValue[] = [{
          name: xl8('deviceStatus_Charging'),
          value: result.percentCharging
        }, {
          name: xl8('deviceStatus_Idle'),
          value: 100 - result.percentCharging
        }];

        let averageSessionLength = formatSeconds(result.averageSeconds);

        return {
          newChartData,
          averageSessionLength
        };
      });
    } else {
      promise = getSessionLengthReport(this.props.organization.id, 
        this.state.displayedDate)
      .then((result) => {
        let newChartData: ChartValue[] = [{
          name: xl8('deviceStatus_Charging'),
          value: result.sumSeconds
        }, {
          name: xl8('deviceStatus_Idle'),
          value: result.reportSeconds * result.deviceCount - 
            result.sumSeconds
        }];

        let averageSessionLength = formatSeconds(result.averageSeconds);

        return {
          newChartData,
          averageSessionLength
        };
      });
    }
    
    return promise.catch((err) => {
      toastError(err.message);
      return {
        newChartData: null,
        averageSessionLength: null
      };
    }).then((data) => {
      if (this.mounted) {
        this.setState({
          chartData: data.newChartData,
          averageSessionLength: data.averageSessionLength
        });
      }
    });
  }

  render(): JSX.Element {
    return (    
      <>
        <h4 className="widget-header">
          {xl8('avgSessionLength')}
          <span>
            {xl8('averageOverLastSevenDays')}
          </span>
        </h4>
        <ResponsiveContainer height={'90%'}>
          <PieChart
          //  onMouseEnter={this.onPieEnter}
           >
            <Pie
              data={this.state.chartData}
              innerRadius="62%" 
              outerRadius="80%"
              fill="#000"
              dataKey="value"
              // cx="29%"
              // cy="50%"
              // cx={64}
              labelLine={false}
              paddingAngle={2}
              isAnimationActive={false}
              // onAnimationEnd={()=>{this.setState({});}}
              label={CustomPieChartLabel(this.state.averageSessionLength)}
            >
              {this.state.chartData?.map((entry, index) => (
                <Cell key={`cell-${index}`}
                  fill={averageSessionPieColors[
                    index % averageSessionPieColors.length]} />
              ))}
            </Pie>

            <Legend content={renderLegend} iconSize={12} layout="vertical"
              verticalAlign="middle" wrapperStyle={style}
              iconType="circle" align="right" />
            
            <Tooltip
              formatter={(label) => label.toFixed(0) + "%"} 
              // formatter={(value, name, props) => {
              //   let totalMinutes = value / 60;
              //   let totalHours = totalMinutes / 60;
              //   let totalDays = totalHours / 24;
              //   let days = Math.floor(totalDays);
              //   let hours = Math.floor(totalHours % 24);
              //   let minutes = Math.floor(totalMinutes % 60);
              //   return (days ? days + 'd ' : '') +
              //     (hours ? hours + 'h ' : '') + 
              //     minutes + 'm';
              // }}
              
               />

          </PieChart>
        </ResponsiveContainer>
      </> 
    ); 
  }
}