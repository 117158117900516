import React, { Component, ReactNode, RefObject } from 'react';
import {
  Aggregation, FaultReportResponse, FaultReportRow, 
  getAllSiteDevices, getReport, OccupancyReportResponse, 
  OccupancyReportRow, Organization, 
  PowerReportResponse, PowerReportRow, 
  ReportType, SessionReportResponse, SessionReportRow, Site, SiteDevices 
} from '../api';
import { OrganizationChangeListener } from '../App';
import {
  addHours, formatDate, timestampDiffToHours, formatFilenameDate, formatReportDate,
  formatUnixDuration, isDemo, renderSpam, rowsToCsvDataUrl, setStatePromise,
  setStatePromiseAtomic,
  toastError
} from './shared/ui';


import { SwychedSpinner } from './spinner.component';
import { PaginationControl } from './PaginationControl';
import './SheetReporting.scss';
import { ModalFactory } from './modals/Modal';
import { ModalUpdateReport } from './modals/ModalUpdateReport';
import { xl8 } from '../translations/i18n';
import { ReportIcon } from './icons/ReportIcon';
import { DownloadIcon } from './icons/DownloadIcon';
import { GenerateReportIcon } from './icons/GenerateReportIcon';
import { GeneratingReportIcon } from './icons/GeneratingReportIcon';
import { ProgressComponent } from './progress.component';
import { RefreshIcon } from './icons/RefreshIcon';

// "messageId":"ConnectorUnplugged"
//
//  [2,"0e031887-f338-4252-8b38-47a764cf8a4a","DataTransfer",
//   {"vendorId":"Phihong Technology","messageId":"ConnectorUnplugged",
//   "data":"{\"idTx\":29777,\"timestamp\":\"2021-11-05T21:40:16Z\"}"}]
//
//  [2,"10314094-0512-4349-8bb8-3ec44de0c0b9","Authorize",{"idTag":"3DE579DC"}]
//
//  [
//    2,
//    "0bcd4ac6-3cd8-4f78-a05b-54864661e4c3",
//    "StopTransaction",
//    {
//      "idTag":"3DE579DC",
//      "meterStop":88571,
//      "transactionId":29751,
//      "timestamp":"2021-11-04T20:48:12Z",
//      "reason":"EVDisconnected",
//      "transactionData":[{
//        "timestamp":"2021-11-04T20:48:12Z",
//        "sampledValue":[{
//          "value":"0.000",
//          "context":"Transaction.Begin",
//          "format":"Raw",
//          "measurand":"Energy.Active.Import.Interval",
//          "phase":"L3-N",
//          "location":"Outlet",
//          "unit":"kWh"
//        },{
//          "value":"0.0",
//          "context":"Transaction.End",
//          "format":"Raw",
//          "measurand":"Current.Import",
//          "phase":"L3-N",
//          "location":"Outlet",
//          "unit":"A"
//        },{
//          "value":"88.571",
//          "context":"Transaction.End",
//          "format":"Raw",
//          "measurand":"Energy.Active.Import.Interval",
//          "phase":"L3-N",
//          "location":"Outlet",
//          "unit":"kWh"
//        },{
//          "value":"0.0",
//          "context":"Transaction.End",
//          "format":"Raw",
//          "measurand":"Power.Active.Import",
//          "phase":"L3-N",
//          "location":"Outlet",
//          "unit":"kW"
//        },{
//          "value":"744.9",
//          "context":"Transaction.End",
//          "format":"Raw",
//          "measurand":"Voltage",
//          "phase":"L3-N",
//          "location":"Outlet",
//          "unit":"V"
//        },{
//          "value":"100",
//          "context":"Transaction.End",
//          "format":"Raw",
//          "measurand":"SoC",
//          "phase":"L3-N",
//          "location":"EV",
//          "unit":"Percent"
//        },{
//          "value":"64",
//          "context":"Transaction.Begin",
//          "format":"Raw",
//          "measurand":"SoC",
//          "phase":"L3-N",
//          "location":"EV",
//          "unit":"Percent"
//        }]
//      }
//    }
//  ]

//a = [2,"a1ead75f-8065-4a2d-accb-cfa25acabe6b","MeterValues",{"connectorId":1,"transactionId":29690,"meterValue":[{"timestamp":"2021-11-03T20:15:36Z","sampledValue":[{"value":"0.1","context":"Sample.Periodic","format":"Raw","measurand":"Current.Import","phase":"L1-N","location":"Outlet","unit":"A"},{"value":"424.760","context":"Sample.Periodic","format":"Raw","measurand":"Energy.Active.Import.Register","phase":"L1-N","location":"Outlet","unit":"kWh"},{"value":"7.090","context":"Sample.Periodic","format":"Raw","measurand":"Energy.Active.Import.Interval","phase":"L1-N","location":"Outlet","unit":"kWh"},{"value":"0.0","context":"Sample.Periodic","format":"Raw","measurand":"Power.Active.Import","phase":"L1-N","location":"Outlet","unit":"kW"},{"value":"206.3","context":"Sample.Periodic","format":"Raw","measurand":"Voltage","phase":"L1-N","location":"Outlet","unit":"V"}]}]}]

export interface ReportingSheetProps {
  organization: Organization;
  visible: boolean;
  busy: RefObject<SwychedSpinner>;
}

interface PaginatorInfo {
  currentPage: number;
  displayOffset: number;
  displayCount: number;
  totalRecords: number;
  displayedReport: DisplayedReportInfo;
}

interface PaginatorInfoMap {
  fault: PaginatorInfo;
  occupancy: PaginatorInfo;
  power: PaginatorInfo;
  session: PaginatorInfo;
}

interface ReportRowsMap {
  fault: FaultReportRow[] | null;
  occupancy: OccupancyReportRow[] | null;
  power: PowerReportRow[] | null;
  session: SessionReportRow[] | null;
}

interface DisplayedReportInfo {
  type: ReportType | null;
  title: string | null;
  dateRange: ReactNode;
  groupSelection: ReactNode | null;
  siteSelection: ReactNode | null;
  aggregationSelection: Aggregation | null;
  deviceSelection: ReactNode | null;
  created: Date;
}

interface ReportingSheetState {
  organization: Organization;
  startDate: Date;
  endDate: Date;  
  renderedDateSelection: ReactNode;
  reportType: ReportType;
  siteDevices: SiteDevices;
  selectedDevices: number[];
  renderedDeviceSelection: ReactNode;
  selectedSites: number[];
  renderedSiteSelection: ReactNode;
  selectedAccessCards: number[];
  renderedAccessCardSelection: ReactNode;
  //deviceLookup: Map<number, Device>;
  //selectedDevices: Set<number>;
  //accessControlPlans: AccessControlPlansResponse;
  aggregation: Aggregation;
  reportRunning: boolean;
  reportSlow: boolean;
  reportWaitCancel: () => void;
  rows: ReportRowsMap;
  paginators: PaginatorInfoMap;
  csvBusy: boolean;
  csvProgress: number;
}

export class ReportingSheet 
    extends Component<ReportingSheetProps, ReportingSheetState>
    implements OrganizationChangeListener {
  private paginationLoader: ((start: number, 
      count: number) => Promise<void>) | null = null;
  private itemsPerPage: number = 10;

  private readonly batchSize = 128;
  //private dateSelectRef = React.createRef<SwychedDateSelect>();

  // private siteDeviceDropdown = React.createRef<SiteDropdown>();
  // private siteOnlyDropdown = React.createRef<SiteDropdown>();
  // private accessCardDropdown = React.createRef<AccessCardDropdown>();

  constructor(props: ReportingSheetProps) {
    super(props);
    this.state = {
      organization: props.organization,
      startDate: null,
      endDate: null,
      renderedDateSelection: null,
      reportType: ReportType.session,
      siteDevices: null,
      //deviceLookup: new Map<number, Device>(),
      //selectedDevices: new Set<number>(),
      selectedDevices: null,
      renderedDeviceSelection: null,
      selectedSites: null,
      renderedSiteSelection: null,
      selectedAccessCards: null,
      renderedAccessCardSelection: null,
      //accessControlPlans: null,
      aggregation: Aggregation.hour,
      rows: this.initializedRows(),
      reportRunning: false,
      reportSlow: false,
      reportWaitCancel: null,
      csvBusy: false,
      csvProgress: 0,
      paginators: this.initializedPaginators()
    };
  }

  initializedRows(): ReportRowsMap {
    return {
      fault: null,
      occupancy: null,
      power: null,
      session: null
    };
  }

  private initializedPaginators(): PaginatorInfoMap {
    return {
      fault: this.initializedPaginator(),
      power: this.initializedPaginator(),
      occupancy: this.initializedPaginator(),
      session: this.initializedPaginator()
    };
  }

  private initializedPaginator(): PaginatorInfo {
    return {
      currentPage: 0,
      totalRecords: 0,
      displayOffset: 0,
      displayCount: 0,
      displayedReport: {
        title: null,
        type: null,
        dateRange: null,
        deviceSelection: null,
        groupSelection: null,
        siteSelection: null,
        aggregationSelection: null,
        created: null
      }
    };
  }

  componentDidMount(): void {
    if (this.state.organization)
      this.refreshLists(this.state.organization);
  }

  // componentWillUnmount(): void {
  // }

  organizationChanged(organization: Organization): Promise<void> {
    // Tell asynchronous pagination loaders
    // that they have been abandoned
    this.paginationLoader = null;

    return setStatePromise<ReportingSheetState, ReportingSheet>(this, {
      rows: this.initializedRows()
    }).then(() => {
      return this.refreshLists(organization);
    });
  }

  private refreshLists(organization: Organization): Promise<void> {
    let organizationPromise = setStatePromise<ReportingSheetState,
      ReportingSheet>(this, {
        organization: organization,
      });

    let siteDevicesPromise = getAllSiteDevices(organization.id);

    // let accessControlPlansPromise = 
    //   getAccessControlPlansByOrganization(organization.id);

    siteDevicesPromise.then((siteDevices) => {
      return setStatePromise<ReportingSheetState, ReportingSheet>(this, {
        siteDevices: siteDevices
      });
    });

    // accessControlPlansPromise.then((accessControlPlansResponse) => {
    //   return setStatePromise<ReportingSheetState, ReportingSheet>(this, {
    //     accessControlPlans: accessControlPlansResponse
    //   });
    // }).catch((err) => {
    //   toastError('Failed to get group list: ' + err.message);
    //   return null;
    // });

    // let accessCardsPromise = 
    //   getAccessCardsByOrganization(this.state.organization.id)
    // .then((cards) => {
    //   // Make lookup table
    //   let cardsByGroup = cards.reduce((cardsByGroup, card) => {
    //     let list = cardsByGroup.get(card.acpgId);
    //     if (!list) {
    //       list = [card];
    //       cardsByGroup.set(card.acpgId, list);
    //     } else {
    //       list.push(card);
    //     }
    //     return cardsByGroup;
    //   }, new Map<number, AccessCard[]>());

    //   return setStatePromise<ReportingSheetState, ReportingSheet>(this, {
    //     accessCards: cards,
    //     cardsByGroup: cardsByGroup
    //   });
    // });

    return organizationPromise.then(() => {
      return siteDevicesPromise;
    }).then(() => {
    }).catch((err) => {
      toastError('Failed to refresh lists: ' + err.message);
      // Handle devices promise rejection too
      siteDevicesPromise.catch((err)=>{
        toastError(err.message);
      });
      return null;
    });
  }

  // shouldComponentUpdate(
  //     nextProps: ReportingSheetProps, 
  //     nextState: ReportingSheetState)
  //     : boolean {
  //   let shouldUpdate =
  //     this.state.accessControlPlans !== nextState.accessControlPlans ||
  //     this.state.aggregation !== nextState.aggregation ||
  //     this.state.organization !== nextState.organization ||
  //     this.state.reportType !== nextState.reportType ||
  //     this.state.selectedDevices !== nextState.selectedDevices ||
  //     this.state.selectedGroups !== nextState.selectedGroups ||
  //     this.state.siteDevices !== nextState.siteDevices ||
  //     this.state.startDate !== nextState.startDate ||
  //     this.state.endDate !== nextState.endDate ||
  //     this.state.deviceLookup !== nextState.deviceLookup;
    
  //   let should = Object.keys(this.state).some((key) => {
  //     // Return and do nothing if no reason to update was found
  //     if (this.state[key] === nextState[key])
  //       return false;
        
  //     // Found a reason to change, report it and update
  //     console.log('should update because', key, 'changed');
  //     return true;
  //   });

  //   return should;
  // }

  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;
    renderSpam('SheetReporting');
    let currentPaginator = this.paginator();

    return (
      <>
        <div className="row sheet-header">
          <div className="page-title-container col-lg-9">
            <h2 className="sheet-title">
            {xl8('reporting')}
            </h2>
            <div className="sheet-subtitle">
              {xl8('generateCustomReports')}
            </div>
          </div>
          <div className="sheet-control-container col-lg-3">
            <button className="btn btn-primary
              sidebar-primary-btn pull-right"
                disabled={!this.props.organization || !this.state.siteDevices}
                type="button" 
                  onClick={(event) => this.onGenerateReport()}
                >
              {
                !this.state.rows[this.state.reportType]
                ? <>
                    <ReportIcon width="24" height="24" fill="#fff"/>
                    {xl8('generateReport')}
                  </> 
                : <> 
                    <RefreshIcon width="24" height="24" fill="#fff"/>
                    {xl8('updateReport')}
                  </>
              }
              
            </button>
          </div>
        </div>
        <div className="row report">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="report-container" 
                  hidden={!currentPaginator?.displayedReport?.created}>
                <div className="report-header pull-left">
                  <h2 className="report-type-title">
                    {this.reportTitle()}
                  </h2>
                  <div className="row report-summary">
                    <ul className="report-summary-list list-group list-group-horizontal">
                      <li className="list-group-item
                          justify-content-between align-items-start">
                        <div className="me-auto report-summary-item">
                          <div className="report-summary-item-title">
                            {xl8('dateRange')}
                          </div>
                          <div className="report-date-range-summary">
                            {currentPaginator.displayedReport.dateRange}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item
                          justify-content-between align-items-start"
                          hidden={!this.needDevices()}>
                        <div className="me-auto report-summary-item">
                          <div className="report-summary-item-title">
                          {xl8('sites')}/{xl8('devices')}
                          </div>
                          {currentPaginator.displayedReport.deviceSelection}
                        </div>
                      </li>
                      {/* <li className="list-group-item d-flex
                          justify-content-between align-items-start "
                          hidden={!this.needGroups()}>
                        <div className=" me-auto report-summary-item">
                          <div className="report-summary-item-title ">
                            Groups
                          </div>
                          {currentPaginator.displayedReport.groupSelection}
                        </div>
                      </li> */}
                      <li className="list-group-item
                          justify-content-between align-items-start"
                          hidden={!this.needSites()}>
                        <div className="me-auto report-summary-item">
                          <div className="report-summary-item-title">
                            {xl8('sites')}
                          </div>
                          {currentPaginator.displayedReport.siteSelection}
                        </div>
                      </li>
                      <li className="list-group-item
                         justify-content-between align-items-start"
                          hidden={!this.needAggregation()}>
                        <div className="me-auto report-summary-item">
                          <div className="report-summary-item-title">
                            {xl8('by')}:
                          </div>
                          {currentPaginator.displayedReport.aggregationSelection}
                        </div>
                      </li>
                      <li className="list-group-item
                          justify-content-between align-items-start"
                          hidden={false}>
                        <div className="me-auto report-summary-item">
                          <div className="report-summary-item-title">
                            {xl8('generated')}:
                          </div>
                          {formatDate(currentPaginator.displayedReport.created)}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="export-report-container">
                  <button className="btn btn-icon-simple export-report-btn"
                      type="button"
                      disabled={this.state.csvBusy || this.state.reportRunning}
                      onClick={(event) => {
                        event.stopPropagation();
                        //export report
                        this.setState((prevState) => {
                          if (prevState.csvBusy)
                            return null;
                          return {
                            csvBusy: true,
                            csvProgress: 0
                          };
                        }, () => {
                          this.exportCsv()
                          .catch((err) => {
                            toastError(err.message);
                          }).then(() => {
                            this.setState((prevState) => {
                              if (!prevState.csvBusy)
                                return null;
                              return {
                                csvBusy: false,
                                csvProgress: 0
                              };
                            });
                          });
                        });
                      }}>
                    <DownloadIcon width="30" height="30" fill="#c1c5c8" />
                  </button>
                  <div className="beta-tag" hidden={isDemo()}>
                    {xl8('beta')}
                  </div>
                </div>
                {/* <ul className="report-summary">
                  <li className="list-group-item">
                    {currentPaginator.displayedReport.title}
                  </li>
                  <li>
                    {currentPaginator.displayedReport.dateRange}
                  </li>
                  <li>
                    {currentPaginator.displayedReport.deviceSelection}
                  </li>
                  <li>
                    {currentPaginator.displayedReport.groupSelection}
                  </li>
                  <li>
                    {currentPaginator.displayedReport.siteSelection}
                  </li>
                  <li>
                    {currentPaginator.displayedReport.aggregationSelection}
                  </li>
                </ul> */}
                <PaginationControl
                  count={this.paginator().totalRecords}
                  displayedPages={5}                
                  page={this.paginator().currentPage}
                  visible={this.itemsPerPage}
                  notNeeded={'hidden'}
                  multiKey={this.state.reportType}
                  onPageChange={(page, count) => {
                    if (this.paginationLoader)
                      this.paginationLoader(page * count, count);                  
                    
                    this.updatePaginator({
                      currentPage: page,
                      displayOffset: page * count,
                      displayCount: count
                    });
                  }}/>
                <div className="scrollable" hidden={!this.haveReportRows()}>
                  <div>
                    <table className="table table-sorter report-table"
                      cell-padding={1} cells-spacing={1}>
                      <thead className="text-primary">
                        <tr>
                          {this.renderReportTableHeadings()}
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderReportRows()}
                      </tbody>
                    </table>
                  </div>
                </div> 
                {!this.haveReportRows() ? this.renderReportLoading() : null}
              </div>
              <div className="empty-report-container" 
                  hidden={!!currentPaginator.displayedReport?.created}>
                <span>
                  <GenerateReportIcon width="96" height="96" />
                </span>

                <h3 className="empty-overlay-container">
                  {xl8('emptyReportHeader')}
                </h3>
                <div className="empty-report-text">
                  {xl8('emptyReportText')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3">
                <h4 className="stop-reason-key-header">
                  *What is the stop reason?
                </h4>
                <span className="stop-reason-key-desc">
                  This is the reason a charging session has stopped. 
                  Common reasons are listed on the right.
                </span>
              </div>
              <div className="col-lg-6">
                <div className="list-group">
                  <div className="list-group-item 
                        list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">Local</h5>
                    </div>
                    <p className="mb-1">
                      Stopped locally on request of the user at the device. 
                      This is a regular termination of a session. 
                      Examples: Presenting an RFID tag, pressing a button to
                      stop.
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">Remote</h5>
                    </div>
                    <p className="mb-1">
                      Stopped remotely on request of the user. This is a
                      regular termination of a session. Examples:
                      Terminating using a smartphone app.
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">EV Disconnected</h5>
                    </div>
                    <p className="mb-1">
                      Stopped due to disconnecting of a cable.
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">Emergency Stop</h5>
                    </div>
                    <p className="mb-1">
                      Stopped due to the use of an emergency stop button.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       */}
      </>
    );
  }
  
  private haveReportRows(type: ReportType = this.state.reportType): boolean {
    return !!this.state.rows[type];
  }

  updatePaginator(partial: Partial<PaginatorInfo>,
      type?: ReportType): Promise<void> {
    return setStatePromiseAtomic<ReportingSheetState, ReportingSheet>(
        this, (prevState) => {
      type = type || this.state.reportType;

      let oldState = prevState.paginators[type];
      
      let newState = Object.assign({}, oldState, partial);

      let newPaginators = {...prevState.paginators};
      newPaginators[type] = newState;
      
      return {
        paginators: newPaginators
      };
    });
  }

  private paginator(type?: ReportType): PaginatorInfo {
    return this.state.paginators[type || this.state.reportType];
  }

  // private get siteDropdown(): SiteDropdown {
  //   return this.siteDeviceDropdown.current || this.siteOnlyDropdown.current;
  // }

  private updateReport(): Promise<void> {
    // Snapshot the value of the stuff in this closure
    // so state changing out under us won't do weird stuff
    let reportType = this.state.reportType;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let organizationId = this.state.organization.id;
    let aggregationSelection = this.state.aggregation;
  
    let selectedCards = this.state.selectedAccessCards;// accessCardDropdown.current?.getSelectedCardIds();

    let selectedDevices = this.state.selectedDevices;// siteDropdown.getSelectedDevices();
    let selectedSites = this.state.selectedSites;
    
    let allSites = this.sites();

    //let selectedSites: number[] = this.state siteDropdown.selectedSiteIds();
    //  allSites.filter((site) => {
    //     return this.isEntireSiteChecked(site) > 0;
    //   }).map((site) => {
    //     return site.id;
    //   });
    
    // If you selected all sites, or didn't select any site,
    // then we might as well not mention sites at all,
    // and every site will be included
    if (!selectedSites.length || allSites.length === selectedSites.length)
      selectedSites = null;
    
    // Same as above for group
    // if (!selectedCards?.length || 
    //     selectedCards?.length === this.state.accessCards?.length)
    //   selectedCards = null;

    let rowsToClear = {};
    rowsToClear[reportType] = null;

    let newDisplayInfo: DisplayedReportInfo = {
      type: reportType,
      title: this.reportTitle(reportType),
      dateRange: this.state.renderedDateSelection,
      deviceSelection: this.needDevices(reportType) 
        ? this.state.renderedDeviceSelection
        : null,
      siteSelection: this.needSites(reportType)
        ? this.state.renderedSiteSelection
        : null,
      groupSelection: this.needGroups(reportType)
        ? this.state.renderedAccessCardSelection
        : null,
      aggregationSelection: this.needAggregation(reportType)
        ? aggregationSelection
        : null,
      created: new Date()
    };

    return setStatePromiseAtomic<ReportingSheetState, ReportingSheet>(this,
    (prevState) => {

      let newPaginators = {
        ...prevState.paginators
      };
  
      newPaginators[reportType] = {
        ...newPaginators[reportType]
      };
  
      newPaginators[reportType].displayedReport = newDisplayInfo;

      return {
        reportRunning: true,
        reportSlow: false,
        
        paginators: newPaginators,
        rows: {
          ...prevState.rows,
          ...rowsToClear
        }
      };
    }).then(() => {
      return this.updatePaginator({
        totalRecords: 0,
        currentPage: 0        
      }, reportType);
    }).then(() => {
      let longWaitHandler = (longWaitStart: boolean,
        longWaitCancel: () => void) => {
        // This runs when long waiting begins or ends
        // It tells you when to show the cancel button,
        // and gives you something to call to ask to cancel
        this.setState({
          reportSlow: longWaitStart,
          reportWaitCancel: longWaitCancel
        });
      };
  
      let loadedBatches: boolean[] = [];
  
      let thisPaginationLoader = (start: number,
          count: number) => {
        // Get inclusive range
        let batchSt = start;
        let batchEn = start + (count - 1);
  
        // Compute the range of batch indices that cover that range
        batchSt = Math.floor(batchSt / this.batchSize);
        batchEn = Math.floor(batchEn / this.batchSize);

        let promises = [];
  
        for (let batch = batchSt; batch <= batchEn; ++batch) {
          if (!loadedBatches[batch]) {
            loadedBatches[batch] = true;
            let batchPromise = this.loadBatch(
              organizationId, startDate, endDate, 
              selectedDevices, selectedSites, 
              selectedCards, aggregationSelection,
              longWaitHandler, 
              reportType, batch, this.batchSize,
              thisPaginationLoader);
            promises.push(batchPromise);
  
            if (this.state.csvBusy) {
              batchPromise.then(() => {
                this.setState({
                  csvProgress: 100 * batch / batchEn
                });
              });
            }
          }
        }

        return Promise.all(promises).then(() => {
          this.setState({
            csvProgress: 100
          });
        });
      };
  
      this.paginationLoader = thisPaginationLoader;
  
      return this.paginationLoader(0, this.batchSize);
    });
  }

  private loadBatch(organizationId: number,
      startDate: Date, 
      endDate: Date, selectedDevices: number[], 
      selectedSites: number[], 
      selectedCards: number[], 
      selectedAggregation: Aggregation,
      longWaitHandler: (longWaitStart: boolean, 
        longWaitCancel: () => void) => void, 
      reportType: ReportType, 
      batch: number, batchSize: number,
      expectedPaginationLoader)
      : Promise<void> {
    this.reserveRows(reportType, batch, batchSize);

    console.log('running report request, batch=', batch);

    let initialPromise: Promise<unknown>;

    if (batch === 0) {
      // Probe to update the cache when doing batch zero
      // This call could do any number of retry requests 
      // before eventually resolving when complete
      initialPromise = getReport(
        reportType,
        organizationId,
        startDate, endDate,
        selectedDevices,
        this.needSites(reportType)
          ? selectedSites
          : null,
        this.needAggregation(reportType)
          ? selectedAggregation
          : null,
        this.needGroups(reportType)
          ? selectedCards
          : null,
        batch, 0,
        longWaitHandler);

      // Get page count
      initialPromise = initialPromise.then(() => {
        console.log('getting record count');
        return getReport(
          reportType,
          organizationId,
          startDate, endDate,
          selectedDevices,
          this.needSites(reportType)
            ? selectedSites
            : null,
          this.needAggregation(reportType)
            ? selectedAggregation
            : null,
          this.needGroups(reportType)
            ? selectedCards
            : null,
          -1, batchSize,
          longWaitHandler);
      }).then((response) => {
        console.log('applying record count');
        return this.updatePaginator({
          totalRecords: response.totalRecords,
          currentPage: 0,
          displayOffset: 0,
          displayCount: Math.min(response.totalRecords, this.itemsPerPage)
        });
      });
    } else {
      initialPromise = Promise.resolve();
    }

    return initialPromise.then(() => {
      // See if abandoned request
      if (this.paginationLoader !== expectedPaginationLoader)
        return null;

      // Actually get report, this time exclusively from webserver db
      console.log('getting report batch');
      return getReport(reportType,
        organizationId,
        startDate, endDate,
        selectedDevices,
        this.needSites(reportType)
          ? selectedSites
          : null,
        this.needAggregation(reportType)
          ? selectedAggregation
          : null,
        this.needGroups(reportType)
          ? selectedCards
          : null,
        batch, batchSize,
        longWaitHandler);
    }).then((response) => {
      // See if abandoned request
      if (this.paginationLoader !== expectedPaginationLoader)
        return null;
      
      console.log('applying batch response');
      
      let faultResponse: FaultReportResponse;
      let occupancyResponse: OccupancyReportResponse;
      let powerResponse: PowerReportResponse;
      let sessionResponse: SessionReportResponse;
      switch (reportType) {
        case ReportType.fault:
          faultResponse = response as FaultReportResponse;

          this.injectRows(batch * batchSize, {
            fault: faultResponse.records
          });
          break;
        case ReportType.occupancy:
          occupancyResponse = response as OccupancyReportResponse;
          this.injectRows(batch * batchSize, {
            occupancy: occupancyResponse.records
          });
          break;
        case ReportType.power:
          powerResponse = response as PowerReportResponse;
          this.injectRows(batch * batchSize, {
            power: powerResponse.records
          });
          break;
        case ReportType.session:
          sessionResponse = response as SessionReportResponse;
          this.injectRows(batch * batchSize, {
            session: sessionResponse.records
          });
          break;
      }

      console.log(response);
    }).catch((err) => {
      toastError(err.message);
      this.setState((prevState) => {
        let newPaginators = {
          paginators: {
            ...prevState.paginators
          }
        };
        newPaginators.paginators[prevState.reportType] = 
          this.initializedPaginator();
        return newPaginators;
      });
    }).then(() => {
      this.setState({
        reportRunning: false
      });
    });
  }
  
  private reserveRows(reportType: ReportType, 
      batch: number, batchSize: number) {
    //
    this.setState((prevState: ReportingSheetState) => {
      let oldRows: Array<unknown> = this.state[reportType + 'Rows'];
      let newRows = oldRows
        ? oldRows.slice()
        : [];
      
      let st = batch * batchSize;
      let en = batch * batchSize + batchSize;
      for (let i = st; i < en; ++i)
        if (!newRows[i])
          newRows[i] = null;
      
      let result = {};
      result[reportType + 'Rows'] = newRows;

      return result;
    });
  }

  injectRows(start: number, 
      rows: Partial<ReportRowsMap>): void {
    console.log('waiting to inject rows');
    this.setState((prevState) => {
      console.log('waiting complete');

      let newRowsTable = {
        ...prevState.rows
      };

      Object.keys(rows).forEach((key) => {
        let injected: Array<unknown> = rows[key];
        let oldRows: Array<unknown> = prevState.rows[key];
        let newRows = oldRows
          ? oldRows.slice()
          : [];
        
        console.log('got', key, 
          'batch of', injected.length, 
          'at', start);
        
        while (newRows.length && !newRows[newRows.length-1])
          newRows.pop();
        
        for (let i = 0; i < injected.length; ++i)
          newRows[i + start] = injected[i];
        
        newRowsTable[key] = newRows;
      });

      //console.log('updated rows:', newRows);

      return {
        rows: newRowsTable
      };
    });
  }

  needAggregation(type?: ReportType): boolean {
    return [
      ReportType.occupancy,
      ReportType.power
    ].includes(type || this.state.reportType);
  }

  needGroups(type?: ReportType): boolean {
    return [
      ReportType.session
    ].includes(type || this.state.reportType);
  }

  needSites(type?: ReportType): boolean {
    return [
      ReportType.occupancy,
      ReportType.power
    ].includes(type || this.state.reportType);
  }

  needDevices(type?: ReportType): boolean {
    return [
      ReportType.fault,
      ReportType.session
    ].includes(type || this.state.reportType);
  }

  componentDidUpdate(
      oldProps: ReportingSheetProps,
      oldState: ReportingSheetState): void {
    if (oldProps.organization !== this.props.organization) {
      // Cancel any async operation
      this.paginationLoader = null;

      this.setState({
        paginators: this.initializedPaginators(),
        rows: this.initializedRows(),
        organization: this.props.organization,

        // This could remember the selection, per organization
        selectedDevices: null,
        selectedSites: null
      });
    }

    if (!oldState.reportRunning && this.state.reportRunning) {
      console.log('reporting busy');
      this.props.busy.current?.moreBusy();
    } else if (oldState.reportRunning && !this.state.reportRunning) {
      console.log('reporting idle');
      this.props.busy.current?.lessBusy();
    }

    if (oldState.organization !== this.state.organization)
      this.refreshLists(this.state.organization);
  }
  

  

  sites(state?: ReportingSheetState): Site[] {
    return Object.values((state || this.state).siteDevices?.sites || {});
  }
  

  columnCount(): number {
    switch (this.state.reportType) {
      case ReportType.fault:
        return 8;
      case ReportType.occupancy:
        return 5;
      case ReportType.power:
        return 4;
      case ReportType.session:
        return 12;
      default:
        return 1;
    }
  }

  reportTitle(type?: ReportType): string {
    switch (type || this.state.reportType) {
      case ReportType.fault:
        return xl8('faultHistoryReport');
      case ReportType.occupancy:
        return xl8('occupancyReport');
      case ReportType.power:
        return xl8('powerReport');
      case ReportType.session:
        return xl8('sessionHistoryReport');
      default:
        return '???';
    }
  }

  renderReportLoading(): ReactNode {
    return (
      <div className="empty-report-container">
        <span>
          <GeneratingReportIcon width="96" height="96" />
        </span>
        <h3 className="empty-overlay-container">
          {xl8('generatingReportHeader')}
        </h3>
        {/* <div className="report-progress-container">
          <ProgressComponent 
            percent={80}
            bgColor="#DCE3EB"
            bgBorderRadius="10px"
            height="8px"
            barColor="#175785"
            barBorderRadius="10px"
          />
        </div> */}
      </div>
    );
  }

  renderReportRows(): ReactNode {
    let loading = this.renderReportLoading();

    let paginator = this.paginator();
    let displayedReport = paginator.displayedReport;

    let reportKey = '_' + (displayedReport?.created?.getTime().toString(16) 
      ?? 'none') + '_';

    switch (this.state.reportType) {
      case ReportType.fault:
        return this.state.rows.fault?.slice(
          paginator.displayOffset,
          paginator.displayOffset + 
          paginator.displayCount)
        .map((row, index) => {
          if (!row)
            return this.emptyRow();
          let rowKey = reportKey + (index + paginator.displayOffset);
          console.log('rowKey', rowKey, 'for', index, 
            'at', paginator.displayOffset);
          return (
            <tr key={rowKey}>
              <td data-label="Date">
                {formatDate(row.timestamp, false, true)}
              </td>

              <td data-label="Time">
                {formatDate(row.timestamp, true, false)}
              </td>

              <td data-label="Site">
                {row.siteName}
              </td>

              <td data-label="Charging Station">
                {row.stationName || row.evseName}
              </td>

              {/* <td data-label="Port" align="right">
                {row.portConnectorId}
              </td> */}

              <td data-label="Session ID" align="right">
                {row.sessionId}
              </td>

              <td data-label="Error">
                {row.error}
              </td>

              <td data-label="Info">
                {row.info}
              </td>
            </tr>
          );
        }) || loading;
      case ReportType.occupancy:
        return this.state.rows.occupancy?.slice(
          paginator.displayOffset,
          paginator.displayOffset + 
          paginator.displayCount)
        .map((row, index) => {
          if (!row)
            return this.emptyRow();
          
          let rowDate = row.date;
          let hourSt = formatDate(rowDate, true, false);
          let hours = displayedReport.aggregationSelection === Aggregation.hour
            ? 1
            : displayedReport.aggregationSelection === Aggregation.day
            ? 24
            : Infinity;
          let hourEn = formatDate(addHours(rowDate, hours), true, false);

          let rowKey = reportKey + (index + paginator.displayOffset);

          return (
            <tr key={rowKey}>
              <td>
                {formatDate(row.date, false)}
              </td>
              <td>
                {hourSt + '-' + hourEn}
              </td>
              <td>
                {row.siteName}
              </td>
              <td align="right">
                {(100 * row.occupied / row.total).toFixed(0) + '%'}
              </td>
              <td align="right">
                {row.occupied}
              </td>
            </tr>
          );
        }) || loading;
      case ReportType.power:
        return this.state.rows.power?.slice(
          paginator.displayOffset,
          paginator.displayOffset + 
          paginator.displayCount)
        .map((row, index) => {
          if (!row)
            return this.emptyRow();
          
          let hourSt = formatDate(row.st, true, false);
          let hourEn = formatDate(row.en, true, false);

          let rowKey = reportKey + (index + paginator.displayOffset);

          return (
            <tr key={rowKey}>
              <td>
                {formatDate(row.st, false, true)}
              </td>
              <td>
                { hourSt + '-' + hourEn }
              </td>
              <td>
                {row.siteName}
              </td>
              <td align="right">
                {(row.peakPower / 1000).toFixed(2)}
              </td>
            </tr>
          );
        }) || loading;
      case ReportType.session:
        return this.state.rows.session?.slice(
          paginator.displayOffset,
          paginator.displayOffset + 
          paginator.displayCount)
        .map((row, index) => {
          if (!row)
            return this.emptyRow();
          let rowKey = reportKey + (index + paginator.displayOffset);
          return (
            <tr key={rowKey}>
              <td>
                {formatDate(row.startTime, false)}
              </td>
              
              <td>
                {formatDate(row.startTime, true, false)}
              </td>

              <td>
                {formatDate(row.endTime, true, false)}
              </td>

              <td>
                {row.siteName}
              </td>
              
              <td>
                {row.stationName || row.evseName}
              </td>

              <td align="right">
                {formatUnixDuration(row.endTime, row.startTime)}
              </td>

              <td align="right">
                {(row.netEnergy / 1000).toFixed(2)}
              </td>

              <td align="right">
                {(row.netEnergy / 
                  (timestampDiffToHours(row.endTime, row.startTime)) / 1000).toFixed(2)}
              </td>
              
              {/* <td align="right">
                {row.portConnectorId}
              </td> */}
              
              <td align="right">
                {row.sessionId}
              </td>
              
              <td>
                {this.filterSessionInitiation(row)}
              </td>
              
              <td>
                {row.groupName}
              </td>
              
              <td>
                {row.stopReason}
              </td> 

              <td align="right" hidden={!isDemo()}>
                {row.chargerType === 'ac' ? '$1.00' : '$10.00'} CAD/hr  
              </td>
              
              <td align="right" hidden={!isDemo()}>
                ${((timestampDiffToHours(row.endTime, row.startTime) *
                  (row.chargerType === 'ac' ? 1 : 10))).toFixed(2)}
              </td>
            </tr>
          );
        }) || loading;
      default:
        return '???';
    }
  }
  
  private emptyRow(): ReactNode {
    return (
      <tr>
        <td colSpan={this.columnCount()}>
          <SwychedSpinner busy={1}/>
        </td>
      </tr>
    );
  }

  loadAllRows(): Promise<void> {
    let paginator = this.paginator();
    let promise = this.paginationLoader(0, paginator.totalRecords);
    return promise;
  }

  escapeRow(fields: string[]): string {
    return fields.map((field) => {
      /*eslint-disable*/
      // yes eslint, I really meant control characters
      field = field === null ? '' : field;
      // Need quotes if it has any 
      // ASCII control characters, a space, doublequote, or comma
      let needQuotes = /[\x00-\x20]",/.test(field);
      /*eslint-enable*/
      
      let result = '';
      
      if (needQuotes)
        result += '"';
      
      for (let i = 0; i < field.length; ++i) {
        result += field[i];
        if (field[i] === '"')
          result += '"';
      }
      
      if (needQuotes)
        result += '"';

      return result;
    }).join(',');
  }

  csvRowsFrom<T>(rows: T[],
      columns: Array<
        // Just name means use same name for source data and csv
        string |
        // Dynamic formatter
        ((row: T) => [string, string]) |
        // outputName, inputName
        ([string, string]) |
        // outputName, formatter
        ([string, (value) => string]) |
        // outputName, inputName, formatter
        ([string, string, (value) => string])>)
      : string[] {
    if (!rows)
      return [];
    
    let result: string[] = [];

    // Write headings row
    result.push(this.escapeRow(columns.map((column) => {
      if (typeof column === 'function') {
        let [ name,  ] = column(null);
        return name;
      }

      if (typeof column === 'string')
        return column;
      
      return column[0];
    })));
    
    // Write rows
    for (let i = 0; i < rows.length; ++i) {
      let row = rows[i];
      
      result.push(this.escapeRow(columns.map((column) => {
        let value;

        if (typeof column === 'function') {
          let [ , customValue ] = column(row);
          value = customValue;
        } else if (typeof column === 'string') {
          // Simple
          value = row[column];
        } else {
          let outputName = column[0];
          let inputName = outputName;

          let formatter: (value: string) => string;
          if (typeof column[2] === 'function')
            formatter = column[2];
          else if (typeof column[1] === 'function')
            formatter = column[1];

          if (typeof column[1] === 'string')
            inputName = column[1];

          if (formatter)
            value = formatter(row[inputName]);
          else
            value = row[inputName];
          
          if (value instanceof Date)
            value = formatReportDate(value);
        } 

        return '' + value;
      })));
    }

    return result;
  }

  encodeCSV(): string[] {
    switch (this.state.reportType) {
      case ReportType.fault:
        return this.csvRowsFrom(this.state.rows.fault, [
          ['timestamp', (value) => formatReportDate(value)],
          // 'evseId',
          // 'evseName',
          // 'siteId',
          // Recommended Edit columns from Fault History CSV Jira App-12
          'siteName',
          'stationName',
          (row) => {
            return [
              'deviceID',
              row ? row.ocppChargepointId + '/' + row.portConnectorId : null
            ];
          },
          'sessionId',
          'error',
          'info',
        ]);
      case ReportType.occupancy:
        return this.csvRowsFrom(this.state.rows.occupancy, [
          ['date', (value) => formatReportDate(value)],
          'hour',
          'siteId',
          'siteName',
          'occupied',
          'total'
        ]);
      case ReportType.power:
        return this.csvRowsFrom(this.state.rows.power, [
          ['st', (value) => formatReportDate(value)],
          ['en', (value) => formatReportDate(value)],
          'siteId',
          'siteName',
          ['peakPower (kW)', (wattHours: number) => {
            return ((wattHours / 1000).toFixed(2));
          }],
        ]);
      case ReportType.session:
        return this.csvRowsFrom(this.state.rows.session, [
          ['startTime', (value) => formatReportDate(value)],
          ['endTime', (value) => formatReportDate(value)],
          'siteName',
          (row) => {
            return [
              'stationName', 
              row ? row.stationName ?? row.evseName : null
            ];
          },
          (row) => {
            return [
              'duration',
              row ? formatUnixDuration(row.endTime, row.startTime) : null
            ];
          },
          (row) => {
            return [
              'durationMinutes',
              row ? ((row.endTime - row.startTime) / 60).toFixed(2) : null
            ];
          },
          ['netEnergy_kWh', 'netEnergy', (wattHours: number) => {
            return ((wattHours / 1000).toFixed(3));
          }],
          (row) => {
            return [
              'avgPower_kW',
              row ? (row.netEnergy / 
                  (timestampDiffToHours(row.endTime, row.startTime)) / 1000).toFixed(2) : null
            ];
          },
          (row) => {
            return [
              'deviceID',
              row 
              ? '' + row.ocppChargepointId + '/' + row.portConnectorId 
              : null
            ];
          },
          // 'portConnectorId',
          'sessionId',
          (row) => {
            return [
              'startMethod',
              row ? this.filterSessionInitiation(row) : null
            ];
          },
          'idTag',
          'groupName',
          (row) => {
            return [
              'stopMethod',
              row ? row.stopReason : null
            ];
          },
        ]);
      default:
        return null;
    }
  }
  
  private filterSessionInitiation(row: SessionReportRow): string {
    if (row.autostartTagId === row.sessionInitiation ||
        row.sessionInitiation.includes('(' + row.autostartTagId + ')'))
      return 'Autostart';
    return row.sessionInitiation;
  }
  
  exportCsv(): Promise<void> {
    return Promise.resolve(this.paginationLoader
      ? null
      : this.updateReport())
    .then(() => {
      return this.loadAllRows();
    }).then(() => {
      return this.encodeCSV();
    }).then((rows) => {
      return rowsToCsvDataUrl(rows);
    }).then((dataUrl) => {
      let anchor = document.createElement('a');
      Object.assign(anchor, {
        href: dataUrl,
        download: this.generateReportFilename(),
        innerHTML: 'Download CSV'
      });
      Object.assign(anchor.style, {
        position: 'fixed',
        display: 'block',
        right: '100%',
        bottom: '100%'
      });
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  }

  onGenerateReport(): Promise<void> {
    //let organizationId = this.state.organization.id;
    return ModalFactory.withDialog(ModalUpdateReport, (updateReportModal) => {
      return updateReportModal.showDialog(this.state).then((result) => {
        if (!result)
          return;
        
        this.setState({
          reportType: result.options.reportType,
          startDate: result.options.startDate,
          endDate: result.options.endDate,
          aggregation: result.options.aggregation,
          selectedAccessCards: result.options.selectedAccessCards,
          selectedSites: result.options.selectedSites,
          selectedDevices: result.options.selectedDevices,
          renderedDateSelection: result.renderedDateSelection,
          renderedSiteSelection: result.renderedSiteSelection,
          renderedDeviceSelection: result.renderedDeviceSelection,
          renderedAccessCardSelection: result.renderedAccessCardSelection
        }, () => {
          this.updateReport();
        });
        
        // startSession(organizationId, site, device, result.duration)
        // .then((transactionInfo) => {
        //   toastSuccess('success: ' + JSON.stringify(transactionInfo));
        // }).catch((err) => {
        //   toastError('failed: ' + JSON.stringify(err));
        // });
      });
    });
  }

  generateReportFilename(): string {
    switch (this.state.reportType) {
      case ReportType.fault:
        return 'Fault report ' +
          formatFilenameDate(this.state.startDate) + '..' +
          formatFilenameDate(this.state.endDate) + '.csv';

      case ReportType.occupancy:
        return 'Occupancy report ' +
          formatFilenameDate(this.state.startDate) + '..' +
          formatFilenameDate(this.state.endDate) + '.csv';
        
      case ReportType.power:
        return 'Power report ' +
          formatFilenameDate(this.state.startDate) + '..' +
          formatFilenameDate(this.state.endDate) + '.csv';

      case ReportType.session:
        return 'Session report ' +
          formatFilenameDate(this.state.startDate) + '..' +
          formatFilenameDate(this.state.endDate) + '.csv';
      default:
        return '';
    }
  }

  renderReportTableHeadings(): ReactNode {
    switch (this.state.reportType) {
      case ReportType.fault:
        return (
          <>
            <th scope="col">{xl8('date')}</th>
            <th scope="col">{xl8('time')}</th>
            <th scope="col">{xl8('site')}</th>
            <th scope="col">{xl8('chargingStation')}</th>
            {/* <th scope="col" align="right">{xl8('port')}</th> */}
            <th scope="col" align="right">{xl8('sessionId')}</th>
            <th scope="col">{xl8('error')}</th>
            <th scope="col">{xl8('info')}</th>
          </>
        );
      case ReportType.occupancy:
        return (
          <>
            <th scope="col">{xl8('date')}</th>
            <th scope="col">{xl8('hour')}</th>
            <th scope="col">{xl8('site')}</th>
            <th scope="col" align="right">{xl8('peakOccupancy')}</th>
            <th scope="col" align="right">{xl8('occupiedDevices')}</th>
          </>
        );
      case ReportType.power:
        return (
          <>
            <th scope="col">{xl8('date')}</th>
            <th scope="col">{xl8('hour')}</th>
            <th scope="col">{xl8('site')}</th>
            <th scope="col" align="right"
              className="no-transform">
                {xl8('peakPower')} (kW)
            </th>
          </>
        );
      case ReportType.session:
        return (
          <>
            <th scope="col">{xl8('date')}</th>
            <th scope="col">{xl8('start')}</th>
            <th scope="col">{xl8('end')}</th>
            <th scope="col">{xl8('site')}</th>
            <th scope="col">{xl8('chargingStation')}</th>
            <th scope="col" align="right">{xl8('duration')}</th>
            <th scope="col" align="right">
              {xl8('energy')} <span className="no-transform">(kWh)</span>
            </th>
            <th scope="col" align="right">
              {xl8('avgPower')} (kW)
            </th>
            {/* <th scope="col" align="right">Port</th> */}
            <th scope="col" align="right">{xl8('sessionId')}</th>
            <th scope="col">{xl8('startMethod')}</th>
            <th scope="col">{xl8('group')}</th>
            {/* <th scope="col" align="right">Cost</th> */}
            <th scope="col">{xl8('stopMethod')}</th>
            <th scope="col"
              hidden={!isDemo()}>
              {xl8('rate')}
            </th>
            <th scope="col"
              hidden={!isDemo()}>
              {xl8('cost')}
            </th>
          </>
        );
      default:
        return (
          <>
          ???
          </>
        );
    }
  }
}
