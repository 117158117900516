import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NotificationWarningIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NotificationWarningIcon extends PureComponent<NotificationWarningIconProps, {}> {
  render(): ReactNode {
    return (
    <svg width={this.props.width} height={this.props.height}  viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <circle cx="28.5" cy="28.5" r="28.5" fill="#F9F9F9"/> */}
      <path fillRule="evenodd" clipRule="evenodd" d="M26.9623 15C27.7321 13.6667 29.6566 13.6667 30.4264 15L42.1177 35.25C42.8875 36.5833 41.9253 38.25 40.3857 38.25H17.003C15.4634 38.25 14.5011 36.5833 15.2709 35.25L26.9623 15ZM28.6967 35.1201C28.2033 35.1201 27.7967 34.9601 27.4767 34.6401C27.1567 34.3067 26.9967 33.8934 26.9967 33.4001C26.9967 32.9067 27.1567 32.5001 27.4767 32.1801C27.7967 31.8467 28.2033 31.6801 28.6967 31.6801C29.19 31.6801 29.5967 31.8467 29.9167 32.1801C30.2367 32.5001 30.3967 32.9067 30.3967 33.4001C30.3967 33.8934 30.2367 34.3067 29.9167 34.6401C29.5967 34.9601 29.19 35.1201 28.6967 35.1201ZM28.0767 29.6C28.25 29.76 28.4567 29.84 28.6967 29.84C28.9367 29.84 29.1367 29.76 29.2967 29.6C29.47 29.44 29.57 29.2067 29.5967 28.9L30.4967 21.92C30.5767 21.3333 30.4367 20.8667 30.0767 20.52C29.73 20.1733 29.27 20 28.6967 20C28.1767 20 27.7433 20.1467 27.3967 20.44C27.05 20.7333 26.8767 21.1267 26.8767 21.62C26.8767 21.74 26.8833 21.8333 26.8967 21.9L27.7767 28.9C27.8167 29.2067 27.9167 29.44 28.0767 29.6Z" fill="#C6C6C6"/>
    </svg>
    );
  }
}