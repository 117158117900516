import { History, createBrowserHistory, LocationState } from 'history';

const defaultHistory: History<LocationState> = createBrowserHistory({
// const defaultHistory: History<LocationState> = createBrowserHashHistory({
  basename: '',
  forceRefresh: false
  // getUserConfirmation?: typeof getConfirmation;
  // keyLength?: number;
});

const normalHistory = history;

export default defaultHistory;
//export default normalHistory;

export function puntToSignIn(history: History, 
    comeBackUrl?: string): void {
  let encodedURL = encodeURIComponent(
    comeBackUrl || location.pathname);
  
  history.replace('/login?destination=' + encodedURL);
}
