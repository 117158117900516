import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface LogoutIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class LogoutIcon extends PureComponent<LogoutIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24" >
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" 
          fill={this.props.fill}/>
      </svg>
    );
  }
}