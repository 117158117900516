import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NoAccessCardsIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NoAccessCardsIcon extends PureComponent<NoAccessCardsIconProps, object> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="15" y="18" width="60" height="60" rx="4.5" fill="#80A7C3"/>
        <rect x="19" y="18" width="56" height="60" rx="4.5" fill="#80A7C3"/>
        <rect x="15" y="18" width="57" height="60" rx="4.5" fill="#E8EEF4"/>
        <circle cx="43.5" cy="48" r="19.5" fill="white"/>
        <path d="M56.2717 46.3293C56.8141 45.7034 56.7497 44.7516 56.0871 44.2547C52.5795 41.6243 48.2217 40.0659 43.5 40.0659C38.7782 40.0659 34.4203 41.6243 30.9127 44.2548C30.2502 44.7517 30.1858 45.7034 30.7282 46.3293C31.2709 46.9555 32.2152 47.0174 32.8841 46.5281C35.8603 44.3511 39.53 43.0659 43.5 43.0659C47.4698 43.0659 51.1396 44.3511 54.1158 46.5281C54.7846 47.0173 55.729 46.9555 56.2717 46.3293Z" fill="#175785"/>
        <path d="M52.342 50.8635C52.8844 50.2376 52.8209 49.2837 52.1444 48.8058C49.7011 47.0799 46.7189 46.0659 43.5 46.0659C40.281 46.0659 37.2988 47.0799 34.8554 48.8059C34.179 49.2837 34.1154 50.2377 34.6578 50.8635C35.2005 51.4897 36.1439 51.5483 36.8325 51.0872C38.7394 49.8105 41.0327 49.0659 43.5 49.0659C45.9671 49.0659 48.2604 49.8105 50.1674 51.0872C50.856 51.5482 51.7993 51.4897 52.342 50.8635Z" fill="#175785"/>
        <path d="M48.4124 55.3977C48.9548 54.7718 48.8923 53.8121 48.1856 53.3804C46.8207 52.5465 45.2165 52.0659 43.5 52.0659C41.7834 52.0659 40.1791 52.5465 38.8142 53.3804C38.1075 53.8122 38.045 54.7719 38.5874 55.3977C39.1302 56.024 40.0734 56.0706 40.8141 55.6992C41.6222 55.294 42.5344 55.0659 43.5 55.0659C44.4654 55.0659 45.3777 55.294 46.1857 55.6992C46.9265 56.0706 47.8696 56.0239 48.4124 55.3977Z" fill="#175785"/>
        <path d="M75.0001 50.8468V73.5C75.0001 75.117 74.1473 76.5347 72.8669 77.328L55.098 67.0692C53.6632 66.2407 53.1715 64.406 54 62.9711L63 47.3826C63.8284 45.9478 65.6632 45.4561 67.098 46.2846L75.0001 50.8468Z" fill="#175785" fillOpacity="0.25"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M93.7894 58.5C95.2243 59.3284 95.7159 61.1632 94.8875 62.598L85.8875 78.1865C85.0591 79.6214 83.2243 80.113 81.7894 79.2846L53.2106 62.7846C51.7757 61.9561 51.2841 60.1214 52.1125 58.6865L61.1125 43.098C61.9409 41.6632 63.7757 41.1715 65.2106 42L93.7894 58.5ZM89.7378 65.248C90.0965 65.4551 90.2194 65.9138 90.0123 66.2726L87.0123 71.4687C86.8052 71.8274 86.3465 71.9503 85.9878 71.7432C85.6291 71.5361 85.5062 71.0774 85.7133 70.7187L88.7133 65.5226C88.9204 65.1638 89.3791 65.0409 89.7378 65.248Z" fill="#175785"/>
      </svg>
      
    );
  }
}