import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface AuthorizeCardIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class AuthorizeCardIcon extends PureComponent<AuthorizeCardIconProps, {}> {
  render(): ReactNode {
    return (
      <svg height={this.props.height} viewBox="0 0 24 24" width={this.props.width} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM11.0505 16.1111L17.0564 8.904L15.9041 7.94373L10.9497 13.889L8.00011 10.9394L6.93945 12L11.0505 16.1111Z" fill={this.props.fill}/>
      </svg>
    );
  }
}