import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NotificationMailReadIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NotificationMailReadIcon extends PureComponent<NotificationMailReadIconProps, {}> {
  render(): ReactNode {
    return (
    <svg width={this.props.width} height={this.props.height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1032 0.202365C12.7464 -0.0675363 12.2536 -0.0675363 11.8968 0.202365L0.396779 8.90004C0.21455 9.03786 0.0871091 9.23216 0.0314462 9.44877L12.5 16.8375L18.7402 13.1396L24.9686 9.44877C24.9129 9.23216 24.7854 9.03786 24.6032 8.90004L13.1032 0.202365ZM25 11.7549L19.7598 14.8602L13.0098 18.8602C12.6955 19.0465 12.3045 19.0465 11.9902 18.8602L0 11.7549V22.9999C0 23.5522 0.447715 23.9999 1 23.9999H24C24.5523 23.9999 25 23.5522 25 22.9999V11.7549Z" fill="#CCCCCC"/>
    </svg>
    
    );
  }
}