import React, { PureComponent, ReactNode } from "react";
import { AccessControlGroupMap } from "../api";
import { ModalFactory } from "./modals/Modal";
import {
  ModalEditAccessControlGroup
} from "./modals/ModalEditAccessControlGroup";
import { ValidatorFocusable } from "./modals/ModalValidator";
import { renderSpam, setStatePromise } from "./shared/ui";

export interface AccessControlGroupDropdownProps {
  organizationId: number;
  groups: AccessControlGroupMap;
  value: number;
  all?: boolean;
  onChange: (newValue: number) => void;
}

export interface AccessControlGroupDropdownState {
  acpgId: number;
}

export class AccessControlGroupDropdown
  extends PureComponent<AccessControlGroupDropdownProps,
    AccessControlGroupDropdownState>
  implements ValidatorFocusable {
  private selectElement = React.createRef<HTMLSelectElement>();
  
  constructor(props: AccessControlGroupDropdownProps) {
    super(props);
    console.log('constructing AccessControlGroupDropdown with value=', 
      props.value);
    let acpgId = props.value;
    if (props.groups) {
      let groupList = Object.values(props.groups);
      if (groupList.length &&
          !Object.prototype.hasOwnProperty.call(props.groups, acpgId))
        acpgId = groupList[0].id;
    } else {
      acpgId = 0;
    }
    this.state = {
      acpgId: acpgId
    };
  }
  
  focus(): void {
    this.selectElement.current?.focus();
  }

  componentDidMount(): void {
    // If the value got nudged onto something valid...
    if (this.props.value !== this.state.acpgId)
      this.props.onChange(this.state.acpgId);
  }

  render(): ReactNode {
    renderSpam('AccessControlGroupDropdown');
    return (
      <>
        <select className="form-select" 
            value={this.state.acpgId}
            onChange={(event) => {
              this.onGroupChange(+event.target.value);
            }}
            name="card-group">
          <option value="-1">{
            this.props.all 
            ? 'All Groups'
            : '------ Add New ------'            
          }</option>
          {
            Object.values(this.props.groups || []).map((group) => {
              return (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              );
            })
          }
        </select>
      </>
    );
  }

  onGroupChange(acpgId: number): Promise<void> {
    if (acpgId > 0 || this.props.all) {
      return setStatePromise<AccessControlGroupDropdownState,
          AccessControlGroupDropdown>(this, {
        acpgId: acpgId
      }).then(() => {
        this.props.onChange(this.state.acpgId);
      });
    }

    // They picked add new...

    return ModalFactory.withDialog(ModalEditAccessControlGroup, 
    (editAccessControlGroupModal) => {
      return editAccessControlGroupModal.showDialog(
        this.props.organizationId, null)
      .then((result) => {
        let group = result.group;
        if (!group)
          return null;
        this.props.groups[group.id] = group;
        return setStatePromise<AccessControlGroupDropdownState,
            AccessControlGroupDropdown>(this, {
          acpgId: group.id
        });
      }).then(() => {
        this.props.onChange(this.state.acpgId);
      }).catch((err) => {
        console.log('setting group failed:', err);
      });
    });
  }
}
