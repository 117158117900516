import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface DashboardIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class DashboardIcon extends PureComponent<DashboardIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 3.03082V12.75H20.9692C20.5881 17.3697 16.718 21 12 21C7.02944 21 3 16.9706 3 12C3 7.28202 6.63035 3.41194 11.25 3.03082Z" fill={this.props.fill || '#555'}/>
        <path d="M12.75 3.03082V11.25H20.9692C20.6085 6.87767 17.1223 3.39153 12.75 3.03082Z" fill={this.props.fill || '#555'}/>
      </svg>
    );
  }
}