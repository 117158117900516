import React, { Component } from "react";
import { renderSpam } from "./shared/ui";
import "./spinner.component.css";

export interface SwychedSpinnerProps {
  busy: number;
  debug?: boolean;
  width?: string;
}

export interface SwychedSpinnerState {
  busy: number;
}

export class SwychedSpinner 
    extends Component<SwychedSpinnerProps, SwychedSpinnerState> {  
  constructor(props: SwychedSpinnerProps) {
    super(props);

    this.state = {
      busy: 0
    };
  }

  shouldComponentUpdate(
      nextProps: Readonly<SwychedSpinnerProps>, 
      nextState: Readonly<SwychedSpinnerState>, 
      nextContext: never): boolean {
    let should = (this.props.busy !== nextProps.busy) ||
      (this.state.busy !== nextState.busy);
    if (this.props.debug) {
      if (!should)
        console.log('skipping spinner update');
      else
        console.log('requesting spinner update');
    }
    return should;
  }

  static hackColors = ['red', 'green', 'blue', 'magenta', 'purple'];
  private hackIndex = 0;

  public adjustBusy(adjustment: number): void {
    this.setState((oldState) => {
      return {
        busy: oldState.busy + adjustment
      };
    });
    if (this.spinnerRef.current)
      this.spinnerRef.current.style.border = 'solid ' + 
      (SwychedSpinner.hackColors[++this.hackIndex % 
        SwychedSpinner.hackColors.length]) + ' 1px';
    else
      console.log('updated busy but null spinner ref, should work though');
  }

  public moreBusy(): void {
    this.adjustBusy(1);
  }

  public lessBusy(): void {
    this.adjustBusy(-1);
  }

  private totalBusy(): number {
    return this.props.busy + this.state.busy;
  }

  private renderCount: number = 0;
  private spinnerRef = React.createRef<HTMLDivElement>();

  render(): JSX.Element {
    renderSpam('SwychedSpinner');
    let total = this.totalBusy();
    console.log('prop busy=', this.props.busy, 
      ', state busy=', this.state.busy);
    return (
      <>
        <div hidden={!total} className="busy-container" ref={this.spinnerRef}>
          {/* <div className="lds-ring" width={this.props.width}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div> */}
          {/* <span className="loader" width={this.props.width}></span> */}
          <i className="fa fa-circle-o-notch fa-spin swyched-spinner" ></i>
          {/* <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
        </div>
        {this.props.debug && ++this.renderCount}
      </>
    );
  }
}
