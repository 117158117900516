import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface FilterIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class FilterIcon extends PureComponent<FilterIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0001 13.3251L19.3108 6.88628C19.8487 6.23404 19.3848 5.25 18.5393 5.25H5.46089C4.61542 5.25 4.15147 6.23404 4.68943 6.88628L10.0001 13.3251V17.75C10.0001 18.3023 10.4478 18.75 11.0001 18.75H13.0001C13.5524 18.75 14.0001 18.3023 14.0001 17.75V13.3251Z" fill={this.props.fill}/>
      </svg>
    );
  }
}