import * as React from 'react';
import { ChangeEventHandler, ReactNode, RefObject } from 'react';
import {
  AccessControlPlan,
  ConnectorType,
  Device, DeviceType, 
  postDeviceBySite, patchDevice, Organization, 
  virtualDeviceName, Connector, ChargerType, connectorTypeOptions, 
  getOcppDevices, OcppDevices, getAllSiteDevices, SiteDevices, allSiteDevices, ConnectorStatus
} from '../../api';
import { xl8 } from '../../translations/i18n';
import { CheckIcon } from '../icons/CheckIcon';
import { CloseIcon } from '../icons/CloseIcon';
import { DownArrowIcon } from '../icons/DownArrowIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { UpArrowIcon } from '../icons/UpArrowIcon';
import { ReorderList } from '../ReorderList.component';
// import { AccessControlPlanList } from '../AccessControlPlanList';
// import { HorizontalTab } from './HorizontalTabContainer';
import { renderSpam, setStatePromise, toastError, toastSuccess } from '../shared/ui';
import { Modal, ModalBase } from './Modal';
import { ModalValidator } from './ModalValidator';

import "./ModalEditDevice.scss";

export interface ModalEditDeviceProps {
}

interface ModalEditDeviceResult {
  device: Device;
}

interface ModalEditDeviceState {
  organization: Organization;
  device: Device;
  isVirtual: boolean;
  ocpp: OcppDevices;
  siteDevices: SiteDevices;
  selectableChargepointIds: Set<string>;
  selfOk: boolean;
  connectorsMatchOcpp: boolean;
}

// interface PropertyBind {
//   element: HTMLElement,
//   parent: any,
//   key: string
// }

export class ModalEditDevice
    extends ModalBase<ModalEditDeviceProps, 
      ModalEditDeviceResult, ModalEditDeviceState> {
  private validator = new ModalValidator();
  private connectorEditorRefs: RefObject<HTMLDivElement>[] = [];

  private setDeviceFieldHandler<T extends { value: string }>(field: keyof Device)
      : React.ChangeEventHandler<T> {
    return (event) => {
      event.stopPropagation();
      this.setDevicePropAtomic(field, (oldValue) => {
        return event.target.value;
      });
    };
  }

  private setDeviceProp<T>(field: keyof Device, value: T): void {
    return this.setDevicePropAtomic(field, (oldValue) => {
      return value;
    });
  }

  private setDevicePropAtomic<T>(field: keyof Device, callback: ((oldValue: T) => T)) {
    // To shut up warnings
    let fieldStr = field as string;

    this.setState((prevState: ModalEditDeviceState) => {
      let newState: Partial<ModalEditDeviceState> = {
        device: {...prevState.device}
      };

      let value: T = callback(newState.device[fieldStr]);
      
      if (newState.device[fieldStr] !== value) {
        newState.device[fieldStr] = value;
        return newState as ModalEditDeviceState;
      }

      return null;
    });
  }

  // private deviceCountSelect = React.createRef<HTMLSelectElement>();
  private nameInput = React.createRef<HTMLInputElement>();
  private ocppChargepointIdInput = React.createRef<HTMLInputElement>();
  private chargerTypeInput = React.createRef<HTMLSelectElement>();
  private virtualChargerTypeInputs: RefObject<HTMLSelectElement>[] = []

  //private propertyBinds: PropertyBind[] = [];

  constructor(props: ModalEditDeviceProps) {
    super(props);
    this.state = {
      organization: null,
      device: {
        id: 0,
        locationId: 0,
        organizationId: 0,
        name: '',
        type: DeviceType.Ocpp,
        ocppChargepointId: '',
        connectors: [],
        accessControlPlans: [],
        accessControlGroups: [],
        status: null,
        isHub: false,
        chargerType: ChargerType.None,
        autostartTagId: null
      },
      isVirtual: false,
      ocpp: null,
      selectableChargepointIds: null,
      siteDevices: null,
      selfOk: false,
      connectorsMatchOcpp: false
    };

    // this.tabs = this.getTabList();

    this.validator.mustBeNotEmptyTrimmed(
      'name', 'Name cannot be empty', this.nameInput);
    this.validator.mustBeNotEmptyTrimmed(
      'ocppChargepointId', 'Charge point ID cannot be empty', 
      this.ocppChargepointIdInput);
    this.validator.mustPass('chargerType', 'Charger type must be selected',
      this.chargerTypeInput,
    (value: string) => {
      // It's automatically valid if it is a hub, 
      // because hubs don't have chargerType
      return this.state.device.isHub || !!value;
    });
    
  }

  shouldComponentUpdate(
      nextProps: ModalEditDeviceProps,
      nextState: ModalEditDeviceState): boolean {
    return nextState.device !== this.state.device ||
      nextState.selectableChargepointIds !== 
        this.state.selectableChargepointIds;
  }

  // private tabs: HorizontalTab[] = [];
  
  // private getTabList(): HorizontalTab[] {
  //   return [{
  //     title: 'Device Info',
  //     icon: 'bolt',
  //     body: (tab) => 
  //       <React.Fragment key={'deviceInfo'}>
  //           {/* Body content moved to render() */}
  //       </React.Fragment>
  //     }, {
  //       title: 'Device Groups',
  //       icon: 'admin_panel_settings',
  //       body: (tab) =>
  //         <React.Fragment key={'deviceGroups'}>
  //           <br/>

  //           <div className="row m-b-20">
  //             <div className="col-md-9 col-lg-9">
  //               <h5>
  //                 Autostart
  //               </h5>
  //               <span className="grey-label">
  //                 Allow users to start charging without
  //                 payment or access control
  //               </span>
  //             </div>
  //             <div className="col-md-3 col-lg-3">
  //               <div className="form-check form-switch m-t-20">
  //                 <input className="form-check-input"
  //                   type="checkbox"/>
  //               </div>
  //             </div>
  //           </div>

  //           <h4>Access Control Groups</h4>
  //           <div className="acg-list">
  //             <AccessControlPlanList
  //               organization={this.state.organization}
  //               accessControlGroups={this.state.device?.accessControlGroups}
  //               onChange={(newGroupList) => {
  //                 this.setGroupList(newGroupList);
  //               }}
  //               />
  //           </div>
  //         </React.Fragment>
  //     }
  //   ];
  // }

  componentDidUpdate(
      prevProps: Readonly<ModalEditDeviceProps>, 
      prevState: Readonly<ModalEditDeviceState>, 
      snapshot?: never): void {
    if (this.state.siteDevices && this.state.ocpp && (
        prevState.siteDevices !== this.state.siteDevices ||
        prevState.ocpp !== this.state.ocpp ||
        prevState.device?.ocppChargepointId !== 
        this.state.device?.ocppChargepointId)) {
      let chargepoints = Object.values(this.state.ocpp?.chargepoints || []);
      let httpCpList = chargepoints.map((cp) => {
        return cp.HTTP_CP;
      });
      let availSet = new Set<string>(httpCpList);
      let selfOk = availSet.has(this.state.device?.ocppChargepointId);
      let devices = allSiteDevices(this.state.siteDevices);
      devices.forEach((device) => {
        if (device.ocppChargepointId !== this.state.device?.ocppChargepointId)
          availSet.delete(device.ocppChargepointId);
      });      

      this.setState({
        selectableChargepointIds: availSet,
        selfOk
      });
    }

    if (prevState.device.ocppChargepointId !== 
        this.state.device.ocppChargepointId) {
      let chargepoints = Object.values(this.state.ocpp?.chargepoints || []);
      let ocppPortLists = Object.values(this.state.ocpp?.ports || []);
      let cpIndex = chargepoints.findIndex((cp) => {
        return cp.HTTP_CP === this.state.device.ocppChargepointId;
      });
      let portList = ocppPortLists[cpIndex] || null;

      // Make sure the connectors match
      let match = false;
      if (portList) {
        match = this.state.device.connectors.every((connector, index) => {
          let port = portList[index];
          let portConnectorIdMatch = (port.portConnectorId === 
            connector.portConnectorId);
          let portTypeMatch = (port.type === connector.type);
          return portConnectorIdMatch && portTypeMatch;
        });
        console.log('ports match', match);
      }

      this.setState({
        connectorsMatchOcpp: match
      });
    }
  }
  
  private hubSelect<T>(isntHub: T, isHub: T): T {
    return this.state.device?.isHub
      ? isHub
      : isntHub;
  }

  render(): JSX.Element {
    renderSpam('ModalEditDevice');
    return (
      <Modal
          bodyRef={this.modalBodyRef}
          title={
            (this.state.device?.isHub && !this.state.isVirtual)
            ? xl8("editHub") 
            : (this.state.device?.isHub && this.state.isVirtual)
            ? xl8("editHubDevice") 
            : this.state.device.id 
            ? xl8("editChargingStation") 
            : xl8("addChargingStation") 
          }
          ref={this.modal}
          onOK={() => this.onOK()}
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}
          confirmButtonContent={
            (this.state.device?.isHub && !this.state.isVirtual)
            ? xl8("saveChanges")
            : (this.state.device?.isHub && this.state.isVirtual)
            ? xl8("saveChanges")
            : this.state.device.id 
            ? xl8("editStation") 
            : xl8("addStation")
          }
          // extraButtons={!this.state.isVirtual && [{
          //   content: <>
          //     <i className="material-icons">sync</i> 
          //     {this.hubSelect(xl8('convertToHub'), xl8('convertToStation'))}
          //   </>,
          //   onClick: () => {
          //     this.setDevicePropAtomic('isHub', (oldValue) => {
          //       return !oldValue
          //     });
          //   }
          // }]}
          // confirmButtonIcon={'edit'}
          >
        {this.renderModalBody()}
      </Modal>
    );
  }

  private renderModalBody(): ReactNode {
    if (this.state.isVirtual) {
      return this.renderConnector(this.state.device.virtualDeviceIndex,
        this.state.device.connectors[this.state.device.virtualDeviceIndex]);
    }

    return (
      <>
        <label>
          <span>{this.hubSelect('Station', 'Hub')} Name</span>
          <input className="form-control" 
            ref={this.nameInput}
            value={this.state.device.name}
            onChange={this.setDeviceFieldHandler('name')}/>
        </label>
        <label>
          <span>{this.hubSelect('Station', 'Hub')} ID</span>
          {/* <select className="form-select"
              value={this.state.device.ocppChargepointId}
              onChange={(event) => {
                let value = event.target.value;

                this.setDeviceProp('ocppChargepointId', value);
              }}>
              <option>-- Select unassigned chargepoint --</option>
              {
                Array.from(this.state.selectableChargepointIds || [])
                .map((cpid) => {
                  return <option key={cpid} value={cpid}>{cpid}</option>;
                })
              }
          </select>
          <div>-- OR --</div> */}
          <input className="form-control" 
              value={this.state.device.ocppChargepointId}
              maxLength={40}
              onChange={(event) => {
                let value = event.target.value;

                this.setDeviceProp('ocppChargepointId', value);
              }}/>
          <span className="input-desc">
            Enter chargepointId assigned during commissioning
          </span>
        </label>
        <label>
          <span>
            Autostart Tag ID
          </span>
          <input className="form-control" 
            value={this.state.device.autostartTagId}
            onChange={this.setDeviceFieldHandler('autostartTagId')}/>
          <span className="input-desc">
            Optional tag to interpret as Autostart
          </span>
        </label>
        <label hidden={true}>
          <span>{xl8('chargerType')}</span>
          <select className="form-select"
            ref={this.chargerTypeInput}
            value={this.state.device.type}
            onChange={this.setDeviceFieldHandler('type')}>
            <option value={DeviceType.Ocpp}>
              Generic OCPP
            </option>
            <option value={DeviceType.EVBox} disabled={true}>
              EVBox Hub
            </option>
            {/*<option value={DeviceType.EVBoxSat} disabled={true}>
              EVBox Satellite
            </option>*/}
            <option value={DeviceType.Modbus} disabled={true}>
              Modbus
            </option>
          </select>
        </label>
        
        {(!this.state.isVirtual && !this.state.device?.isHub) && 
          this.renderChargerTypeSelect(this.state.device.chargerType, 
            this.chargerTypeInput, 
            this.setDeviceFieldHandler('chargerType'))}
        <label>
          {/* <span>{this.hubSelect('Connectors', 'Devices')}</span> */}
          <ReorderList 
            items={this.renderConnectors().map((node, index) => {
              return ['' + this.state.device?.connectors[index].id, node];
            })}
            onReorder={(fromIndex, toIndex) => {
              this.moveConnector(fromIndex, toIndex);
            }}/>
        </label>
        <button className='btn add-custom-element'
            onClick={(event) => {
              this.addConnector();
            }}
            >
          <span>
            <PlusIcon width="21" height="21" fill="#aaa" />
          </span>

          {this.hubSelect(xl8('addPort'), xl8('addStation'))}
        </button>
        {/* <HorizontalTabContainer tabs={this.tabs}/> */}
      </>
    );
  }

  private renderConnectors(): ReactNode[] {
    return this.state.device.connectors.map((connector, index) => {
      return (!connector.id || connector.portConnectorId)
        ? this.renderConnector(index, connector)
        : null;
    });
  }

  private renderConnector(index: number, connector: Connector) {
    switch (this.state.device.type) {
      case DeviceType.Ocpp:
        return (
          <div className={"connector-config" +
            (this.state.device.virtualDeviceIndex === index
              ? ' selected'
              : '')}
            key={connector.id}
            ref={this.connectorEditorRefByIndex(index)}>

            <div className="flex-header"
              hidden={!this.state.device?.isHub || this.state.isVirtual}>
              <span className="connector-index">
                {this.hubSelect('Connector', 'Station') +
                  ' ' + (index + 1)}
              </span>
              <div className="line"></div>
              <button
                className='btn move-station-btn'
                title={"Move " + this.hubSelect('Connector', 'Station') + 
                  " Up"}
                onClick={(event) => {
                  event.preventDefault();
                  this.moveConnector(index, index - 1);
                }}
                disabled={index <= 0}>
                <UpArrowIcon width="20" height="20" fill="#aaa"/>
              </button>
              <button
                className='btn move-station-btn'
                title={"Move " + this.hubSelect('Connector', 'Station') + 
                  " Down"}
                onClick={(event) => {
                  event.preventDefault();
                  this.moveConnector(index, index + 2);
                }}
                disabled={index >= 
                  (this.state.device?.connectors?.length ?? 0) - 1}>
                <DownArrowIcon width="20" height="20" fill="#aaa"/>
              </button>
              <button
                className='btn delete-station-btn'
                title={"Delete " + this.hubSelect('Connector', 'Station')}
                onClick={(event) => {
                  event.preventDefault();
                  this.removeConnector(index);
                } }>
                <CloseIcon width="18" height="18" fill="#AAAAAA"/>
              </button>
            </div>
            <div className="connector-name"
              hidden={!this.state.device?.isHub}>
              <label>
                Station Name
                <input
                  className="form-control" 
                  value={connector.name || ''}
                  placeholder={virtualDeviceName(
                    this.state.device, false, index)}
                  onChange={(event) => {
                    this.setConnectorProp(
                      index, 'name', event.target.value);
                  } } />
                </label>
            </div>

            <label
              hidden={!this.state.device?.isHub}>
              <span>{this.hubSelect('Connector ID', 'Station ID')}</span>
              <input
                className="form-control" 
                value={(connector.portConnectorId || '') + ''}
                onChange={(event) => {
                  let intText = event.target.value;
                  let intMatch = intText?.match(/^\s*([0-9]+)$/);
                  let intValue = intMatch ? Math.floor(+intMatch[1]) : null;
                  if (intValue !== null && intValue < 0)
                    return;
                  if (intValue !== null && intValue >= Math.pow(2,31))
                    return;
                  let value = intValue !== null ? intValue.toFixed(0) : '';
                  this.setConnectorProp(index, 'portConnectorId', value);
                }}/>
            </label>
            {(this.state.device?.isHub) &&
              this.renderChargerTypeSelect(connector.chargerType,
                this.virtualChargerTypeInputs[index] = 
                this.virtualChargerTypeInputs[index] || 
                React.createRef<HTMLSelectElement>(),
              (event) => {
                this.setConnectorProp(index, 
                  'chargerType', event.target.value);
              })
            }
            <label>{this.hubSelect('Port Type: Port ' + (index + 1), 
              'Port Type')}
              <select className="form-select"
                value={connector.type}
                onChange={(event) => {
                  this.setConnectorProp(index, 'type', event.target.value);
                } }>
                {this.renderConnectorTypeOptions()}
              </select>
            </label>
          </div>
        );
      default:
        return (
          <div>
            Don&apos;t know how to render
            &quot;{this.state.device.type}&quot;
            type of device yet
          </div>
        );
    }
  }
  
  private connectorEditorRefByIndex(index: number)
      : React.RefObject<HTMLDivElement> {
    if (!this.connectorEditorRefs[index])
      this.connectorEditorRefs[index] = React.createRef<HTMLDivElement>();
    return this.connectorEditorRefs[index];
  }

  private moveConnector(from: number, to: number) {
    let ignored = false;
    this.setState((prevState) => {
      let newDevice = {
        ...prevState.device
      };
      if (to < 0)
        to = 0;
      if (to > newDevice.connectors.length)
        to = newDevice.connectors.length;
      if (from === to) {
        ignored = true;
        return null;
      }
      newDevice.connectors = ReorderList.reorderItems(
        from, to, newDevice.connectors);
      return {
        device: newDevice
      };
    }, () => {
      if (!ignored) {
        to -= +(from < to);
        this.connectorEditorRefByIndex(to)?.current?.scrollIntoView();
      }
    });
  }

  private renderConnectorTypeOptions(): React.ReactNode {
    return connectorTypeOptions().map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      );
    });
  }

  private renderChargerTypeSelect(
      chargerType: ChargerType, 
      ref: RefObject<HTMLSelectElement>,
      onChange: ChangeEventHandler<HTMLSelectElement>): ReactNode {
    return (
      <label>
        Charger Type
        <select className="form-select"
          value={chargerType}
          ref={ref}
          onChange={(event) => {
            return onChange(event);
          }}>
          <option value={ChargerType.ACL2Charger}>
            {xl8('chargerType_ac')}
          </option>
          <option value={ChargerType.DCFastCharger}>
            {xl8('chargerType_dcfc')}
          </option>
        </select>
      </label>
    );
  }

  private removeConnector(index: number): void {
    this.setDevicePropAtomic<Connector[]>('connectors', (oldConnectors) => {
      let newConnectors = oldConnectors.slice();
      newConnectors.splice(index, 1);
      return newConnectors;
    });
  }

  private setConnectorProp<T>(index: number, field: keyof Connector, value: T) {
    this.setState((prevState) => {
      let fieldStr: string = field;

      let prevConnectors = prevState.device.connectors;
      let newConnectors = prevConnectors.slice();

      newConnectors[index] = {
        ...newConnectors[index]
      };

      newConnectors[index][fieldStr] = value;

      let newDevice = {
        ...prevState.device
      };

      newDevice.connectors = newConnectors;

      return {
        device: newDevice
      };
    });
  }

  private addConnector(): void {
    let lastConnector = this.state.device.connectors[
      this.state.device.connectors.length - 1] || null;
    let connector = this.newConnector(lastConnector);

    this.setDevicePropAtomic<Connector[]>('connectors', (oldConnectors) => {
      let updatedConnectors = oldConnectors.slice();
      updatedConnectors.push(connector);
      return updatedConnectors;
    });
  }

  private lastNewConnectorId = 0;

  private newConnector(basis?: Connector): Connector {
    return {
      id: --this.lastNewConnectorId,
      name: '',
      type: basis?.type || ConnectorType.SAEJ1772,
      portConnectorId: (basis?.portConnectorId ?? 0) + 1,
      chargerType: basis?.chargerType || ChargerType.None,
      status: basis?.status || ConnectorStatus.Unknown
    };
  }

  public showDialog(organization: Organization,
      device: Device, isVirtual: boolean): Promise<ModalEditDeviceResult> {
    console.assert(organization);
    console.assert(device);
    let siteDevices = getAllSiteDevices(organization.id);
    //let ocppDevices = getOcppDevices(organization.id);
    //ocppDevices.then((ocpp) => {
    //  return Promise.all([ocpp, siteDevices]);
    //})
    return siteDevices.then((siteDevices) => {
      return setStatePromise<ModalEditDeviceState, ModalEditDevice>(this, {
        organization,
        device: {...device},
        isVirtual: isVirtual,
        ocpp: null,
        siteDevices
      });
    }).then(() => {
      return this.show();
    });
  }

  private isAccessControlPlanChecked(plan: AccessControlPlan): boolean {
    return this.state.device &&
        this.state.device.accessControlPlans &&
        this.state.device.accessControlPlans.findIndex((acpId) => {
          return acpId === plan.id;
        }) >= 0;
  }

  private toggleAccessControlPlan(plan: AccessControlPlan, add: boolean) {
    if (!this.state.device.accessControlPlans)
      this.state.device.accessControlPlans = [];
    
    let index = this.state.device.accessControlPlans.findIndex((acpId) => {
      return acpId === plan.id;
    });

    let deviceClone: Device;

    if (index >= 0 && !add) {
      let newPlans = this.state.device.accessControlPlans.slice();
      newPlans.splice(index, 1);
      deviceClone = {
        ...this.state.device,
        accessControlPlans: newPlans
      };
    } else if (index < 0 && add) {
      let newPlans = this.state.device.accessControlPlans.concat(plan.id);

      deviceClone = {
        ...this.state.device,
        accessControlPlans: newPlans
      };
    } else {
      // ???
      console.log('huh?');
      return;
    }

    this.setState({
      device: deviceClone
    });
  }

  private onOK(): Promise<ModalEditDeviceResult> {
    // Work with clone of device object, and make temp connector ids be 0
    let device: Device = {
      ...this.state.device,
      connectors: [
        ...this.state.device.connectors.map((conn) => {
          return {
            id: conn.id > 0 ? conn.id : 0,
            ...conn
          };
        })
      ]
    };

    // If it is not a hub, force number the connector ids 1, 2, 3, ...
    if (!device.isHub) {
      device.connectors.forEach((connector, index) => {
        connector.portConnectorId = index + 1;
      });
    }

    if (!this.validator.isValid(device))
      return null;
    
    let result: Promise<Device>;
    if (device.id > 0) {
      result = patchDevice(this.state.organization.id, 
        device.locationId, device)
      .then(() => {
        toastSuccess('Device "'+ device.name +'" updated');
        return device;
      });
    } else {  
      result = postDeviceBySite(this.state.organization.id, 
        device.locationId, device)
      .then((result) => {
        toastSuccess('Device "'+ device.name +'" added');
        return {
          id: result.id,
          ...device
        };
      });
    }
    
    return result.then((device: Device) => {
      return {
        device: device
      };
    }).catch((err) => {
      toastError('Device update failed: ' + err.message);
      return null;
    });
  }

  public static newDevice(organizationId: number, siteId: number, 
      isHub: boolean): Device {
    return {
      id: 0,
      locationId: siteId,
      organizationId: organizationId,
      name: '',
      type: DeviceType.Ocpp,
      ocppChargepointId: '',
      connectors: [],
      accessControlPlans: [],
      accessControlGroups: [],
      status: null,
      isHub: isHub,
      chargerType: !isHub ? ChargerType.ACL2Charger : ChargerType.None,
      autostartTagId: null
    };
  }

  private setGroupList(accessControlGroups: number[]) {
    this.setState((prevState) => {
      return {
        device: {
          ...prevState.device,
          accessControlGroups
        }
      };
    });
  }
}

