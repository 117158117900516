import * as React from 'react';
import { Component, ReactNode, RefObject } from 'react';
import { xl8 } from '../../translations/i18n';
import { CloseIcon } from '../icons/CloseIcon';
import { CheckIcon } from '../icons/CheckIcon';
import { 
  focusFirstWithin, preventAndStop, renderSpam, setStatePromise, toastError
} from '../shared/ui';
import { SwychedSpinner } from '../spinner.component';
import './Modal.scss';

export interface ModalExtraButton {
  content: ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface ModalProps<TResult> {
  bodyRef?: RefObject<HTMLDivElement>;
  width?: string;
  height?: string;
  title: string;
  children?: ReactNode | ReactNode[];
  bodyClassName?: string;
  extraButtons?: ModalExtraButton[],
  confirmButtonContent?: ReactNode;
  confirmButtonIcon?: ReactNode;
  cancelButtonContent?: ReactNode;
  cancelButtonIcon?: ReactNode;
  confirmDisabled?: boolean;
  extraDisabled?: boolean[];
  onOK: { (): Promise<TResult | null> } | null;
  onCancel?: { (): Promise<TResult | boolean | null> } | null;
  onClose?: { (): Promise<TResult | boolean | null> } | null;
}

export interface ModalState {
  open: boolean;
  busy: number;
  forceFit: boolean;
}

export class Modal<TResult>
    extends Component<ModalProps<TResult>, ModalState> {
  public static defaultProps: ModalProps<any> = {
    width: '450px',
    height: '',
    title: '',
    confirmButtonContent: xl8('confirm'),
    cancelButtonContent: xl8('cancel'),
    onOK: null
  };
  
  private resolve: { (result: TResult | null): void } | null = null;

  private dialogRef = React.createRef<HTMLDivElement>();

  constructor(props: ModalProps<TResult | null>) {
    super(props);

    this.state = {
      open: false,
      busy: 0,
      forceFit: false
    };
  }

  private openChanged(): void {
    focusFirstWithin(this.dialogRef.current);
  }

  componentDidUpdate(
      prevProps: ModalProps<TResult>, 
      prevState: ModalState): void {
    if (!prevState.open && this.state.open) {
      this.openChanged();
    }
  }

  private resizeListener: (event: Event) => void = null;

  componentDidMount(): void {
    console.assert(!this.resizeListener);

    this.resizeListener = (event) => {
      this.checkSize();
    };
    
    window.addEventListener('resize', this.resizeListener);

    this.checkSize();
  }

  componentWillUnmount(): void {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  }

  private checkSize(): Promise<void> {
    let dialog = this.dialogRef.current;

    if (dialog) {
      let rect = dialog.getBoundingClientRect();

      let needForce = (rect.top <= 0 || rect.bottom >= window.innerHeight);

      if (this.state.forceFit !== needForce) {
        return setStatePromise<ModalState, Modal<TResult>>(this, {
          forceFit: needForce
        });
      }
    }

    return Promise.resolve();
  }

  toggle(show: boolean): Promise<TResult | null> | void {
    if (show)
      return this.show();
    
    return this.hide();
  }

  public show(): Promise<TResult | null> {
    return setStatePromise<ModalState, Modal<TResult>>(this, {
      open: true,
      forceFit: false
    }).then(() => {
      return this.checkSize();
    }).then(() => {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    });
  }

  public hide(): void {
    if (this.props.onCancel)
      this.props.onCancel();
    
    this.setState({
      open: false,
      forceFit: false
    });
  }

  public adjBusy(amount: number, state?: Partial<ModalState>): Promise<void> {
    return setStatePromise<ModalState, Modal<TResult>>(this, {
      busy: this.state.busy + amount,
      ...state
    });
  }

  public render(): JSX.Element {
    renderSpam('Modal');
    return (//ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className={'modal-frame ' + 
              (this.state.forceFit ? ' modal-force-fit' : '')}
            ref={this.dialogRef}
            role="dialog"
            aria-modal={true}
            hidden={!this.state.open}
            onKeyDown={(event) => this.onKeyDown(event)}
            style={{
              ...this.size
            }}>
          <div className="modal-header">
            <div className="modal-title-text">{this.props.title}</div>
            <div className="modal-controls">
              <div className="modal-controls-close"
                  onClick={(event) => this.onClose(event)}>
                <CloseIcon width="24" height="24" fill="#aaa"/>
              </div>
            </div>
          </div>
          <div className={"modal-body " + (this.props.bodyClassName ?? '')}
              ref={this.props.bodyRef}>
            {this.props.children}
          </div>
          <div className="modal-footer">
            <button className="btn btn-back mr-auto"
                type="button" 
                onClick={(event) => this.onCancel(event)}>
              {/* <i className="material-icons">arrow_back_ios</i> */}
              {this.props.cancelButtonContent}
            </button>
            {
              (this.props.extraButtons || []).map((button, buttonIndex) => {
                return (
                  <div key={'extraButton' + buttonIndex} 
                    className="scan-new-btn">
                    <button className="btn btn-secondary"
                        type="button" 
                        hidden={!!button.hidden}
                        disabled={!!button.disabled || 
                          this.props.extraDisabled?.[buttonIndex]}
                        onClick={(event) => button.onClick(event)}>
                      {button.content}
                    </button>
                  </div>
                );
              })
            }
            <button className="btn btn-primary"
                type="button" 
                disabled={!!this.state.busy || this.props.confirmDisabled}
                hidden={this.props.confirmButtonContent === ''}
                onClick={(event) => this.onOK(event)}>
              
              <span className="modal-confirm-next-icon">
                {this.props.confirmButtonIcon} 
              </span>

              {this.props.confirmButtonContent}

              <SwychedSpinner busy={this.state.busy}/>
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    switch (event.code) {
      case 'Escape':
        this.onCancel(event);
        return;

      case 'Enter':
      case 'NumpadEnter':
        this.onOK(event);
        return;
    }
  }

  private get size(): React.CSSProperties {
    return {
      width: this.props.width,
      height: this.props.height
    };
  }

  // private get visible(): React.CSSProperties {
  //   return {
  //     display: this.state.open ? 'block' : 'none'
  //   };
  // }

  private onOK(event: React.MouseEvent | React.KeyboardEvent): void {
    preventAndStop(event);

    this.adjBusy(1).then(() => {
      let resultPromise: Promise<any> = this.props.onOK
        ? Promise.resolve(this.props.onOK())
        : Promise.resolve(undefined);

      return resultPromise;
    }).then((result) => {
      return this.adjBusy(-1).then(() => result);
    }, (err) => {
      return this.adjBusy(-1).then(() => Promise.reject(err));
    }).then((result) => {
      if (result !== null) {
        this.hide();
      
        if (this.resolve)
          this.resolve(result);
      }
    }).catch((err) => {
      toastError(err.message);
    });
  }

  private handleCloseCancel(promise: Promise<boolean | TResult>): void {
    promise.then((result) => {
      if (result !== false) {
        this.resolve(result as TResult);
        this.hide();
      }
    });
  }

  private onClose(event: React.MouseEvent): void {
    preventAndStop(event);
    if (!this.props.onClose)
      return this.onCancel(event);
    
    let resultPromise = this.props.onClose();
    return this.handleCloseCancel(resultPromise);    
  }

  private onCancel(event: React.MouseEvent | React.KeyboardEvent): void {
    preventAndStop(event);
    let resultPromise = this.props.onCancel
      ? this.props.onCancel()
      : Promise.resolve(null);
    return this.handleCloseCancel(resultPromise);
  }
}

export class ModalBase<ModalProps, TResult, State = {}>
    extends Component<ModalProps, State> {
  
  //protected modal: Modal<TResult> | null = null;
  protected modal = React.createRef<Modal<TResult>>();
  
  protected modalBodyRef = React.createRef<HTMLDivElement>();

  public show(): Promise<TResult | null> {
    return this.modal.current.show();
  }

  public hide(): void {
    this.modal.current.hide();
  }
}

export interface ModalFactoryProps {

}

interface ModalData {
  node: ReactNode;
  uniqueId: number;
}

interface ModalFactoryState {
  modalStack: ModalData[];
}

// Manages the dynamic creation, hosting, nesting, and destruction of modals
export class ModalFactory 
    extends Component<ModalFactoryProps, ModalFactoryState> {
  static instance: ModalFactory | null = null;

  private lastUniqueId: number = 0;
  private dialogMap = new WeakMap<Component, JSX.Element>();
  private static instanceReadyResolver: (factory: ModalFactory) => void;
  private static instanceReady: Promise<ModalFactory> = 
      new Promise<ModalFactory>((resolve) => {
        ModalFactory.instanceReadyResolver = resolve;
      });

  constructor(props: ModalFactoryProps) {
    super(props);

    this.state = {
      modalStack: []
    };
  }

  shouldComponentUpdate(
      nextProps: ModalFactoryProps, 
      nextState: ModalFactoryState): boolean {
    return this.state.modalStack !== nextState.modalStack;
  }

  private static newReadyPromise(): Promise<ModalFactory> {
    return ModalFactory.instanceReady = new Promise<ModalFactory>((resolve) => {
      ModalFactory.instanceReadyResolver = resolve;
    });
  }

  componentDidMount(): void {
    console.assert(!ModalFactory.instance);
    if (!ModalFactory.instance) {
      ModalFactory.instance = this;
      ModalFactory.instanceReadyResolver(this);
    }
  }

  componentWillUnmount(): void {
    console.assert(ModalFactory.instance);
    ModalFactory.instance = null;
    ModalFactory.newReadyPromise();
  }

  static createDialog<R extends Component, P>(
      Type: { new(props: P): R }): Promise<R> {
        
    return ModalFactory.instanceReady.then((self) => {
      console.assert(self);
      return new Promise<R>((resolve, _) => {
        let node = <Type
          ref={(component) => {
            if (component) {
              self.dialogMap.set(component, node);
              resolve(component);
            } else {
              self.dialogMap.delete(component);
            }
          }}/>;
        
        return setStatePromise<ModalFactoryState, ModalFactory>(self, {
          modalStack: self.state.modalStack.concat({
            uniqueId: ++self.lastUniqueId,
            node: node
          })
        });
      });
    });
  }

  static removeDialog(dialog: Component): void {
    let self = ModalFactory.instance;
    let node = self.dialogMap.get(dialog);
    let newStack = self.state.modalStack.filter((candidate) => {
      return candidate.node !== node;
    });
    self.setState({
      modalStack: newStack
    });
  }

  static withDialog<R, C extends Component, P>(
      Type: { new(props: P): C }, 
      callback: (dialog: C) => Promise<R>): Promise<R> {
    return ModalFactory.createDialog(Type).then((dialog) => {
      let result = callback(dialog);
      let remove = () => {
        ModalFactory.removeDialog(dialog);
      }; 
      Promise.resolve(result).then(remove, remove);
      return result;
    });
  }

  render(): ReactNode {
    renderSpam('ModalFactory');
    return this.state.modalStack.map((modal) => {
      return (
        <React.Fragment key={modal.uniqueId}>
          {modal.node}
        </React.Fragment>
      );
    });
  }
}
