import * as React from 'react';
import { Component } from 'react';
import { getAllSiteDevices, loadResponsiveLayout, makeEvseIdToSiteIdMap, makeSaveResponsiveLayout, Organization, SiteDevices } from '../api';
import { OrganizationChangeListener } from '../App';
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './SheetDashboard.scss';
import { DeviceStatusWidget } from './dashboardWidgets/DeviceStatusWidget';
import { NetEnergyWidget } from './dashboardWidgets/NetEnergyWidget';
import { AverageSessionWidget } from './dashboardWidgets/AverageSessionWidget';
import { RealtimePowerWidget } from './dashboardWidgets/RealtimePowerWidget';
import { setStatePromise, toastError } from './shared/ui';
import { UserProperty } from './UserProperty';
import { xl8 } from '../translations/i18n';
import { FilterIcon } from './icons/FilterIcon';
import { CloseIcon } from './icons/CloseIcon';
import { SiteFilter } from './SiteFilter';

const ResponsiveGridLayout = WidthProvider(Responsive);


let xsLayout = [
  { i: "port-status-widget", x: 0, y: 0, w: 12, h: 2, minW: 12, minH: 2, maxW: 12 },
  { i: "realtime-power-widget", x: 0, y: 0, w: 12, h: 2, minW: 12, minH: 2, maxW: 12 },
  { i: "average-session-widget", x: 0, y: 0, w: 12, h: 2, minW: 12, minH: 2, maxW: 12 },
  { i: "net-energy-widget", x: 0, y: 1, w: 12, h: 3, minW: 12, minH: 2, maxW: 12 }
];

let smLayout = [
  { i: "port-status-widget", x: 0, y: 0, w: 4, h: 2, minW: 3, minH: 2 },
  { i: "realtime-power-widget", x: 0, y: 0, w: 4, h: 2, minW: 3, minH: 2 },
  { i: "average-session-widget", x: 0, y: 0, w: 4, h: 2, minW: 3, minH: 2 },
  { i: "net-energy-widget", x: 0, y: 1, w: 12, h: 3, minW: 3, minH: 2 }
];

let mdLayout = [
  { i: "port-status-widget", x: 0, y: 0, w: 4, h: 2, minW: 3, minH: 2 },
  { i: "realtime-power-widget", x: 4, y: 0, w: 3, h: 2, minW: 3, minH: 2 },
  { i: "average-session-widget", x: 8, y: 0, w: 4, h: 2, minW: 3, minH: 2 },
  { i: "net-energy-widget", x: 1, y: 1, w: 12, h: 3, minW: 4, minH: 2 }
];

let lgLayout = [
  { i: "port-status-widget", x: 0, y: 0, w: 5, h: 2, minW: 4, minH: 2},
  { i: "realtime-power-widget", x: 5, y: 0, w: 3, h: 2, minW: 3, minH: 2},
  { i: "average-session-widget", x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2},
  { i: "net-energy-widget", x: 1, y: 1, w: 12, h: 3, minW: 4, minH: 2}
];

let defautLayouts: Layouts = {
  xs: xsLayout,
  sm: smLayout,
  md: mdLayout,
  lg: lgLayout
};

export interface DashboardSheetProps {
  visible: boolean;
  organization: Organization | null;
}

interface DashboardSheetState {
  filterEvseIds: Set<number> | null;
  evseIdToSiteId: Map<number, number> | null;
  siteDevices: SiteDevices | null;
}

export class DashboardSheet 
    extends Component<DashboardSheetProps, DashboardSheetState>
    implements OrganizationChangeListener {

  private realtimePowerRef = React.createRef<RealtimePowerWidget>();
  private static readonly storageKey = 'dashboardSiteFilter';

  constructor(props: DashboardSheetProps) {
    super(props);

    this.state = {
      filterEvseIds: null,
      siteDevices: null,
      evseIdToSiteId: null,
      // layouts: JSON.parse(JSON.stringify(originalLayouts))
    };
  }

  componentDidMount(): void {
    this.updateDevices();
  }

  componentDidUpdate(
    prevProps: Readonly<DashboardSheetProps>,
    prevState: Readonly<DashboardSheetState>,
    snapshot?: never): void {
    if (prevProps.organization !== this.props.organization && 
        this.props.organization) {
      this.updateDevices();
    }
  }

  private updateDevices() {
    if (!this.props.organization)
      return;
    getAllSiteDevices(this.props.organization.id)
      .catch((err) => {
        toastError(err.message);
        return null as SiteDevices;
      }).then((siteDevices) => {
        let evseIdToSiteId = makeEvseIdToSiteIdMap(siteDevices);
        this.setState({
          siteDevices,
          evseIdToSiteId
        });
      });
  }

  // componentDidUpdate(
  //     prevProps: Readonly<DashboardSheetProps>,
  //     prevState: Readonly<DashboardSheetState>,
  //     snapshot?: never): void {
  //   if (prevProps.organization !== this.props.organization)
  //     this.organizationChanged(this.props.organization);
  // }

  organizationChanged(org: Organization): Promise<void> {
    return Promise.resolve();
    // return setStatePromise<DashboardSheetState, DashboardSheet>(this, {
    //   organization: org
    // });
  }

  getLayouts = () => {
    const savedLayouts = localStorage.getItem("grid-layout");
  
    return savedLayouts ? JSON.parse(savedLayouts) : { 
      lg: lgLayout,
      md: mdLayout,
      sm: smLayout,
      xs: xsLayout
     };
  };

  // resetLayout() {
  //   this.setState({ layouts: {} });
  // }

  // onLayoutChange(layout, layouts) {
  //   this.saveToLS("layouts", layouts);
  //   this.setState({ layouts });
  // }

  // getFromLS(key) {
  //   let ls = {};
  //   if (global.localStorage) {
  //     try {
  //       ls = JSON.parse(global.localStorage.getItem("react-grid-layout")) || {};
  //     } catch (e) {
  //       /*Ignore*/
  //     }
  //   }
  //   return ls[key];
  // }
  
  // saveToLS(key, value) {
  //   if (global.localStorage) {
  //     global.localStorage.setItem(
  //       "react-grid-layout",
  //       JSON.stringify({
  //         [key]: value
  //       })
  //     );
  //   }
  // }
  
  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;
   
    return (
      <>
        <div className="row sheet-header">
          <div className="page-title-container col-lg-12">
            <h2 className="sheet-title">
              {xl8('myDashboard')}
            </h2>
            <div className="sheet-subtitle">
              {xl8('welcomeBack')}, <UserProperty propertyName="firstName"/>
            </div>
            <div className="filter-by-site-container">
              <SiteFilter
                organization={this.props.organization}
                siteDevices={this.state.siteDevices}
                evseIdToSiteId={this.state.evseIdToSiteId}
                storageKey={DashboardSheet.storageKey}
                onDevicesChanged={(evseIds) => {
                  this.setState({
                    filterEvseIds: evseIds
                  });
                }}/>
            </div>
          </div>
          
        </div>
{/*         
        <button className="btn btn-primary" onClick={() => this.setState({})}>Goofy Update</button>
        <button className="btn btn-primary" onClick={() => this.setState((prevState) => {
          return {
            filterEvseIds: new Set(prevState.filterEvseIds)
          };
        })}>Legit Update</button>
        <button className="btn btn-primary" onClick={() => {
          this.forceUpdate();
        }}>Update that never works</button>

        <hr/> */}

        <ResponsiveGridLayout className="layout clear" 
          layouts={loadResponsiveLayout('dashboard', defautLayouts)}
          onLayoutChange={makeSaveResponsiveLayout('dashboard')}
          // layouts={this.getLayouts()}
          // onLayoutChange={(layout, layouts) =>
          //   // this.onLayoutChange(layout, layouts)
          // }
          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          useCSSTransforms={false}
          margin={[30, 30]}
          isDraggable={true}
          isResizable={true}
          rowHeight={147}
          cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2} }
          onResizeStop={(layout, oldItem, newItem, 
              placeholder, event, element) => {
            console.log('onResize(',
              layout, oldItem, newItem, placeholder, event, element,')');
            this.realtimePowerRef.current?.checkSize();
          }}
        >
          <div key="port-status-widget">
            <DeviceStatusWidget 
              organization={this.props.organization}
              siteDevices={this.state.siteDevices}
              evseIdToSiteId={this.state.evseIdToSiteId}
              filterEvseIds={this.state.filterEvseIds}/>
          </div>
          <div key="realtime-power-widget">
            <RealtimePowerWidget
              ref={this.realtimePowerRef}
              siteDevices={this.state.siteDevices}
              evseIdToSiteId={this.state.evseIdToSiteId}
              filterEvseIds={this.state.filterEvseIds}
              organization={this.props.organization}
              refreshIntervalMs={10000}/>
          </div>
          <div key="average-session-widget">
            <AverageSessionWidget
              organization={this.props.organization}
              siteDevices={this.state.siteDevices}
              evseIdToSiteId={this.state.evseIdToSiteId}
              filterEvseIds={this.state.filterEvseIds}
              charging={true}
              />
          </div>
          <div key="net-energy-widget">
            <NetEnergyWidget
              organization={this.props.organization}
              siteDevices={this.state.siteDevices}
              evseIdToSiteId={this.state.evseIdToSiteId}
              filterEvseIds={this.state.filterEvseIds}/>
          </div>
        </ResponsiveGridLayout>
      </>
    );
  }
}

