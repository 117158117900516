import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface AccessControlIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class AccessControlIcon extends PureComponent<AccessControlIconProps, object> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM18 10.1989C16.3749 8.82693 14.2748 8 11.9816 8C9.70561 8 7.61989 8.81451 6 10.1679L7.0263 11.2969C8.37455 10.1891 10.1004 9.52406 11.9816 9.52406C13.88 9.52406 15.6203 10.2014 16.9739 11.3275L18 10.1989ZM15.9047 12.5037C14.8342 11.6333 13.4688 11.1116 11.9815 11.1116C10.5116 11.1116 9.16071 11.6212 8.09589 12.4734L9.17914 13.665C9.95625 13.0707 10.9277 12.7176 11.9815 12.7176C13.0528 12.7176 14.0388 13.0824 14.8221 13.6945L15.9047 12.5037ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15Z" fill={this.props.fill || '#555'}/>
      </svg> 
    );
  }
}