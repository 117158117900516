import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface RestartIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class RestartIcon extends PureComponent<RestartIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 13C6 11.35 6.67 9.85 7.76 8.76L6.34 7.34C4.9 8.79 4 10.79 4 13C4 17.08 7.05 20.44 11 20.93V18.91C8.17 18.43 6 15.97 6 13ZM20 13C20 8.58 16.42 5 12 5C11.94 5 11.88 5.01 11.82 5.01L12.91 3.92L11.5 2.5L8 6L11.5 9.5L12.91 8.09L11.83 7.01C11.89 7.01 11.95 7 12 7C15.31 7 18 9.69 18 13C18 15.97 15.83 18.43 13 18.91V20.93C16.95 20.44 20 17.08 20 13Z" fill={this.props.fill}/>
      </svg>
    );
  }
}