import React, { Component, ReactNode } from 'react';

export interface ReorderListProps {
  items: Array<[string,ReactNode]>;
  onReorder: (fromIndex: number, toIndex: number) => void;
}

interface ReorderListState {
  // empty
}

export class ReorderList extends Component<ReorderListProps, ReorderListState> {
  private rootRef = React.createRef<HTMLDivElement>();
  private static readonly dragGuid: string = 
    'b3a85e14-1ee9-4467-8ecb-0ab80b8e8f40';

  public static reorderItems<T>(
      fromIndex: number, toIndex: number, 
      oldItems: T[]): T[] {
    console.log('reorder', fromIndex,
      'to', toIndex,
      'in', oldItems);
    let newItems = oldItems.slice();
    let moved = newItems.splice(fromIndex, 1);
    if (toIndex > fromIndex)
      --toIndex;
    newItems.splice(toIndex, 0, moved[0]);
    console.log('after move:', newItems);
    return newItems;
  }

  constructor(props: ReorderListProps) {
    super(props);

    this.state = {

    };
  }

  render(): ReactNode {
    return (
      <div ref={this.rootRef}
        onDrop={(event) => {
          this.onDrop(event);
        }}
        onDragOver={(event) => {
          this.onDragOver(event);
        }}>
        {this.props.items.map((item, index) => {
          let [ key, node ] = item;
          return (
            <div key={key}
              draggable={true}
              onDragStart={(event) => {
                return this.onDragStart(event, node, index);
              }}>
              {node}
            </div>
          );
        })}
      </div>
    );
  }

  private static dragFormat = 'application/json';

  private onDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();    
  }

  private onDragStart(event: React.DragEvent<HTMLDivElement>, 
    item: ReactNode, index: number): void {
    event.dataTransfer.setData(ReorderList.dragFormat, JSON.stringify({
      app: ReorderList.dragGuid,
      index
    }));
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
  }

  private onDrop(event: React.DragEvent<HTMLDivElement>): void {
    let fromText = event.dataTransfer.getData(ReorderList.dragFormat);
    let from: number;
    try {
      let fromData = JSON.parse(fromText);
      // See if ours
      if (fromData.app !== ReorderList.dragGuid)
        return;
      from = fromData.index;
    } catch (err) {
      // Can't be ours
      return;
    }
    
    if (Number.isNaN(from))
      return;
    
    event.preventDefault();

    let root = this.rootRef.current;
    
    // How can there be no root element? Just in case, though
    if (!root)
      return;
    
    let x = event.clientX;
    let y = event.clientY;
    let children = Array.from(root.children);
    let count = children.length;

    // Really? What are they dragging then? Just in case, though
    if (!count)
      return;

    let rects = children.map((child) => {
      return child.getBoundingClientRect();
    });

    let mids = rects.map((rect) => {
      return rect.top + ((rect.bottom - rect.top) >> 1);
    });

    let insertAt = mids.findIndex((mid) => {
      return mid > y;
    });
    if (insertAt < 0)
      insertAt = mids.length;
    
    this.props.onReorder(from, insertAt);
  }
}