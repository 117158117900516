import React, { PureComponent, ReactNode } from "react";
import { LeftArrowIcon } from "./icons/LeftArrowIcon";
import { RightArrowIcon } from "./icons/RightArrowIcon";
import './PaginationControl.css';
import { renderSpam } from "./shared/ui";

export interface PaginationControlProps {
  count: number;
  visible: number;
  displayedPages: number;
  page: number;
  notNeeded?: 'collapse' | 'hidden' | 'visible';
  multiKey?: any;
  onPageChange: (page: number, count: number) => void;
}

interface PaginationControlState extends PaginationControlProps {
  totalPages: number;
}

export class PaginationControl
    extends PureComponent<PaginationControlProps, PaginationControlState> {
  constructor(props: PaginationControlProps) {
    super(props);

    if (this.props.displayedPages < 5)
      throw new Error('Invalid paginator displayedPages property value,' +
        ' must be >= 5');
    
    this.state = {
      ...props,
      totalPages: 0
    };
  }

  static getDerivedStateFromProps(
      props: PaginationControlProps, 
      state: PaginationControlState)
      : Partial<PaginationControlState> {
    let result: Partial<PaginationControlState> = {};
    let effectiveCount: number;
    if (props.count !== state.count)
      effectiveCount = result.count = props.count;
    else
      effectiveCount = state.count;
    
    let effectiveVisible: number;
    if (props.visible !== state.visible)
        effectiveVisible = result.visible = props.visible;
      else
        effectiveVisible = state.visible;
    
    let expectedTotalPages = Math.ceil(effectiveCount / effectiveVisible);
    if (expectedTotalPages !== state.totalPages)
        result.totalPages = expectedTotalPages;
    
    if (!props.notNeeded !== !state.notNeeded || 
        props.notNeeded !== state.notNeeded)
      result.notNeeded = props.notNeeded;
    
    if (!props.onPageChange !== !state.onPageChange)
      result.onPageChange = props.onPageChange;

    if (props.page !== state.page)
      result.page = props.page;
    
    if (props.displayedPages !== state.displayedPages)
      result.displayedPages = props.displayedPages;
    
    if (props.multiKey !== state.multiKey) {
      result.multiKey = props.multiKey;
    }

    return result;
  }

  componentDidUpdate(
      prevProps: Readonly<PaginationControlProps>,
      prevState: Readonly<PaginationControlState>): void {
    let expectedTotalPages: number = 
      Math.ceil(this.props.count / this.props.visible);
    
    if (this.state.totalPages !== expectedTotalPages) {
      this.setState({
        totalPages: expectedTotalPages
      });
    }

    if (prevState.multiKey !== this.state.multiKey &&
        this.state.onPageChange) {
      this.state.onPageChange(this.state.page, this.state.visible);
    }
  }

  componentDidMount(): void {
    let totalPages = this.calculateTotalPages(
      this.props.count, this.props.visible);
    
    this.setState({
      count: this.props.count,
      page: this.props.page,
      totalPages: totalPages
    });

    this.props.onPageChange(0, this.props.visible);
  }

  calculateTotalPages(count: number, visible: number): number {
    return Math.max(0, Math.ceil(count / visible));
  }

  // shouldComponentUpdate(nextProps: PaginationControlProps, 
  //     nextState: PaginationControlState): boolean {
  //   return true;
  // }

  render(): ReactNode {
    renderSpam('PaginationControl');
    // 
    // <1>  2   3   4   5  ... 18
    //  1  <2>  3   4   5  ... 18
    //  1   2  <3>  4   5  ... 18
    //  1   2   3  <4>  5  ... 18
    //  1  ...  4  <5>  6  ... 18
    //  1  ...  5  <6>  7  ... 18
    //  1  ...  6  <7>  8  ... 18
    //  1  ...  7  <8>  9  ... 18
    //  1  ...  8  <9> 10  ... 18
    //  1  ...  9 <10> 11  ... 18
    //  1  ... 10 <11> 12  ... 18
    //  1  ... 11 <12> 13  ... 18
    //  1  ... 12 <13> 14  ... 18
    //  1  ... 13 <14> 15  ... 18
    //  1  ... 14 <15> 16  17  18
    //  1  ... 14  15 <16> 17  18
    //  1  ... 14  15  16 <17> 18
    //  1  ... 14  15  16  17 <18>
    // 

    let elements: ReactNode[] = [];

    // Number of pages before center page selector
    let beforeCenter = (this.state.displayedPages) >> 1;
    let atCenter = this.state.displayedPages - beforeCenter;

    // Completely stop doing ... thing if it all fits
    let canSeeAllPages = (this.state.totalPages <= this.state.displayedPages);

    // Don't need ... if this is true
    let atStart = canSeeAllPages || (this.state.page <= beforeCenter);

    // Don't need ... if this is true
    let atEnd = canSeeAllPages ||
      (this.state.page >= this.state.totalPages - atCenter);
    
    let midStart: number;
    let midEnd: number;
  
    if (atStart && atEnd) {
      midStart = 0;
      midEnd = this.state.totalPages;
    } else {
      if (atStart) {
        midStart = 0;
        midEnd = Math.min(this.state.displayedPages, this.state.totalPages);
      } else {
        // Need "1 ..."" at start
        midStart = Math.max(0, Math.min(
          this.state.totalPages - (this.state.displayedPages - 2),
          this.state.page - (beforeCenter - 2)));
        midEnd = Math.min(this.state.totalPages,
            midStart + this.state.displayedPages - 2);
      }
      
      if (midEnd !== this.state.totalPages) {
        // Need "... 9" at end
        midEnd -= 2;
      }
    }

    elements.push(
      <div className={'pagination-control-button'}
          key={'prev'}
          onClick={(event) => {
            this.adjPage(-1);
          }}>
        <span>
          <LeftArrowIcon width="24" height="24" fill="#C1C5C8" />
        </span>
      </div>
    );

    if (!atStart) {
      elements.push(
        <div className={this.numberClass(0)}
          key={'1'}
           onClick={(event) => this.setPage(0)}>1</div>,
        <div key={'startEllipsis'}>...</div>
      );
    }

    for (let i = midStart; i < midEnd; ++i) {
      elements.push(
        <div className={this.numberClass(i)}
            key={'' + (i + 1)}
            onClick={(event) => {
              this.setPage(i);
            }}>
          {i + 1}
        </div>
      );
    }

    if (!atEnd) {
      elements.push(
        <div key={'endEllipsis'}>...</div>,
        <div 
          key={'' + this.state.totalPages}
          className={this.numberClass(this.state.totalPages - 1)}
          onClick={(event) => {
            this.setPage(this.state.totalPages - 1);
          }}>
          {this.state.totalPages}
        </div>
      );
    }

    elements.push(
      <div className="pagination-control-button"
          key={'next'}
          onClick={(event) => {
            this.adjPage(1);
          }}>
        <span>
          <RightArrowIcon width="24" height="24" fill="#C1C5C8" />
        </span>
      </div>
    );

    return (
      <div key={Date.now()} 
          style={{
            visibility: this.state.totalPages <= 1
              ? (this.state.notNeeded || 'visible')
              : 'visible'
          }}
          className="pagination-control-container pull-right">
        {elements}
      </div>
    );
  }

  numberClass(page: number): string {
    return  ' paginagion-control-page ' + 
      ((this.state.page === page) 
        ? 'pagination-control-selected' 
        : '');
  }

  private adjPage(amount: number) {
    this.setPage(this.state.page + amount);
  }

  private setPage(page: number): void {
    page = Math.max(0, Math.min(this.state.totalPages - 1, page));
    this.props.onPageChange(page, this.state.visible);
  }
}