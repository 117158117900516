import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface SwychedLogoProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class SwychedLogo extends PureComponent<SwychedLogoProps, {}> {
  constructor(props: SwychedLogoProps) {
    super(props);
    this.state = {
      fill: null,
      hoverFill: null,
      width: '',
      height: ''
    };
  }
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 356 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M330.291 55.143V2.198L287.473 55.143H330.291Z" fill="#E43D30"/>
        <path d="M353.437 42.316H310.618L310.618 95.261L353.437 42.316Z" fill="#993921"/>
        <path d="M330.292 42.316H310.618V55.142H330.292V42.316Z" fill="#BC2F2C"/>
        <path d="M12.619 112.928C13.148 116.784 16.475 118.069 20.407 118.069C24.107 118.069 26.307 116.406 26.307 114.289C26.307 112.928 25.399 111.567 22.148 110.889L14.433 109.3C7.63299 107.863 3.77299 104.007 3.77299 98.564C3.77299 91.305 9.67299 86.088 19.197 86.088C28.118 86.088 34.318 90.7 35.603 97.278L26.077 99.168C25.699 96.144 23.053 93.951 18.894 93.951C14.887 93.951 13.753 95.917 13.753 97.651C13.753 98.861 14.207 100.151 17.153 100.827L26.153 102.793C32.806 104.229 36.053 108.387 36.053 113.453C36.053 121.77 29.324 126.08 19.495 126.08C11.178 126.08 3.54199 122.828 2.55899 114.965L12.619 112.928Z" fill="#003A5D"/>
        <path d="M81.709 125.025H72.709L65.3 102.8L57.815 125.028H48.815L36.264 87.2279H46.169L53.654 110.44L61.366 87.2279H69.153L76.865 110.515L84.35 87.2279H94.26L81.709 125.025Z" fill="#003A5D"/>
        <path d="M114.842 137.8H103.728L109.776 123.738L94.125 87.146H105.24L115.22 111.264L125.654 87.146H136.768L114.842 137.8Z" fill="#003A5D"/>
        <path d="M163.585 109.6L172.809 111.944C170.692 120.563 162.909 126.083 153.909 126.083C142.87 126.083 133.721 117.992 133.721 106.122C133.721 94.2519 142.87 86.0859 153.909 86.0859C158.147 86.0223 162.289 87.3482 165.702 89.8608C169.115 92.3734 171.611 95.9346 172.809 99.9999L163.209 102.722C161.848 97.4289 158.294 95.2369 153.909 95.2369C147.633 95.2369 143.399 99.6969 143.399 106.124C143.399 112.624 147.633 116.936 153.909 116.936C158.292 116.935 161.846 114.818 163.585 109.6Z" fill="#003A5D"/>
        <path d="M212.074 101.965V124.949H202.093V104.082C202.093 98.789 199.069 95.236 194.458 95.236C188.787 95.236 185.082 99.016 185.082 108.236V124.945H175.1V70.512H185.08V90.624C188.029 87.675 191.961 86.087 196.95 86.087C206.025 86.087 212.074 92.59 212.074 101.965Z" fill="#003A5D"/>
        <path d="M255.058 108.769H226.328C227.31 114.213 231.166 117.54 235.779 117.54C238.727 117.54 242.432 117.162 245.154 112.55L254.076 114.44C250.748 122.303 244.02 126.084 235.776 126.084C225.115 126.084 216.42 117.993 216.42 106.123C216.42 94.2529 225.115 86.0869 235.926 86.0869C245.983 86.0869 254.677 93.8749 255.055 105.367L255.058 108.769ZM226.63 101.738H244.851C244.422 99.7129 243.292 97.9046 241.659 96.6319C240.027 95.3592 237.997 94.704 235.929 94.7819C233.827 94.7442 231.772 95.4074 230.088 96.6669C228.404 97.9263 227.188 99.7105 226.63 101.738V101.738Z" fill="#003A5D"/>
        <path d="M298.379 124.949H288.4V122C284.846 124.776 280.432 126.22 275.924 126.083C266.324 126.083 257.401 117.992 257.401 106.122C257.401 94.252 266.322 86.086 275.924 86.086C280.446 85.937 284.872 87.4128 288.4 90.246V70.512H298.38L298.379 124.949ZM288.399 106.123C288.449 104.671 288.21 103.223 287.696 101.864C287.182 100.505 286.402 99.2618 285.403 98.2066C284.405 97.1514 283.206 96.3052 281.877 95.7173C280.548 95.1294 279.116 94.8114 277.663 94.782C271.614 94.782 267.078 99.167 267.078 106.123C267.078 113.079 271.614 117.389 277.663 117.389C279.112 117.368 280.542 117.059 281.87 116.478C283.198 115.898 284.397 115.059 285.397 114.01C286.396 112.961 287.177 111.723 287.693 110.369C288.208 109.015 288.449 107.571 288.4 106.123H288.399Z" fill="#003A5D"/>
      </svg>
    );
  }
}