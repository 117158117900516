import React, { PureComponent, ReactNode } from "react";
import { 
  TransitionGroup, CSSTransition 
} from "react-transition-group";
import { 
  locationsFromDeviceIds, Organization, 
  Site, SiteDevices 
} from "../api";
import { xl8 } from "../translations/i18n";
import { CloseIcon } from "./icons/CloseIcon";
import { FilterIcon } from "./icons/FilterIcon";
import { SiteDropdown } from "./SiteDropdown";
import "./SiteFilter.scss";


export interface SiteFilterProps {
  organization: Organization;
  siteDevices: SiteDevices | null;
  evseIdToSiteId: Map<number, number> | null;
  storageKey: string;
  onDevicesChanged: (evseIds: Set<number>) => void;
}

export interface SiteFilterState {
  selectedDevices: Set<number> | null;
}

type SiteFilterOrgMap = { [orgId: string]: number[] }; 

export class SiteFilter
    extends PureComponent<SiteFilterProps, SiteFilterState> {  
  // public static defaultProps: SiteFilterProps = {

  // };

  private siteDropdownRef = React.createRef<SiteDropdown>();

  constructor(props: SiteFilterProps) {
    super(props);

    this.state = {
      selectedDevices: this.getSiteFilterStorage(
        this.props.organization) || new Set<number>(),
      // selectedDevices: null
    };
  }

  componentDidUpdate(
      prevProps: Readonly<SiteFilterProps>,
      prevState: Readonly<SiteFilterState>,
      snapshot?: never): void {    
    // If device list changed, select all locations (devices)
    if (prevProps.siteDevices !== this.props.siteDevices) {
      this.setState({
        selectedDevices: this.getSiteFilterStorage(this.props.organization)
      });      
    }

    if (prevState.selectedDevices !== this.state.selectedDevices) {
      let evseIds = new Set<number>(this.state.selectedDevices || []);
      this.props.onDevicesChanged(evseIds);
      if (prevProps.organization !== this.props.organization) {
        console.log('sitefilter update outgoing:',
          prevState.selectedDevices, prevProps.organization);
        this.updateSiteFilterStorage(prevState.selectedDevices,
          prevProps.organization);
      }
      console.log('sitefilter update incoming:',
        this.state.selectedDevices, this.props.organization);
      this.updateSiteFilterStorage(this.state.selectedDevices,
        this.props.organization);
    }
  }

  render(): JSX.Element {
    return (
      <div className="filter-by-site">
        <SiteDropdown
          ref={this.siteDropdownRef}
          asButton={(onClick) => {
            return (
              <button className="filter-by-site-btn" onClick={onClick}>
                <FilterIcon width="24px" height="24px" fill="#C1C5C8"/>
                {xl8('filterBy')} {xl8('site')}:
              </button>
            );
          }}
          siteOnly={true}
          organization={this.props.organization}
          siteDevices={this.props.siteDevices}
          hideLabel={true}
          value={this.state.selectedDevices}
          onGetInitialSelection={(incomingOrg: Organization) => {
            return this.getSiteFilterStorage(incomingOrg);
          }}
          onChange={(value, organization) => {
            console.log('sites changed', value, 'for', organization?.name);
            this.setState({
              selectedDevices: value
            });
          }}
        />
          {
            (this.state.selectedDevices && this.state.selectedDevices.size)
            ? <>
                <TransitionGroup className="inline">{
                    locationsFromDeviceIds(this.state.selectedDevices, 
                    this.props.siteDevices, this.props.evseIdToSiteId)
                  .map((site: Site) => {
                    return (
                      <CSSTransition
                        key={site.id}
                        className="filter-tag"
                        timeout={500}
                        classNames='fadeinout'>
                        <span>
                          {site.name}
                          <CloseIcon width="20" height="20" fill="#175785"
                            onClick={(event) => {
                              this.removeSelectedDevicesAtSite(site.id);
                            }}/>
                        </span>
                      </CSSTransition>                
                    );
                  })
                }</TransitionGroup>
              </>
            : (
              <span className="filter-tag">
                {xl8('all')}
              </span> 
            )
            
          }
        <span className="clear-filter-btn"
          hidden={!this.state.selectedDevices?.size}
          onClick={(event) => {
            this.clearSiteFilter();
          }}>
          {xl8('clearFilter')}
        </span>

      </div>  
    );
  }


  private removeSelectedDevicesAtSite(siteId: number) {
    this.setState((prevState, prevProps) => {
      let newSelection = new Set<number>(prevState.selectedDevices);

      let devicesAtSite = prevProps.siteDevices.deviceListBySite[siteId];
      
      devicesAtSite.forEach((device) => {
        newSelection.delete(device.id);
      });

      return {
        selectedDevices: newSelection
      };
    });
  }

  private clearSiteFilter() {
    this.setState({
      selectedDevices: new Set<number>()
    });
  }

  private safeParseJson<T>(text: string): T | null {
    try {
      return JSON.parse(text);
    } catch (err) {
      return null;
    }
  }
  
  private updateSiteFilterStorage(selectedDevices: Set<number>,
      organization: Organization) {
    let orgMapText = sessionStorage.getItem(this.props.storageKey);
    let orgMap = orgMapText
      ? this.safeParseJson<SiteFilterOrgMap>(orgMapText)
      : {};
    let selected = Array.from(selectedDevices || []);
    orgMap['' + organization.id] = selected;
    orgMapText = JSON.stringify(orgMap);
    sessionStorage.setItem(this.props.storageKey, orgMapText);
    localStorage.setItem(this.props.storageKey, orgMapText);
  }

  private getSiteFilterStorage(
      organization: Organization): Set<number> | null {
    let data: SiteFilterOrgMap | null = {};
    let dataSet: Set<number> | null = null;

    try {
      let text = localStorage.getItem(this.props.storageKey);
      if (text)
        data = JSON.parse(text) || {};
    } catch (err) {
      // ignore, data will be null if it failed
    }

    dataSet = new Set(organization && data[organization.id] || []);
    
    return dataSet;
  }
}
