import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NotificationMailUnreadIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NotificationMailUnreadIcon extends PureComponent<NotificationMailUnreadIconProps, {}> {
  render(): ReactNode {
    return (
    <svg width={this.props.width} height={this.props.height} viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.75499V14C0 14.5523 0.447716 15 1 15H24C24.5523 15 25 14.5523 25 14V2.75499L13.0098 9.86029C12.6955 10.0466 12.3045 10.0466 11.9902 9.86029L0 2.75499ZM24.8703 0.507073C24.6984 0.204276 24.3731 0 24 0H1C0.626947 0 0.301605 0.204276 0.129729 0.507073L12.5 7.8376L24.8703 0.507073Z" fill="#CCCCCC"/>
    </svg>
    );
  }
}