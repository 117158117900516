import * as React from 'react';
import { 
  AccessControlPlansResponse,
  patchLocationById,
  postSiteByOrganization,
  Site, 
  Organization} from '../../api';
import { 
  renderSpam, setComponentStateObjectProp, 
  setStatePromise, toastError,
  isDemo
} from '../shared/ui';
import { Modal, ModalBase } from './Modal';
import { ModalValidator } from './ModalValidator';
import { 
  CountryDropdown, 
  RegionDropdown
} from 'react-country-region-selector';
import { NestedTab, NestedTabContainer } from '../NestedTabContainer';
import { AccessControlPlanList } from '../AccessControlPlanList';
import { AccessControlIcon } from '../icons/AccessControlIcon';
import { PricingIcon } from '../icons/PricingIcon';
import { xl8 } from '../../translations/i18n';
import { PlayIcon } from '../icons/PlayIcon';
import { InfoIcon } from '../icons/InfoIcon';
import { CheckIcon } from '../icons/CheckIcon';
import { CogIcon } from '../icons/CogIcon';
import { HonkLogo } from '../icons/HonkLogo';

export interface ModalEditSiteProps {
}

export interface ModalEditSiteResult {
  site: Site;
}

interface ModalEditSiteState {
  organization: Organization;
  site: Site;
  title: string;
  okText: string;
  plansResponse: AccessControlPlansResponse;
  allowUIC: boolean;
}

export class ModalEditSite
    extends ModalBase<ModalEditSiteProps, ModalEditSiteResult,
    ModalEditSiteState> {
  private siteNameInput = React.createRef<HTMLInputElement>();
  private addressInput = React.createRef<HTMLInputElement>();
  private popul8URLInput = React.createRef<HTMLInputElement>();
  private siteNotes = React.createRef<HTMLTextAreaElement>();
  private nestedAllSettings = React.createRef<NestedTabContainer>();
  private nestedIntegrationSettings = React.createRef<NestedTabContainer>();

  private validator = new ModalValidator();

  static newSite(organizationId: number): Site {
    return {
      id: 0,
      organizationId: organizationId,
      name: '',
      address: '',
      popul8_url: '',
      notes: '',
      accessControlGroups: [],
      billingContact: '',
      billingCity: '',
      billingPostal: '',
      billingCountry: '',
      billingRegion: ''
    };
  }

  constructor(props: ModalEditSiteProps) {
    super(props);
    this.state = {
      organization: null,
      site: null,
      plansResponse: null,
      title: 'Edit',
      okText: 'OK',
      allowUIC: true
    };

    this.validator.mustBeNotEmptyTrimmed(
      'site_name', 'Name cannot be empty', this.siteNameInput);
  }

  public showDialog(organization: Organization, 
      site: Site, title: string, okText: string)
      : Promise<ModalEditSiteResult> {
    return setStatePromise<ModalEditSiteState, ModalEditSite>(this, {
      organization,
      site,
      title, 
      okText
    }).then(() => {
      return this.show();
    });
  }

  private tabsAllSettingsPayment(): NestedTab[] {
    return [{
      titleText: 'UIC Terminal Settings',
      titleRender: (text) => this.generateTabHeader(
        text, true, false, true, 'allowUIC'),
      icon: '',
      image: '/images/uic.svg',
      body: () => {
        return (
          <>
            <div className="modal-tab-header">
              Honk Mobile Settings
            </div>
            <div className="vertical-tab-content-body">
              <div className="row m-b-20">
                <div className="col-md-10 col-lg-10">
                  <h5>UIC Terminal</h5>
                  <span className="grey-label">
                    Allow users to pay for charging with a UIC Terminal
                  </span>
                </div>
                <div className="col-md-2 col-lg-2 m-t-30">
                    <div className="form-check form-switch">
                      <input className="form-check-input"
                        type="checkbox" checked />
                    </div>
                  </div>
              </div>
              <div className="row m-b-20 site-rate-container">
                <div className="col-4">
                  <label className="m-b-0">
                    Rate (Level-2)
                    <input type="text" className="form-control" value=""/>
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Currency
                    <select className="form-select">
                      <option>CAD</option>
                      <option>USD</option>
                      <option>EUR</option>
                    </select> 
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Units
                    <select className="form-select">
                      <option>hr</option>
                      <option>min</option>
                      <option>kWh</option>
                      <option>session</option>
                      </select>
                  </label>
                </div>
                <span className="input-desc">
                  Enter how much users will be charged for Level-2
                </span>
              </div>
              <div className="row m-b-20 site-rate-container">
                <div className="col-4">
                  <label className="m-b-0">
                    Rate (DCFC)
                    <input type="text" className="form-control" value=""/>
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Currency
                    <select className="form-select">
                      <option>CAD</option>
                      <option>USD</option>
                      <option>EUR</option>
                    </select> 
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Units
                    <select className="form-select">
                      <option>hr</option>
                      <option>min</option>
                      <option>kWh</option>
                      <option>session</option>
                    </select>
                  </label>
                </div>
                <span className="input-desc">
                  Enter how much users will be charged for DC fast charging
                </span>
              </div>
            </div>
          </>
        );
      }
    }, {
      titleText: 'Honk Mobile Settings',
      titleRender: (text) => this.generateTabHeader(
        text, true, false, true, 'allowUIC'),
      icon: <HonkLogo width="20" height="20" />,
      // image: 'images/honk.svg',
      body: () => {
        return (
          <>
            <div className="modal-tab-header">
              Honk Mobile Settings
            </div>
            <div className="vertical-tab-content-body">
              <div className="row m-b-20">
                <div className="col-md-10 col-lg-10">
                  <h5>Honk Mobile</h5>
                  <span className="grey-label">
                    Allow users to pay for charging using the honk mobile app
                  </span>
                </div>
                <div className="col-md-2 col-lg-2 m-t-30">
                    <div className="form-check form-switch">
                      <input className="form-check-input"
                        type="checkbox" checked />
                    </div>
                  </div>
              </div>
              <div className="row m-b-20 site-rate-container">
                <div className="col-4">
                  <label className="m-b-0">
                    Rate (Level-2)
                    <input type="text" className="form-control" value=""/>
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Currency
                    <select className="form-select">
                      <option>CAD</option>
                      <option>USD</option>
                      <option>EUR</option>
                    </select> 
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Units
                    <select className="form-select">
                      <option>hr</option>
                      <option>min</option>
                      <option>kWh</option>
                      <option>session</option>
                      </select>
                  </label>
                </div>
                <span className="input-desc">
                  Enter how much users will be charged for Level-2
                </span>
              </div>
              <div className="row m-b-20 site-rate-container">
                <div className="col-4">
                  <label className="m-b-0">
                    Rate (DCFC)
                    <input type="text" className="form-control" value=""/>
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Currency
                    <select className="form-select">
                      <option>CAD</option>
                      <option>USD</option>
                      <option>EUR</option>
                    </select> 
                  </label>
                </div>
                <div className="col-4">
                  <label className="m-b-0">
                    Units
                    <select className="form-select">
                      <option>hr</option>
                      <option>min</option>
                      <option>kWh</option>
                      <option>session</option>
                    </select>
                  </label>
                </div>
                <span className="input-desc">
                  Enter how much users will be charged for DC fast charging
                </span>
              </div>
            </div>
          </>
        );
      }
    }];
  }

  private tabsAllSetting(): NestedTab[] {
    return [{
      titleText: 'General Info',
      titleRender: (text) => this.generateTabHeader(text, false, false, null, null),
      icon: <InfoIcon width="27px" height="27px" fill="#c1c5c8"/>,
      body: () => {
        return (
          <>
            <div className="modal-tab-header">
              General Info
            </div>
            <label>
              <span>Site Name</span>
              <input className="form-control" 
                ref={this.siteNameInput}
                value={this.state.site.name}
                onChange={(event) => {
                  this.setSiteProp({
                    name: event.target.value
                  });
                }}/>
            </label>
            <label>
              <span>Contact</span>
              <input className="form-control" 
                type="text"
                value={this.state.site.billingContact}
                onChange={(event) => {
                  this.setSiteProp({
                    billingContact: event.target.value
                  });
                }}/>
            </label>
            <label>
              <span>Country</span>
              <CountryDropdown
                  showDefaultOption={true}
                  priorityOptions={['CA','US', 'DE', 'CH', 'UK', 'SG']}
                  classes={'form-select'}
                  value={this.state.site.billingCountry}
                  onChange={(country) => {
                    this.setSiteProp({
                      billingCountry: country
                    });
                  }}/>
  
              </label>
            <label>
              <span>Province/State</span>
                <RegionDropdown
                  classes={'form-select'}
                  country={this.state.site?.billingCountry}
                  value={this.state.site?.billingRegion}
                  onChange={(region) => {
                    this.setSiteProp({
                      billingRegion: region
                    });
                  }}/>
            </label>            
            <label>
              <span>City</span>
              <input className="form-control" 
                type="text"
                value={this.state.site.billingCity}
                onChange={(event) => {
                  this.setSiteProp({
                    billingCity: event.target.value
                  });
                }}/>
            </label>
            <label>
              <span>Address</span>
              <input className="form-control" 
                type="text"
                ref={this.addressInput}
                value={this.state.site.address}
                onChange={(event) => {
                  this.setSiteProp({
                    address: event.target.value
                  });
                }}/>
            </label>
            <label>
              <span>Postal/Zip</span>
              <input className="form-control" 
                type="text"
                value={this.state.site.billingPostal}
                onChange={(event) => {
                  this.setSiteProp({
                    billingPostal: event.target.value
                  });
                }}/>
            </label>

            <label>
              <span>Popul8 URL</span>
              <input className="form-control" 
                ref={this.popul8URLInput}
                value={this.state.site.popul8_url}
                onChange={(event) => {
                  this.setSiteProp({
                    popul8_url: event.target.value                    
                  });
                }}/>
            </label>
            <label>
              <span>Notes</span>
              <textarea className="form-control" 
                value={this.state.site.notes}
                defaultValue=""
                onChange={(event) => {
                  this.setSiteProp({
                    notes: event.target.value
                  });
                }}/>
            </label>
          </>
        );
      }
    }, {
      titleText: 'Autostart',
      titleRender: (text) => this.generateTabHeader(
        text, true, true, false, 'allowUIC'),
      icon: <PlayIcon width="24" height="24" fill="#c1c5c8" />,
      body: () => {
        return (
          <>
            <div className="modal-tab-header">
              Autostart
            </div>
            <div className="vertical-tab-content-body">
              <div className="row">
                <div className="col-md-9 col-lg-9">
                  <h5>
                    Autostart
                  </h5>
                  <span className="grey-label">
                    Allow users to start charging without
                    payment or access control
                  </span>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="form-check form-switch m-t-40">
                    <input className="form-check-input"
                      type="checkbox"
                      //checked
                      />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }, {
      titleText: 'Access Control',
      titleRender: (text) => this.generateTabHeader(
        text, true, true, true, 'allowUIC'),
      icon: <span>
              <AccessControlIcon width="27px" height="27px" fill="#c1c5c8"/>
            </span>,
      body: () => {
        return (
          <>
            <div className="modal-tab-header">
              Access Control
            </div>
            <div className="vertical-tab-content-body">
              <div className="row m-b-15">
                <div className="col-md-10 col-lg-10">
                  <h5>Access Control Groups</h5>
                  <span className="grey-label">
                    Allow the following access control groups to
                  </span>
                </div>
                <div className="col-md-2 col-lg-2 m-t-30">
                    <div className="form-check form-switch">
                      <input className="form-check-input"
                        type="checkbox" checked />
                    </div>
                  </div>
              </div>
              <div className="acg-list">
                <AccessControlPlanList
                  organization={this.state.organization}
                  accessControlGroups={this.state.site.accessControlGroups}
                  onChange={(newGroupList) => {
                    this.updateGroupList(newGroupList);
                  }}
                  />
              </div>
            </div>
          </>
        );
      }
    }, {
      titleText: 'Integrations',
      titleRender: (text) => this.generateTabHeader(
        text, true, true, true, 'allowUIC'),
      icon: <span>
              <CogIcon width={'24'} height={'24'} fill="#c1c5c8"/>
            </span>,
      body: () => {
        return (
          <>
            <div className="modal-tab-header" hidden={
              this.nestedIntegrationSettings.current?.isExpanded()
            }>
              Integration Settings
            </div>
            <NestedTabContainer
              ref={this.nestedIntegrationSettings}
              parent={this.nestedAllSettings}
              parentName='Integrations'
              tabs={this.tabsAllSettingsPayment()}/>
          </>
        );
      }
    }];
  }
  
  setSiteProp(partial: Partial<Site>): void {
    setComponentStateObjectProp<Site, ModalEditSite>(this, 'site', partial);
  }
  
  private updateGroupList(newGroupList: number[]): void {
    this.setSiteProp({
      accessControlGroups: newGroupList    
    });
  }

  generateTabHeader(title: string, beta: boolean, 
      hasStatus: boolean, status: boolean,
      propKey: keyof ModalEditSiteState): React.ReactNode {
    return (   
      
      <>
        {title}
        <div className="beta-tag" 
          hidden={!beta}
          // hidden={isDemo()}
          >
          {xl8('comingSoon')}
        </div>
        <div className="pull-right">
          {/* {propKey &&
            <FormSwitch
              value={this.state[propKey] as unknown as boolean}
              label={null}
              onChange={(newValue) => {
                let changes: any = {};
                changes[propKey] = newValue;
                this.setState(changes as ModalEditSiteState);
            }}/>
          } */}

          <span className="status-indicator" hidden={!hasStatus}>
            {status ? 'On' : 'Off'}
          </span>
        </div>
      </>
    );
  }

  render(): JSX.Element {
    renderSpam('ModalEditSite');
    return (
      <Modal 
          ref={this.modal}
          onOK={() => this.onOK()}
          width="475px"
          title={this.state.title}
          confirmButtonContent={this.state.okText}
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}>

          {/* <div className="row">
            <div className="col-8">
              <div className="input-group search-filter">
                <input type="search" className="form-control"
                    placeholder="Search integrations" aria-label="Search"
                    // value="value"
                    onChange={(event) => {
                    }}
                    aria-describedby="search-addon" />
                <button className="btn btn-outline"
                    type="button" 
                    onClick={(event) => {

                    }}>
                  <SearchIcon width="20" height="20">

                </button>
              </div>
            </div>
          </div> */}
          <NestedTabContainer
            ref={this.nestedAllSettings}
            parentName='Settings'
            tabs={this.tabsAllSetting()}
            />

      </Modal>
    );
  }

  private onOK(): Promise<ModalEditSiteResult> {
    if (!this.validator.isValid(this.state))
      return null;
    
    let site = this.state.site;

    if (site && +site.id > 0) {
      return patchLocationById(
        site.organizationId, site)
      .then(() => {
        return {
          site: site
        };
      }).catch((err) => {
        toastError(err.message);
        return null;
      });
    }
    
    return postSiteByOrganization(site.organizationId, site)
    .then((site) => {
      return {
        site: site
      };
    }).catch((err) => {
      toastError(err.message);
      return null;
    });
  }
}
