import * as React from 'react';
import { Component, ReactNode } from 'react';
import './Drawer.scss';

export interface DrawerProps {
  title: ReactNode;
  open?: boolean;
  style?: React.CSSProperties;
  openChanged?: (isOpen: boolean) => void;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  children?: ReactNode;
}

interface DrawerState {
  open: boolean;
}

export class Drawer extends Component<DrawerProps, DrawerState> {
  private bodyRef = React.createRef<HTMLDivElement>();

  constructor(props: DrawerProps) {
    super(props);
    this.state = {
      open: props.open || false
    };
  }

  componentDidUpdate(
      prevProps: Readonly<DrawerProps>, 
      prevState: Readonly<DrawerState>, 
      snapshot?: never): void {
    // open is controlled if that prop is boolean
    if (typeof this.props.open === 'boolean' &&
        this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open
      });
    }

    // Tell containing component open changed if they are into that
    if (this.props.openChanged && (prevState.open !== this.state.open))
      this.props.openChanged(this.state.open);
  }

  render(): JSX.Element {
    let drawerClasses = 'drawer';

    if (this.state.open) {
      drawerClasses = 'drawer open';
    }

    return (
      <>
        <div className={drawerClasses} style={this.props.style}>
          {/* <div className="drawer-header">
            <div className="drawer-header-inner">
              {this.props.title}
            </div>
          </div> */}
          <div className="drawer-body" ref={this.bodyRef}
              onScroll={(event) => {
                if (this.props.onScroll)
                  this.props.onScroll(event);
              }}>
            {this.props.children}
          </div>
        </div>
      </>
    );
  }

  public getDrawerClientRect(): DOMRect {
    let rect = this.bodyRef.current.getBoundingClientRect();
    return rect;
  }

  public getScrollTop(): number {
    return this.bodyRef.current.scrollTop;
  }
}