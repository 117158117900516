import React, { Component, ReactNode, RefObject } from "react";
import { DownArrowIcon } from "./icons/DownArrowIcon";
import { LeftArrowIcon } from "./icons/LeftArrowIcon";
import { HorizontalTab, renderTabTitle } from "./modals/HorizontalTabContainer";
import './NestedTabContainer.css';

export interface NestedTab extends HorizontalTab {
  image?: string;
}

export interface NestedTabContainerProps {
  parent?: RefObject<NestedTabContainer>;
  parentName: string;
  tabs: NestedTab[];
  activeTab?: number;
  tabChanged?: (index: number) => void;
}

interface NestedTabContainerState {
  activeTab: number;
  childExpanded: boolean;
}

export class NestedTabContainer 
    extends Component<NestedTabContainerProps, NestedTabContainerState> {
  constructor(props: NestedTabContainerProps) {
    super(props);
    this.state = {
      activeTab: typeof props.activeTab === 'number' ? props.activeTab : -1,
      childExpanded: false
    };
  }

  componentDidUpdate(
      prevProps: Readonly<NestedTabContainerProps>, 
      prevState: Readonly<NestedTabContainerState>, 
      snapshot?: never): void {
    if (prevState.activeTab !== this.state.activeTab) {
      if (this.props.tabChanged) 
        this.props.tabChanged(this.state.activeTab);

      if ((prevState.activeTab >= 0) !== (this.state.activeTab >= 0)) {
        this.props.parent?.current?.notifyChildExpanded(
          this.state.activeTab >= 0);
      }
    }
    
    if (typeof prevProps.activeTab === 'number' &&
        prevProps.activeTab !== this.props.activeTab &&
        this.state.activeTab !== this.props.activeTab) {
      this.setState({
        activeTab: this.props.activeTab
      });
    }
  }

  public isExpanded(): boolean {
    return this.state.activeTab >= 0;
  }

  public notifyChildExpanded(expanded: boolean): void {
    this.setState({
      childExpanded: expanded
    });
  }

  render(): ReactNode {
    let visibleTabs: NestedTab[];
    let expanded = this.state.activeTab >= 0;

    if (expanded) {
      visibleTabs = [
        this.props.tabs[this.state.activeTab]
      ];
    } else {
      // Nothing expanded
      visibleTabs = this.props.tabs;
    }

    // Something expanded
    return visibleTabs.map((tab, index) => {      
      let key = tab?.key || tab?.titleText || ('tab'+index);
      if (!expanded) {
        return (
          <div className="vertical-tab-item"
            key={key}
            onClick={() => {
              this.setState({
                  activeTab: index
                });
              }}>
            { tab.icon
              ? (
                <span className="vertical-tab-header-icon pull-left">
                  {tab.icon} 
                </span>
              ) 
              : <img src={tab.image} alt="" />
            }
            {renderTabTitle(tab)}
            
            <span className="expand-tab">
              <DownArrowIcon width="24" height="24" fill="#c1c5c8"/>
            </span>

          </div>
        );
      } else {
        return (
          <div className="nested-tab-back" key={key}>
            {
              !this.state.childExpanded &&
              <div className="nested-tab-back-btn" onClick={(event) => {
                  this.setState({
                    activeTab: -1
                  });
                }}>
                <span>
                  <LeftArrowIcon width="24" height="24" fill="#C1C5C8" />
                </span>
                  {this.props.parentName}
              </div>
            }
            <div>
              {
                typeof tab.body === 'function' 
                  ? tab.body(tab, tab.visible) 
                  : tab.body
              }
            </div>
          </div>
        );
      }
    });
  }
}
