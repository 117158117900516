import * as React from 'react';
import { Component, PureComponent } from 'react';
import { ResponsiveContainer } from 'recharts';
import { 
  getRealtimePower, Organization, RealtimePowerPortConnectorLookup, 
  RealtimePowerPortConnectorSamplePair, RealtimePowerResponse, SiteDevices
} from '../../api';
import { xl8 } from '../../translations/i18n';
import { toastError } from '../shared/ui';
import { DashboardGauge } from './DashboardGauge';

export interface RealtimePowerWidgetProps {
  organization: Organization;
  siteDevices: SiteDevices | null;
  evseIdToSiteId: Map<number, number> | null;
  filterEvseIds: Set<number> | null;
  refreshIntervalMs: number;
}

interface RealtimePowerWidgetState {
  realtimePowerWatts: number;
  realtimePowerResponse: RealtimePowerResponse;
}

export class RealtimePowerWidget
    extends Component<RealtimePowerWidgetProps, RealtimePowerWidgetState> {
  // offsetX={0}
  // offsetY={0}
  // unitText={'kW'}
  // unitAngle={90}
  // unitRadius={18}
  // unitBaselineAdjust={5}
  // unitFontSize={16}
  // faceRadius={80}
  // faceStartAngle={-225}
  // faceEndAngle={45}
  // faceStartAngle={90}
  // faceEndAngle={100}
  // needleStartRadius={-10}
  // needleEndRadius={87}
  // graduationStartRadius={64}
  // graduationEndRadius={75}
  // numberRadius={53}
  // numberRotates={false}
  // numberFontFamily="Arial"
  // numberFontSize={16}
  // numberAnchor="middle"
  // //numberFontStyle="italic"
  // //numberFontWeight="normal"
  // numberBaselineAdjust={5}
  // numberColor="#B8BCC0"
  // valueMin={0}

  private testTimeout: NodeJS.Timeout | null = null;
  private refreshTimeout: NodeJS.Timeout | null = null;

  private dashboardGaugeRef = React.createRef<DashboardGauge>();

  private static readonly metronome: boolean = false;

  constructor(props: RealtimePowerWidgetProps) {
    super(props);

    this.state = {
      realtimePowerWatts: 0,
      realtimePowerResponse: null
    };
  }

  componentDidMount(): void {
    console.assert(this.refreshTimeout === null);
    if (!RealtimePowerWidget.metronome)
      this.startRefresh();
    else
      this.metronomeHack();
  }

  componentWillUnmount(): void {
    if (this.testTimeout)
      clearTimeout(this.testTimeout);
    this.testTimeout = null;
    
    if (this.refreshTimeout)
      clearTimeout(this.refreshTimeout);
    this.refreshTimeout = null;
  }

  componentDidUpdate(
      prevProps: Readonly<RealtimePowerWidgetProps>,
      prevState: Readonly<RealtimePowerWidgetState>, 
      snapshot?: never): void {
    if (this.props.organization &&
        prevProps.organization?.id !== this.props.organization?.id)
      this.startRefresh(true);
    
    if (prevProps.filterEvseIds !== this.props.filterEvseIds &&
        this.state.realtimePowerResponse) {
      let watts = this.applySiteFilter(this.state.realtimePowerResponse);
      this.setState({
        realtimePowerWatts: watts
      });
    }
  }

  private refreshRealtimePower(force: boolean = false) {
    if (!force && !this.refreshTimeout)
      return;
    
    let organization = this.props.organization;
    
    if (organization) {
      getRealtimePower(organization.id)
      .catch((err) => {
        if (organization?.id === this.props.organization?.id)
          toastError('Error updating realtime power: ' + err.message);
        return null as RealtimePowerResponse;
      }).then((response) => {
        if (organization.id !== this.props.organization?.id)
          return;
        if (!this.refreshTimeout)
          return;
        let watts = this.applySiteFilter(response);
        this.setState({
          realtimePowerResponse: response,
          realtimePowerWatts: watts
        }, () => {
          this.startRefresh();
        });
      });
    }
  }
  
  private applySiteFilter(response: RealtimePowerResponse | null)
      : number | null {
    if (!response)
      return null;
    let evseIds = Object.keys(response.devices);
    let connectorMaps: RealtimePowerPortConnectorLookup[];
    connectorMaps = Object.values(response.devices);
    
    let samplePairs = evseIds.reduce((selectedDevices, evseIdStr, index) => {
      let evseId = +evseIdStr;
      //let siteId = this.props.evseIdToSiteId?.get(evseId);
      if (!this.props.filterEvseIds?.size || 
          this.props.filterEvseIds?.has(evseId)) {
        let connectorMap = connectorMaps[index];
        let pairs = Object.values(connectorMap);
        pairs.forEach((pair) => {
          selectedDevices.push(pair);
        });
      }
      
      return selectedDevices;
    }, [] as RealtimePowerPortConnectorSamplePair[]);

    let totalWatts = samplePairs.reduce((totalWatts, pair) => {
      if (pair.age_sec < 15*60)
        return totalWatts + pair.power;
      return totalWatts;
    }, 0);

    return totalWatts;
  }

  private startRefresh(hurry: boolean = false): void {
    if (hurry)
      return this.refreshRealtimePower(true);
    
    if (this.refreshTimeout)
      clearTimeout(this.refreshTimeout);
    
    this.refreshTimeout = setTimeout(() => {
      this.refreshRealtimePower();
    }, this.props.refreshIntervalMs);
  }

  private metronomeHack(value: number = 8500) {
    this.testTimeout = setTimeout(() => {
      if (this.testTimeout === null)
        return;
      
      console.log('set value', value);
      this.setState({
        realtimePowerWatts: value
      }, () => {
        if (this.testTimeout !== null)
          this.metronomeHack(value + 500);
      });
    }, 2500);
  }

  public checkSize(): void {
    this.dashboardGaugeRef.current?.checkSize();
  }

  render(): JSX.Element {
    return (    
      <>
        <h4 className="widget-header">
          {xl8('totalPower')}
          <span>
          {xl8('realtimePower')}
          </span>
        </h4>
        
        <ResponsiveContainer height={'85%'}>
          <DashboardGauge
            ref={this.dashboardGaugeRef}
            barCornerRadius={30}
            // barCornerRadius={16}
            // offsetX={0}
            scale={0.85}
            // offsetX={100}
            // offsetY={0}
            // unitText={'kW'}
            // unitAngle={90}
            // unitRadius={18}
            // unitBaselineAdjust={5}
            // unitFontSize={16}
            // faceRadius={80}
            // faceStartAngle={-225}
            // faceEndAngle={45}
            // faceStartAngle={90}
            // faceEndAngle={-100}
            // needleStartRadius={-10}
            // needleEndRadius={87}
            // graduationStartRadius={64}
            // graduationEndRadius={75}
            // numberRadius={53}
            // numberRotates={false}
            // numberFontFamily="Arial"
            // numberFontSize={16}
            // numberAnchor="middle"
            // //numberFontStyle="italic"
            // //numberFontWeight="normal"
            // numberBaselineAdjust={5}
            // numberColor="#B8BCC0"
            // valueMin={0}
            valueMax={10}
            value={this.state.realtimePowerWatts / 1000}
            // valueMax={10}
            // value={this.state.testValue}
            // needleStartAngle={-225}
            // needleEndAngle={45}
            // needleWidth={3}
            // needleColor="#eee"
            // bezelWidth={0}
            // bezelColor="#d4d4e4"
            // faceFill="#272932"
            // graduationCount={11}
            // graduationWidth={2}
            // graduationColor="#bbb"
            // currentValueAngle={90}
            // currentValueHidden={false}
            // currentValueTextAlign={'middle'},
            // currentValueFontSize={12}
            // currentValueFontWeight="normal"
            // currentValueFontStyle="normal"
            // currentValueFontFamily="Arial"
            // currentValueBaselineAdjust={0}
            // currentValuePrecision={0}
            // currentValueColor="#89BCC0"
          
            unitText="kW"
            unitConcat={true}

            needleStartRadius={-18}
            needleEndRadius={70}
            needleStartAngle={-225}
            needleEndAngle={45}
            needleWidth={2.23}
            needleColor="#175785"

            bezelWidth={0}
            faceRadius={3}
            faceColor="#175785"
            barInnerRadius={77}
            barOuterRadius={92}
            barColor="#175785"
            barBackgroundColor= '#DCE3EB'
            graduationStartRadius={64}
            graduationEndRadius={75}
            graduationCount={11}
            graduationWidth={1}
            graduationColor="#bbb"

            numberRadius={53}
            numberRotates={false}
            numberFontFamily="GalanoGrotesqueRegular"
            numberFontSize={12}
            numberAnchor="middle"
            //numberFontStyle="italic"
            //numberFontWeight="normal"
            numberBaselineAdjust={2}
            numberColor="#AAA"
            valueMin={0}
            currentValueRadius={74}
            currentValueFontSize={20}/>
        </ResponsiveContainer>
      </> 
    );
  }
}
