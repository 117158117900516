import * as React from 'react';
import {
  getPermissionOverrides,
  getPermissionRoles,
  getPermissionTree,
  PermissionOverride, PermissionRole, 
  PermissionTreeNode, PermissionUser
} from '../../api';
import { renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';
export interface ModalEditPermissionsProps {
}

interface ModalEditPermissionsResult {
  name: string;
}

type OverrideMap = { [permissionId: number]: PermissionOverride };

interface ModalEditPermissionsState {
  permissions: PermissionTreeNode[];
  roles: PermissionRole[];
  overrides: OverrideMap;
}

interface PermissionRow {
  node: PermissionTreeNode;
  rowInherit: HTMLInputElement;
  rowReadable: HTMLInputElement;
  rowWritable: HTMLInputElement;
}

export class ModalEditPermissions
  extends ModalBase<
  ModalEditPermissionsProps,
  ModalEditPermissionsResult,
  ModalEditPermissionsState
  > {
  private subtitle: HTMLDivElement | null = null;

  user: PermissionUser;

  static newPermissions(userId: number): Promise<void> {
    return null;
  }

  constructor(props: ModalEditPermissionsProps) {
    super(props);

    this.state = {
      permissions: [],
      roles: [],
      overrides: []
    };
  }

  componentDidMount(): void {
    let nodes = getPermissionTree();
    let roles = getPermissionRoles();
    let overrides = getPermissionOverrides();
    
    Promise.all([
      nodes,
      roles,
      overrides
    ]).then((results) => {
      let nodes = results[0];
      let roles = results[1];
      let overrides = results[2];

      this.setState({
        permissions: nodes,
        roles: roles,
        overrides: overrides
      });
    });
  }    

  private overrideFromPermissionId(
      permissionId: number): PermissionOverride {
    return this.state.overrides[permissionId] || null;
  }

  private permissionTreeNodeFromPermissionId(
      permissionId: number): PermissionTreeNode {
    return this.state.permissions.find((permission) => {
      return permission.permission.id === permissionId;
    });
  }

  public showDialog(user: PermissionUser)
      : Promise<ModalEditPermissionsResult> {
    this.user = user;
    return setStatePromise<ModalEditPermissionsState, 
      ModalEditPermissions>(this, {})
    .then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalEditPermission');
    return (
      <Modal
          ref={this.modal}
          onOK={() => this.onOK()}
          title="Edit Permissions"
          confirmButtonContent="Update Permissions">
        <label> Role:</label> 
        <select className="form-select" 
          onChange={(event) => {
            this.roleChanged(event);
          }}>
          {this.state.roles.map((role) => {
            return (
              <option value={role.id} key={role.id}>{role.name}</option>
            );
          })}
        </select>
        <div className="permissions-table">
          <div className="row">
            <div className="col-lg-4">
              Resource
                </div>
            <div className="col-lg-2">
              Inherit
                </div>
            <div className="col-lg-2">
              Read
                </div>
            <div className="col-lg-2">
              Write
            </div>
          </div>
          <div>
            {this.state.permissions.map((node) => {
              let data: PermissionRow = {
                node: node,
                rowInherit: null as HTMLInputElement,
                rowReadable: null as HTMLInputElement,
                rowWritable: null as HTMLInputElement
              };
              
              return (
                <div className="row" key={node.permission.id}>
                  <div className="col-lg-4">
                    <div style={{ paddingLeft: node.indent * 8 }}>
                      <span>{node.permission.name}</span>
                    </div>
                  </div>
                  <div className="col-lg-2">
                  <input type="checkbox"
                      ref={(el) => data.rowInherit = el}
                      onChange={(event) => {
                        this.onPermChange('Inherit', event, data);
                      }}
                      defaultChecked={this.permInherited(node)} />
                  </div>
                  <div className="col-lg-2">
                    {
                      node.readable
                      ? <input type="checkbox"
                        ref={(el) => data.rowReadable = el}
                        disabled={this.permInherited(node)}
                        onChange={(event) => this.onPermChange('Read', event, data)}
                        defaultChecked={this.permReadable(node)}
                        />
                      : <></>
                    }
                  </div>
                  <div className="col-lg-2">
                    {
                      node.writable
                        ? <input type="checkbox"
                        ref={(el) => data.rowWritable = el}
                        onChange={(event) => this.onPermChange('Write', event, data)}
                        defaultChecked={this.permWritable(node)}
                        disabled={true} />
                        : <></>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }

  private permInherited(node: PermissionTreeNode): boolean {
    let existing = this.state.overrides[node.permission.id];

    return !existing;
  }
  
  private permReadable(node: PermissionTreeNode): boolean {
    let override = this.overrideFromPermissionId(node.permission.id);
    return (override && override.canRead) || false;
  }
  
  private permWritable(node: PermissionTreeNode): boolean {
    let override = this.overrideFromPermissionId(node.permission.id);
    return (override && override.canWrite) || false;
  }

  private roleChanged(event: React.ChangeEvent<HTMLSelectElement>): void {

  }

  private onPermChange(field: string,
      event: React.ChangeEvent<HTMLInputElement>, row: PermissionRow): void {
    let checked: boolean = event.target.checked;

    switch (field) {
    case 'Inherit':
      if (checked) {
        delete this.state.overrides[row.node.permission.id];
      }
      break;

    case 'Read':
      // if (checked) {
      //   if (!existing)
      //     this.state.overrides[row.node.permission.id] = {
      //       row
      //     };
        
      // }
      break;
      
    case 'Write':
      // if (checked) {

      // }
      break;
      
    }

  }

  private setPermissionReadable(permissionId: number, allow: boolean) {

  }

  private setPermissionWritable(permissionId: number, allow: boolean) {
    
  }

  private setPermissionInherited(permissionId: number, allow: boolean) {
    
  }

  private onOK(): Promise<ModalEditPermissionsResult> {
    return Promise.resolve({
      name: this.user.username
    });
  }
}