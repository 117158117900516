import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface CloseIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export class CloseIcon extends PureComponent<CloseIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" 
        onClick={this.props.onClick}
        width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill}>
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
      </svg>
    );
  }
}