import React, { Component, ReactNode } from "react";

import './SwychedPopover.css';

export type SwychedPopoverPlacementNames = 'bottom';
export type SwychedPopoverTriggerNames = 'click';

export interface SwychedPopoverProps {
  content: ReactNode | JSX.Element;
  placement?: SwychedPopoverPlacementNames;
  overlayStyle?: React.CSSProperties;
  trigger?: SwychedPopoverTriggerNames;
  visibleKey: any;
  dismissTarget?: Component;
  dismissKey?: string;
  dismissCtor?: () => any;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, 
      key: string | number) => void;
  visibleMap: Set<any> | Set<any>;
  children: ReactNode | ReactNode[];
}

interface SwychedPopoverState {
}

export class SwychedPopover 
    extends Component<SwychedPopoverProps, SwychedPopoverState> {
  public static makeUpdater<T extends Component<P, S, SS>, P, S, SS>(
    component: T, stateKey: keyof T['state'])
    : (event: React.MouseEvent<HTMLDivElement, MouseEvent>, 
        key: string | number) => void {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>, 
        key: string | number) => {
      console.log('Running SwychedPopover updater');
      let updatedSet = component.state[stateKey] as unknown as Set<string>;

      // If they are holding ctrl or shift, preserve the opened items
      if (!event.ctrlKey && !event.shiftKey)
        updatedSet.clear();
      
      let exists = updatedSet.has(key as string);
      if (exists)
        updatedSet.delete(key as string);
      else
        updatedSet.add(key as string);
      component.setState({
        [stateKey]: updatedSet
      } as unknown as S);

      event.stopPropagation();
    };
  }

  public static makeDismissPopoversOnClickRawEvent<
    K, T extends Component<P, S, SS>, P, S, SS>(
    component: T, stateKey: keyof T['state'], ctor: () => K, 
    bubble: boolean = false)
    : ((event: Event) => void) {
    return SwychedPopover.makeDismissPopoversOnClick(
      component, stateKey, ctor, bubble) as never as ((event: Event) => void);
  }

  public static makeDismissPopoversOnClick<
    K, T extends Component<P, S, SS>, P, S, SS>(
    component: T, stateKey: keyof T['state'], ctor: () => K, 
    bubble: boolean = false)
    : ((event: React.MouseEvent<Element>) => void) {
    return (event) => {
      if (!bubble && event.target !== event.currentTarget || 
          !(component.state[stateKey] as unknown as Set<K>).size)
        return;
      component.setState({
        [stateKey]: ctor()
      } as unknown as T['state']);
    };
  }

  private clickHandler: ((event: Event) => void) |
    null = null;

  componentDidMount(): void {
    if (!this.props.dismissTarget)
      return;
    this.clickHandler = SwychedPopover.makeDismissPopoversOnClickRawEvent(
      this.props.dismissTarget, this.props.dismissKey as never,
      this.props.dismissCtor, true);
    window.addEventListener('click', this.clickHandler);
  }

  componentWillUnmount(): void {    
    if (this.clickHandler) {
      window.removeEventListener('click', this.clickHandler);
      this.clickHandler = null;
    }
  }

  render() {
    return (
      <div className="swyched-popover-hotspot" 
        onClick={(event) => { 
          this.props?.onClick(event, this.props.visibleKey);
        }}>
        {this.props.children}
        <div className="swyched-popover-placeholder">
          <div className="swyched-popover" 
              hidden={!this.props.visibleMap?.has(this.props.visibleKey)} 
              style={this.props.overlayStyle}>
            {this.props.content}
          </div>
        </div>
      </div> 
    );
  }
}