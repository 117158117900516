import React, { KeyboardEvent, PureComponent, RefObject } from "react";
import { ReactNode } from "react";
import { DownArrowIcon } from "./icons/DownArrowIcon";
import "./select.component.scss";

import {
  preventAndStop, 
  renderSpam, setStatePromise
} from "./shared/ui";

// export interface HostInBodyProps {
//   children: ReactNode;
// }

// interface HostInBodyState {

// }

// export class HostInBody 
//     extends PureComponent<HostInBodyProps, HostInBodyState> {
//   private portalHost: HTMLDivElement;
//   private ownElement = React.createRef<HTMLDivElement>();
//   private childContainerRef = React.createRef<HTMLDivElement>();

//   constructor(props: HostInBodyProps) {
//     super(props);
//     this.state = {

//     };
//   }

//   componentDidMount(): void {
//     console.log('creating HostInBody host', this.ownElement.current);
//     this.portalHost = document.createElement('div');
//     document.getElementById('root').appendChild(this.portalHost);
//   }

//   componentWillUnmount(): void {
//     console.log('destroying HostInBody host', this.ownElement.current);
//     document.getElementById('root').removeChild(this.portalHost);
//     this.portalHost = null;
//   }

//   render(): ReactNode {
//     if (!this.portalHost)
//       return null;

//     let content = (
//       <div ref={this.ownElement} className="host-in-body-host">
//         <div tabIndex={0}
//           onFocus={(event) => {
//             focusAdjacentElement(1, this.childContainerRef.current);
//           }}/>
//         <div ref={this.childContainerRef} style={{position: 'relative'}}>
//           {this.props.children}
//         </div>
//         <div tabIndex={0}
//           onFocus={(event) => {
//             focusAdjacentElement(1, this.ownElement.current);
//           }}/>
//       </div>
//     );

//     return ReactDOM.createPortal(content, this.portalHost);
//   }
// }

export interface SwychedOptionProps {
  children: ReactNode | ReactNode[];
  value: string;
}

interface SwychedOptionState {
  value: string;
}

export class SwychedOption
    extends PureComponent<SwychedOptionProps, SwychedOptionState> {
  constructor(props: SwychedOptionProps) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  render(): ReactNode {
    renderSpam('SwychedOption');
    return (
      <div className="swyched-option">
        {this.props.children}
      </div>
    );
  }
}

export enum SwychedSelectMode {
  CUSTOM,
  OPTIONS
}

export interface SwychedSelectProps {
  value?: string;
  mode?: SwychedSelectMode
  children?: ReactNode;
  label?: ReactNode;
  onChange?: (value) => void;
}

const defaultMode = SwychedSelectMode.OPTIONS;

interface SwychedSelectState {
  mode: SwychedSelectMode
  children: ReactNode[];
  value: string;
  open: boolean;
  lockOpen: boolean;
  selectedIndex: number;
}

export class SwychedSelect
    extends PureComponent<SwychedSelectProps, SwychedSelectState> {
  private childRefs: RefObject<HTMLDivElement>[] = [];
  //private childRefs: { current: HTMLDivElement }[] = [];
  private childValues: string[] = [];
  
  //private dropdownRef = React.createRef<HTMLDivElement>();
  private dropdownRef = { current: null as HTMLDivElement };
  
  private rootRef = React.createRef<HTMLDivElement>();
  private selectorRef = React.createRef<HTMLDivElement>();
  //private dropdownRoot: HTMLDivElement;
  private scrollHandler: (event: Event) => void;

  constructor(props: SwychedSelectProps) {
    super(props);
    this.state = {
      mode: SwychedSelectMode.OPTIONS,
      children: React.Children.toArray(props.children),
      value: props.value,
      open: false,
      lockOpen: false,
      selectedIndex: 0
    };
  }

  public close(): void {
    this.setState({
      open: false
    });
  }

  // componentDidMount(): void {
  //   this.scrollHandler = (event: Event) => {
  //     if (this.state.open)
  //       this.updateDropdownPosition();
  //   };
  //   window.addEventListener('scroll', this.scrollHandler, true);
  //   window.addEventListener('resize', this.scrollHandler, true);
  // }

  // componentWillUnmount(): void {
  //   // window.removeEventListener('scroll', this.scrollHandler, true);
  //   // window.removeEventListener('resize', this.scrollHandler, true);
  //   this.destroyDropdownElement();
  //   //this.unhookFocusBlur();
  // }

  // destroyDropdownElement() {
  //   // if (this.dropdownRoot) {
  //   //   this.dropdownRoot.parentNode.removeChild(this.dropdownRoot);
  //   //   this.dropdownRoot = null;
  //   // }
  // }

  componentDidUpdate(
      prevProps: SwychedSelectProps, 
      prevState: SwychedSelectState): void {
    if (this.state.children !== prevState.children) {
      this.childValues = this.state.children.map((child) => {
        if (React.isValidElement(child))
          return child.props.value;
        return null;
      });
    }
    if (this.childValues[this.state.selectedIndex] !== this.state.value) {
      let newSelectedIndex = this.childValues.indexOf(this.state.value);
      if (this.state.selectedIndex !== newSelectedIndex) {
        this.setState({
          selectedIndex: newSelectedIndex
        });
      }
    }

    // if (prevState.open !== this.state.open) {
    //   if (this.state.open)
    //     this.updateDropdownPosition();
    // }
  }

  // updateDropdownPosition() {
  //   // Return if unable
  //   // if (!this.dropdownRef.current || !this.rootRef.current)
  //   //   return;
    
  //   //let rootRect = this.rootRef.current.getBoundingClientRect();
  //   //let dropdownRect = this.dropdownRef.current.getBoundingClientRect();

  //   // Object.assign(this.dropdownRef.current.style, {
  //   // });
  // }

  static getDerivedStateFromProps(
      props: SwychedSelectProps,
      state: SwychedSelectState): Partial<SwychedSelectState> {
    let result: Partial<SwychedSelectState> = {};

    let newMode = props.mode !== undefined ? props.mode : defaultMode;

    let newChildren: ReactNode[] = 
      React.Children.toArray(props.children || []);

    if (state.mode !== newMode)
      result.mode = props.mode;
    
    if (state.children !== newChildren)
      result.children = newChildren;
      
    if (props.value !== undefined &&
        state.value !== props.value) {
      result.value = props.value;
    }
    
    if (!Object.keys(result).length)
      return null;

    return result;
  }

  render(): ReactNode {
    renderSpam('SwychedSelect');
    return (
      <>
        <label className="control-label">
          {this.props.label}
        </label>

        <div className={'swyched-select'}
            ref={this.rootRef}
            onFocusCapture={(event) => this.onFocus(event)}
            onBlurCapture={(event) => this.onBlur(event)}>
          <div className="swyched-select-selector"
              ref={this.selectorRef}
              tabIndex={0}
              onKeyDown={(event) => this.onKeyDown(event)}
              onClick={(event) => this.onClick(event)}>
            <div className="swyched-select-value-display">
              {this.renderSelectedValue()}
            </div>
            <div className="swyched-select-selector-button">
              <span>
                <DownArrowIcon width="24" height="24" fill="#aaa"/>
              </span>
            </div>
          </div>
          {this.createDropdownElement()}
        </div>
      </>
    );
  }

  createDropdownElement(): ReactNode {
    // if (!this.dropdownRoot) {
    //   this.dropdownRoot = document.createElement('div');
    //   Object.assign(this.dropdownRoot.style, {
    //     position: 'fixed',
    //     left: 0,
    //     top: 0,
    //     overflow: 'visible'
    //   });
    //   document.body.appendChild(this.dropdownRoot);
    // }

    if (!this.state.open && !this.state.lockOpen)
      return null;

    let justStopPropagation = (event) => {
      event.stopPropagation();
    };

    return /*ReactDOM.createPortal*/(
      <div 
          className="swyched-select-dropdown" 
          //hidden={!this.state.open && !this.state.lockOpen}
          ref={(el) => {
            this.dropdownRef.current = el;

            if (el && this.rootRef.current && this.state.open)
              this.scrollDropdownIntoView();

            // el?.addEventListener('click', (event) => {
            //   console.log('click hook');
            // });
          }}
          onClick={justStopPropagation}
          onMouseDown={justStopPropagation}          
          onMouseMove={justStopPropagation}          
          onMouseUp={justStopPropagation}
          onPointerDown={justStopPropagation}
          onPointerMove={justStopPropagation}
          onPointerUp={justStopPropagation}
          onTouchStart={justStopPropagation}
          onTouchEnd={justStopPropagation}
          onTouchMove={justStopPropagation}
          onKeyDown={(event) => {
            if (event.code === 'Escape') {
              event.stopPropagation();
              event.preventDefault();
            }
          }}>
        {this.renderDropdown()}
      </div>
      //,
      //this.dropdownRoot
    );
  }

  renderDropdown(): ReactNode {
    this.childRefs.splice(this.childRefs.length);

    let effectiveChildren = this.state.mode === SwychedSelectMode.OPTIONS
      ? this.state.children
      : [this.state.children[1]];

    let childComponents = React.Children.map(effectiveChildren, 
    (child, index) => {
      if (!React.isValidElement(child))
        return child;
      
      // Lazily create ref
      if (!this.childRefs[index])
        this.childRefs[index] = React.createRef<HTMLDivElement>();
      
      return (
        <div className={
            (this.state.mode === SwychedSelectMode.OPTIONS
            ? 'swyched-option-container'
            : '') +
            ' ' + 
            (index === this.state.selectedIndex
            ? 'selected'
            : '')}
            key={this.childValues[index] ?? index}
            tabIndex={-1}
            ref={this.childRefs[index]}
            onBlurCapture={(event) => this.onBlur(event)}
            onClick={(event) => {
              event.stopPropagation();
              this.onItemClickAt(index, true);
            }}>
          {child}
        </div>
      );
    });
    
    return <>{childComponents}</>;
  }

  onFocus(event: React.FocusEvent<HTMLDivElement>): void {
    if (!this.rootRef.current?.contains(event.target) &&
        !this.dropdownRef.current?.contains(event.target)) {
      this.setOpen(false);
      return;
    }

    // console.log('select focus target', 
    //   event.target, 'related', event.relatedTarget,
    //   'active', document.activeElement);
  }

  onBlur(event: React.FocusEvent<HTMLDivElement>): void {
    // console.log('select blur target', event.target, 
    //   'related', event.relatedTarget,
    //   'active', document.activeElement);
    
    if (!event.relatedTarget) {
      if (this.state.open) {
        console.log('closing because null relatedTarget');
        this.setOpen(false);
      }
      return;
    }
     
    if (!this.rootRef.current?.contains(
          event.relatedTarget as HTMLElement) &&
        !this.dropdownRef.current?.contains(
          event.relatedTarget as HTMLElement)) {
      console.log('closing because relatedTarget is not a descendent');
      this.setOpen(false);
    }

    // If there is a blur with target=selector 
    // and not rootref contains activeelement
    // then close the dropdown
    // if (this.selectorRef.current?.contains(event.target) &&
    //     !this.rootRef.current?.contains(event.target)) {
    //   this.setOpen(false);
    //   return;
    // }

  }

  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    this.toggleOpen();
  }

  private setOpen(open: boolean): Promise<void> {
    return setStatePromise<SwychedSelectState, SwychedSelect>(this, {
      open: open
    });
  }

  private toggleOpen() {
    this.setState((prevState, props) => {
      return {
        open: !prevState.open
      };
    });
  }

  onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    console.log(event.code);
    switch (event.code) {
      case 'KeyL':
        if (event.altKey) {
          console.log('locked open');
          this.setState({
            lockOpen: event.ctrlKey
          });
        }
        break;

      case 'Escape':
        if (this.state.open)
          this.setOpen(false);
        else
          this.selectorRef.current?.blur();
        break;
          
      case 'Space':
      case 'Enter':
      case 'NumpadEnter':
        this.toggleOpen();
        break;

      case 'ArrowDown':
        if (event.altKey || this.state.mode === SwychedSelectMode.CUSTOM) {
          this.toggleOpen();
        } else {
          this.adjustSelectedIndex(event, 1);
        }
        break;

      case 'ArrowUp':
        // aria spec, alt up should close the dropdown
        if (event.altKey)
          this.setOpen(false);
        else
          this.adjustSelectedIndex(event, -1);
        break;

      case 'Home':
        this.adjustSelectedIndex(event, 0, true);
        break;

      case 'End':
        this.adjustSelectedIndex(event, this.childValues.length - 1, true);
        break;

      case 'PageUp':
        this.pageScroll(event, -1);
        break;
        
      case 'PageDown':
        this.pageScroll(event, 1);
        break;
      
      default:
        return;
    }

    event.preventDefault();
    event.stopPropagation();
  }

  private pageScroll(event: React.KeyboardEvent<HTMLElement>, 
    direction: number) {
    if (!this.dropdownRef.current)
      return;
    
    let selectedRect = this.childRefs[
      this.state.selectedIndex]?.current?.getBoundingClientRect();
    
    if (!selectedRect)
      return;

    let dropdownRect = this.dropdownRef.current.getBoundingClientRect();

    let selectedCenter = (selectedRect.bottom - selectedRect.top) / 2 +
      selectedRect.top;

    let newSelectionIndex = this.optionIndexFromClientY(
      selectedCenter + dropdownRect.height * direction);

    if (newSelectionIndex !== this.state.selectedIndex) {
      preventAndStop(event);

      this.dropdownRef.current.scrollTop += dropdownRect.height * direction;

      this.adjustSelectedIndex(event, newSelectionIndex, true);
    }
  }

  optionIndexFromClientY(clientY: number): number {
    let validRefs = this.childRefs.filter((ref) => !!ref.current);

    // Binary search for item
    let st = 0;
    let en = validRefs.length;

    // Initial value is definitely not in validRefs
    let mid: RefObject<HTMLDivElement> = null;

    while (st < en) {
      let md = st + ((en - st) >> 1);
      mid = validRefs[md];
      let rect = mid.current.getBoundingClientRect();

      if (rect.bottom <= clientY) {
        en = md;
      } else if (rect.top > clientY) {
        st = md + 1;
      } else {
        break;
      }
    }
    
    // Find index of found item in unfiltered list, return -1 if not found.
    return this.childRefs.findIndex((ref) => ref === mid);
  }

  private scrollDropdownIntoView() {
    this.dropdownRef.current.scrollIntoView();
  }

  private scrollSelectedIntoView() {
    let selectedChild = this.childRefs[this.state.selectedIndex];
    selectedChild?.current?.scrollIntoView();
  }

  adjustSelectedIndex(event: KeyboardEvent<HTMLElement>, 
      amount: number, absolute: boolean = false): Promise<void> {
    let newIndex = absolute
      ? amount
      : this.state.selectedIndex + amount;

    if (newIndex >= this.childValues.length) {
      console.log('clamping to last');
      newIndex = this.childValues.length - 1;
    }
    
    if (newIndex < 0) {
      console.log('clamping to first');
      newIndex = 0;
    }
    
    if (newIndex === this.state.selectedIndex) {
      console.log('early out because adjusted selected index did not change');
      return Promise.resolve();
    }

    let newValue = this.childValues[newIndex];

    if (this.props.onChange)
      this.props.onChange(newValue);
    
    return Promise.resolve();
  }

  private onItemClickAt(index: number, closeDropdown: boolean): Promise<void> {
    if (this.state.mode === SwychedSelectMode.CUSTOM)
      return Promise.resolve();
    
    console.log('got option click');
    if (this.props.onChange)
      this.props.onChange(this.childValues[index]);
    
    this.childRefs[index].current?.focus();

    if (closeDropdown) {
      return this.setOpen(false).then(() => {
        this.selectorRef.current?.focus();
      });
    }

    return Promise.resolve();
  }

  renderSelectedValue(): ReactNode {
    let effectiveIndex = this.state.mode === SwychedSelectMode.OPTIONS
      ? this.state.selectedIndex
      : 0;
    let ChildOption = React.forwardRef((props, ref) => {
      let selectedChild = this.state.children[effectiveIndex];
      if (!React.isValidElement(selectedChild))
        return null;
      return React.cloneElement(selectedChild, {
        ...props
      });
    });

    return (
      <div className="swyched-option-container">
        <ChildOption/>
      </div>
    );
  }
}
