import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface DownArrowIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class DownArrowIcon extends PureComponent<DownArrowIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width}  height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.35984 8.2318C6.78412 7.87824 7.41468 7.93556 7.76825 8.35984L12 13.438L16.2318 8.35984C16.5854 7.93556 17.2159 7.87824 17.6402 8.2318C18.0645 8.58537 18.1218 9.21593 17.7682 9.64021L12.7682 15.6402C12.5783 15.8682 12.2968 16 12 16C11.7032 16 11.4218 15.8682 11.2318 15.6402L6.2318 9.64021C5.87824 9.21593 5.93556 8.58537 6.35984 8.2318Z" fill={this.props.fill}/>
      </svg>
    );
  }
}