
export class ConfigService {
  private static instance: ConfigService = null;
  
  public static getInstance(): ConfigService {
    if (!ConfigService.instance)
    ConfigService.instance = new ConfigService();
    return ConfigService.instance;
  }

  // For tests, mostly
  public static dropInstance(): void {
    ConfigService.instance = null;
  }

  public get awsIdentityPoolGuid(): string {
    return 'a51b3cad-cb18-464b-b1fa-3f7d323d94af';
  }

  public get awsIdentityPoolId(): string {
    return this.awsRegion + ':' + this.awsIdentityPoolGuid;
  }
  
  public get awsPoolId(): string {
    return 'HURyOwgZE';
  }

  public get awsUserPoolId(): string {
    return this.awsRegion + '_' + this.awsPoolId;
  }

  public get awsClientId(): string {
    return '78ldg85l630aigr9vm6m1r8205';
  }

  public get awsRegion(): string {
    return 'ca-central-1';
  }

  // public get awsTenantId(): string {
  //   return 'A42e022aU';
  // }

  public get awsAppId(): string {
    return '1xev67zyxi';
  }

  public get devSigninUsername(): string {
    return 'hi@marshallwhite.ca';
  }

  public get devSigninPassword(): string {
    return 'Newpassword1!';
  }
}
