import * as React from 'react';
import { Organization, postOrganization, updateOrganization } from '../../api';
import { setStatePromise, toastError, toastSuccess } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

import Avatar from 'react-avatar-edit';
import { 
  CountryDropdown, 
  RegionDropdown} from 'react-country-region-selector';

export interface ModalEditOrganizationProps {
}

export interface ModalEditOrganizationResult {
  organization: Organization;
}

interface ModalEditOrganizationState {
  organization: Organization,
  // orgName: string,
  preview: string,
  defaultPreview: string,
  avatarSrc,
  // parentOrganizationId: number,
  // contactName: string,
  // contactEmail: string,
  // contactPhone: string,
  // billingStreet: string,
  // billingCity: string,
  // billingRegion: string,
  // billingCountry: string,
  // billingPostalZip: string,
  // billingPlan: string,
  // billingFrequency: string,
  // billingCurrency: string,
  title: string;
  okText: string;
}

export class ModalEditOrganization
    extends ModalBase<ModalEditOrganizationProps, 
    ModalEditOrganizationResult,
    ModalEditOrganizationState> {
  private subtitle: HTMLDivElement | null = null;

  constructor(props: ModalEditOrganizationProps) {
    super(props);
    
    const src = '/images/swyched-brandmark2021.jpeg';
    // const src = '/images/vancity.jpg'

    this.state = {
      organization: null,
      // orgName: '',
      preview: null,
      defaultPreview: null,
      avatarSrc: src,
      // parentOrganizationId: 0,
      // contactName: '',
      // contactEmail: '',
      // contactPhone: '',
      // billingStreet: '',
      // billingCity: '',
      // billingRegion: '',
      // billingCountry: '',
      // billingPostalZip: '',
      // billingPlan: '',
      // billingFrequency: '',
      // billingCurrency: '',
      title: 'Edit Organization',
      okText: 'Confirm',
    };

    this.onCrop = this.onCrop.bind(this);
    this.onCropDefault = this.onCropDefault.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseDefault = this.onCloseDefault.bind(this);
    this.onLoadNewImage = this.onLoadNewImage.bind(this);
  }

  public showDialog(record: Organization | null)
      : Promise<ModalEditOrganizationResult> {
    return setStatePromise<ModalEditOrganizationState, 
    ModalEditOrganization>(this, {
      organization: record
    }).then(() => {
      return this.show();
    });
  }

  private setOrganizationProp(partial: Partial<Organization>,
      unconditional: boolean = false) {
    this.setState((prevState) => {
      let changed = Object.keys(partial).some((key) => {
        return this.state.organization[key] !== partial[key];
      });
      if (changed || unconditional)
        return Object.assign({}, prevState, partial);
      return null;
    });
  }

  private selectCountry (val) {
    this.setOrganizationProp({ billingCountry: val });
  }

  private selectCity (val) {
    this.setOrganizationProp({ billingCity: val });
  }

  private selectPostal (val) {
    this.setOrganizationProp({ billingPostalZip: val });
  }

  private selectRegion (val) {
    this.setOrganizationProp({ billingRegion: val });
  }


  private onCropDefault(preview) {
    this.setState({
      defaultPreview: preview
    });
  }

  private onCrop(preview) {
    this.setState({
      preview
    });
  }

  private onCloseDefault() {
    this.setState({
      defaultPreview: null
    });
  }

  private onClose() {
    this.setState({
      preview: null
    });
  }

  private onLoadNewImage() {
    const src = '/images/vancity.jpg';
    this.setState({
      avatarSrc: src
    });
  }

  render(): JSX.Element {
    return (
      <Modal 
          ref={this.modal}
          title={this.state.title}
          confirmButtonContent={this.state.okText}
          onOK={() => this.onOK()}
          >
        <div className="organization-modal-container">
          <div>
            <label>
              Organization Name
              {this.organizationInput('name')}
            </label>
          </div>
          <div className="row avatar-editor-container">
            <div className="col-lg-6 col-md-6">
              <span className="avatar-preview-text">
                Avatar Editor
              </span>
              <Avatar
                width={150}
                height={120}
                onCrop={this.onCrop}
                onClose={this.onClose}
                //onBeforeFileLoad={this.onBeforeFileLoad}
                src={this.state.avatarSrc}
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <span className="avatar-preview-text">Avatar preview</span>
              <img alt="" style={{width: '80px', height: '80px'}}
                className="avatar-preview" src={this.state.preview}/>
            </div>
          </div>
          <div>
            <label className="clear">
              Nest Organization Under
              <select className="form-select" 
                placeholder="Select parent organization">
                <option value="" disabled selected>
                  Select parent organization
                </option>
              </select>
            </label>
          </div>
          <h4>Contact Person</h4>
          <div className="row">
            <div className="col-md-6">
              <div>
                <label>
                  First Name
                  {this.organizationInput('contactFirstName')}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label>
                  Last Name
                  {this.organizationInput('contactLastName')}
                </label>
              </div>
            </div>
          </div>
          <div>
            <label>
              Email
              {this.organizationInput('contactEmail')}
            </label>
          </div>
          <div>
            <label>
              Phone Number
              {this.organizationInput('contactPhone')}
            </label>
          </div>
          <h4>Billing Contact</h4>
          <div>
            <label>
              Billing Address
              {this.organizationInput('billingStreet', {
                placeholder: "Street"
              })}
            </label>
          </div>
          <div>
            <label>
              City
              {this.organizationInput('billingStreet', {
                placeholder: "Street"
              })}
            </label>
          </div>
          <div>
            <label>
              Postal / Zip
              {this.organizationInput('billingPostalZip', {
                placeholder: "Street"
              })}
            </label>
          </div>
          {/* <div className="row">
            <div className="col-md-8 col-lg-8">
              <CountryDropdown
                classes={'form-select'}
                value={this.state.organization.billingCountry}
                onChange={(val) => this.selectCountry(val)} />
            </div>
            <div className="col-md-4 col-lg-4">
              <RegionDropdown
                classes={'form-select'}
                country={this.state.organization?.billingCountry}
                value={this.state.organization?.billingRegion}
                onChange={(val) => this.selectRegion(val)} />
            </div>
          </div> */}
          <div className="row m-t-20">
            <div className="col-md-8 col-lg-8">
              {this.organizationInput('billingCity')}
            </div>
            <div className="col-md-4 col-lg-4">
              {this.organizationInput('billingPostalZip')}
            </div>
          </div>
          <h4>Billing Settings</h4>
          <div>
            <label>
              Billing Plan
              <select className="form-select" 
                placeholder="Select billing plan">
                <option value="" disabled selected>
                  Select billing plan
                </option>
              </select>
            </label>
          </div>
          <div>
            <label>
              Billing Frequency
              <select className="form-select" 
                placeholder="Select billing frequency">
                <option value="" disabled selected>
                  Select billing frequency
                </option>
              </select>
            </label>
          </div>
          <div>
            <label>
              Preferred Currency
              <select className="form-select" 
                placeholder="Select Preferred Currency">
                <option value="" disabled selected>
                  Select Preferred Currency
                </option>
              </select>
            </label>
          </div>
        </div>
      </Modal>
    );
  }

  private organizationInput(prop: keyof Organization, attr?: any) {
    return (
      <input className="form-control" {...attr}
        value={this.state.organization && '' + this.state.organization[prop]}
        onChange={(event) => {
          let upd: any = {};
          upd[prop] = event.target.value;
          this.setOrganizationProp(upd);
        }}/>
    );
  }  

  private onOK(): Promise<ModalEditOrganizationResult> {
    let txn: Promise<Organization>;

    if (!this.state.organization.id) {
      txn = postOrganization(this.state.organization.id, 
        this.state.organization);
    } else {
      txn = updateOrganization(this.state.organization);
    }

    return txn.then((organization) => {
      toastSuccess('Organization updated');
      return {
        organization: organization
      };
    }).catch((err) => {
      toastError('Org update failed: ' + err.message);
      return null;
    });
  }
}
