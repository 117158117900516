import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface PlusIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class PlusIcon extends PureComponent<PlusIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0002 13.0002H13.0002V19.0002H11.0002V13.0002H5.00024V11.0002H11.0002V5.00015H13.0002V11.0002H19.0002V13.0002Z" fill={this.props.fill || '#C1C5C8'}/>
      </svg> 
    );
  }
}