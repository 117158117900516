import React from 'react';
import { PureComponent } from 'react';
import './Drawer.scss';

export default class DrawerBackdrop extends PureComponent <any, any> {
  render(): JSX.Element {
    return(
      <div
        className="drawer-backdrop"
        onClick={this.props.close}
      />
    );
  }
}