import * as React from 'react';
import { AccessControlGroup, upsertAccessControlGroup } from '../../api';
import { xl8 } from '../../translations/i18n';
import { CheckIcon } from '../icons/CheckIcon';
import { BroadcastService } from '../services/broadcast.service';
import { renderSpam, setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

export interface ModalEditAccessControlGroupProps {
}

export interface ModalEditAccessControlGroupResult {
  group: AccessControlGroup;
}

interface ModalEditAccessControlGroupState {
  //accessControlGroup: AccessControlGroup;
}

export class ModalEditAccessControlGroup
    extends ModalBase<ModalEditAccessControlGroupProps,
      ModalEditAccessControlGroupResult, ModalEditAccessControlGroupState> {
  private title: string = 'Edit Access Control Group';
  private okText: string = 'Confirm';
  private originalGroup: AccessControlGroup = null;
  private group: AccessControlGroup = null;
  private generationCounter: number = 0;

  static newAccessControlGroup(organizationId: number): AccessControlGroup {
    return {
      id: 0,
      organizationId: organizationId,
      name: ''
    };
  }

  public showDialog(organizationId: number,
      accessControlGroup: AccessControlGroup | null)
      : Promise<ModalEditAccessControlGroupResult> {
    ++this.generationCounter;
    this.originalGroup = accessControlGroup;

    if (accessControlGroup) {
      this.group = {...accessControlGroup};
      this.title = xl8('editAccessControlGroup');
      this.okText = 'Update Group';
    } else {
      this.group = {
        id: 0,
        organizationId: organizationId,
        name: ''
      };
      this.title = xl8('createAccessControlGroup');
      this.okText = xl8('createGroup');
    }

    return setStatePromise<ModalEditAccessControlGroupState, 
        ModalEditAccessControlGroup>(this, {})
    .then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    renderSpam('ModalEditAccessControlGroup');
    return (
      <Modal 
          key={this.generationCounter}
          ref={this.modal}
          onOK={() => this.onOK()}
          title={this.title}
          confirmButtonContent={this.okText}
          confirmButtonIcon={<CheckIcon width="24" height="24" fill="#fff"/>}>
        <label>
          <span>
            {xl8('groupName')}
          </span>
          <input className="form-control"
            defaultValue={this.currentGroupNameValue()}
            onChange={(event) => this.group.name = event.target.value}
            />
        </label>
      </Modal>
    );
  }

  currentGroupNameValue(): string | number | readonly string[] {
    return this.group ? this.group.name : '';
  }
  
  private onOK(): Promise<ModalEditAccessControlGroupResult> {
    // if (!/\S/.test(this.accessControlGroupNameInput.value))
    //   return null;

    let verb = !this.group.id ? 'groupAdd' : 'groupUpd';

    return upsertAccessControlGroup(this.group)
    .then((groupId) => {
      this.group.id = groupId;
      
      if (this.originalGroup)
        Object.assign(this.originalGroup, this.group);
      else
        this.originalGroup = this.group;
      
      BroadcastService.getInstance().broadcast(verb, this.originalGroup);
      
      return {
        group: this.originalGroup
      };     
    });
  }
}
