import * as React from 'react';

import { Component, PureComponent } from 'react';
import { 
  PieChart, Pie, Cell, 
  ResponsiveContainer,
  Legend, Tooltip
} from 'recharts';
import { 
  Device, DeviceStatusConnectorLookup, DeviceStatusesResponse, 
  getDeviceStatuses, Organization, SiteDevices
} from '../../api';
import { xl8, xl8e } from '../../translations/i18n';
import { InfoIcon } from '../icons/InfoIcon';
import { Debouncer, toastError } from '../shared/ui';
import { CustomPieChartLabel } from './CustomPieChartLabel';
import './CustomPieChartLegend.scss';
import { Popover } from 'antd';
import { RightArrowIcon } from '../icons/RightArrowIcon';

interface StatusPieValue {
  color: string;
  name: string;
  value: number;
  deviceConnectorTuples: [Device, number][];
}

const testdata = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const statusColors: { [status: string]: string } = {
  Available: '#82C882',
  Preparing: '#6AC8CE',
  Charging: '#6899E3',
  SuspendedEV: '#FED556',
  SuspendedEVSE: '#FED556',
  Finishing: '#9C8BCC',
  //Occupied: '#f39c11'??
  Reserved: '#FBAC63',
  Faulted: '#E43D30',
  Unavailable: '#C1C5C8',
  Unknown: '#E8EEF4'
};


const style = {
  top: '50%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '22px',
};

const popoverStyle = { 
  minWidth: "165px", 
  borderRadius: "3px",
  boxShadow: "0px 10px 25px -8px rgba(0, 58, 93, 0.2);",
  // border: "1px solid #DCE3EB"    
};

const renderLegend = (props) => {
  const { payload } = props;

  return payload.map((entry, index) => {

    return (
      <div key={index} className="widget-legend-item">
        <svg className="recharts-surface widget-legend-icon" 
          width="16" height="16"
          viewBox="0 0 32 32" version="1.1">
            <rect fill={entry.color} x="5" y="5" width="26"
              height="26" 
              stroke={entry.color} 
              strokeWidth="3px" 
              rx="4px" 
              ry="4px" 
              strokeLinejoin="round" 
              />
        </svg>
        <span key={`item-${index}`} className="widget-legend-text">
          {entry.payload.value} {entry.value} 

          {/* {entry.value == 'Faulted' &&
            <>
              <Popover 
                content={(
                  <>
                    <div className="faulted-port-list">
                      <div>
                        1 Port at EV1028
                        <span>
                          <RightArrowIcon width='18' height='18' fill="#C1C5C8"/>
                        </span>

                      </div>
                      <div>
                        2 Port at EV1021
                        <span>
                          <RightArrowIcon width='18' height='18' fill="#C1C5C8"/>
                        </span>
                      </div>
                    </div>
                  </>
                )} 
                placement="right" 
                trigger="click"
                overlayStyle={popoverStyle}
              >
                <span className="status-warning-popover-trigger">
                  <InfoIcon width="16" height="16" fill='#C1C5C8' /> 
                </span>

              </Popover>
            </>
          } */}
        </span>
      </div>
    );
  });
};

export interface DeviceStatusWidgetProps {
  organization: Organization;
  filterEvseIds: Set<number> | null;
  evseIdToSiteId: Map<number, number> | null;
  siteDevices: SiteDevices;
  debug?: boolean;
}

interface DeviceStatusWidgetState {
  deviceStatusData: StatusPieValue[] | null;
  deviceLookup: Map<number, Device>;
  statusesResponse: DeviceStatusesResponse;
  deviceStatusCount: number;
  animate: boolean;
}

export class DeviceStatusWidget 
    extends PureComponent<DeviceStatusWidgetProps, DeviceStatusWidgetState> {
  private mounted: boolean = false;
  constructor(props: DeviceStatusWidgetProps) {
    super(props);

    this.state = {
      deviceStatusData: null,
      deviceLookup: null,
      statusesResponse: null,
      deviceStatusCount: null,
      animate: false
    };

    
  }

  private hackTimer;

  // getSnapshotBeforeUpdate(prevProps) {
  //   const shouldAnimate = !equals(prevProps.data, this.props.data);
  //   return shouldAnimate;
  // }

  componentDidUpdate(
      prevProps: Readonly<DeviceStatusWidgetProps>, 
      prevState: Readonly<DeviceStatusWidgetState>, 
      snapshot?: never,
      // shouldAnimate
      ): void {
    if (prevProps.organization !== this.props.organization)
      this.requestEverything();

    // if (prevState.animate !== shouldAnimate) {
    //   if (this.mounted) {
    //     this.setState({ animate: shouldAnimate });
    //   }
    // }
    
    if (prevProps.siteDevices !== this.props.siteDevices) {
      let allDevices = Object.values(
        this.props.siteDevices?.deviceListBySite ?? {})
      .reduce((prev, curr) => {
        return prev.concat(curr);
      }, []);

      let lookup = allDevices.reduce((map, device) => {
        map.set(device.id, device);
        return map;
      }, new Map<number, Device>());

      if (this.mounted) {
        this.setState({
          deviceLookup: lookup
        });
      }
    }
    
    if (prevState.statusesResponse !== this.state.statusesResponse ||
        prevState.deviceLookup !== this.state.deviceLookup ||
        prevProps.siteDevices !== this.props.siteDevices ||
        prevProps.organization !== this.props.organization ||
        prevProps.evseIdToSiteId !== this.props.evseIdToSiteId ||
        prevProps.filterEvseIds !== this.props.filterEvseIds) {
      this.updateDebounce.trigger(() => {
        this.updateChartData();
      });
    }
  }

  private updateDebounce = new Debouncer(200);

  private updateChartData() {
    // Just return if insufficient information
    if (!this.state.deviceLookup || 
        !this.state.statusesResponse || 
        !this.props.siteDevices)
      return;

    //let deviceCount = this.state.deviceLookup.size;

    let statusValues: DeviceStatusConnectorLookup[] = 
      Object.values(this.state.statusesResponse.devices);
    
    let statusDeviceIds = Object.keys(this.state.statusesResponse.devices);
    
    let statusCount = 0;

    let anySpam = false;
    let counts = new Map<string, [number,[Device, number][]]>();
    statusValues.forEach((statusLookup, deviceIndex) => {
      let deviceId = +statusDeviceIds[deviceIndex];
      if (this.props.filterEvseIds?.size &&
          !this.props.filterEvseIds.has(deviceId))
        return;
      let device = this.state.deviceLookup.get(deviceId);
      let statusConnectorIds = Object.keys(statusLookup);
      let statusList = Object.values(statusLookup);
      statusList.forEach((timestampStatus, connectorIndex) => {
        let connectorId = statusConnectorIds[connectorIndex];
        if (+connectorId) {
          ++statusCount;
          let oldValue = counts.get(timestampStatus.status);
          if (!oldValue) {
            oldValue = [0, []];
            counts.set(timestampStatus.status, oldValue);
          }
          ++oldValue[0];
          oldValue[1].push([device, +connectorId]);
        } else {
          if (this.props.debug) {
            anySpam = true;
            console.log('connector[' + connectorIndex + '] is 0 on', 
              device?.name);
          }
        }
      });
      
      let deviceConnectorIds = device?.connectors.map((conn) => {
        return conn.portConnectorId;
      }) ?? [];
      let missingInDevice: number[] = statusConnectorIds.map((id) => {
        return +id;
      }).filter((id) => {
        return !deviceConnectorIds.includes(+id);
      });
      let missingInStatus: number[] = deviceConnectorIds.filter((id) => {
        return !statusConnectorIds.includes('' + id);
      });

      if (statusList.length !== (device?.connectors.length ?? 0) || 
            missingInDevice.length || missingInStatus.length) {
          console.log([
            !device ? 'device ' + deviceId + ' not found in dashboard, ' : '',
            'status count ', statusList.length, 
            ' does not match connector count ', 
            device?.connectors.length ?? '<null>',
            ' on evseId ', device?.id ?? '<null>', ' ',
            device ? '"' + (device.name || '') + '"' : '<null>', 
            ', status connector ids are ', 
            '[', statusConnectorIds.map((id) => +id), ']',
            ', device connector ids are ', 
            device?.connectors
            ? '[' + device.connectors.map((conn) => conn.portConnectorId) + ']'
            : '<null>',
            ', missing in device are ', 
            missingInDevice.length 
            ? '[' + missingInDevice + ']' 
            : 'none',
            ', missing in status are ',
            missingInStatus.length 
            ? '[' + missingInStatus + ']' 
            : 'none'
          ].join(''));
        anySpam = true;
      }
    });

    if (anySpam)
      console.log('--end of connector warnings');

    let unknownCount = 0;
    this.state.deviceLookup.forEach((device, key, map) => {
      if (this.props.filterEvseIds?.size &&
          !this.props.filterEvseIds.has(device.id))
        return;
      if (!Object.prototype.hasOwnProperty.call(
        this.state.statusesResponse.devices, key)) {
        unknownCount += device.connectors.filter((connector) => {
          return !!connector.portConnectorId;
        }).length;
      }
    });

    if (unknownCount)
      counts.set('Unknown', [unknownCount, []]);
    
    statusCount += unknownCount;
  
    let results: StatusPieValue[] = [];
    counts.forEach((value, key) => {
      results.push({
        color: statusColors[key],
        name: xl8e('deviceStatus_' + key),
        value: value[0],
        deviceConnectorTuples: value[1]
      });
    });


    if (this.mounted) {
      this.setState({
        deviceStatusData: results,
        deviceStatusCount: statusCount
      });
    }
  }

  private static readonly refreshIntervalMs = 10000;
  private timeout: NodeJS.Timeout | null = null;

  componentDidMount(): void {
    console.log('status widget mount');
    this.mounted = true;
    console.assert(this.timeout === null);
    let handler = (): void => {
      if (this.timeout !== null && this.mounted) {
        this.requestStatus();
        this.timeout = setTimeout(handler, 
          DeviceStatusWidget.refreshIntervalMs);
      }
    };

    console.assert(!this.timeout);
    this.timeout = setTimeout(handler,  
      DeviceStatusWidget.refreshIntervalMs);

    if (this.props.organization)
      this.requestEverything();
  }

  componentWillUnmount(): void {
    console.log('status widget unmount');
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.mounted = false;
  }

  private requestEverything(): void {
    this.setState({
      deviceStatusData: null,
      statusesResponse: null      
    }, () => {
      this.requestStatus();
    });
  }

  private deviceRequestSequence = 0;

  private statusRequestSequence = 0;

  private requestStatus(): void {
    if (!this.props.organization)
      return;

    let req = ++this.statusRequestSequence;
    
    getDeviceStatuses(this.props.organization.id, 
      this.state.statusesResponse)
    .catch((err) => {
      if (req === this.statusRequestSequence)
        toastError(err.message);
      return null;
    }).then((statusesResponse) => {
      if (req === this.statusRequestSequence) {
        if (this.mounted) {
          this.setState({
            statusesResponse: statusesResponse
          });
        }
      } else {
        console.log('discarded response for old request');
      }
    });
  }

  render(): JSX.Element {
    return (
      <>
        <h4 className="widget-header">
          {xl8('portStatus')}
          <span>
            {xl8('availabilityOverview')}
          </span>
        </h4>
          {this.renderDeviceStatusPieChart()}
          {/* {this.renderDeviceStatusEmpty()} */}
      </> 
    ); 
  }

  private renderDeviceStatusPieChart() {
    return (

      <ResponsiveContainer height={'90%'}
      >
        <PieChart 
         
          //margin={{ top: 5, right: 5, bottom: 5, left: -40 }}
        >
          
          {this.state.deviceStatusData ? <Pie  
            data={this.state.deviceStatusData || []}
            innerRadius="62%" 
            outerRadius="80%"
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
            // cx="50%"
            // cx={'37%'}
            labelLine={false}
            isAnimationActive={false}
            label={CustomPieChartLabel(
              this.state.deviceStatusCount + ' ' + xl8('ports'))}
          >
            {
              this.state.deviceStatusData?.map((entry) => {
                let locName = xl8e('deviceStatus_' + entry.name);
                return <Cell key={locName} fill={entry.color}/>;
              }) || null
            }
            </Pie> : 'Loading'}
          <Legend content={renderLegend} 
            iconSize={12} 
            layout="vertical"
            verticalAlign="middle" 
            wrapperStyle={style}
            align="right" 
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

    );
  }
}