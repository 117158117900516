import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface ReportIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class ReportIcon extends PureComponent<ReportIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM18 7.25H6V8.75H18V7.25ZM6 11.25H18V12.75H6V11.25ZM15 15.25H6V16.75H15V15.25Z" fill={this.props.fill}/>
      </svg>
    );
  }
}