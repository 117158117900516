import ReactDOM from 'react-dom';
import { swychedRouterFactory } from './App';

import { DropdownWorkaround } from './components/menu.component';
import { toastError } from './components/shared/ui';

import './fonts/GalanoGrotesqueLight.woff';
import './fonts/GalanoGrotesqueLight.woff2';
import './fonts/GalanoGrotesqueSemiBold.woff';
import './fonts/GalanoGrotesqueSemiBold.woff2';


let root = document.getElementById('root');

DropdownWorkaround.hook(root);
window.addEventListener('unhandledrejection', 
(event: PromiseRejectionEvent) => {
  toastError(event.reason.message);
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();
}, true);
ReactDOM.render(swychedRouterFactory(), root);
