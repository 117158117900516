
type RegistrationCallback = (info: any) => void;

interface Registration {
  name: string;
  callbacks: RegistrationCallback[];
}

export class BroadcastService {
  private static instance: BroadcastService = null;

  private regs: Map<string, Registration> = new Map();

  private constructor() {
  }

  public static getInstance(): BroadcastService {
    return BroadcastService.instance ||
      (BroadcastService.instance = new BroadcastService());
  }

  public static dropInstance(): void {
    BroadcastService.instance = null;
  }

  public register(name: string, 
      callback: RegistrationCallback): RegistrationCallback {        
    console.assert(!!name);
    console.assert(!!callback);

    console.log('registering broadcast listener for', name, callback);
    
    let reg = this.regs.get(name);

    if (!reg) {
      reg = {
        name: name,
        callbacks: []
      };

      this.regs.set(name, reg);
    }

    reg.callbacks.push(callback);

    return callback;
  }

  public unregister(name: string, callback: RegistrationCallback): boolean {
    console.log('unregistering broadcast listener for', name, callback);
    if (!callback)
      return false;
    
    let reg = this.regs.get(name);

    if (!reg)
      return false;

    let index = reg.callbacks.indexOf(callback);

    if (index < 0)
      return false;

    reg.callbacks.splice(index, 1);

    if (!reg.callbacks.length)
      this.regs.delete(name);

    return true;
  }

  public broadcast(name: string, info: any): void {
    let reg = this.regs.get(name);
    
    if (!reg)
      return;

    console.log('notifying', reg.callbacks.length, 
      'callbacks for', name, 'broadcast with', info);
      
    reg.callbacks.forEach((callback) => {
      callback(info);
    });
  }
}
