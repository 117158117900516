import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface DownloadIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class DownloadIcon extends PureComponent<DownloadIconProps, {}> {
  render(): ReactNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill}>
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
      </svg>
    );
  }
}