import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NoDevicesIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NoDevicesIcon extends PureComponent<NoDevicesIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="34" y="16" width="38" height="59" rx="4.5" fill="#175785"/>
        <rect x="27" y="16" width="38" height="59" rx="4.5" fill="#80A7C3"/>
        <path d="M47.1491 32.5V38.7298L50.7433 38.7298L44.0941 46.9513V40.7215H40.5L47.1491 32.5Z" fill="white"/>
        <path d="M19.6983 19V21.9966L21.4272 21.9966L18.2288 25.9513V22.9547H16.5L19.6983 19Z" fill="#175785"/>
        <path d="M13.5 34H24V40C24 42.4853 21.9853 44.5 19.5 44.5H18C15.5147 44.5 13.5 42.4853 13.5 40V34Z" fill="#175785"/>
        <rect x="15.75" y="31" width="1.5" height="4.5" fill="#175785"/>
        <rect x="20.25" y="31" width="1.5" height="4.5" fill="#175785"/>
        <path d="M18.75 42.25V68.875C18.75 72.81 21.94 76 25.875 76V76C29.81 76 33 72.81 33 68.875V65.875C33 60.2831 28.4669 55.75 22.875 55.75H22.5C16.701 55.75 12 60.451 12 66.25V70.375C12 78.4522 18.5478 85 26.625 85H35.25C41.049 85 45.75 80.299 45.75 74.5V74.5" stroke="#175785" strokeWidth="2.25"/>
      </svg>
    );
  }
}