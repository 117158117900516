import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface NotificationBatteryFullIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class NotificationBatteryFullIcon extends PureComponent<NotificationBatteryFullIconProps, {}> {
  render(): ReactNode {
    return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <circle cx="28.5" cy="28.5" r="28.5" fill="#F9F9F9"/> */}
      <rect x="21" y="18" width="15" height="25" rx="2" stroke="#C6C6C6" strokeWidth="2"/>
      <path d="M25 15C25 14.4477 25.4477 14 26 14H31C31.5523 14 32 14.4477 32 15V17H25V15Z" fill="#C6C6C6"/>
      <rect x="24" y="21" width="9" height="19" fill="#C4C4C4"/>
    </svg>
    
    );
  }
}