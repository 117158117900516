import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface GeneratingReportIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class GeneratingReportIcon extends PureComponent<GeneratingReportIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 117 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="20.9998" y="19" width="78" height="58.5" rx="4.5" fill="#175785"/>
        <rect x="19.5" y="20.5" width="75" height="55.5" rx="3" fill="white" stroke="#80A7C3" strokeWidth="3"/>
        <rect x="46.5001" y="28.0001" width="3" height="19.5" rx="1.5" transform="rotate(90 46.5001 28.0001)" fill="#175785"/>
        <rect x="40.5" y="35.5" width="3" height="13.5" rx="1.5" transform="rotate(90 40.5 35.5)" fill="#DCE3EB"/>
        <rect x="61.5001" y="35.5" width="3" height="9" rx="1.5" transform="rotate(90 61.5001 35.5)" fill="#DCE3EB"/>
        <rect x="81.0001" y="35.5" width="3" height="10.5" rx="1.5" transform="rotate(90 81.0001 35.5)" fill="#DCE3EB"/>
        <rect x="61.5001" y="43.0001" width="3" height="9" rx="1.5" transform="rotate(90 61.5001 43.0001)" fill="#DCE3EB"/>
        <rect x="79.5" y="43.0001" width="3" height="9" rx="1.5" transform="rotate(90 79.5 43.0001)" fill="#DCE3EB"/>
        <rect x="61.5001" y="50.5" width="3" height="9" rx="1.5" transform="rotate(90 61.5001 50.5)" fill="#DCE3EB"/>
        <rect x="82.5002" y="50.5" width="3" height="12" rx="1.5" transform="rotate(90 82.5002 50.5)" fill="#DCE3EB"/>
        <rect x="46.5001" y="43.0001" width="3" height="19.5" rx="1.5" transform="rotate(90 46.5001 43.0001)" fill="#DCE3EB"/>
        <rect x="42.0001" y="58.0001" width="3" height="15" rx="1.5" transform="rotate(90 42.0001 58.0001)" fill="#DCE3EB"/>
        <rect x="46.5001" y="50.5" width="3" height="19.5" rx="1.5" transform="rotate(90 46.5001 50.5)" fill="#DCE3EB"/>
        <rect x="64.5001" y="28.0001" width="3" height="12" rx="1.5" transform="rotate(90 64.5001 28.0001)" fill="#175785"/>
        <rect x="87.0001" y="28.0001" width="3" height="16.5" rx="1.5" transform="rotate(90 87.0001 28.0001)" fill="#175785"/>
        <path d="M89.25 48.25L113.25 79" stroke="#175785" strokeWidth="4.5" strokeLinecap="round"/>
        <circle cx="89.25" cy="48.2501" r="15.75" fill="#E8EEF4"/>
        <path d="M84.7502 40.0001C85.9929 40.0001 87.0002 41.0074 87.0002 42.2501V42.2501C87.0002 43.4927 85.9929 44.5001 84.7502 44.5001L75.0002 44.5001L75.0002 40.0001L84.7502 40.0001Z" fill="#80A7C3"/>
        <path d="M83.2501 50.5001C84.4928 50.5001 85.5001 51.5074 85.5001 52.7501V52.7501C85.5001 53.9927 84.4928 55.0001 83.2501 55.0001L73.5001 55.0001L73.5001 50.5001L83.2501 50.5001Z" fill="#80A7C3"/>
        <path d="M99 37V37C102.314 37 105 39.6863 105 43V53.5C105 56.8137 102.314 59.5 99 59.5V59.5V37Z" fill="#80A7C3"/>
        <circle cx="89.25" cy="48.2501" r="15.75" stroke="#175785" strokeWidth="3"/>
      </svg>
      
      
    );
  }
}