import { PureComponent, ReactNode } from "react";
import { User } from "../api";
import { AuthService } from "./services/auth.service";
import { UserAttributes } from "./services/cognito.service";
import { renderSpam, setStatePromise } from "./shared/ui";

export interface UserPropertyProps {
  propertyName: keyof User;
}

interface UserPropertyState {
  userAttrPromise: Promise<UserAttributes>;
  userAttr: UserAttributes;
  value: ReactNode;
}

export class UserProperty
    extends PureComponent<UserPropertyProps, UserPropertyState> {
  constructor(props: UserPropertyProps) {
    super(props);
    this.state = {
      userAttrPromise: null,
      userAttr: null,
      value: ''
    };
  }

  static getDisplayedValue(userAttr: UserAttributes, 
      propertyName: string): ReactNode {
    let value: ReactNode;

    // console.log('UserProperty getting ', propertyName, ' of ', userAttr);
    
    switch (propertyName) {
      case 'email':
        value = userAttr?.email || '';
        break;
      case 'firstName':
          value = userAttr?.given_name || '';
          break;
      default:
        value = '<bad propertyName>';
        break;
    }
    
    return value;
  }

  static getDerivedStateFromProps(
      props: UserPropertyProps, 
      state: UserPropertyState)
      : Partial<UserPropertyState> {
    let result: Partial<UserPropertyState> = {};

    let updatedValue: ReactNode = UserProperty.getDisplayedValue(
      state.userAttr, props.propertyName);

    if (state.value !== updatedValue)
      result.value = updatedValue;

    return result;
  }

  componentDidMount(): void {
    let authService: AuthService = AuthService.getInstance();
    //console.log('UserProperty getting authData');
    let authDataPromise = authService.getUserAttributes().then((userAttr) => {
      //console.log('UserProperty got authData');
      let newValue = UserProperty.getDisplayedValue(
        userAttr, this.props.propertyName);
      //console.log('UserProperty setting display value:', newValue);
      return setStatePromise<UserPropertyState, UserProperty>(this, {
        userAttr: userAttr,
        value: newValue
      }).then(() => userAttr);
    });
    //console.log('UserProperty requested authData');
    this.setState({
      userAttrPromise: authDataPromise
    });
  }

  render(): ReactNode {
    renderSpam('UserProperty');
    return this.state.value;
  }
}
