import { RefObject } from 'react';
import { toastError } from '../shared/ui';

export interface ModalValidation {
  name: string;
  callback: (value: any) => boolean;
  message: string;
  clean?: (value: any) => any;
  ref?: RefObject<ValidatorFocusable>;
}

export interface ValidatorFocusable {
  focus(): void;
}

export class ModalValidator {
  public constructor(private validations: ModalValidation[] = []) {
  }

  public reset(): void {
    this.validations.splice(0, this.validations.length);
  }

  public cleanInput<T>(state: T): Partial<T> {
    let changes: Partial<T> = {};
    this.validations.forEach((validation) => {
      if (validation.clean) {
        let oldValue = state[validation.name];
        let newValue = validation.clean(oldValue);
        if (oldValue !== newValue)
          changes[validation.name] = newValue;
      }
    });
    return changes;
  }

  public isValid<T>(state: T): boolean {
    return !this.validations.some((validation) => {
      if (!validation.callback(state[validation.name])) {
        validation.ref?.current?.focus();
        toastError(validation.message);
        return true;
      }
      return false;
    });
  }

  public mustPass(name: string, message: string, 
      ref: RefObject<HTMLElement>, callback: (value: any) => boolean): void {
    this.validations.push({
      name: name,
      callback: callback,
      message: message,
      ref: ref
    });
  }

  public mustBeChecked(name: string,
    message: string, ref?: RefObject<HTMLElement>): void {
    this.validations.push({
      name: name,
      callback: (value: any) => {
        return !!value;
      },
      message: message,
      ref: ref
    });
  }

  public mustBeNonzero<T extends ValidatorFocusable>(name: string,
    message: string, ref?: RefObject<T>): void {
    this.validations.push({
      name: name,
      callback: (value: any) => {
        return +value !== 0;
      },
      message: message,
      ref: ref
    });
  }

  // Text input field that must have something other than whitespace,
  // whose leading and trailing whitespace are trimmed off
  public mustBeNotEmptyTrimmed(name: string,
    message: string, ref?: RefObject<HTMLElement>): void {
    this.validations.push({
      name: name,
      callback: (value: any) => {
        return /\S/.test(value);
      },
      message: message,
      clean: (value: any) => {
        return value.match(/^\s*(.*?)\s*$/)[1];
      },
      ref: ref
    });
  }
}
