import * as React from 'react';
import { Connector, connectorTypeLookup, Device, Site, startSession } from '../../api';
import { PlayIcon } from '../icons/PlayIcon';
import { renderSpam, setStatePromise, uniqueId } from '../shared/ui';
import { Modal, ModalBase } from './Modal';

import "./ModalStartSession.scss";

export interface ModalStartSessionProps {
}

export interface ModalStartSessionResult {
}

interface ModalStartSessionState {
  organizationId: number;
  site: Site;
  device: Device;
  selectedConnector: Connector;
  duration: number;
}

export class ModalStartSession
    extends ModalBase<ModalStartSessionProps, ModalStartSessionResult,
    ModalStartSessionState> {
  private subtitle: HTMLDivElement | null = null;
  private sessionDurationInput: HTMLInputElement | null = null;

  constructor(props: ModalStartSessionProps) {
    super(props);
    this.state = {
      organizationId: null,
      site: null,
      device: null,
      selectedConnector: null,
      duration: null
    };
  }

  public showDialog(organizationId: number,
      site: Site, device: Device): Promise<ModalStartSessionResult> {
    //this.siteAddressInput.value = site.address;
    return setStatePromise<ModalStartSessionState, 
        ModalStartSession>(this, {
          organizationId: organizationId,
          site: site,
          device: device,
          selectedConnector: device.connectors[0]
        })
    .then(() => {
      return this.show();
    });
  }

  private uniq: string = uniqueId()

  private connectorTypeMap = connectorTypeLookup();

  render(): JSX.Element {
    renderSpam('ModalStartSession');
    
    return (
      <Modal 
          ref={this.modal}
          onOK={() => this.onOK()}
          confirmDisabled={!+this.state.duration}
          title="Start Session"
          confirmButtonIcon={<PlayIcon width="24" height="24" fill="#fff"/>}
          confirmButtonContent="Start Session">
            <p className="p-b-20">
              Select the port where you would like
              to start a charging session.
            </p>
            <div className="connector-select-container p-b-20">
              {this.state.device?.connectors?.map((conn, index) => {
                return (
                  <label className="form-check-label" key={conn.id}>
                    <input className="form-check-input"                      
                      type="radio" name="connectorPick"
                      checked={this.state.selectedConnector?.id === conn.id}
                      value={conn.id}
                      onChange={(event) => {
                        console.log('changing to ', conn);
                        this.setState({
                          selectedConnector: conn
                        });
                      }}/>
                      Port {index + 1}: {conn.type}
                      {conn.name ? ' (' + conn.name + ')' : null}
                  </label>
                );
              })}
            </div>
            <div>
              <span>Session Duration (Minutes)</span>
              <input className="form-control"
                ref={(element) => {
                  this.sessionDurationInput = element;
                }}
                value={'' + (this.state.duration ?? '')}
                onChange={(event) => {
                  this.setState({
                    duration: event.target.value.length 
                      ? +event.target.value 
                      : null
                  });
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
      </Modal>
    );
  }
  
  private onOK(): Promise<ModalStartSessionResult> {
    if (!/\S/.test(this.sessionDurationInput.value))
      return Promise.resolve(null);
    
    let organizationId = this.state.organizationId;
    let site =  this.state.site;
    let device = this.state.device;
    let portConnectorId = this.state.selectedConnector.portConnectorId;
    let duration = this.state.duration;
    
    console.log('starting session, org=', organizationId, 
      'site=', site, 'device=', device, 
      'portConnectorId=', portConnectorId, 
      'duration=', duration);

    return startSession(organizationId, site, device, 
      portConnectorId, duration)
      .then(() => {
        return {};
      });
  }
}