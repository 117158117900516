import { CognitoService, UserAttributes } from './cognito.service';
import { ICurrentTenant, ITenantInfo, StorageService } from './storage.service';
import HistoryService from './history.service';
import { minutesAdd, minutesUntil, noop } from '../shared/ui';

const tokenStorageKey: string = 'swychedtoken';

export class AuthService {
  private static instance: AuthService = null;

  public static getInstance(): AuthService {
    if (!AuthService.instance)
      AuthService.instance = new AuthService();
    return AuthService.instance;
  }

  // For tests, mostly
  public static dropInstance(): void {
    AuthService.instance = null;
  }

  public static authToken(): Promise<string> {
    return AuthService.getInstance().getData().then((authData) => {
      return authData.idToken.jwtToken;
    });
  }

  // Dependencies
  private cognitoService: CognitoService = CognitoService.getInstance();

  // State
  
  private data: Promise<AuthData> = null;
  private expires: number = 0;
  public staySignedIn: boolean = false;

  private currentTenant: ICurrentTenant = {
    tenant: null,
    tenants: [],
    tenantId: 0
  };

  private lastCallbackId: number = 0
  private tenantChangingCallbacks: TenantChangeRegistration[] = []
  private tenantChangedCallbacks: TenantChangeRegistration[] = []

  private constructor() {
  }

  public getCurrentTenant(): ICurrentTenant {
    return {
      ...this.currentTenant
    };
  }
  
  public getData(): Promise<AuthData> {
    let now: number = Date.now();

    let slightlyBeforeExpiry: number = minutesAdd(this.expires, -5);
    let expiringSoon: boolean = now > slightlyBeforeExpiry;
    let minutesUntilExpiry: number = minutesUntil(now, this.expires);

    if (!this.expires)
      console.log('There is no auth data expiry');
    else if (expiringSoon) {
      console.log('auth data expiring in ', 
        minutesUntilExpiry.toFixed(2), 'minutes');
    }

    // If we do have auth data, and,
    // the auth data is going to expire in the next 5 minutes
    if (!this.data || expiringSoon) {
      console.log('auth header cache ' + 
        (this.data ? 'missed' : 'expiring soon'));
      
      // Then refresh it and return that promise
      this.data = this.refreshAuthData();

      return this.data;
    }
    
    // Return the promise we already have
    return this.data;
  }

  public observeTenantChanging(
      callback: (ITenantInfo) => Promise<void>): number {
    let id = ++this.lastCallbackId;
    this.tenantChangingCallbacks.push({ id, callback });
    if (this.currentTenant.tenant)
      callback(this.currentTenant.tenant);
    return id;
  }

  public observeTenantChanged(
      callback: (ITenantInfo) => Promise<void>): number {
    let id = ++this.lastCallbackId;
    this.tenantChangedCallbacks.push({ id, callback });
    callback(this.currentTenant.tenant);
    return id;
  }

  private refreshAuthData(): Promise<AuthData> {
    console.log('refreshing cognitoService');
    this.data = this.cognitoService.refresh();
    console.log('refreshing token');
    let newExpires = minutesAdd(Date.now(), 60);
    let backupExpires = this.expires;
    this.expires = newExpires;
    this.data = this.data.then((authData) => {
      console.assert(authData, 'cognito refresh returned null!');
      if (!authData)
        throw new Error('Unable to refresh authentication');
      authData.expires = this.expires;
      this.saveAutoSignIn(authData);
      return authData;
    }).catch((err) => {
      console.log('kicking to login because unable to refresh token');
      this.expires = backupExpires;
      AuthService.getInstance().signOut();
      return null;
    });

    return this.data;
  }

  public forceTokenRefresh(): Promise<AuthData> {
    return this.refreshAuthData();
  }

  public signIn(tenantId?: number, fileId?: string): Promise<void> {
    let storageService: StorageService;
    if (!storageService)
      storageService = StorageService.getInstance();
    
    StorageService.flushCaches();

    // let getFileTenantPromise: Promise<ITenantFile>;

    // if (0 && !tenantId && this.destinationFileId) {
    //   getFileTenantPromise = storageService.findTenantIdForFile(
    //     this.destinationFileId);
    // } else {
    //   getFileTenantPromise = Promise.resolve({
    //     tenantId: tenantId,
    //     fileId: fileId
    //   });
    // }
  
    let getFileTenantPromise = Promise.resolve(null);

    // Aggressively overlap getting tenants with determining file's tenantId
    let tenantsPromise = storageService.getTenants(true);

    return getFileTenantPromise.then((tenantFile) => {
      if (tenantFile && tenantFile.tenantId)
        tenantId = tenantFile.tenantId;
      return tenantsPromise;
    }).then((tenants) => {
      // If not specifying a tenantId
      if (!tenantId) {
        // Use the one in sessionStorage
        let savedTenantId: number = +sessionStorage.getItem('currentTenantId');

        // Resort to global one if this is a new tab or something
        if (!savedTenantId)
          savedTenantId = +localStorage.getItem('currentTenantId');

        let savedTenantExists = (savedTenantId &&
          tenants.some((tenantInfo) => {
            return +tenantInfo.tenantid === +savedTenantId;
          })) || null;

        if (!savedTenantExists) {
          savedTenantId = null;
          sessionStorage.removeItem('currentTenantId');
          localStorage.removeItem('currentTenantId');
        }

        // Resort to the first tenant in the list
        if (!savedTenantId)
          savedTenantId = tenants[0].tenantid;

        tenantId = savedTenantId;
      }

      sessionStorage.setItem('currentTenantId', '' + tenantId);
      localStorage.setItem('currentTenantId', '' + tenantId);
      console.log('set currentTenantId=' + tenantId);

      let tenant = tenants.find((candidate) => {
        return candidate.tenantid === tenantId;
      });

      if (!tenant)
        tenant = tenants[0];

      this.currentTenant = {
        tenant: tenant,
        tenantId: tenant.tenantid,
        tenants: tenants
      };
      
      return this.runTenantChangeCallbacks();
    }).then(() => {
      if (!fileId) {
        this.navigateToDestination();
      } else {
        // if (!storageService)
        //   storageService = StorageService.getInstance();
        // return storageService.getFileById(fileId).then((file) => {
        //   return file.open(this.router, false, storageService);
        // }).then((ok) => {
        //   if (!ok)
        //     return Promise.reject(new Error('Unable to open file'));
        // });
      }
    }).catch((err) => {
      if (err.message)
        throw err;
      // Workaround hack to handle missing CORS headers
      err = new Error('Server did not send CORS headers, unknown error.');
      throw err;
    });
    // this.router.navigate(['/dashboard']);
  }

  private navigateToDestination() {
    //router.props.
    Promise.resolve().then(() => {
      let search = location.search;
      // If the URL contains sensitive information, replace it
      let destinationMatch = search.match(/[?&]destination=([^&]*)/);
      let destination = destinationMatch && destinationMatch[1] &&
        decodeURIComponent(destinationMatch[1]);
      if (!search.includes('username=') && !search.includes('password='))
        HistoryService.push(destination || '/');
      else
        HistoryService.replace(destination || '/');
      HistoryService.go(0);
    });
  }

  public unsubscribeTenantChanged(id: number): void {
    return this.unsubscribeRegistration(this.tenantChangedCallbacks, id);
  }

  public unsubscribeTenantChanging(id: number): void {
    return this.unsubscribeRegistration(this.tenantChangingCallbacks, id);
  }

  public unsubscribeRegistration(
      list: TenantChangeRegistration[], id: number): void {
    let index = list.findIndex((reg) => {
      return reg.id === id;
    });

    if (index >= 0)
    list.splice(index, 1);
  }

  private runTenantChangeCallbacks(): Promise<void> {
    // Invoke each changing callbacks and collect the promises they return
    let changingPromises: Promise<void>[] =
    this.tenantChangingCallbacks.map((reg) => {
      return reg.callback(this.currentTenant.tenant);
    });

    // Wait for all of the changing callbacks to complete, then...
    return Promise.all(changingPromises).then(() => {
      // Run all of the changed callbacks...
      let changedPromises: Promise<void>[];
      changedPromises = this.tenantChangedCallbacks.map((reg) => {
        return reg.callback(this.currentTenant.tenant);
      });

      // And wait for all of them
      return Promise.all(changedPromises);
    }).then(noop);
  }

  public signOut(): void {
    this.data = null;
    this.deleteAutoSignIn();
    this.cognitoService.signOut();

    Promise.resolve().then(() => {
      HistoryService.push('/login');
      HistoryService.go(0);
      StorageService.flushCaches();
    });
  }

  public isAuthenticated(): boolean {
    this.restoreAuthData();
    return this.data !== null;
  }

  // canActivate(route: ActivatedRouteSnapshot, 
  //     state: RouterStateSnapshot): boolean {
  //   if (this.isAuthenticated()) {
  //     console.log('checked for authentication, returning true');
  //     return true;
  //   }

  //   console.log('route data:', route.data);

  //   let fileId: any =
  //     route.data && 
  //     route.data.componentType && 
  //     route.data.componentType.fileIdFromRoute && 
  //     route.data.componentType.fileIdFromRoute(route) ||
  //     null;

  //   setTimeout(() => {
  //     let params: any = {
  //       destination: state.url
  //     };
      
  //     if (fileId)
  //       params.fileId = fileId;

  //     this.router.navigate(['/login', params]);
  //   });

  //   return false;
  // }

  private restoreAuthData(): boolean {
    if (this.data !== null)
      return true;
    
    // Try tab-local storage first
    let authDataRaw = sessionStorage.getItem(tokenStorageKey);

    // If not found in tab-local storage, read from persistent storage
    if (!authDataRaw)
      authDataRaw = localStorage.getItem(tokenStorageKey);
    
    // If not found at all, fail
    if (!authDataRaw)
      return false;
    
    try {
      this.data = null;
      let restoredData: AuthData = JSON.parse(authDataRaw);
      this.expires = restoredData.expires || 0;
      this.data = Promise.resolve(restoredData);
      console.log('restored auth data:', restoredData);
    } catch (e) {
      console.warn('caught exception while parsing saved auth data');
      return false;
    }

    return true;
  }

  public attemptAutoSignIn(): Promise<boolean> {
    console.log('attempting auto sign in');

    if (this.restoreAuthData()) {
      if (!this.isAuthenticated())
        return this.signIn().then(() => true);
    }

    return Promise.resolve(false);
  }

  public saveAutoSignIn(authData: AuthData): void {
    this.data = Promise.resolve(authData);
    
    let storedData: any = JSON.stringify(authData);
    
    //console.log('saving auth information:', storedData);

    console.assert(tokenStorageKey);

    // Store it in both localStorage and sessionStorage if staying signed in
    if (authData.staySignedIn)
      localStorage.setItem(tokenStorageKey, storedData);

    sessionStorage.setItem(tokenStorageKey, storedData);
  }

  public deleteAutoSignIn(): void {
    console.log('deleting auto sign in');
    localStorage.removeItem(tokenStorageKey);
    sessionStorage.removeItem(tokenStorageKey);
  }

  public getUserAttributes(): Promise<UserAttributes> {
    return this.cognitoService.getUserAttributes();
  }

  public setUserAttributes(attr: UserAttributes): Promise<void> {
    return this.cognitoService.setUserAttributes(attr);
  }

  public verifyUserAttribute(name: string,
      acceptCodeCallback: (callback: (code: string) => void) => void)
      : Promise<void> {
    return this.cognitoService.verifyAttribute(
      'email', null, acceptCodeCallback);
  }

  public changePassword(oldPassword: string,
      newPassword: string): Promise<void> {
    return this.cognitoService.changePassword(oldPassword, newPassword);
  }

  public setCurrentTenant(
      tenants: ITenantInfo[], tenantId: number): Promise<void> {
    this.currentTenant = {
      tenantId: tenantId,
      tenant: tenants.find((tenant) => tenant.tenantid === tenantId),
      tenants: tenants
    };

    sessionStorage.setItem('currentTenantId', '' + tenantId);
    localStorage.setItem('currentTenantId', '' + tenantId);

    StorageService.flushCaches();

    return this.runTenantChangeCallbacks();
  }
}

export interface AuthDataJwtToken {
  jwtToken: string
}

export interface AuthDataRefreshToken {
  token: string
}

export interface AuthData {
  accessToken: AuthDataJwtToken,
  idToken: AuthDataJwtToken,
  refreshToken: AuthDataJwtToken,
  expires: number,
  staySignedIn: boolean,
  email: string
}

export interface TenantChangeRegistration {
  id: number;
  callback: (tenantInfo: ITenantInfo) => Promise<void>
}
