import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface UpArrowIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class UpArrowIcon extends PureComponent<UpArrowIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width}  height={this.props.height} viewBox="0 0 24 24" fill={this.props.fill} xmlns="http://www.w3.org/2000/svg">
       <path opacity="0.9" fillRule="evenodd" clipRule="evenodd" d="M17.6402 15.7682C17.2159 16.1218 16.5853 16.0644 16.2318 15.6402L12 10.562L7.7682 15.6402C7.41463 16.0644 6.78407 16.1218 6.35979 15.7682C5.93551 15.4146 5.87819 14.7841 6.23175 14.3598L11.2318 8.35979C11.4217 8.1318 11.7032 7.99998 12 7.99998C12.2968 7.99998 12.5782 8.1318 12.7682 8.35979L17.7682 14.3598C18.1218 14.7841 18.0644 15.4146 17.6402 15.7682Z" fill={this.props.fill}/>
      </svg>    
    );
  }
}