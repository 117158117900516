import { dateFromUnix, isDeveloper } from "../components/shared/ui";

export interface I18nWord<T = string> {
  // Charger Types
  chargerType_ac: I18nLanguages<T>;
  chargerType_dcfc: I18nLanguages<T>;


  // Sign in
  signIntoSwyched: I18nLanguages<T>;
  keepMeSignedIn: I18nLanguages<T>;
  signIn: I18nLanguages<T>;
  finishRegistering: I18nLanguages<T>;
  accountRecovery: I18nLanguages<T>;
  verifyAccount: I18nLanguages<T>;
  codeSentTo: I18nLanguages<T>;
  checkEmailForCode: I18nLanguages<T>;
  createNewPassword: I18nLanguages<T>;
  lostNeedReset: I18nLanguages<T>;
  nameIsRequiredField: I18nLanguages<T>;
  lastNameIsRequiredField: I18nLanguages<T>;
  validEmailRequired: I18nLanguages<T>;
  verificationCode: I18nLanguages<T>;
  verificationCodeRequired: I18nLanguages<T>;
  password: I18nLanguages<T>;
  forgotPassword: I18nLanguages<T>;
  validPasswordRequired: I18nLanguages<T>;
  passwordPolicy: I18nLanguages<T>;
  confirmPassword: I18nLanguages<T>;
  passwordsMustMatch: I18nLanguages<T>;
  resendConfirmationCode: I18nLanguages<T>;
  createAccount: I18nLanguages<T>;
  resetPassword: I18nLanguages<T>;
  updatePassword: I18nLanguages<T>;
  getNewVerificationCode: I18nLanguages<T>;
  loginExistingAccount: I18nLanguages<T>;
  verifyingCredentials: I18nLanguages<T>;
  contact: I18nLanguages<T>;

  // Dashboard
  welcomeBack: I18nLanguages<T>;
  myDashboard: I18nLanguages<T>;
  portStatus: I18nLanguages<T>;
  availabilityOverview: I18nLanguages<T>;
  devices: I18nLanguages<T>;
  totalPower: I18nLanguages<T>;
  realtimePower: I18nLanguages<T>;
  avgSessionLength: I18nLanguages<T>;
  averageOverLastSevenDays: I18nLanguages<T>;
  netEnergy: I18nLanguages<T>;
  overLastSevenDays: I18nLanguages<T>;

  filterBy: I18nLanguages<T>;
  all: I18nLanguages<T>;
  clearFilter: I18nLanguages<T>;

  // Days 
  monday: I18nLanguages<T>;
  tuesday: I18nLanguages<T>;
  wednesday: I18nLanguages<T>;
  thursday: I18nLanguages<T>;
  friday: I18nLanguages<T>;
  saturday: I18nLanguages<T>;
  sunday: I18nLanguages<T>;

  weekdays: I18nLanguages<T>;
  weekends: I18nLanguages<T>;
  holidays: I18nLanguages<T>;

  // Sites & Devicess
  mySitesAndStations: I18nLanguages<T>;
  addSite: I18nLanguages<T>;
  addDevice: I18nLanguages<T>;
  emptySiteHeader: I18nLanguages<T>;
  emptySiteText: I18nLanguages<T>;
  search: I18nLanguages<T>;
  allSites: I18nLanguages<T>;
  chargingStation: I18nLanguages<T>;
  hub: I18nLanguages<T>;
  signOut: I18nLanguages<T>;
  newOrganization: I18nLanguages<T>;
  editStation: I18nLanguages<T>;
  addStation: I18nLanguages<T>;
  addPort: I18nLanguages<T>;
  convertToHub: I18nLanguages<T>;
  convertToStation: I18nLanguages<T>;


  startSession: I18nLanguages<T>;
  stopSession: I18nLanguages<T>;
  deleteHub: I18nLanguages<T>;
  restartDevice: I18nLanguages<T>;
  editSettings: I18nLanguages<T>;
  editAccessControlGroup: I18nLanguages<T>;
  createAccessControlGroup: I18nLanguages<T>;
  editAccessCard: I18nLanguages<T>;
  viewUsage: I18nLanguages<T>;
  remove: I18nLanguages<T>;
  deleteAccessCard: I18nLanguages<T>;
  editGroup: I18nLanguages<T>;
  deleteGroup: I18nLanguages<T>;
  editUser: I18nLanguages<T>;
  import: I18nLanguages<T>;
  sites: I18nLanguages<T>;
  select: I18nLanguages<T>;
  chargerType: I18nLanguages<T>;
  editChargingStation: I18nLanguages<T>;
  editHub: I18nLanguages<T>;
  editHubDevice: I18nLanguages<T>;
  addChargingStation: I18nLanguages<T>;


  // Reporting
  reporting: I18nLanguages<T>;

  generateCustomReports: I18nLanguages<T>;
  updateReport: I18nLanguages<T>;
  emptyReportHeader: I18nLanguages<T>;
  emptyReportText: I18nLanguages<T>;
  generatingReportHeader: I18nLanguages<T>;
  generateReport: I18nLanguages<T>;
  generatingReport: I18nLanguages<T>;


  // Report modal filter
  sessionHistory: I18nLanguages<T>;
  faultHistory: I18nLanguages<T>;
  peakPower: I18nLanguages<T>;
  peakOccupancy: I18nLanguages<T>;
  sessionIdle: I18nLanguages<T>;

  reportType: I18nLanguages<T>;
  dateRange: I18nLanguages<T>;

  selectSite: I18nLanguages<T>;
  aggregatedBy: I18nLanguages<T>;

  // Date presets 
  today: I18nLanguages<T>;
  yesterday: I18nLanguages<T>;
  last7Days: I18nLanguages<T>;
  last30Days: I18nLanguages<T>;
  custom: I18nLanguages<T>;
  thisWeek: I18nLanguages<T>;
  thisMonth: I18nLanguages<T>;
  earlier: I18nLanguages<T>;

  // Report table header
  powerReport: I18nLanguages<T>;
  faultHistoryReport: I18nLanguages<T>;
  sessionHistoryReport: I18nLanguages<T>;
  occupancyReport: I18nLanguages<T>;
  sessionIdleReport: I18nLanguages<T>;

  // Report table summary
  by: I18nLanguages<T>;
  generated: I18nLanguages<T>;

  // Report table column headers
  start: I18nLanguages<T>;
  end: I18nLanguages<T>;

  date: I18nLanguages<T>;
  time: I18nLanguages<T>;

  avgPower: I18nLanguages<T>;

  cost: I18nLanguages<T>;
  rate: I18nLanguages<T>;


  year: I18nLanguages<T>;
  month: I18nLanguages<T>;
  week: I18nLanguages<T>;
  day: I18nLanguages<T>;
  hour: I18nLanguages<T>;
  minute: I18nLanguages;

  years: I18nLanguages<T>;
  months: I18nLanguages<T>;
  weeks: I18nLanguages<T>;
  days: I18nLanguages<T>;
  hours: I18nLanguages<T>;
  minutes: I18nLanguages;

  yearsAgo: I18nLanguages<T>;
  monthsAgo: I18nLanguages<T>;
  weeksAgo: I18nLanguages<T>;
  daysAgo: I18nLanguages<T>;
  hoursAgo: I18nLanguages<T>;
  minutesAgo: I18nLanguages;

  yearAgo: I18nLanguages<T>;
  monthAgo: I18nLanguages<T>;
  weekAgo: I18nLanguages<T>;
  dayAgo: I18nLanguages<T>;
  hourAgo: I18nLanguages<T>;
  minuteAgo: I18nLanguages;

  justNow: I18nLanguages;

  error: I18nLanguages<T>;
  info: I18nLanguages<T>;

  duration: I18nLanguages<T>;
  energy: I18nLanguages<T>;

  occupiedDevices: I18nLanguages<T>;

  sessionId: I18nLanguages<T>;

  startMethod: I18nLanguages<T>;
  stopMethod: I18nLanguages<T>;

  // Access Control
  accessCard: I18nLanguages<T>;
  accessCards: I18nLanguages<T>;

  manageAccessCards: I18nLanguages<T>;

  authorizeAccessCard: I18nLanguages<T>;
  disableAccessCard: I18nLanguages<T>;

  deviceSelection: I18nLanguages<T>;

  selectADevice: I18nLanguages<T>;

  selectTheDeviceDesc: I18nLanguages<T>;
  cardRead: I18nLanguages<T>
  tapAccessCardDesc: I18nLanguages<T>;

  searchCards: I18nLanguages<T>;
  allGroups: I18nLanguages<T>;
  createGroup: I18nLanguages<T>;
  groupListEmpty: I18nLanguages<T>;
  group: I18nLanguages<T>;

  groupName: I18nLanguages<T>;

  idName: I18nLanguages<T>;
  dateAdded: I18nLanguages<T>;
  noAccessCardsHeader: I18nLanguages<T>;
  noAccessCardsText: I18nLanguages<T>;

  authorized: I18nLanguages<T>;
  disabled: I18nLanguages<T>;


  // Tickets 
  support: I18nLanguages<T>;
  manageSupportTickets: I18nLanguages<T>;
  createTicket: I18nLanguages<T>;
  allTickets: I18nLanguages<T>;
  noSupportTickets: I18nLanguages<T>;
  searchTickets: I18nLanguages<T>;
  subject: I18nLanguages<T>;
  station: I18nLanguages<T>;
  status: I18nLanguages<T>;
  assignee: I18nLanguages<T>;

  // Users
  users: I18nLanguages<T>;
  manageUsersAndNotificationSettings: I18nLanguages<T>;
  addUser: I18nLanguages<T>;
  account: I18nLanguages<T>;
  userNotifications: I18nLanguages<T>;
  firstName: I18nLanguages<T>;
  lastName: I18nLanguages<T>;
  emailAddress: I18nLanguages<T>;
  role: I18nLanguages<T>;
  removeUser: I18nLanguages<T>;
  allowEmailNotifications: I18nLanguages<T>;
  allowBrowserNotifications: I18nLanguages<T>;
  getNotificationsForTheseEvents: I18nLanguages<T>;
  getNotificationsFromTheseSites: I18nLanguages<T>;
  emailsWillBeSentTo: I18nLanguages<T>;
  receiveAppNotifications: I18nLanguages<T>;

  // Notifications
  notifications: I18nLanguages<T>;
  unread: I18nLanguages<T>;
  markAsRead: I18nLanguages<T>;
  markAsUnread: I18nLanguages<T>;
  markAllAsRead: I18nLanguages<T>;

  // Pricing
  managePricingPlans: I18nLanguages<T>;
  addPricingPlan: I18nLanguages<T>;
  staticPricingPlans: I18nLanguages<T>;
  dynamicPricingPlans: I18nLanguages<T>;
  pricingName: I18nLanguages<T>;
  pricingTypePricing_level2: I18nLanguages<T>;
  pricingTypePricing_dcfc: I18nLanguages<T>;
  pricingTypePricing_ultrafast: I18nLanguages<T>;
  pricingType_level2: I18nLanguages<T>;
  pricingType_dcfc: I18nLanguages<T>;
  pricingType_ultrafast: I18nLanguages<T>;

  // Payment / API Sheet
  APIKeys: I18nLanguages<T>;
  integrations: I18nLanguages<T>;
  APIDesc: I18nLanguages<T>;
  generateAPIKey: I18nLanguages<T>;

  // Device Status
  deviceStatus_Available: I18nLanguages<T>;
  deviceStatus_Preparing: I18nLanguages<T>;
  deviceStatus_Charging: I18nLanguages<T>;
  deviceStatus_SuspendedEV: I18nLanguages<T>;
  deviceStatus_SuspendedEVSE: I18nLanguages<T>;
  deviceStatus_Finishing: I18nLanguages<T>;
  deviceStatus_Reserved: I18nLanguages<T>;
  deviceStatus_Faulted: I18nLanguages<T>;
  deviceStatus_Unavailable: I18nLanguages<T>;
  deviceStatus_Unknown: I18nLanguages<T>;
  deviceStatus_Idle: I18nLanguages<T>;

  //Navigation Sheets
  dashboard: I18nLanguages;
  pricing: I18nLanguages;
  pricingPlans: I18nLanguages;
  payment: I18nLanguages;
  accessControl: I18nLanguages;

  // Nouns
  device: I18nLanguages;
  site: I18nLanguages;
  port: I18nLanguages;
  ports: I18nLanguages;
  charger: I18nLanguages;

  cancel: I18nLanguages<T>;
  back: I18nLanguages<T>;
  next: I18nLanguages<T>;
  continue: I18nLanguages<T>;
  done: I18nLanguages<T>;
  finish: I18nLanguages<T>;
  confirm: I18nLanguages<T>;
  authorize: I18nLanguages<T>;
  disable: I18nLanguages<T>;
  authorizeMore: I18nLanguages<T>;

  // Titles
  userSettings: I18nLanguages;
  deleteUser: I18nLanguages;

  // Popup menus
  editSiteCommand: I18nLanguages;
  deleteSiteCommand: I18nLanguages;
  
  
  siteSettings: I18nLanguages;
  siteUpdated: I18nLanguages;
  siteUpdateFailed: I18nLanguages;
  deleteDevice: I18nLanguages;
  deleteStation: I18nLanguages;

  // Completion messages  
  copyDeviceSuccess: I18nLanguages;
  copyDeviceFail: I18nLanguages;
  deleteSiteSuccess: I18nLanguages;
  deleteSiteFail: I18nLanguages;
  deleteUserSuccess: I18nLanguages;
  deleteUserFail: I18nLanguages;
  deleteDeviceSuccess: I18nLanguages;
  deleteDeviceFail: I18nLanguages;

  // Day and month name translation
  january: I18nLanguages;
  february: I18nLanguages;
  march: I18nLanguages;
  april: I18nLanguages;
  may: I18nLanguages;
  june: I18nLanguages;
  july: I18nLanguages;
  august: I18nLanguages;
  september: I18nLanguages;
  october: I18nLanguages;
  november: I18nLanguages;
  december: I18nLanguages;

  // Beta / Coming soon 
  beta: I18nLanguages;
  comingSoon: I18nLanguages;

  saveChanges: I18nLanguages;
  signedInAs: I18nLanguages;

  // selection
  selectAll: I18nLanguages;
  unselectAll: I18nLanguages;

  // Ticket status
  open: I18nLanguages;
  pending: I18nLanguages;
  solved: I18nLanguages;
  closed: I18nLanguages;

    // Modals
    areYouSureDelete: I18nLanguages;

    // Edit Site Modal

    createSite: I18nLanguages;
    generalInfo: I18nLanguages;
    on: I18nLanguages;
    settings: I18nLanguages;
    siteName: I18nLanguages;
    country: I18nLanguages;
    province: I18nLanguages;
    state: I18nLanguages;
    city: I18nLanguages;
    address: I18nLanguages;
    postal: I18nLanguages;
    zip: I18nLanguages;
    popul8Url: I18nLanguages;
    notes: I18nLanguages;

    autostart: I18nLanguages;
    autostartDesc: I18nLanguages;

    accessControlGroups: I18nLanguages;
    accessControlGroupsDesc: I18nLanguages;

    integrationSettings: I18nLanguages;
    
    terminalSettings: I18nLanguages; 
    mobileSettings: I18nLanguages;
    
    uicTerminalDesc: I18nLanguages;
    honkMobileDesc: I18nLanguages;

    // Edit Device modal
    stationName: I18nLanguages;
    stationId: I18nLanguages;
    stationIdLabel: I18nLanguages;
    //chargerType: I18nLanguages;
    portName: I18nLanguages;
    type1: I18nLanguages;
    type2: I18nLanguages;

    // Edit Hub Modal
    hubName: I18nLanguages;
    hubId: I18nLanguages;

    // Pricing modal 

    addStaticPricingPlan: I18nLanguages;
    staticPricing: I18nLanguages;
    staticPricingDesc: I18nLanguages;

    dynamicPricing: I18nLanguages;
    dynamicPricingDesc: I18nLanguages;

    pricingPlanName: I18nLanguages;
    currency: I18nLanguages;
    unitsToChargeBy: I18nLanguages;

    level2PricingDesc: I18nLanguages;
    dcfcPricingDesc: I18nLanguages;
    ultrafastPricingDesc: I18nLanguages;

    timeOfUse: I18nLanguages;
    timeOfUseDesc: I18nLanguages;

    selectPricingType: I18nLanguages;
    selectTrigger :I18nLanguages;

    powerDemand: I18nLanguages;
    powerDemandDesc: I18nLanguages;
    ifDemandExceeds: I18nLanguages;

    sessionDuration: I18nLanguages;
    sessionDurationDesc: I18nLanguages;
    sessionDurationExceeds: I18nLanguages;
    sessionDurationPricingRules: I18nLanguages;

    defaultPricing: I18nLanguages;
    defaultPricingLabel: I18nLanguages;
    
    pricingRules: I18nLanguages;
    applyPricingPlan: I18nLanguages;
    addPricingRule: I18nLanguages;

    from: I18nLanguages;
    to: I18nLanguages;

    weekendsAndHolidays: I18nLanguages;
    allDaysOfTheWeek: I18nLanguages;
}



export interface I18nLanguages<T = string> {
  en: T;
  fr: T;
}

let currentLanguage: keyof I18nLanguages = 'en';

export let i18nLookup: I18nWord = {
  signIntoSwyched: {
    en: 'Sign in to Swyched',
    fr: 'Connectez-vous à Swyched'
  },
  signIn: {
    en: 'Sign In',
    fr: 'S\'identifier'
  },
  keepMeSignedIn: {
    en: 'Keep me signed in',
    fr: 'Gardez-moi connecté'
  },
  finishRegistering: {
    en: 'Finish Registering',
    fr: 'Terminer l\'enregistrement'
  },
  accountRecovery: {
    en: 'Account Recovery',
    fr: 'Récupération du compte'
  },
  verifyAccount: {
    en: 'Verify Account',
    fr: 'vérifier le compte'
  },
  codeSentTo: {
    en: 'A verification code has been sent to',
    fr: 'Un code de vérification a été envoyé à'
  },
  checkEmailForCode: {
    en: 'Please check your email and enter the verification code below.',
    fr: 'Veuillez vérifier votre e-mail et entrer le code de vérification ci-dessous.r'
  },
  createNewPassword: {
    en: 'Please create a new password for your account below',
    fr: 'Veuillez créer un nouveau mot de passe pour votre compte ci-dessous'
  },
  lostNeedReset: {
    en: 'If you\'ve lost your password or need to reset it, enter your email below and reset password.',
    fr: 'Si vous avez perdu votre mot de passe ou avez besoin de le réinitialiser, entrez votre email ci-dessous et réinitialisez le mot de passe.'
  },
  nameIsRequiredField: {
    en: 'Name is required',
    fr: 'Le nom est requis'
  },
  lastNameIsRequiredField: {
    en: 'Last Name is required',
    fr: 'Le nom de famille est requis'
  },
  validEmailRequired: {
    en: 'Valid email required',
    fr: 'Courriel valide requis'
  },
  verificationCode: {
    en: 'Verification Code',
    fr: 'Code de vérification'
  },
  verificationCodeRequired: {
    en: 'Verification code required',
    fr: 'Code de vérification requis'
  },
  password: {
    en: 'Password',
    fr: 'Passeport'
  },
  forgotPassword: {
    en: 'Forgot Password',
    fr: 'Mot de passe oublié'
  },
  validPasswordRequired: {
    en: 'Valid password required',
    fr: 'Mot de passe valide requis'
  },
  passwordPolicy: {
    en: 'Password must include an uppercase, a lowercase, a special character, a number, and a minimum length of 8 characters.',
    fr: 'Le mot de passe doit inclure une majuscule, une minuscule, un caractère spécial, un chiffre et une longueur minimale de 8 caractères.'
  },
  confirmPassword: {
    en: 'Confirm Password',
    fr: 'Confirmez le mot de passe'
  },
  passwordsMustMatch: {
    en: 'Passwords must match',
    fr: 'Les mots de passe doivent correspondre'
  },
  resendConfirmationCode: {
    en: 'Resend confirmation code',
    fr: 'Renvoyer le code de confirmation'
  },
  createAccount: {
    en: 'Create Account',
    fr: 'Créer un compte'
  },
  resetPassword: {
    en: 'Reset Password',
    fr: 'réinitialiser le mot de passe'
  },
  updatePassword: {
    en: 'Update Password',
    fr: 'Mettre à jour le mot de passe'
  },
  getNewVerificationCode: {
    en: 'Get new password reset verification code',
    fr: 'Obtenir un nouveau code de vérification de réinitialisation du mot de passe'
  },
  loginExistingAccount: {
    en: 'Log in with an existing account',
    fr: 'Connectez-vous avec un compte existant'
  },
  verifyingCredentials: {
    en: 'Verifying credentials',
    fr: 'Vérification des informations d\'identification'
  },
  contact: {
    en: 'Contact',
    fr: 'Contacter'
  },
  welcomeBack: {
    en: 'Welcome back',
    fr: 'Content de te revoir'
  },
  chargerType_ac: {
    en: 'Level-2 AC Charger',
    fr: 'Chargeur de niveau 2',
  },
  chargerType_dcfc: {
    en: 'DC Fast Charger',
    fr: 'Chargeur rapide CC'
  },
  myDashboard: {
    en: 'My Dashboard',
    fr: 'Mon tableau de bord'
  },
  portStatus: {
    en: 'Port Status',
    fr: 'État du port'
  },
  availabilityOverview: {
    en: 'Availability Overview',
    fr: 'Aperçu de la disponibilité'
  },
  totalPower: {
    en: 'Total Power',
    fr: 'Pouvoir total'
  },
  realtimePower: {
    en: 'Real-Time Power (kW)',
    fr: 'Puissance en temps réel'
  },
  avgSessionLength: {
    en: 'Average Session Length',
    fr: 'Durée moyenne des sessions'
  },
  averageOverLastSevenDays: {
    en: 'Averaged over last 7 days',
    fr: 'Moyenne sur les 7 derniers jours'
  },
  netEnergy: {
    en: 'Net Energy (kWh)',
    fr: 'Énergie Nette (kWh)'
  },
  overLastSevenDays: {
    en: 'Over last 7 days',
    fr: 'Au cours des 7 derniers jours'
  },
  
  // Nouns
  site: {
    en: 'Site',
    fr: 'Placer'
  },
  device: {
    en: 'Device',
    fr: 'Appareil'
  },
  port: {
    en: 'Port',
    fr: 'Port'
  },
  ports: {
    en: 'Ports',
    fr: 'Ports'
  },
  charger: {
    en: 'Charger',
    fr: 'Chargeur'
  },
  chargerType: {
    en: 'Charger Type',
    fr: 'Type de chargeur'
  },
  filterBy: {
    en: 'Filter by',
    fr: 'Filtrer par'
  },
  all: {
    en: 'All',
    fr: 'Tout'
  },
  clearFilter: {
    en: 'Clear Filter',
    fr: 'Effacer le filtre'
  },

  // Days of week
  monday: {
    en: 'Monday',
    fr: 'Lundi'
  },
  tuesday: {
    en: 'Tuesday',
    fr: 'Mardi'
  },
  wednesday: {
    en: 'Wednesday',
    fr: 'Mercredi'
  },
  thursday: {
    en: 'Thursday',
    fr: 'Jeudi'
  },
  friday: {
    en: 'Friday',
    fr: 'Vendredi'
  },
  saturday: {
    en: 'Saturday',
    fr: 'Samedi'
  },
  sunday: {
    en: 'Sunday',
    fr: 'Dimanche'
  },
  weekdays: {
    en: 'Weekdays',
    fr: 'Jours de la semaine'
  },
  weekends: {
    en: 'Weekends',
    fr: 'Fins de semaine'
  },
  holidays: {
    en: 'Holidays',
    fr: 'Vacances'
  },
  dashboard: {
    en: 'Dashboard',
    fr: 'Tableau de bord'
  },
  payment: {
    en: 'Payment',
    fr: 'Paiement'
  },
  pricing: {
    en: 'Pricing',
    fr: 'Tarification'
  },
  pricingPlans: {
    en: 'Pricing Plans',
    fr: 'Forfaits tarifaires'
  },
  accessControl: {
    en: 'Access Control',
    fr: 'Contrôle d\'accès'
  },
  devices: {
    en: 'Devices',
    fr: 'Dispositifs'
  },
  sites: {
    en: 'Sites',
    fr: 'Des sites'
  },
  emptySiteHeader: {
    en: 'Build your charging network',
    fr: 'Construisez votre réseau de recharge'
  },
  emptySiteText: {
    en: 'Click "Add Site" to get started',
    fr: 'Cliquez sur "Ajouter un site" pour commencer'
  },
  mySitesAndStations: {
    en: 'My sites and charging stations',
    fr: 'Mes sites et bornes de recharge'
  },
  addSite: {
    en: 'Add Site',
    fr: 'Ajouter un site'
  },
  addDevice: {
    en: 'Add Device',
    fr: 'Ajouter un appareil'
  },
  search: {
    en: 'Search',
    fr: 'Rechercher'
  },
  allSites: {
    en: 'All Sites',
    fr: 'Tous les sites'
  },
  chargingStation: {
    en: 'Charging Station',
    fr: 'Borne de recharge'
  },
  hub: {
    en: 'Hub',
    fr: 'Centre'
  },
  signOut: {
    en: 'Sign out',
    fr: 'Se déconnecter'
  },
  newOrganization: {
    en: 'New Organization',
    fr: 'Créer une organisation'
  },
  reporting: {
    en: 'Reporting',
    fr: 'Rapports'
  },
  reportType: {
    en: 'Report Type',
    fr: 'Type de rapport'
  },
  dateRange: {
    en: 'Date Range',
    fr: 'Plage de dates'
  },
  selectSite: {
    en: 'Select Site',
    fr: 'Sélectionnez un site'
  },
  aggregatedBy: {
    en: 'Aggregated By',
    fr: 'Agrégé par'
  },
  // Date presets 
  today: {
    en: 'Today',
    fr: 'Aujourd\'hui'
  },
  yesterday: {
    en: 'Yesterday',
    fr: 'Hier'
  },
  last7Days: {
    en: 'Last 7 days',
    fr: 'Les 7 derniers jours'
  },
  last30Days: {
    en: 'Last 30 days',
    fr: 'Les 7 derniers jours'
  },
  custom: {
    en: 'Custom',
    fr: 'Personnalisé'
  },
  thisWeek: {
    en: 'This Week',
    fr: 'Cette semaine'
  },
  thisMonth: {
    en: 'This Month',
    fr: 'Ce mois-ci'
  },
  earlier: {
    en: 'Earlier',
    fr: 'Plus tôt'
  },
  generateCustomReports: {
    en: 'Generate custom reports',
    fr: 'Générer des rapports personnalisés'
  },
  updateReport: {
    en: 'Update Report',
    fr: 'Mettre à jour le rapport'
  },
  emptyReportHeader: {
    en: 'Generate a Report',
    fr: 'Générer un rapport'
  },
  emptyReportText: {
    en: 'Click "Generate Report" to get started',
    fr: 'Cliquez sur "Générer un rapport" pour commencer'
  },
  generatingReportHeader: {
    en: 'Your report is in progress',
    fr: 'Votre rapport est en cours'
  },
  generateReport: {
    en: 'Generate Report',
    fr: 'Générer un rapport'
  },
  generatingReport: {
    en: 'Generating Report',
    fr: 'Génération de rapport'
  },  
  sessionHistory: {
    en: 'Session History',
    fr: 'Historique des sessions'
  },
  faultHistory: {
    en: 'Fault History',
    fr: 'Historique des défauts'
  },
  peakPower: {
    en: 'Peak Power',
    fr: 'Puissance de crête'
  },
  peakOccupancy: {
    en: 'Peak Occupancy',
    fr: 'Occupation maximale'
  },
  sessionIdle: {
    en: 'Session Idle',
    fr: 'Session inactive'
  },
  powerReport: {
    en: 'Power Report',
    fr: 'Session inactive'
  },
  sessionHistoryReport: {
    en: 'Session History Report',
    fr: 'Rapport d\'historique de session'
  },
  faultHistoryReport: {
    en: 'Fault History Report',
    fr: 'Rapport d\'historique des pannes'
  },
  occupancyReport: {
    en: 'Occupancy Report',
    fr: 'Rapport d\'occupation'
  },
  sessionIdleReport: {
    en: 'Session Idle Report',
    fr: 'Rapport d\'inactivité de session'
  },
  by: {
    en: 'By',
    fr: 'Par'
  },
  generated: {
    en: 'Generated',
    fr: 'Généré'
  },
  start: {
    en: 'Start',
    fr: 'Commencer'
  },
  end: {
    en: 'End',
    fr: 'Fin'
  },
  date: {
    en: 'Date',
    fr: 'Date'
  },
  time: {
    en: 'Time',
    fr: 'Temps'
  },
  avgPower: {
    en: 'Avg power',
    fr: 'Puissance moyenne'
  },
  rate: {
    en: 'Rate',
    fr: 'Fréquence'
  },
  cost: {
    en: 'Cost',
    fr: 'Coût'
  },
  year: {
    en: 'Year',
    fr: 'An'
  },
  yearAgo: {
    en: 'year ago',
    fr: 'il y\'a un an'
  },
  years: {
    en: 'Years',
    fr: 'Ans'
  },
  yearsAgo: {
    en: 'years ago',
    fr: 'il y a des années'
  },
  month: {
    en: 'Month',
    fr: 'Mois'
  },
  monthAgo: {
    en: 'month ago',
    fr: 'il y a un mois'
  },
  months: {
    en: 'Months',
    fr: 'Mois'
  },
  monthsAgo: {
    en: 'months ago',
    fr: 'il y a des mois'
  },
  week: {
    en: 'Week',
    fr: 'La semaine'
  },
  weekAgo: {
    en: 'week ago',
    fr: 'il y a une semaine'
  },
  weeks: {
    en: 'Weeks',
    fr: 'Semaines'
  },
  weeksAgo: {
    en: 'weeks ago',
    fr: 'il y a des semaines'
  },
  day: {
    en: 'Day',
    fr: 'Jour'
  },
  dayAgo: {
    en: 'day ago',
    fr: 'il y a un jour'
  },
  days: {
    en: 'Day',
    fr: 'Journées'
  },
  daysAgo: {
    en: 'days ago',
    fr: 'il y a quelques jours'
  },
  hour: {
    en: 'Hour',
    fr: 'Heure'
  },
  hourAgo: {
    en: 'hour ago',
    fr: 'Une heure avant'
  },
  hours: {
    en: 'Hours',
    fr: 'Heures'
  },
  hoursAgo: {
    en: 'hours ago',
    fr: 'il y a des heures'
  },
  minute: {
    en: 'Minute',
    fr: 'Minute'
  },
  minuteAgo: {
    en: 'minute ago',
    fr: 'il y a une minute'
  },
  minutes: {
    en: 'Minutes',
    fr: 'Minutes'
  },
  minutesAgo: {
    en: 'minutes ago',
    fr: 'il y a quelques minutes'
  },
  justNow: {
    en: 'Just now',
    fr: 'Juste maintenant'
  },
  error: {
    en: 'Error',
    fr: 'Erreur'
  },
  info: {
    en: 'Info',
    fr: 'Info'
  },
  duration: {
    en: 'Duration',
    fr: 'Durée'
  },
  energy: {
    en: 'Energy',
    fr: 'Énergie'
  },
  occupiedDevices: {
    en: 'OccupiedDevices',
    fr: 'Appareils occupés'
  },
  startMethod: {
    en: 'Start Method',
    fr: 'Durée'
  },
  stopMethod: {
    en: 'Stop Method',
    fr: 'Méthode d\'arrêt'
  },
  sessionId: {
    en: 'Session ID',
    fr: 'ID de session'
  },
  accessCard: {
    en: '',
    fr: ''
  },
  accessCards: {
    en: 'Access Cards',
    fr: 'Cartes d\'accès'
  },
  manageAccessCards: {
    en: 'Manage the RFID access cards used to start charging sessions. To authorize a new access card, start by clicking Authorize Access Card.',
    fr: 'Gérez les cartes d\'accès RFID utilisées pour démarrer les sessions de recharge. Pour autoriser une nouvelle carte d\'accès, commencez par cliquer sur Autoriser la carte d\'accès.'
  },
  authorizeAccessCard: {
    en: 'Authorize Access Card',
    fr: 'Autoriser la carte d\'accès'
  },
  deviceSelection: {
    en: 'Device Selection',
    fr: ''
  },
  selectADevice: {
    en: 'Select a Device',
    fr: ''
  },
  selectTheDeviceDesc: {
    en: 'Select the device where you would like to scan your RFID card(s)',
    fr: ''
  }, 
  cardRead: {
    en: 'Card Read',
    fr: ''
  },
  tapAccessCardDesc: {
    en: 'Tap your access card on the card reader of station',
    fr: ''
  },
  searchCards: {
    en: 'Search Cards',
    fr: 'Cartes de recherche'
  },
  allGroups: {
    en: 'All Groups',
    fr: 'Tous les groupes'
  },
  createGroup: {
    en: 'Create Group',
    fr: 'Créer un groupe'
  },
  groupListEmpty: {
    en: 'The group list is empty',
    fr: 'La liste des groupes est vide'
  },
  group: {
    en: 'Group',
    fr: 'Grouper'
  },
  groupName: {
    en: 'Group Name',
    fr: 'Group Name'
  },
  idName: {
    en: 'ID / Name',
    fr: 'Identifiant / Nom'
  },
  dateAdded: {
    en: 'Date Added',
    fr: 'date ajoutée'
  },
  noAccessCardsHeader: {
    en: 'You have no access cards',
    fr: 'Vous n\'avez pas de carte d\'accès'
  },
  noAccessCardsText: {
    en: 'Click "Authorize Access Card" to get started',
    fr: 'Cliquez sur "Autoriser la carte d\'accès" pour commencer'
  },
  authorized: {
    en: 'Authorized',
    fr: 'Autorisé'
  },
  disabled: {
    en: 'Disabled',
    fr: 'Désactivé'
  },
  disableAccessCard: {
    en: 'Disable access card',
    fr: 'Désactiver la carte d\'accès'
  },
  support: {
    en: 'Support',
    fr: 'Soutien'
  },
  manageSupportTickets: {
    en: 'Manage your support tickets',
    fr: 'Gérez vos tickets d\'assistance'
  },
  createTicket: {
    en: 'Create Ticket',
    fr: 'Créer un billet'
  },
  allTickets: {
    en: 'All Tickets',
    fr: 'Tous les billets'
  },
  noSupportTickets: {
    en: 'No Support Tickets',
    fr: 'Aucun ticket d\'assistance'
  },
  searchTickets: {
    en: 'Search Tickets',
    fr: 'Rechercher des billets'
  },
  station: {
    en: 'Station',
    fr: 'Station'
  },
  subject: {
    en: 'Subject',
    fr: 'Matière'
  },
  status: {
    en: 'Status',
    fr: 'Statut'
  },
  assignee: {
    en: 'Assignee',
    fr: 'Cessionnaire'
  },
  users: {
    en: 'Users',
    fr: 'Utilisateurs'
  },
  manageUsersAndNotificationSettings: {
    en: 'Manage users and notification settings',
    fr: 'Gérer les utilisateurs et les paramètres de notification'
  },
  addUser: {
    en: 'Add User',
    fr: 'Ajouter un utilisateur'
  },
  account: {
    en: 'Account',
    fr: 'Compte'
  },
  userNotifications: {
    en: 'User Notifications',
    fr: 'Notifications utilisateur'
  },
  firstName: {
    en: 'First Name',
    fr: 'Prénom'
  },
  lastName: {
    en: 'Last Name',
    fr: 'Nom de famille'
  },
  emailAddress: {
    en: 'Email',
    fr: 'Adresse e-mail'
  },
  role: {
    en: 'Role',
    fr: 'Rôle'
  },
  removeUser: {
    en: 'Remove user',
    fr: 'Supprimer utilisateur'
  },
  allowEmailNotifications: {
    en: 'Allow email notifications',
    fr: 'Autoriser les notifications par e-mail'
  },
  emailsWillBeSentTo: {
    en: 'Emails will be sent to',
    fr: 'Les e-mails seront envoyés à'
  },
  allowBrowserNotifications: {
    en: 'Allow browser notifications',
    fr: 'Autoriser les notifications du navigateur'
  },
  receiveAppNotifications: {
    en: 'Receive notifications on your computer, even when the app is not open',
    fr: 'Recevez des notifications sur votre ordinateur, même lorsque l\'application n\'est pas ouverte'
  },
  getNotificationsForTheseEvents: {
    en: 'Get notifications for these events',
    fr: 'Recevez des notifications pour ces événements'
  },
  getNotificationsFromTheseSites: {
    en: 'Get notifications from these sites',
    fr: 'Recevoir des notifications de ces sites'
  },
  notifications: {
    en: 'Notifications',
    fr: 'Avis'
  },
  unread: {
    en: 'Unread',
    fr: 'Non lu'
  },
  markAsRead: {
    en: 'Mark as read',
    fr: 'Marquer comme lu'
  },
  markAsUnread: {
    en: 'Mark as unread',
    fr: 'marquer comme non lu'
  },
  markAllAsRead: {
    en: 'Mark All as Read',
    fr: 'Tout marquer comme lu'
  },
  integrations: {
    en: 'Integrations',
    fr: 'Intégrations'
  },
  APIKeys: {
    en: 'API Keys',
    fr: 'Clés API'
  },
  APIDesc: {
    en: 'These secret keys grant external apps access to our API. See more at',
    fr: 'These secret keys grant external apps access to our API. See more at'
  },
  generateAPIKey: {
    en: 'Generate API Key',
    fr: 'Générer une clé API'
  },
  managePricingPlans: {
    en: 'Manage your static and dynamic pricing plans to control the price of EV charging',
    fr: 'Gérez vos plans de tarification statiques et dynamiques pour contrôler le prix de la recharge des véhicules électriques'
  },
  addPricingPlan: {
    en: 'Add Pricing Plan',
    fr: 'Ajouter un forfait'
  },
  staticPricingPlans: {
    en: 'Static Pricing Plans',
    fr: 'Plans de tarification statiques'
  },
  dynamicPricingPlans: {
    en: 'Dynamic Pricing Plans',
    fr: 'Plans de tarification dynamiques'
  },
  pricingName: {
    en: 'Pricing Name',
    fr: 'Nom du prix'
  },

  pricingTypePricing_level2: {
    en: 'Level-2 Pricing',
    fr: 'Tarification de niveau 2'
  },
  pricingTypePricing_dcfc: {
    en: 'DCFC Pricing',
    fr: 'Tarification DCFC'
  },
  pricingTypePricing_ultrafast: {
    en: 'Ultrafast Pricing',
    fr: 'Tarification ultra-rapide'
  },
  pricingType_level2: {
    en: 'Level-2',
    fr: 'niveau 2'
  },
  pricingType_dcfc: {
    en: 'DCFC',
    fr: 'DCFC'
  },
  pricingType_ultrafast: {
    en: 'Ultrafast',
    fr: 'ultra-rapide'
  },

  // expression key, 'deviceStatus_' + [keyof DeviceStatus]
  deviceStatus_Available: {
    en: 'Available',
    fr: 'Disponible'
  },
  deviceStatus_Preparing: {
    en: 'Preparing charge',
    fr: 'Préparer'
  },
  deviceStatus_Charging: {
    en: 'Charging',
    fr: 'Charger'
  },
  deviceStatus_SuspendedEV: {
    en: 'Suspended EV',
    fr: 'Suspendu EV'
  },
  deviceStatus_SuspendedEVSE: {
    en: 'Suspended station',
    fr: 'SuspenduEVSE'
  },
  deviceStatus_Finishing: {
    en: 'Finishing charge',
    fr: 'Finition'
  },
  deviceStatus_Reserved: {
    en: 'Reserved',
    fr: 'Réservé'
  },
  deviceStatus_Faulted: {
    en: 'Faulted',
    fr: 'En panne'
  },
  deviceStatus_Unavailable: {
    en: 'Unavailable',
    fr: 'Indisponible'
  },
  deviceStatus_Unknown: {
    en: 'Unknown',
    fr: 'Inconnu'
  },
  deviceStatus_Idle: {
    en: 'Idle',
    fr: 'Inactif'
  },

  // Titles
  userSettings: {
    en: 'User Settings',
    fr: 'Paramètres du Utilisateur'
  },

  deleteUser: {
    en: 'Delete User',
    fr: 'Supprimer utilisateur'
  },

  deleteUserSuccess: {
    en: 'User "{0}" deleted successfully',
    fr: 'Utilisateur « {0} » supprimé avec succès'
  },

  siteUpdated: {
    en: 'Site "{0}" updated',
    fr: 'Site « {0} » mis à jour'
  },
  siteUpdateFailed: {
    en: 'Site update failed: {1}',
    fr: 'Échec de la mise à jour du site: {1}'
  },
  copyDeviceFail: {
    en: 'Error copying device: {0}',
    fr: ''
  },
  copyDeviceSuccess: {
    en: 'Device "{0} copied successfully',
    fr: ''
  },
  deleteSiteSuccess: {
    en: 'Site deleted successfully',
    fr: 'Site supprimé avec succès'
  },
  deleteSiteFail: {
    en: 'Delete site failed: {0}',
    fr: 'Échec de la suppression du site: {0}'
  },
  deleteDeviceSuccess: {
    en: '"{0}" device deleted successfully',
    fr: 'Appareil « {0} » supprimé avec succès'
  },
  deleteDeviceFail: {
    en: 'Delete device failed: {0}',
    fr: 'Échec de la suppression de l\'appareil'
  },  
  deleteStation: {
    en: 'Delete Station',
    fr: 'Supprimer la station'
  },
  deleteDevice: {
    en: 'Delete Device',
    fr: ''
  },

  siteSettings: {
    en: 'Site Settings',
    fr: 'Paramètres du site'
  },

  // Popup menus
  editSiteCommand: {
    en: 'Edit Site',
    fr: 'Modifier le Site'
  },
  deleteSiteCommand: {
    en: 'Delete Site',
    fr: 'Supprimer le Site'
  },
  areYouSureDelete: {
    en: 'Are you sure you want to delete "{0}"?',
    fr: 'Êtes-vous sûr de vouloir supprimer « {0} »?'
  },
  deleteUserFail: {
    en: 'Error deleting {0}: {1}',
    fr: 'Erreur lors de la suppression du {0}: {1}'
  },
  editStation: {
    en: 'Edit Station',
    fr: 'Modifier la station'
  },
  addStation: {
    en: 'Add Charging Station',
    fr: 'Ajouter une station'
  },
  addPort: {
    en: 'Add Port',
    fr: 'Ajouter un port'
  },
  convertToHub: {
    en: 'Convert to hub',
    fr: 'Convertir en hub'
  },
  convertToStation: {
    en: 'Convert to station',
    fr: 'Convertir en station'
  },
  cancel: {
    en: 'Cancel',
    fr: 'Annuler'
  },
  back: {
    en: 'Back',
    fr: 'Précédent'
  },
  next: {
    en: 'Next',
    fr: 'Prochain'
  },
  continue: {
    en: 'Continue',
    fr: 'Continuer'
  },
  done: {
    en: 'Done',
    fr: 'Fait'
  },
  finish: {
    en: 'Finish',
    fr: 'Finir'
  },
  confirm: {
    en: 'Confirm',
    fr: 'Confirmer'
  },
  authorize: {
    en: 'Authorize',
    fr: 'Autoriser'
  },
  disable: {
    en: 'Disable',
    fr: 'Désactiver'
  },
  authorizeMore: {
    en: 'Authorize more',
    fr: 'Autoriser plus'
  },

  startSession: {
    en: 'Start session',
    fr: 'Démarrer la session'
  },
  stopSession: {
    en: 'Stop session',
    fr: 'Arrêter la session'
  },
  deleteHub: {
    en: 'Delete hub',
    fr: 'Supprimer le hub'
  },
  restartDevice: {
    en: 'Restart device',
    fr: 'Redémarrer l\'appareil'
  },
  editSettings: {
    en: 'Edit Settings',
    fr: 'Modifier les paramètres'
  },
  editAccessControlGroup: {
    en: 'Edit access control group',
    fr: 'Modifier le groupe de contrôle d\'accès'
  },
  createAccessControlGroup: {
    en: 'Create access control group',
    fr: 'Créer un groupe de contrôle d\'accès'
  },
  editAccessCard: {
    en: 'Edit access card',
    fr: 'modifier la carte d\'accès'
  },
  viewUsage: {
    en: 'View usage',
    fr: 'afficher l\'utilisation'
  },
  remove: {
    en: 'Remove',
    fr: 'Enlever'
  },
  deleteAccessCard: {
    en: 'Delete access card',
    fr: 'Supprimer la carte d\'accès'
  },
  editGroup: {
    en: 'Edit group',
    fr: 'Modifier le groupe'
  },
  deleteGroup: {
    en: 'Delete group',
    fr: 'supprimer le groupe'
  },
  editUser: {
    en: 'Edit user',
    fr: 'Modifier l\'utilisateur'
  },
  import: {
    en: 'Import',
    fr: 'Importer'
  },
  select: {
    en: 'Select',
    fr: 'Sélectionner'
  },
  january: {
    en: 'January',
    fr: ''
  },
  february: {
    en: 'February',
    fr: ''
  },
  march: {
    en: 'March',
    fr: ''
  },
  april: {
    en: 'April',
    fr: ''
  },
  may: {
    en: 'May',
    fr: ''
  },
  june: {
    en: 'June',
    fr: ''
  },
  july: {
    en: 'July',
    fr: ''
  },
  august: {
    en: 'August',
    fr: ''
  },
  september: {
    en: 'September',
    fr: ''
  },
  october: {
    en: 'October',
    fr: ''
  },
  november: {
    en: 'November',
    fr: ''
  },
  december: {
    en: 'December',
    fr: ''
  },
  beta: {
    en: 'beta',
    fr: 'Bêta'
  },
  comingSoon: {
    en: 'Coming Soon',
    fr: 'À venir'
  },
  editChargingStation: {
    en: 'Edit Charging Station',
    fr: 'Modifier la borne de recharge'
  },
  editHub: {
    en: 'Edit Hub',
    fr: 'Modifier le hub'
  },
  editHubDevice: {
    en: 'Edit Hub Device',
    fr: 'Modifier le périphérique concentrateur'
  },
  addChargingStation: {
    en: 'Add Charging Station',
    fr: 'Ajouter une borne de recharge'
  },
  saveChanges: {
    en: 'Save Changes',
    fr: 'Sauvegarder les modifications'
  },
  signedInAs: {
    en: 'Signed in as',
    fr: 'Connecté en tant que'
  },
  selectAll: {
    en: 'Select All',
    fr: 'Tout sélectionner'
  },  
  unselectAll: {
    en: 'Unselect All',
    fr: 'Tout déselectionner'
  },
  // Ticket status
  open: {
    en: 'Open',
    fr: 'Ouvert'
  }, 
  pending: {
    en: 'Pending',
    fr: 'En attente'
  }, 
  solved: {
    en: 'Solved',
    fr: 'Résolu'
  }, 
  closed: {
    en: 'Closed',
    fr: 'Fermé'
  },
  createSite: {
    en: '',
    fr: ''
  },
  generalInfo: {
    en: '',
    fr: ''
  },
  on: {
    en: '',
    fr: ''
  },
  settings: {
    en: '',
    fr: ''
  },
  siteName: {
    en: '',
    fr: ''
  },
  country: {
    en: '',
    fr: ''
  },
  province: {
    en: '',
    fr: ''
  },
  state: {
    en: '',
    fr: ''
  },
  city: {
    en: '',
    fr: ''
  },
  address: {
    en: '',
    fr: ''
  },
  postal: {
    en: '',
    fr: ''
  },
  zip: {
    en: '',
    fr: ''
  },
  popul8Url: {
    en: '',
    fr: ''
  },
  notes: {
    en: '',
    fr: ''
  },
  autostart: {
    en: '',
    fr: ''
  },
  autostartDesc: {
    en: '',
    fr: ''
  },
  accessControlGroups: {
    en: '',
    fr: ''
  },
  accessControlGroupsDesc: {
    en: '',
    fr: ''
  },
  integrationSettings: {
    en: '',
    fr: ''
  },
  terminalSettings: {
    en: '',
    fr: ''
  },
  mobileSettings: {
    en: '',
    fr: ''
  },
  uicTerminalDesc: {
    en: '',
    fr: ''
  },
  honkMobileDesc: {
    en: '',
    fr: ''
  },
  stationName: {
    en: '',
    fr: ''
  },
  stationId: {
    en: '',
    fr: ''
  },
  stationIdLabel: {
    en: '',
    fr: ''
  },
  portName: {
    en: '',
    fr: ''
  },
  type1: {
    en: '',
    fr: ''
  },
  type2: {
    en: '',
    fr: ''
  },
  hubName: {
    en: '',
    fr: ''
  },
  hubId: {
    en: '',
    fr: ''
  },
  addStaticPricingPlan: {
    en: '',
    fr: ''
  },
  staticPricing: {
    en: '',
    fr: ''
  },
  staticPricingDesc: {
    en: '',
    fr: ''
  },
  dynamicPricing: {
    en: '',
    fr: ''
  },
  dynamicPricingDesc: {
    en: '',
    fr: ''
  },
  pricingPlanName: {
    en: '',
    fr: ''
  },
  currency: {
    en: '',
    fr: ''
  },
  unitsToChargeBy: {
    en: '',
    fr: ''
  },
  level2PricingDesc: {
    en: '',
    fr: ''
  },
  dcfcPricingDesc: {
    en: '',
    fr: ''
  },
  ultrafastPricingDesc: {
    en: '',
    fr: ''
  },
  timeOfUse: {
    en: '',
    fr: ''
  },
  timeOfUseDesc: {
    en: '',
    fr: ''
  },
  selectPricingType: {
    en: '',
    fr: ''
  },
  selectTrigger: {
    en: '',
    fr: ''
  },
  powerDemand: {
    en: '',
    fr: ''
  },
  powerDemandDesc: {
    en: '',
    fr: ''
  },
  ifDemandExceeds: {
    en: '',
    fr: ''
  },
  sessionDuration: {
    en: '',
    fr: ''
  },
  sessionDurationDesc: {
    en: '',
    fr: ''
  },
  sessionDurationExceeds: {
    en: '',
    fr: ''
  },
  sessionDurationPricingRules: {
    en: '',
    fr: ''
  },
  defaultPricing: {
    en: '',
    fr: ''
  },
  defaultPricingLabel: {
    en: '',
    fr: ''
  },
  pricingRules: {
    en: '',
    fr: ''
  },
  applyPricingPlan: {
    en: '',
    fr: ''
  },
  addPricingRule: {
    en: '',
    fr: ''
  },
  from: {
    en: '',
    fr: ''
  },
  to: {
    en: '',
    fr: ''
  },
  weekendsAndHolidays: {
    en: '',
    fr: ''
  },
  allDaysOfTheWeek: {
    en: '',
    fr: ''
  },

};

function utf16CharsToStrings(utf16: string): string[] {
  let result: string[] = [];
  for (let i = 0, e = utf16.length; i < e; ++i) {
    let surrogate = utf16.charCodeAt(i);
    if (surrogate >= 0xD800 && surrogate < 0xDC00) {
      result.push(utf16.substring(i, i + 2));
      ++i;
    } else {
      result.push(utf16[i]);
    }
  }
  return result;
}

// Make it !0 to enable, or !!0 to disable
// Don't use this to disable pseudoloc, see nearby below
const debug_xl8 = !0 && isDeveloper();

const sharedDigits = '𝟘𝟙𝟚𝟛𝟜𝟝𝟞𝟟𝟠𝟡';
const hideousDigits = '𐒠➊❷❸❹❺❻❼❽❾';

const ent = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 
  'abcdefghijklmnopqrstuvwxyz' + 
  '01234567890';

const noticiblePL = utf16CharsToStrings(
  'ΛβϾÐεϝƓĦȊĴϏĹḾИ0ρႳ℟ṦϮǕṼϢẌϒŽ' + 
  'åḅçḑəḟğḩȉǰǩĺṁñôṕգṝṧţûṽẘẍẙẑ' + 
  hideousDigits);

const oldEngLightPL = utf16CharsToStrings(
  '𝔄𝔅ℭ𝔇𝔈𝔉𝔊ℌℑ𝔍𝔎𝔏𝔐𝔑𝔒𝔓𝔔ℜ𝔖𝔗𝔘𝔙𝔚𝔛𝔜ℨ' + 
  '𝔞𝔟𝔠𝔡𝔢𝔣𝔤𝔥𝔦𝔧𝔨𝔩𝔪𝔫𝔬𝔭𝔮𝔯𝔰𝔱𝔲𝔳𝔴𝔵𝔶𝔷' + 
  sharedDigits);

const oldEngPL = utf16CharsToStrings(
  '𝕬𝕭𝕮𝕯𝕰𝕱𝕲𝕳𝕴𝕵𝕶𝕷𝕸𝕹𝕺𝕻𝕼𝕽𝕾𝕿𝖀𝖁𝖂𝖃𝖄𝖅' + 
  '𝖆𝖇𝖈𝖉𝖊𝖋𝖌𝖍𝖎𝖏𝖐𝖑𝖒𝖓𝖔𝖕𝖖𝖗𝖘𝖙𝖚𝖛𝖜𝖝𝖞𝖟' + 
  sharedDigits);

const tilesPL = utf16CharsToStrings(
  '🅰🅱🅲🅳🅴🅵🅶🅷🅸🅹🅺🅻🅼🅽🅾🅿🆀🆁🆂🆃🆄🆅🆆🆇🆈🆉' +
  '🄰🄱🄲🄳🄴🄵🄶🄷🄸🄹🄺🄻🄼🄽🄾🄿🅀🅁🅂🅃🅄🅅🅆🅇🅈🅉' + 
  sharedDigits);

const caligPL = utf16CharsToStrings(
  '𝓐𝓑𝓒𝓓𝓔𝓕𝓖𝓗𝓘𝓙𝓚𝓛𝓜𝓝𝓞𝓟𝓠𝓡𝓢𝓣𝓤𝓥𝓦𝓧𝓨𝓩' + 
  '𝓪𝓫𝓬𝓭𝓮𝓯𝓰𝓱𝓲𝓳𝓴𝓵𝓶𝓷𝓸𝓹𝓺𝓻𝓼𝓽𝓾𝓿𝔀𝔁𝔂𝔃' + 
  sharedDigits);

const weddingPL = utf16CharsToStrings(
  '𝒜𝐵𝒞𝒟𝐸𝐹𝒢𝐻𝐼𝒥𝒦𝐿𝑀𝒩𝒪𝒫𝒬𝑅𝒮𝒯𝒰𝒱𝒲𝒳𝒴𝒵' + 
  '𝒶𝒷𝒸𝒹𝑒𝒻𝑔𝒽𝒾𝒿𝓀𝓁𝓂𝓃𝑜𝓅𝓆𝓇𝓈𝓉𝓊𝓋𝓌𝓍𝓎𝓏' + 
  sharedDigits);

const circlesPL = utf16CharsToStrings(
  'ⒶⒷⒸⒹⒺⒻⒼⒽⒾⒿⓀⓁⓂⓃⓄⓅⓆⓇⓈⓉⓊⓋⓌⓍⓎⓏ' + 
  'ⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ' + 
  sharedDigits);

const verticalPL = utf16CharsToStrings(
  '𝔸𝔹ℂ𝔻𝔼𝔽𝔾ℍ𝕀𝕁𝕂𝕃𝕄ℕ𝕆ℙℚℝ𝕊𝕋𝕌𝕍𝕎𝕏𝕐ℤ' + 
  '𝕒𝕓𝕔𝕕𝕖𝕗𝕘𝕙𝕚𝕛𝕜𝕝𝕞𝕟𝕠𝕡𝕢𝕣𝕤𝕥𝕦𝕧𝕨𝕩𝕪𝕫' + 
  sharedDigits);

const spaciousPL = utf16CharsToStrings(
  'ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ' + 
  'ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ' +
  '０１２３４５６７８９');

// Driving you nuts? Run this in your console:
//  localStorage.setItem('pseudolocStyle', 'disable');
// Want it back?
//  localStorage.setItem('pseudolocStyle', 'oldEnglish');
// or one of the names checked below:

const pstPref = localStorage.getItem('pseudolocStyle');
const pst = (pstPref === 'default' || !pstPref)
  ? noticiblePL
  : pstPref === 'oldEnglish'
  ? oldEngLightPL
  : pstPref === 'oldEnglishDark'
  ? oldEngPL
  : pstPref === 'calligraphy'
  ? caligPL
  : pstPref === 'wedding'
  ? weddingPL
  : pstPref === 'tiles'
  ? tilesPL
  : pstPref === 'circles'
  ? circlesPL
  : pstPref === 'vertical'
  ? verticalPL
  : pstPref === 'spacious'
  ? spaciousPL
  : ent;

// Make it clear what text is localized, in English
export function pseudoLoc(en: string): string {
  let result = '';
  for (let i = 0; i < en.length; ++i) {
      let c = en.charAt(i);
      let o = ent.indexOf(c);
      if (o >= 0) {
          result += pst[o];
      } else {
          result += c;
      }
  }
  return result;
}

// Naming rationale: translate from key we got from (e)xpression
export function xl8e(key: string, ...args: string[]): string {
  return xl8(key as unknown as keyof I18nWord, ...args);
}

// Naming rationale: translate to (l)anguage from key we got from (e)xpression
export function xl8le(lang: keyof I18nLanguages<string>,
    key: string, ...args: string[]): string {
  return xl8l(lang, key as unknown as keyof I18nWord, ...args);
}

// Naming rationale: translate from key
export function xl8(key: keyof I18nWord, ...args: string[]): string {
  return xl8l(currentLanguage, key, ...args);
}

const pseudoLocalize = true;

// Naming rationale: translate to (l)anguage from key
export function xl8l(lang: keyof I18nLanguages<string>,
    key: keyof I18nWord, ...args: string[]): string {
  let langLookup = i18nLookup[key];
  let word = langLookup && langLookup[lang];
  word = args.reduce((pattern, arg, index) => {
    return pattern.replace('{' + index + '}', arg);
  }, word || '');
  if (!word)
    //word = debug_xl8 ? '<xl8(' + key + ')>' : '';
    word = debug_xl8 ? pseudoLoc(langLookup?.en ?? ('<' + key + '>')) : '';
  
  else if (location.href.includes('//localhost'))
    word = pseudoLoc(word);

  return word;
}

// i18n fixme
const dayNameLookup: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

// English needs at least 2 letters to differentiate day names
const dayNameMinLookup: I18nLanguages<number> = {
  en: 2,
  fr: 2
};

export function formatDayOfWeek(date: Date | number, 
    len?: number, 
    lang: keyof I18nLanguages<number> = currentLanguage): string {
  if (typeof date === 'number')
    date = dateFromUnix(date);
  
  let english = dayNameLookup[date.getDay()].substring(0, 
    len ? Math.max(len, dayNameMinLookup[lang]) : undefined);
  
  return xl8le(lang, english.toLowerCase());
}

export function formatDayOfWeekNumber(dayOfWeek: number, 
    lang: keyof I18nLanguages<number> = currentLanguage): string {
  return xl8le(lang, dayNameLookup[dayOfWeek].toLowerCase());
}

export function dayNameList(
    lang: keyof I18nLanguages<number> = currentLanguage): string[] {
  return [
    xl8l(lang, 'sunday'),
    xl8l(lang, 'monday'),
    xl8l(lang, 'tuesday'),
    xl8l(lang, 'wednesday'),
    xl8l(lang, 'thursday'),
    xl8l(lang, 'friday'),
    xl8l(lang, 'saturday')
  ];
}
