import React, { PureComponent, ReactNode } from "react";
import { uniqueId } from "../shared/ui";

interface BezelCircleInfo {
  cx: number;
  cy: number;
  radius: number;
}

export interface DashboardGaugeProps {
  style?: React.CSSProperties;
  scale?: number;

  offsetX: number;
  offsetY: number;

  faceRadius: number;
  faceStartAngle: number;
  faceEndAngle: number;

  barInnerRadius?: number;
  barOuterRadius?: number;
  barLineWidth: number;
  barBackgroundLineWidth: number;
  barBackgroundColor: string;
  barColor: string;
  barCornerRadius: number;

  unitText: string;
  unitConcat: boolean;
  unitAngle: number;
  unitRadius: number;
  unitBaselineAdjust: number;
  unitFontSize: number;

  needleStartRadius: number;
  needleEndRadius: number;
  needleMass: number;
  needleDamping: number;
  needleTorque: number;

  graduationStartRadius: number;
  graduationEndRadius: number;
  minorGraduationStartRadius: number;
  minorGraduationEndRadius: number;

  numberRadius: number;
  numberRotates: boolean;
  numberFontSize?: number;
  numberFontWeight?: string;
  numberFontStyle?: string;
  numberFontFamily?: string;
  numberBaselineAdjust?: number;
  numberColor: string;
  numberAnchor: string;

  autorange: boolean;
  autorangeDown: boolean;
  valueMin: number;
  valueMax: number;
  value: number;

  needleStartAngle: number;
  needleEndAngle: number;
  needleColor: string;
  needleWidth: number;

  bezelColor: string;
  bezelWidth: number;
  bezelStartAngle: number;
  bezelEndAngle: number;
  faceColor: string;

  graduationCount: number;
  graduationWidth: number;
  minorGraduationWidth: number;
  graduationColor: string;

  currentValueAngle: number;
  currentValueHidden: boolean;
  currentValueTextAlign: 'left' | 'middle' | 'right' | '' | null,
  currentValueRadius: number;
  currentValueFontSize: number;
  currentValueFontWeight: string;
  currentValueFontStyle: string;
  currentValueFontFamily: string;
  currentValueBaselineAdjust: number;
  currentValuePrecision: number;
  currentValueColor: string;
}

interface DashboardGaugeState {
  value: number;
  valueMin: number;
  valueMax: number;
  width: number;
  height: number;
  viewBox: string;
  faceLine: string;
  needleLine: string;
  graduationLines: string;
  minorGraduationLines: string;
  bezelCircle: BezelCircleInfo | null;
  barMask: string;
  barMaskId: string;
  barMaskStrokeWidth: number;
  barLine: string;
  barBackgroundLine: string;
  texts: DisplayedText[];
  unitX: number;
  unitY: number;
  numberFontSize: number;
  unitFontSize: number;
  graduationWidth: number;
  barLineWidth: number;
  barBackgroundLineWidth: number;
  minorGraduationWidth: number;
  needleWidth: number;
  numberBaselineAdjust: number;
  currentValueX: number;
  currentValueY: number;
  currentValueText: string;
  currentValueFontSize: number;
  currentValueBaselineAdjust: number;
}

//const RAD2DEG = 180 / Math.PI;
const DEG2RAD = Math.PI / 180;

interface DisplayedText {
  x: number;
  y: number;
  text: string;
}

export class DashboardGauge
  extends PureComponent<DashboardGaugeProps, DashboardGaugeState> {
  private elem = React.createRef<SVGSVGElement>();
  private needleVel: number = 0;
  private physicsFrame: number | null = null;
  private lastUpdate: number = 0;

  public static readonly defaultProps: DashboardGaugeProps = {
    offsetX: 0,
    offsetY: 8,
    barCornerRadius: 0,
    unitText: 'kW',
    unitConcat: false,
    unitAngle: 90,
    unitRadius: 28,
    unitBaselineAdjust: 5,
    unitFontSize: 12,
    faceRadius: 80,
    faceStartAngle: -225,
    faceEndAngle: 45,
    needleStartRadius: -10,
    needleEndRadius: 66,
    needleMass: 10,
    needleDamping: 0.1,
    needleTorque: 10,
    numberRadius: 53,
    numberRotates: false,
    numberFontFamily: "GalanoGrotesqueRegular",
    numberFontSize: 12,
    numberAnchor: "middle",
    numberFontStyle: "normal",
    numberFontWeight: "normal",
    numberBaselineAdjust: 1,
    numberColor: "#AAA",
    autorange: true,
    autorangeDown: false,
    valueMin: 0,
    valueMax: 10,
    value: 0,
    needleStartAngle: -225,
    needleEndAngle: 45,
    needleWidth: 1.33,
    needleColor: "#EF553F",
    bezelWidth: 0,
    bezelColor: "#C4C4C4",
    bezelStartAngle: 0,
    bezelEndAngle: 360,
    faceColor: "#C4C4C4",
    graduationStartRadius: 64,
    graduationEndRadius: 75,
    graduationCount: 11,
    graduationWidth: 2,
    graduationColor: "#AAA",
    barColor: '#6899e3',
    barLineWidth: 1,
    barBackgroundLineWidth: 1,
    barInnerRadius: 85,
    barOuterRadius: 95,
    barBackgroundColor: '#eee',
    minorGraduationWidth: 1,
    minorGraduationStartRadius: 69,
    minorGraduationEndRadius: 75,
    currentValueHidden: false,
    currentValueAngle: 90,
    currentValueTextAlign: 'middle',
    currentValueFontFamily: "GalanoGrotesqueRegular",
    currentValueBaselineAdjust: 0,
    currentValueColor: "#555",
    currentValueFontSize: 12,
    currentValueFontStyle: 'normal',
    currentValueFontWeight: 'normal',
    currentValuePrecision: 1,
    currentValueRadius: 52
  };

  private static readonly faceProps: (keyof DashboardGaugeProps)[] = [
    'valueMin',
    'valueMax',
    'offsetX',
    'offsetY',
    'barColor',
    'barLineWidth',
    'barInnerRadius',
    'barOuterRadius',
    'barBackgroundColor',
    'barCornerRadius',
    'unitConcat',
    'faceRadius',
    'faceStartAngle',
    'faceEndAngle',
    'unitText',
    'unitAngle',
    'unitRadius',
    'unitBaselineAdjust',
    'unitFontSize',
    'graduationStartRadius',
    'graduationEndRadius',
    'minorGraduationStartRadius',
    'minorGraduationEndRadius',
    'numberRadius',
    'numberRotates',
    'numberFontSize',
    'numberFontWeight',
    'numberFontStyle',
    'numberFontFamily',
    'numberBaselineAdjust',
    'numberColor',
    'numberAnchor',
    'needleStartAngle',
    'needleEndAngle',
    'bezelColor',
    'bezelWidth',
    'graduationCount',
    'graduationWidth',
    'graduationColor',
    'minorGraduationWidth',
  ];

  private static readonly faceStateProps: (keyof DashboardGaugeState)[] = [
    'valueMin',
    'valueMax',
    'width',
    'height',
    'viewBox'
  ];

  private static readonly needleStateProps: (keyof DashboardGaugeState)[] = [
    'valueMin',
    'valueMax',
    'value',
    'numberFontSize',
    'unitFontSize',
    'needleWidth',
    'barBackgroundLine',
    'barBackgroundLineWidth',
    'barLine',
    'barMask',
    'bezelCircle'
  ];

  private static readonly needleProps: string[] = [
    'needleColor',
    'needleWidth',
    'needleStartRadius',
    'needleEndRadius',
  ];
  private unmounted: boolean = false;

  constructor(props: DashboardGaugeProps) {
    super(props);

    this.state = {
      value: 0,
      valueMin: this.props.valueMin,
      valueMax: this.roundToMultiple(this.props.valueMax,
        this.props.graduationCount - 1),
      width: 0,
      height: 0,
      viewBox: '0 0 0 0',
      faceLine: '',
      needleLine: '',
      graduationLines: '',
      minorGraduationLines: '',
      barLine: '',
      barMask: '',
      barMaskId: uniqueId().substring(1) + 'barMask',
      barMaskStrokeWidth: 0,
      barBackgroundLine: '',
      barLineWidth: 0,
      barBackgroundLineWidth: 0,
      bezelCircle: null,
      texts: [],
      unitX: 0,
      unitY: 0,
      numberFontSize: 12,
      unitFontSize: 12,
      graduationWidth: 1,
      minorGraduationWidth: 1,
      needleWidth: 2,
      numberBaselineAdjust: 0,
      currentValueX: 0,
      currentValueY: 0,
      currentValueText: '',
      currentValueBaselineAdjust: 0,
      currentValueFontSize: 12
    };
  }

  componentDidMount(): void {
    if (this.elem.current) {
      this.checkSize();
    }
  }

  componentWillUnmount(): void {
    this.unmounted = true;
  }

  public checkSize(): void {
    if (this.unmounted)
      return;

    let parent = this.elem.current.parentElement;
    this.setState({
      width: parent.offsetWidth,
      height: parent.offsetHeight
    });
  }

  propDiffers<T>(lhs: T, rhs: T, key: string): boolean {
    let lh = lhs[key];
    let rh = rhs[key];

    let la = Array.isArray(lh);
    let ra = Array.isArray(rh);

    if (la !== ra)
      return true;

    if (!la)
      return lh !== rh;

    // Don't even need to compare arrays
    return false;

    // Compare the values of arrays
    // let l = lh as Array<any>;
    // let r = rh as Array<any>;
    // let differs = l.length !== r.length || l.some((value, index) => {
    //   let otherValue = r[index];
    //   let differs = this.objectsDiffer(value, otherValue);
    //   return differs;
    // });
    // return differs;
  }

  objectsDiffer<T>(lhs: T, rhs: T,
    props: string[] = Object.keys(lhs)): boolean {
    let differs = props.some((key) => {
      let differs = this.propDiffers(lhs, rhs, key);
      // if (differs)
      //   console.log(key, 'differs');
      return differs;
    });
    return differs;
  }

  somethingChanged(
    otherProps: Readonly<DashboardGaugeProps>,
    otherState: Readonly<DashboardGaugeState>): boolean {
    return this.objectsDiffer(otherProps, this.props) ||
      this.objectsDiffer(otherState, this.state);
  }

  // shouldComponentUpdate(
  //   nextProps: Readonly<DashboardGaugeProps>, 
  //   nextState: Readonly<DashboardGaugeState>, 
  //   nextContext: never): boolean {
  //   return this.somethingChanged(nextProps, nextState);
  // }

  componentDidUpdate(
    prevProps: Readonly<DashboardGaugeProps>,
    prevState: Readonly<DashboardGaugeState>,
    snapshot?: never): void {
    let faceChanged = this.didFaceChange(prevProps, prevState);
    let needleChanged = faceChanged ||
      this.didNeedleChange(prevProps, prevState);

    if (faceChanged || needleChanged)
      this.recalculate(faceChanged, needleChanged);

    // If we aren't running physics already
    if (this.physicsFrame === null) {
      // If we need to start running physics updates
      if (this.state.value !== this.props.value || this.needleVel)
        this.startPhysics();
    }
  }

  private startPhysics(now: number = Date.now()): void {
    this.lastUpdate = now;
    console.log('running gauge physics step');
    this.physicsFrame = requestAnimationFrame(() => {
      if (this.physicsFrame !== null)
        this.updatePhysics();
    });
  }

  private updatePhysics(): void {
    if (this.unmounted)
      return;

    if (!this.needleVel && this.props.value === this.state.value) {
      this.physicsFrame = null;
      return;
    }

    let stateUpd: Partial<DashboardGaugeState> = {};

    let newValue = this.state.value;

    let curPos = this.valueToNeedlePos(newValue);

    if (this.props.autorange) {
      let finalPos = this.valueToNeedlePos(this.props.value);

      const spd = 1.1;
      if (finalPos > 0.9) {
        stateUpd.valueMax = this.state.valueMax * spd;
        newValue *= spd;
      } else if (this.props.autorangeDown && finalPos < 0.5 &&
        this.state.valueMax > this.props.graduationCount * 1) {
        stateUpd.valueMax = this.state.valueMax / spd;
        newValue /= spd;
      }

      if (stateUpd.valueMax !== undefined) {
        let adjMax = this.props.graduationCount - 1;
        adjMax = this.roundToMultiple(stateUpd.valueMax, adjMax);
        newValue = curPos * adjMax;
        stateUpd.valueMax = adjMax;
      }
    }

    let correction = this.props.value - newValue;
    let torque = correction * this.props.needleTorque;
    let now = Date.now();
    let elap = Math.min(0.1, (now - this.lastUpdate) * 0.001);

    //console.log('elap=', elap);
    this.needleVel += torque * elap;
    let drag = Math.pow(this.props.needleDamping, elap);
    this.needleVel *= drag * drag;
    newValue = newValue + this.needleVel * elap;

    // If it is really close and really slow, snap to exact value and stop
    if (Math.abs(correction) < 0.1 && Math.abs(this.needleVel) < 0.001) {
      this.needleVel = 0;
      newValue = this.props.value;
    }

    if (newValue > (stateUpd.valueMax || this.state.valueMax)) {
      newValue = this.state.valueMax;
      // Bounce
      this.needleVel *= -0.2;
    } else if (newValue < this.state.valueMin) {
      newValue = this.state.valueMin;
      // Bounce
      this.needleVel *= -0.2;
    }

    stateUpd.value = newValue;

    this.setState(stateUpd as DashboardGaugeState, () => {
      this.startPhysics(now);
    });
  }

  private roundToMultiple(valueMax: number, multiple: number): number {
    return Math.ceil(valueMax / multiple) * multiple;
  }

  private didNeedleChange(prevProps: Readonly<DashboardGaugeProps>,
    prevState: Readonly<DashboardGaugeState>): boolean {
    let propChange = this.objectsDiffer(
      prevProps, this.props,
      DashboardGauge.needleProps);
    let anyChange = propChange || this.objectsDiffer(
      prevState, this.state,
      DashboardGauge.needleStateProps);

    return anyChange;
  }

  private didFaceChange(otherProps: Readonly<DashboardGaugeProps>,
    otherState: Readonly<DashboardGaugeState>): boolean {
    let propChange = this.objectsDiffer(otherProps, this.props,
      DashboardGauge.faceProps);
    let anyChange = propChange || this.objectsDiffer(
      otherState, this.state, DashboardGauge.faceStateProps);
    return anyChange;
  }

  polarVertex(radians: number,
    cx: number = 0, cy: number = 0,
    rad: number = 1): [number, number] {
    return [
      cx + rad * Math.cos(radians),
      cy + rad * Math.sin(radians)
    ];
  }

  recalculate(faceChanged: boolean, needleChanged: boolean): void {
    if (this.unmounted)
      return;

    //console.log(this.state.width, this.state.height);

    let minSz = Math.min(this.state.height, this.state.width);
    let xofs = (this.state.width - minSz) / 2;
    let yofs = (this.state.height - minSz) / 2;
    let halfSz = minSz / 2;
    let percentScale = 0.01 * halfSz * (this.props.scale ?? 1);
    let cx = xofs + halfSz + this.props.offsetX * percentScale;
    let cy = yofs + halfSz + this.props.offsetY * percentScale;

    //console.log('need ', 16 / percentScale);

    if (faceChanged) {
      let faceRad = percentScale * this.props.faceRadius;

      let [bezelSx, bezelSy] = this.polarVertex(
        DEG2RAD * this.props.bezelStartAngle, cx, cy,
        faceRad);

      let [bezelEx, bezelEy] = this.polarVertex(
        DEG2RAD * this.props.bezelEndAngle, cx, cy,
        faceRad);

      let [unitX, unitY] = this.polarVertex(
        DEG2RAD * this.props.unitAngle, cx, cy,
        percentScale * this.props.unitRadius);

      let [currentX, currentY] = this.polarVertex(
        DEG2RAD * this.props.currentValueAngle, cx, cy,
        percentScale * this.props.currentValueRadius);

      let texts: DisplayedText[] = [];
      let graduations = [];
      let minor = [];
      let invCount = 1 / (this.props.graduationCount - 1);
      let graduationValue = 0;
      let graduationStartRadius = percentScale * 
        this.props.graduationStartRadius;
      let graduationEndRadius = percentScale * 
        this.props.graduationEndRadius;
      let numberRadius = percentScale *
        this.props.numberRadius;
      for (let i = 0; i < this.props.graduationCount;
        ++i, (graduationValue += invCount)) {
        let radians = this.needleRadiansFromNeedlePos(graduationValue);

        let [gradStX, gradStY] = this.polarVertex(radians, cx, cy,
          graduationStartRadius);

        let [gradEnX, gradEnY] = this.polarVertex(radians, cx, cy,
          graduationEndRadius);

        let [textX, textY] = this.polarVertex(radians, cx, cy,
          numberRadius);

        graduations.push('M', gradStX, gradStY, gradEnX, gradEnY);

        let text = ((this.state.valueMax - this.state.valueMin) *
          graduationValue + this.state.valueMin).toFixed(0);

        texts.push({
          x: textX,
          y: textY,
          text: text
        });
      }

      graduationValue = invCount * 0.5;

      for (let i = 0; i < this.props.graduationCount - 1;
        ++i, (graduationValue += invCount)) {
        let radians = this.needleRadiansFromNeedlePos(graduationValue);

        let [gradStX, gradStY] = this.polarVertex(radians, cx, cy,
          percentScale * this.props.minorGraduationStartRadius);

        let [gradEnX, gradEnY] = this.polarVertex(radians, cx, cy,
          percentScale * this.props.minorGraduationEndRadius);

        minor.push('M', gradStX, gradStY, gradEnX, gradEnY);
      }

      let faceStA = this.props.faceStartAngle;
      let faceEnA = this.props.faceEndAngle;

      let arcSz: number;
      if (faceStA < 0 && faceEnA >= 0) {
        arcSz = faceEnA - faceStA;
      } else if (faceEnA < 0 && faceStA >= 0) {
        arcSz = faceStA - faceEnA;
      } else if (faceEnA < faceStA) {
        arcSz = 360 - faceEnA - faceStA;
      } else {
        arcSz = faceEnA - faceStA;
      }

      this.setState({
        viewBox: '0 0 ' + this.state.width + ' ' + this.state.height,
        faceLine: arcSz < 360 ? [
          'M',
          bezelSx, bezelSy,

          'A',

          // horizontal and vertical radius
          faceRad, faceRad,

          // rotation
          0,

          // 1 if it is >= than half a circle (large arc)
          +(arcSz >= 180),

          // Sweep flag
          +(this.props.faceEndAngle > this.props.faceStartAngle),

          bezelEx, bezelEy,
          'Z'
        ].join(' ') : null,
        bezelCircle: (this.props.bezelEndAngle -
          this.props.bezelStartAngle) >= 360
          ? { cx, cy, radius: this.props.faceRadius * percentScale }
          : null,
        graduationLines: graduations.join(' '),
        minorGraduationLines: minor.join(' '),
        texts: texts,
        unitX: unitX,
        unitY: unitY,
        numberFontSize: this.props.numberFontSize * percentScale,
        numberBaselineAdjust: this.props.numberBaselineAdjust * percentScale,
        unitFontSize: this.props.unitFontSize * percentScale,
        graduationWidth: this.props.graduationWidth * percentScale,
        minorGraduationWidth: this.props.minorGraduationWidth * percentScale,
        barLineWidth: this.props.barLineWidth * percentScale,
        barBackgroundLineWidth: this.props.barBackgroundLineWidth * percentScale,
        currentValueX: currentX,
        currentValueY: currentY,
        currentValueFontSize: this.props.currentValueFontSize * percentScale,
        currentValueBaselineAdjust:
          this.props.currentValueBaselineAdjust * percentScale
      });
    }

    if (needleChanged) {
      let needlePos = this.valueToNeedlePos(this.state.value);

      let needleRadians = this.needleRadiansFromNeedlePos(needlePos);

      let barOuterRadius = percentScale * this.props.barOuterRadius;
      let barInnerRadius = percentScale * this.props.barInnerRadius;

      let twoPI = 2 * Math.PI;
      let barInnerCirc = twoPI * barInnerRadius;
      let barOuterCirc = twoPI * barOuterRadius;
      let barWidth = barOuterRadius - barInnerRadius;
      let barCornerRadius = barWidth * this.props.barCornerRadius / 100;
      let barOuterProportion = barCornerRadius / barOuterCirc;
      let barInnerProportion = barCornerRadius / barInnerCirc;
      let barInnerAngleDiffDeg = barInnerProportion * 360;
      let barOuterAngleDiffDeg = barOuterProportion * 360;
      let barInnerAngleDiffRad = barInnerAngleDiffDeg * DEG2RAD;
      let barOuterAngleDiffRad = barOuterAngleDiffDeg * DEG2RAD;

      let needleStartRadius = percentScale * this.props.needleStartRadius;
      let needleEndRadius = percentScale * this.props.needleEndRadius;

      let barInnerSweepFlag = +(((needleRadians - barInnerAngleDiffRad) - 
        (DEG2RAD * this.props.needleStartAngle + barInnerAngleDiffRad)) >= 
        Math.PI);

      let barOuterSweepFlag = +(((needleRadians - barOuterAngleDiffRad) - 
        (DEG2RAD * this.props.needleStartAngle + barOuterAngleDiffRad)) >=
        Math.PI);

      let barBackgroundSweepFlag = +(-(needleRadians - 
        DEG2RAD * this.props.needleEndAngle) > Math.PI);

      let maskInnerSweepFlag = +(this.props.needleEndAngle - 
        this.props.needleStartAngle - barInnerAngleDiffDeg * 2 >= 180);
      let maskOuterSweepFlag = +(this.props.needleEndAngle - 
        this.props.needleStartAngle - barOuterAngleDiffDeg * 2 >= 180);

      let [needleTipX, needleTipY] = this.polarVertex(
        needleRadians, cx, cy, needleEndRadius);
      
      let [ needleStX, needleStY ] = this.polarVertex(
        needleRadians, cx, cy, needleStartRadius);
      

      let [ barInnerStX, barInnerStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle, cx, cy,
        barInnerRadius);

      let [barInnerEnX, barInnerEnY] = this.polarVertex(
        needleRadians, cx, cy,
        barInnerRadius);
      

      let [ barOuterStX, barOuterStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle, cx, cy,
        barOuterRadius);

      let [barOuterEnX, barOuterEnY] = this.polarVertex(
        needleRadians, cx, cy,
        barOuterRadius);


      let [ barInnerMxX, barInnerMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle, cx, cy,
        barInnerRadius);

      let [ barOuterMxX, barOuterMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle, cx, cy,
        barOuterRadius);

      // maskA is the point on the inside or outside edge with angle adjustment
      // bar ends are the corner point for the control handles on end and edge
      // maskC is the point on the end of the scale, away from the edge

      let maskCClamp = barWidth * 0.5 + barInnerRadius;
  
      let [ maskAInnerStX, maskAInnerStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle + barInnerAngleDiffRad, cx, cy,
        barInnerRadius);
  
      let [ maskAOuterStX, maskAOuterStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle + barOuterAngleDiffRad, cx, cy,
        barOuterRadius);
      
      let [ maskCInnerStX, maskCInnerStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle, cx, cy,
        Math.min(maskCClamp, 
          percentScale * (this.props.barInnerRadius + barCornerRadius)));
      
      let [ maskCOuterStX, maskCOuterStY ] = this.polarVertex(
        DEG2RAD * this.props.needleStartAngle, cx, cy,
        Math.max(maskCClamp, 
          percentScale * (this.props.barOuterRadius - barCornerRadius)));
  
      let [ maskAInnerEnX, maskAInnerEnY ] = this.polarVertex(
        needleRadians - barInnerAngleDiffRad, cx, cy,
        barInnerRadius);
  
      let [ maskAOuterEnX, maskAOuterEnY ] = this.polarVertex(
        needleRadians - barOuterAngleDiffRad, cx, cy,
        barOuterRadius);
      
      let [ maskCInnerEnX, maskCInnerEnY ] = this.polarVertex(
        needleRadians, cx, cy,
        Math.min(maskCClamp,
          percentScale * (this.props.barInnerRadius + barCornerRadius)));
      
      let [ maskCOuterEnX, maskCOuterEnY ] = this.polarVertex(
        needleRadians, cx, cy,
        Math.max(maskCClamp,
          percentScale * (this.props.barOuterRadius - barCornerRadius)));

  
      let [ maskAInnerMxX, maskAInnerMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle - barInnerAngleDiffRad, cx, cy,
        barInnerRadius);
  
      let [ maskAOuterMxX, maskAOuterMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle - barOuterAngleDiffRad, cx, cy,
        barOuterRadius);
      
      let [ maskCInnerMxX, maskCInnerMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle, cx, cy,
        Math.min(maskCClamp,
          percentScale * (this.props.barInnerRadius + barCornerRadius)));
      
      let [ maskCOuterMxX, maskCOuterMxY ] = this.polarVertex(
        DEG2RAD * this.props.needleEndAngle, cx, cy,
        Math.max(maskCClamp,
          percentScale * (this.props.barOuterRadius - barCornerRadius)));

      this.setState({
        needleLine: [
          'M', needleStX, needleStY, 
          needleTipX, needleTipY
        ].join(' '),
        needleWidth: this.props.needleWidth * percentScale,
        currentValueText: Number(this.props.value).toFixed(
          this.props.currentValuePrecision),
        barMask: [
          'M',
          maskAInnerStX, maskAInnerStY,

          'A',
          barInnerRadius, barInnerRadius,
          0, maskInnerSweepFlag, 1,
          maskAInnerMxX, maskAInnerMxY,

          'Q',
          barInnerMxX, barInnerMxY,
          maskCInnerMxX, maskCInnerMxY,

          'L',
          maskCOuterMxX, maskCOuterMxY,

          'Q',
          barOuterMxX, barOuterMxY,
          maskAOuterMxX, maskAOuterMxY,

          'A',
          barOuterRadius, barOuterRadius,
          0, maskOuterSweepFlag, 0,
          maskAOuterStX, maskAOuterStY,
          
          'Q',
          barOuterStX, barOuterStY,
          maskCOuterStX, maskCOuterStY,

          'L',
          maskCInnerStX, maskCInnerStY,

          'Q',
          barInnerStX, barInnerStY,
          maskAInnerStX, maskAInnerStY,

          'Z'
        ].join(' '),
        barLine: [
          'M',
          maskAInnerStX, maskAInnerStY,

          'A',
          barInnerRadius, barInnerRadius,
          0, barInnerSweepFlag, 1,
          maskAInnerEnX, maskAInnerEnY,

          'Q',
          barInnerEnX, barInnerEnY,
          maskCInnerEnX, maskCInnerEnY,

          'L',
          maskCOuterEnX, maskCOuterEnY,

          'Q',
          barOuterEnX, barOuterEnY,
          maskAOuterEnX, maskAOuterEnY,

          'A',
          barOuterRadius, barOuterRadius,
          1, barOuterSweepFlag, 0,
          maskAOuterStX, maskAOuterStY,

          'Q',
          barOuterStX, barOuterStY,
          maskCOuterStX, maskCOuterStY,

          'L',
          maskCInnerStX, maskCInnerStY,

          'Q',
          barInnerStX, barInnerStY,
          maskAInnerStX, maskAInnerStY,

          'Z'
        ].join(' '),
        barBackgroundLine: [
          'M',
          maskAInnerEnX, maskAInnerEnY,

          'A',
          barInnerRadius,
          barInnerRadius,
          0, barBackgroundSweepFlag, 1,
          maskAInnerMxX, maskAInnerMxY,

          'Q',
          barInnerMxX, barInnerMxY,
          maskCInnerMxX, maskCInnerMxY,

          'L',
          maskCOuterMxX, maskCOuterMxY,

          'Q',
          barOuterMxX, barOuterMxY, 
          maskAOuterMxX, maskAOuterMxY,

          'A',
          barOuterRadius,
          barOuterRadius,
          1, barBackgroundSweepFlag, 0,
          maskAOuterEnX, maskAOuterEnY,

          'Q',
          barOuterEnX, barOuterEnY,
          maskCOuterEnX, maskCOuterEnY,

          'L',
          maskCInnerEnX, maskCInnerEnY,

          'Q',
          barInnerEnX, barInnerEnY,
          maskAInnerEnX, maskAInnerEnY,

          'Z'
        ].join(' ')
      });
    }
  }
  
  // private fixRadius(x: number, y: number, 
  //     cx: number, cy: number, enforcedRadius: number): string {
  //   let vx = x - cx;
  //   let vy = y - cy;
  //   let sqlen = vx * vx + vy * vy;
  //   let len = Math.sqrt(sqlen);
  //   let nc = enforcedRadius / len;
  //   vx *= nc;
  //   vy *= nc;
  //   vx += cx;
  //   vy += cy;
  //   return vx + ' ' + vy;
  // }

  private needleDegreesFromNeedlePos(needlePos: number) {
    return ((this.props.needleEndAngle -
      this.props.needleStartAngle) * needlePos + 
      this.props.needleStartAngle);
  }

  private needleRadiansFromNeedlePos(needlePos: number) {
    return DEG2RAD * this.needleDegreesFromNeedlePos(needlePos);
  }

  private valueToNeedlePos(value: number): number {
    return (value - this.state.valueMin) /
      (this.state.valueMax - this.state.valueMin);
  }

  render(): ReactNode {
    let valueText = this.props.value.toFixed(this.props.currentValuePrecision);

    return (
      <svg ref={this.elem}
        style={this.props.style}
        viewBox={this.state.viewBox}
        width={this.state.width}
        height={this.state.height}>
        {
          this.state.bezelCircle
            ? <circle
              cx={this.state.bezelCircle.cx}
              cy={this.state.bezelCircle.cy}
              r={this.state.bezelCircle.radius}
              stroke={this.props.bezelColor}
              strokeWidth={this.props.bezelWidth}
              fill={this.props.faceColor} />
            : <path
              d={this.state.faceLine}
              stroke={this.props.bezelColor}
              strokeWidth={this.props.bezelWidth}
              fill={this.props.faceColor} />
        }
        <path strokeWidth={this.state.graduationWidth}
          stroke={this.props.graduationColor}
          d={this.state.graduationLines} />
        <path strokeWidth={this.state.minorGraduationWidth}
          stroke={this.props.graduationColor}
          d={this.state.minorGraduationLines} />
        <path strokeWidth={this.state.barLineWidth}
          fill={this.props.barColor}
          strokeLinecap="square"
          mask={'url(#' + this.state.barMaskId + ')'}
          d={this.state.barLine}/>
        <path strokeWidth={this.state.barBackgroundLineWidth}
          fill={this.props.barBackgroundColor}
          strokeLinecap="square"
          mask={'url(#' + this.state.barMaskId + ')'}
          d={this.state.barBackgroundLine}/>
        <mask id={this.state.barMaskId}>
          <path
            fill="white"
            d={this.state.barMask}/>
        </mask>
        {
          this.state.texts?.map((text) => {
            return (
              <text key={text.text}
                x={text.x} y={text.y + this.state.numberBaselineAdjust}
                fontFamily={this.props.numberFontFamily}
                fontWeight={this.props.numberFontWeight}
                fontStyle={this.props.numberFontStyle}
                fontSize={this.state.numberFontSize}
                fill={this.props.numberColor}
                textAnchor={this.props.numberAnchor}
                dominantBaseline="middle">
                {text.text}
              </text>
            );
          })
        }
        {
          !this.props.unitConcat &&
          <text
            x={this.state.unitX}
            y={this.state.unitY}
            fontFamily={this.props.numberFontFamily}
            fontStyle={this.props.numberFontStyle}
            fontSize={this.state.unitFontSize}
            fill={this.props.numberColor}
            textAnchor={this.props.numberAnchor}
            dominantBaseline="middle">
            {this.props.unitText}
          </text>
        }
        <text
          x={this.state.currentValueX}
          y={this.state.currentValueY}
          fontFamily={this.props.currentValueFontFamily}
          fontStyle={this.props.currentValueFontStyle}
          fontSize={this.state.currentValueFontSize}
          textAnchor={this.props.currentValueTextAlign}
          fill={this.props.currentValueColor}>
          {this.props.unitConcat
            ? valueText + ' ' + this.props.unitText
            : valueText
          }
        </text>
        <path stroke={this.props.needleColor}
          strokeWidth={this.state.needleWidth}
          d={this.state.needleLine} />
      </svg>
    );
  }
}
