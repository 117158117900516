import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface GenerateReportIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class GenerateReportIcon extends PureComponent<GenerateReportIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="10.5" y="19" width="78" height="58.5" rx="4.5" fill="#175785"/>
        <rect x="9" y="20.5" width="75" height="55.5" rx="3" fill="white" stroke="#80A7C3" strokeWidth="3"/>
        <rect x="36" y="28" width="3" height="19.5" rx="1.5" transform="rotate(90 36 28)" fill="#175785"/>
        <rect x="30" y="35.5" width="3" height="13.5" rx="1.5" transform="rotate(90 30 35.5)" fill="#DCE3EB"/>
        <rect x="51" y="35.5" width="3" height="9" rx="1.5" transform="rotate(90 51 35.5)" fill="#DCE3EB"/>
        <rect x="70.5" y="35.5" width="3" height="10.5" rx="1.5" transform="rotate(90 70.5 35.5)" fill="#DCE3EB"/>
        <rect x="51" y="43" width="3" height="9" rx="1.5" transform="rotate(90 51 43)" fill="#DCE3EB"/>
        <rect x="69" y="43" width="3" height="9" rx="1.5" transform="rotate(90 69 43)" fill="#DCE3EB"/>
        <rect x="51" y="50.5" width="3" height="9" rx="1.5" transform="rotate(90 51 50.5)" fill="#DCE3EB"/>
        <rect x="72" y="50.5" width="3" height="12" rx="1.5" transform="rotate(90 72 50.5)" fill="#DCE3EB"/>
        <rect x="51" y="65.5" width="3" height="9" rx="1.5" transform="rotate(90 51 65.5)" fill="#DCE3EB"/>
        <rect x="70.5" y="65.5" width="3" height="10.5" rx="1.5" transform="rotate(90 70.5 65.5)" fill="#DCE3EB"/>
        <rect x="72" y="58" width="3" height="12" rx="1.5" transform="rotate(90 72 58)" fill="#DCE3EB"/>
        <rect x="51" y="58" width="3" height="9" rx="1.5" transform="rotate(90 51 58)" fill="#DCE3EB"/>
        <rect x="36" y="43" width="3" height="19.5" rx="1.5" transform="rotate(90 36 43)" fill="#DCE3EB"/>
        <rect x="31.5" y="58" width="3" height="15" rx="1.5" transform="rotate(90 31.5 58)" fill="#DCE3EB"/>
        <rect x="36" y="50.5" width="3" height="19.5" rx="1.5" transform="rotate(90 36 50.5)" fill="#DCE3EB"/>
        <rect x="34.5" y="65.5" width="3" height="18" rx="1.5" transform="rotate(90 34.5 65.5)" fill="#DCE3EB"/>
        <rect x="54" y="28" width="3" height="12" rx="1.5" transform="rotate(90 54 28)" fill="#175785"/>
        <rect x="76.5" y="28" width="3" height="16.5" rx="1.5" transform="rotate(90 76.5 28)" fill="#175785"/>
        <circle cx="82.5" cy="53.5" r="13.5" fill="#175785"/>
        <path d="M76.5 53.5H88.5" stroke="white" strokeWidth="2.25" strokeLinecap="round"/>
        <path d="M82.5 47.5L82.5 59.5" stroke="white" strokeWidth="2.25" strokeLinecap="round"/>
      </svg>
      
    );
  }
}