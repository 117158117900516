import React from "react";
import { ReactNode } from "react";
import { xl8 } from "../../translations/i18n";
import { renderSpam, setStatePromise } from "../shared/ui";
import { Modal, ModalBase, ModalExtraButton } from "./Modal";

export interface WizardPageParams {
  name: string;
  title: string;
  modalWidth?: string;
  content: () => ReactNode;
  extraButtons?: ModalExtraButton[];
  nextContent?: ReactNode;
  backContent?: ReactNode;
  nextHook?: () => boolean | Promise<boolean>;
  onActivate?: () => void;
  onDeactivate?: () => void;
}

export interface WizardState {
  pageIndex: number;// = -1;
}

export abstract class Wizard<TResult, TState extends WizardState = WizardState> 
    extends ModalBase<{}, TResult, TState> {  
  private pages: WizardPageParams[] = [];
  private pagesLookup: { [name: string]: ReactNode } = {};
  public result: TResult;
  public pageHistory: number[] = [];

  constructor(props: {}) {
    super(props);
    this.state = Object.assign({}, {
      pageIndex: -1
    }, this.initialState());
  }

  public initialState(): TState {
    return {} as TState;
  }

  public showDialog(initialResult: TResult): Promise<TResult> {
    this.result = initialResult;
    return this.prepareWizard();
  }

  abstract prepareWizard(): Promise<TResult>;

  public addPage(params: WizardPageParams): void {
    this.pages.push(params);
    this.pagesLookup[params.name] = params;
  }
  
  public setPageByIndex(index: number, 
      skipHistory: boolean = false): Promise<void> {
    if (this.state.pageIndex === index)
      return Promise.resolve();
    
    // Save outgoing page to history
    if (!skipHistory)
      this.pageHistory.push(this.state.pageIndex);
    
    if (this.state.pageIndex >= 0) {
      if (this.pages[this.state.pageIndex].onDeactivate)
        this.pages[this.state.pageIndex].onDeactivate();
    }
    
    let newPageIndex: number = -1;
    if (index >= 0)
      newPageIndex = Math.max(0, Math.min(this.pages.length - 1, index));
    
    return setStatePromise<WizardState, Wizard<TResult>>(this, {
      pageIndex: newPageIndex
    }).then(() => {
      if (newPageIndex >= 0 && this.pages[this.state.pageIndex].onActivate)
        this.pages[this.state.pageIndex].onActivate();
    });
  }

  public setPageByName(name: string): Promise<boolean> {
    let index = this.pages.findIndex((page) => {
      return page.name === name;
    });
    console.assert(index >= 0, 'bad name');
    if (index >= 0)
      return this.setPageByIndex(index).then(() => true);
    return Promise.resolve(false);
  }

  public nextPage(): boolean {
    let result = this.state.pageIndex < this.pages.length;
    
    this.setPageByIndex(this.state.pageIndex + 1);
    
    return result;
  }

  public render(): ReactNode {
    renderSpam('Wizard');
    let page = this.pages[this.state.pageIndex] || null;
    
    let defaultNextText = this.state.pageIndex + 1 < this.pages.length
      ? xl8('next')
      : xl8('finish');
    
    let defaultBackText = this.state.pageIndex > 0
      ? xl8('back')
      : xl8('cancel');
    
    let title = page ? page.title : '';
    let confirmButtonContent = (page && page.nextContent);
    let cancelButtonContent = (page && page.backContent);

    let forcedWidth = page ? page.modalWidth : undefined;
    
    if (!confirmButtonContent && confirmButtonContent !== '')
      confirmButtonContent = defaultNextText;
    
    if (!cancelButtonContent && cancelButtonContent !== '')
      cancelButtonContent = defaultBackText;

    return <Modal
        ref={this.modal}
        title={title}
        width={forcedWidth}
        extraButtons={ (page && page.extraButtons) || [] }
        confirmButtonContent={ confirmButtonContent }
        cancelButtonContent={ cancelButtonContent }
        onOK={() => this.onOK()}
        onCancel={() => this.onCancel()}
        onClose={() => this.onClose()}>
      { page ? page.content() : null }
    </Modal>;
  }

  onClose(): Promise<TResult | boolean> {
    this.setPageByIndex(-1);
    return Promise.resolve(null);
  }

  onCancel(): Promise<TResult | boolean> {
    if (this.pageHistory.length === 0)
      return Promise.resolve(null);
    
    let lastPage = this.pageHistory.pop();

    if (lastPage < 0)
      return Promise.resolve(null);

    return this.setPageByIndex(lastPage, true)
    .then(() => {
      return false;
    });
  }

  onOK(): Promise<TResult> {
    let page = this.pages[this.state.pageIndex];

    // If there is a page hook and the page hook says don't go next
    // then prevent going next
    let nextHookPromise: Promise<boolean>;
    nextHookPromise = Promise.resolve(page.nextHook
      ? page.nextHook()
      : true);
    
    return nextHookPromise.then((nextOk) => {
      if (!nextOk)
        return null;
      if (this.state.pageIndex + 1 < this.pages.length) {
        return this.setPageByIndex(this.state.pageIndex + 1)
        .then(() => null);
      }
      return this.result;
    });    
  }
}
