import React, { Component, ReactNode } from "react";
import { AccessControlGroup, AccessControlPlansResponse, 
  getAccessControlPlansByOrganization, Organization, updateReportCache
} from "../api";
import { SwychedSpinner } from "./spinner.component";

export interface AccessControlPlanListProps {
  organization: Organization;
  accessControlGroups: number[];
  onChange: (accessControlGroups: number[]) => void;
}

interface AccessControlPlanListState {
  plansResponse: AccessControlPlansResponse;
}

export class AccessControlPlanList 
    extends Component<AccessControlPlanListProps, AccessControlPlanListState> {
  constructor(props: AccessControlPlanListProps) {
    super(props);
    this.state = {
      plansResponse: null
    };
  }

  componentDidMount(): void {
    if (this.props.organization)
      this.updateAccessControlPlans();
  }

  componentDidUpdate(
      prevProps: Readonly<AccessControlPlanListProps>, 
      prevState: Readonly<AccessControlPlanListState>, 
      snapshot?: never): void {
    if (prevProps.organization !== this.props.organization)
      this.updateAccessControlPlans();
  }

  private updateAccessControlPlans() {
    getAccessControlPlansByOrganization(
      this.props.organization.id)
      .then((plansResponse) => {
        this.setState({
          plansResponse
        });
      });
  }

  private getGroupList(): AccessControlGroup[] {
    return Object.values(this.state.plansResponse?.groups || {});
  }

  private isGroupChecked(group: AccessControlGroup): boolean {
    return this.props.accessControlGroups?.includes(group.id) || false;
  }

  private setGroupChecked(groupId: number, checked: boolean): void {
    let updatedChecked = this.props.accessControlGroups?.slice() || [];
    let index = updatedChecked.indexOf(groupId);
    if (index < 0 && checked) {
      updatedChecked.push(groupId);
    } else if (index >= 0 && !checked) {
      updatedChecked.splice(index, 1);
    }
    if (this.props.onChange)
      this.props.onChange(updatedChecked);
  }

  render(): ReactNode {
    if (!this.props.organization || !this.state.plansResponse ||
        !this.props.accessControlGroups)
      return <SwychedSpinner busy={1}/>;

    return this.getGroupList().map((group) => {
      return (
        <label key={group.id}><input type="checkbox" 
          className="form-check-input"
          defaultChecked={this.isGroupChecked(group)}
          onChange={(event) => {
            this.setGroupChecked(group.id, event.target.checked);
          }}/>
          {group.name}
        </label>
      );
    });
  }
}