import * as React from 'react';
import { PureComponent, ReactNode } from "react";

interface CHAdeMOIconProps {
  fill?: string;
  hoverFill?: string;
  width: string;
  height: string;
}

export class CHAdeMOIcon extends PureComponent<CHAdeMOIconProps, {}> {
  render(): ReactNode {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.0004 4C10.2682 4 4 10.2682 4 18.0004C4 25.7327 10.2682 32.0009 18.0004 32.0009C25.7327 32.0009 32.0009 25.7327 32.0009 18.0004C32.0009 10.2682 25.7327 4 18.0004 4ZM3 18.0004C3 9.71592 9.71592 3 18.0004 3C26.2849 3 33.0009 9.71592 33.0009 18.0004C33.0009 26.2849 26.2849 33.0009 18.0004 33.0009C9.71592 33.0009 3 26.2849 3 18.0004ZM10.5 21.5C12.433 21.5 14 19.933 14 18C14 16.067 12.433 14.5 10.5 14.5C8.567 14.5 7 16.067 7 18C7 19.933 8.567 21.5 10.5 21.5ZM10.5 22.5C12.9853 22.5 15 20.4853 15 18C15 15.5147 12.9853 13.5 10.5 13.5C8.01472 13.5 6 15.5147 6 18C6 20.4853 8.01472 22.5 10.5 22.5ZM29 18C29 19.933 27.433 21.5 25.5 21.5C23.567 21.5 22 19.933 22 18C22 16.067 23.567 14.5 25.5 14.5C27.433 14.5 29 16.067 29 18ZM30 18C30 20.4853 27.9853 22.5 25.5 22.5C23.0147 22.5 21 20.4853 21 18C21 15.5147 23.0147 13.5 25.5 13.5C27.9853 13.5 30 15.5147 30 18ZM12 18C12 18.8284 11.3284 19.5 10.5 19.5C9.67157 19.5 9 18.8284 9 18C9 17.1716 9.67157 16.5 10.5 16.5C11.3284 16.5 12 17.1716 12 18ZM25.5 19.5C26.3284 19.5 27 18.8284 27 18C27 17.1716 26.3284 16.5 25.5 16.5C24.6716 16.5 24 17.1716 24 18C24 18.8284 24.6716 19.5 25.5 19.5ZM17.3097 9.65686L14.8349 7.18199L15.542 6.47488L18.0169 8.94975L20.4917 6.47489L21.1988 7.18199L18.724 9.65686L21.1988 12.1317L20.4917 12.8388L18.0169 10.364L15.542 12.8388L14.8349 12.1317L17.3097 9.65686ZM17.3097 26.34L14.8349 23.8652L15.542 23.158L18.0169 25.6329L20.4917 23.1581L21.1988 23.8652L18.724 26.34L21.1988 28.8149L20.4917 29.522L18.0169 27.0471L15.542 29.522L14.8349 28.8149L17.3097 26.34Z"
          fill={this.props.fill || "#C1C5C8"}/>
      </svg>
    );
  }
}